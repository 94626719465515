import {
  CITIES_STATUS_FETCH,
  CITIES_STATUS_FETCH_SUCCESS,
  CITIES_STATUS_FETCH_ERROR,
  CITIES_STATUS_BY_COUNTRY_ID,
  CITIES_STATUS_BY_COUNTRY_ID_SUCCESS,
  CITIES_STATUS_BY_COUNTRY_ID_ERROR,
  CITIES_STATUS_AND_LOCATIONS,
  CITIES_STATUS_AND_LOCATIONS_SUCCESS,
  CITIES_STATUS_AND_LOCATIONS_ERROR
} from '../constants/ActionTypes'

export const doFetchCities = payload => ({ type: CITIES_STATUS_FETCH, payload })
export const doFetchCitiesSuccess = payload => ({ type: CITIES_STATUS_FETCH_SUCCESS, payload })
export const doFetchCitiesError = payload => ({ type: CITIES_STATUS_FETCH_ERROR, payload })

export const doFetchCitiesByCountryId = payload => ({ type: CITIES_STATUS_BY_COUNTRY_ID, payload })
export const doFetchCitiesByCountryIdSuccess = payload => ({ type: CITIES_STATUS_BY_COUNTRY_ID_SUCCESS, payload })
export const doFetchCitiesByCountryIdError = payload => ({ type: CITIES_STATUS_BY_COUNTRY_ID_ERROR, payload })

export const doFetchCitiesAndLocations = payload => ({ type: CITIES_STATUS_AND_LOCATIONS, payload })
export const doFetchCitiesAndLocationsSuccess = payload => ({ type: CITIES_STATUS_AND_LOCATIONS_SUCCESS, payload })
export const doFetchCitiesAndLocationsError = payload => ({ type: CITIES_STATUS_AND_LOCATIONS_ERROR, payload })
