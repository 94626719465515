import { sidebarWidth } from './../../components/sidebar/Sidebar'
export const dashboardStyles = {
  content: {
    padding: '90px 20px 20px 20px',
    marginLeft: sidebarWidth,
    width: '100%' - sidebarWidth
  },
  textInfo: {
    marginLeft: '10px'
  }
}
