import { sidebarWidth } from './../../components/sidebar/Sidebar'

export const kioskByLocationStyles = {
  content: {
    marginLeft: sidebarWidth,
    width: '100%' - sidebarWidth,
    paddingTop: '60px'
  },
  paper: {
    padding: '10px'
  },
  statusValue: {
    fontSize: '17px',
    color: '#3f3f3f'
  },
  titleCard: {
    color: '#3f3f3f',
    fontWeight: 'bold',
    fontSize: '19px'
  },
  subtitleCard: {
    color: '#3f3f3f',
    fontWeight: 'bold',
    fontSize: '17px'
  },
  mediumFont: {
    color: '#3f3f3f',
    fontWeight: 'bold',
    fontSize: '15px'
  },
  tableHeader: {
    color: '#3f3f3f',
    fontWeight: 'bold',
    fontSize: '17px',
    height: '50px',
    padding: '10px'
  },
  chooseModule: {
    width: '250px'
  },
  kioskGrid: {
    marginTop: '10px'
  },
  iconsContainer: {
    width: '80px'
  },
  iconAction: {
    color: '#8c8c8c',
    cursor: 'pointer'
  },
  locationIcon: {
    color: '#EFA817',
    marginRight: '10px'
  },
  kioskInfo: {
    margin: '20px',
    padding: '0 120px'
  }
}
