import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'

const styles = () => ({
  container: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1200,
    '&:before': {
      position: 'fixed',
      top: 0,
      left: 0,
      height: '100vh',
      width: '100vw',
      backgroundColor: 'var(--var-color-primary-grey)',
      opacity: 0.9,
      content: '""'
    }
  },
  colorPrimary: {
    color: '#efa817'
  },
  content: {
    zIndex: 1
  }
})

function CircularLoading(props) {
  const { classes, isLoading = false } = props
  return (
    <div
      className={classes.container}
      style={{ display: isLoading ? 'flex' : 'none' }}
    >
      <Typography variant="subtitle1" className={classes.content}>
        Cargando interfaz, ten un poco de paciencia este proceso tardará unos
        segundos.
      </Typography>
      <Typography variant="h6" className={classes.content}>
        - Equipo Kiosk -
      </Typography>
      <CircularProgress
        classes={{
          colorPrimary: classes.colorPrimary
        }}
      />
    </div>
  )
}

CircularLoading.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(CircularLoading)
