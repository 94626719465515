import React from 'react'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import BannerCell from '../BannerCell/BannerCell'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography/Typography'
import SelectLocation from './SelectLocation'
import Radio from '@material-ui/core/Radio'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { Select, MenuItem } from '@material-ui/core'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import TextField from '@material-ui/core/TextField'
import EditIcon from '@material-ui/icons/Edit'
import { PROMOTION_SECTION } from '../../constants/Constants'

import './bannerdetail.sass'

const BannerDetailDialog = (props) => {
  const {
    screenType,
    cities,
    isEdit,
    banner,
    section,
    screenValue,
    onSave
  } = props
  const [open, setOpen] = React.useState(false)
  const [video, setVideo] = React.useState('image')
  const [bannerResolution, setBannerResolution] = React.useState('')
  const [url, setUrl] = React.useState('')
  const [corporateId, setCorporateId] = React.useState('')
  const [callToAction, setCallToAction] = React.useState('Peliculas')
  const [pickedFile, setpickedFile] = React.useState(null)
  const idsSelected = []
  const validateVideo = video === 'video'
  const hiddenCallToAction = true
  const validateSaveButto = validateVideo
    ? idsSelected.length === 0 && url === ''
    : idsSelected.length === 0 && pickedFile === null

  const returnItemKey = (index) => {
    return index + Math.random()
  }
  const handleClose = () => {
    setOpen(false)
  }
  const handleVideo = (event) => {
    setVideo(event.target.value)
  }
  const handleCallToAction = (value) => {
    setCallToAction(value)
  }
  const handleChangeUrl = (value) => {
    setUrl(value)
  }
  const handleCorporateId = (value) => {
    setCorporateId(value)
  }
  const onPickFile = (file, image) => {
    setpickedFile({ file: file, image: image.src })
  }
  const onBanner = () => {
    if (banner) {
      if (banner.video_url) {
        setVideo('video')
        setUrl(banner.video_url)
      } else setpickedFile({ image: banner.image_url })
    }
  }

  const handleLocations = (value) => {
    const citySelected = idsSelected.find((city) => city.index === value.index)
    if (citySelected) {
      const indexOf = idsSelected.indexOf(citySelected)
      idsSelected[indexOf] = value
    } else idsSelected.push(value)
  }

  const saveBanner = () => {
    const body = {
      params: {
        screen_type: screenValue,
        section: section,
        text: ''
      },
      scopes: {
        city_ids: [],
        location_ids: [],
        kiosk_ids: []
      }
    }
    if (banner) body.params.banner_id = banner.id
    idsSelected.forEach((city) => {
      if (city.id !== 0) body.scopes.city_ids.push(city.id)
      else if (city.location_ids.length > 0 || city.kiosk_ids.length > 0) {
        const selectedCity = cities[city.index]
        city.location_ids.forEach((locationId) => {
          body.scopes.location_ids.push(locationId)
          const location = selectedCity.locations.find(
            (location) => location.id === locationId
          )
          if (location) {
            location.areas.forEach((area) => {
              area.kiosks.forEach((kiosk) => {
                const selectedKiosk = city.kiosk_ids.indexOf(kiosk.id)
                if (selectedKiosk !== -1)
                  city.kiosk_ids.splice(selectedKiosk, 1)
              })
            })
          }
        })
        city.kiosk_ids.forEach((kioskId) => {
          if (!body.scopes.kiosk_ids.includes(kioskId))
            body.scopes.kiosk_ids.push(kioskId)
        })
      }
    })
    if (!validateVideo && pickedFile.file) body.params.image = pickedFile.file
    else {
      body.params.text = '----'
      body.params.video_url = url
    }
    body.scopes.location_ids = Array.from(new Set(body.scopes.location_ids))
    body.scopes.kiosk_ids = Array.from(new Set(body.scopes.kiosk_ids))
    onSave(body)
  }

  const callToActions = ['Peliculas', 'Confiteria'].map((label) => {
    return (
      <MenuItem value={label} key={label}>
        {label}
      </MenuItem>
    )
  })

  function dimesions() {
    switch (screenType) {
      case 'RP9':
        if (section === PROMOTION_SECTION.HOMESCREEN)
          setBannerResolution('1366 x 768')
        else setBannerResolution('400 x 768')
        break
      case 'RP7':
        if (section === PROMOTION_SECTION.HOMESCREEN)
          setBannerResolution('1280 x 1024')
        else setBannerResolution('1280 x 400')
        break
      default:
        if (section === PROMOTION_SECTION.HOMESCREEN)
          setBannerResolution('1080 x 1920')
        else setBannerResolution('450 x 1080')
        break
    }
  }

  const handleOpen = () => {
    dimesions()
    onBanner()
    setOpen(true)
  }

  const AddButton = () => {
    if (!isEdit) {
      return (
        <Button
          className="add_banner_button"
          variant="contained"
          onClick={handleOpen}
          color="default"
        >
          AGREGAR <AddIcon />
        </Button>
      )
    } else {
      return (
        <IconButton onClick={handleOpen}>
          {' '}
          <EditIcon />{' '}
        </IconButton>
      )
    }
  }

  const DialogTitleLabel = () => {
    return (
      <MuiDialogTitle disableTypography className="dialog_title">
        <Typography variant="h6"></Typography>
        {handleClose ? (
          <IconButton
            className="button_close"
            aria-label="close"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    )
  }

  const AddImage = () => {
    if (!validateVideo)
      return (
        <BannerCell pickedFile={pickedFile} onPickFile={onPickFile}>
          {' '}
        </BannerCell>
      )
    else
      return (
        <TextField
          required
          label="Url video YouTube"
          variant="outlined"
          className="url_youtube"
          onChange={(event) => handleChangeUrl(event.target.value)}
          defaultValue={url}
          fullWidth
        />
      )
  }

  const TextAddIMage = () => {
    if (!validateVideo) {
      return (
        <div className="text_container">
          <div className="text_container">
            <Typography color="textSecondary"> Dimesiones Recomendadas: </Typography>
            <Typography> {bannerResolution} </Typography>
          </div>
          <div className="text_container">
            <Typography color="textSecondary">
              {' '}
              Formatos de imagen validos:{' '}
            </Typography>
            <Typography> pgn, jpg, jpeg peso maximo 2 MB </Typography>
          </div>
        </div>
      )
    } else return <Typography></Typography>
  }

  const CallToActionImage = () => {
    if (!hiddenCallToAction) {
      return (
        <div className="call_to_action">
          <Select
            fullWidth
            label="Call to action"
            input={<OutlinedInput name="selectedCity" id="city-select" />}
            value={callToAction}
            onChange={(event) => handleCallToAction(event.target.value)}
          >
            {callToActions}
          </Select>
          <TextField
            required
            label="Id"
            variant="outlined"
            onChange={(event) => handleCorporateId(event.target.value)}
            defaultValue={corporateId}
            fullWidth
          />
        </div>
      )
    } else return <Typography></Typography>
  }

  const ExpansionsCity = () => {
    return cities.map((city, index) => {
      return (
        <div key={returnItemKey(city.id)} className="expansion_panels">
          <SelectLocation
            screenValue={screenValue}
            index={index}
            banner={banner}
            saveLocations={handleLocations}
            city={city}
          />
        </div>
      )
    })
  }

  const RadioButtons = () => {
    if (isEdit) return <Typography></Typography>
    else {
      return (
        <RadioGroup
          className="radio_container"
          value={video}
          onChange={handleVideo}
        >
          <FormControlLabel
            value="image"
            control={<Radio className="radio" />}
            label="Imagen"
          />
          {
            // <FormControlLabel
            // value="video"
            // control={<Radio className="radio" />}
            // label="Video"
            // />
            ''
          }
        </RadioGroup>
      )
    }
  }

  return (
    <div>
      <div className="banner_button">
        <AddButton />
      </div>
      <Dialog
        fullScreen={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <div className="container">
          <DialogTitleLabel></DialogTitleLabel>
          <div className="title_container">
            <Typography> AGREGAR BANNER </Typography>
            <Typography color="textSecondary">
              Seleccione el tipo de archivo que desea agregar
            </Typography>
          </div>
          <RadioButtons />
          <AddImage />
          <TextAddIMage />
          <CallToActionImage />
          <ExpansionsCity />
          <Button
            disabled={validateSaveButto}
            onClick={saveBanner}
            className="save_button"
          >
            GUARDAR
          </Button>
        </div>
      </Dialog>
    </div>
  )
}

export default BannerDetailDialog
