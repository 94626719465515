/* eslint-disable camelcase */
// Dependences
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { ValidatorForm } from 'react-material-ui-form-validator'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography/Typography'
import CircularLoading from '../Loading/CircularLoading'

import { SETTINGS_DATA_TYPES } from '../../constants/Constants'

import './settingsconfig.sass'
import { settingsConfigStyles } from './settingsconfig.styles'

class SettingsConfig extends Component {
  constructor() {
    super()
    this.state = {
      kiosksStatusRefresh: {
        value: null,
        setting: {
          name: 'kiosks_status_refresh',
          data_type: SETTINGS_DATA_TYPES.NUMBER
        }
      },
      billboardRefresh: {
        value: null,
        setting: {
          name: 'billboard_refresh',
          data_type: SETTINGS_DATA_TYPES.NUMBER
        }
      },
      localesRefresh: {
        value: null,
        setting: {
          name: 'locales_refresh',
          data_type: SETTINGS_DATA_TYPES.NUMBER
        }
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.settings || this.props.settings) {
      const { settings } = nextProps || this.props
      this.setInitialSettings(settings)
    }
  }

  setInitialSettings = (settings) => {
    var kiosksStatusRefresh, billboardRefresh, localesRefresh
    if (settings) {
      settings.forEach((setting) => {
        if (setting.name === 'billboard_refresh') {
          billboardRefresh = setting
        } else if (setting.name === 'locales_refresh') {
          localesRefresh = setting
        } else if (setting.name === 'kiosks_status_refresh') {
          kiosksStatusRefresh = setting
        }
      })
      this.setState({
        kiosksStatusRefresh:
          kiosksStatusRefresh !== undefined
            ? kiosksStatusRefresh
            : this.state.kiosksStatusRefresh,
        billboardRefresh:
          billboardRefresh !== undefined
            ? billboardRefresh
            : this.state.billboardRefresh,
        localesRefresh:
          localesRefresh !== undefined
            ? localesRefresh
            : this.state.localesRefresh
      })
    }
  }

  onChange = (event) => {
    const { name, value } = event.target
    this.setState({
      [name]: { ...this.state[name], value: value }
    })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const { kiosksStatusRefresh, billboardRefresh, localesRefresh } = this.state
    const settings = [kiosksStatusRefresh, billboardRefresh, localesRefresh]
    this.props.onSetSettings(settings)
  }

  render() {
    const { kiosksStatusRefresh, billboardRefresh, localesRefresh } = this.state
    const { loading, classes, headerText } = this.props
    return (
      <>
        <div className={classes.container}>
          <Paper className={classes.paper}>
            <div className={classes.containerGroup}>
              <Typography variant="h6">{headerText}</Typography>
              <div>
                <br />
                <ValidatorForm
                  ref="form"
                  onSubmit={this.handleSubmit}
                  onError={(errors) => console.log(errors)}
                >
                  <div className="form-group">
                    <small className="form-input-subtitle">
                      Consulta estado de kiosco cada
                    </small>
                    <FormControl
                      variant="filled"
                      id="settings-select-log-status"
                      className={classes.selectConfig}
                    >
                      <Select
                        input={
                          <OutlinedInput
                            labelWidth={0}
                            name="kiosksStatusRefresh"
                            value={kiosksStatusRefresh.value || ''}
                            onChange={this.onChange}
                          />
                        }
                      >
                        <MenuItem value={5}>
                          <em>5 minutos</em>
                        </MenuItem>
                        <MenuItem value={60}>1 hora</MenuItem>
                        <MenuItem value={360}>6 horas</MenuItem>
                        <MenuItem value={720}>12 horas</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="form-group">
                    <small className="form-input-subtitle">
                      Actualizar cartelera cada
                    </small>
                    <FormControl
                      variant="filled"
                      className={classes.selectConfig}
                    >
                      <Select
                        input={
                          <OutlinedInput
                            labelWidth={0}
                            name="billboardRefresh"
                            value={billboardRefresh.value || ''}
                            onChange={this.onChange}
                          />
                        }
                      >
                        <MenuItem value={5}>
                          <em>5 minutos</em>
                        </MenuItem>
                        <MenuItem value={30}>
                          <em>30 minutos</em>
                        </MenuItem>
                        <MenuItem value={60}>1 hora</MenuItem>
                        <MenuItem value={360}>6 horas</MenuItem>
                        <MenuItem value={720}>12 horas</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="form-group">
                    <small className="form-input-subtitle">
                      Actualizar locales cada
                    </small>
                    <FormControl
                      variant="filled"
                      className={classes.selectConfig}
                    >
                      <Select
                        input={
                          <OutlinedInput
                            labelWidth={0}
                            name="localesRefresh"
                            id="filled-age-simple"
                            value={localesRefresh.value || ''}
                            onChange={this.onChange}
                          />
                        }
                      >
                        <MenuItem value={5}>
                          <em>5 minutos</em>
                        </MenuItem>
                        <MenuItem value={30}>
                          <em>30 minutos</em>
                        </MenuItem>
                        <MenuItem value={60}>1 hora</MenuItem>
                        <MenuItem value={360}>6 horas</MenuItem>
                        <MenuItem value={720}>12 horas</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="form-button-container">
                    <Button
                      id="settings-button-save-config"
                      type="submit"
                      color="primary"
                      autoFocus
                      className={classes.btnLink}
                      fullWidth={false}
                    >
                      GUARDAR
                    </Button>
                  </div>
                </ValidatorForm>
              </div>
            </div>
          </Paper>
        </div>
        <CircularLoading isLoading={loading} />
      </>
    )
  }
}

export default withStyles(settingsConfigStyles)(SettingsConfig)
