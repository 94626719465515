import React from 'react'

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography/Typography'
import TextField from '@material-ui/core/TextField'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { settingsConfigStyles } from '../SettingsConfig/settingsconfig.styles'
import { withStyles, Button, InputAdornment, IconButton, OutlinedInput } from '@material-ui/core'
import { SETTINGS_DATA_TYPES } from '../../constants/Constants'
import { filterApiToken } from '../../utils/utils'
import './countrysetting.sass'

function CountrySettings(props) {
  const { settings, onSaveCountrySettings } = props

  const [moneyType, setMoneyType] = React.useState({
    value: '',
    setting: { name: 'money_type', data_type: SETTINGS_DATA_TYPES.STRING }
  })
  const [apiToken, setApiToken] = React.useState({
    value: '',
    setting: {
      name: 'connect_api_token',
      data_type: SETTINGS_DATA_TYPES.STRING
    }
  })
  const [clientId, setClientId] = React.useState({
    value: '',
    setting: { name: 'client_id', data_type: SETTINGS_DATA_TYPES.STRING }
  })

  const [showApiToken, setShowApiToken] = React.useState(false)

  React.useEffect(() => {
    if (settings) {
      const moneytype = settings.find(
        (setting) => setting.name === 'money_type'
      )
      const clientid = settings.find((setting) => setting.name === 'client_id')
      const apitoken = settings.find(
        (setting) => setting.name === 'connect_api_token'
      )
      if (moneytype) {
        setMoneyType(moneytype)
      }
      if (clientid) {
        setClientId(clientid)
      }
      if (apitoken) {
        setApiToken(apitoken)
      }
    }
  }, [settings])

  function onSave(event) {
    if (event) {
      const settings = [moneyType, clientId, apiToken]
      onSaveCountrySettings(settings)
    }
  }

  const onChangeApiToken = (value) => {
    setApiToken({ ...apiToken, value: value })
  }
  const onChangeClientId = (value) => {
    setClientId({ ...clientId, value: value })
  }
  const onChangeMoneyType = (value) => {
    setMoneyType({ ...moneyType, value: value })
  }

  const handleChangeShowApiToken = () => {
    setShowApiToken(!showApiToken)
  }

  return (
    <Paper className="container">
      <Typography variant="h6">CONFIGURACIONES GENERALES</Typography>
      <div className="settings_container">
        <div>
          <Typography variant="subtitle2">Tipo de moneda</Typography>
          <TextField
            className="field"
            variant="outlined"
            onChange={(event) => onChangeMoneyType(event.target.value)}
            name="moneyType"
            value={moneyType.value || ''}
            validators={['required']}
            errorMessages={['No enviar vacío']}
          />
        </div>
        <div>
          <Typography variant="subtitle2">Client id</Typography>
          <TextField
            className="field"
            variant="outlined"
            onChange={(event) => onChangeClientId(event.target.value)}
            name="clientId"
            validators={['required']}
            errorMessages={['No enviar vacío']}
            value={clientId.value || ''}
          />
        </div>
        <div>
          <Typography variant="subtitle2">Api token</Typography>
          <OutlinedInput
            type="text"
            className="field"
            validators={['required']}
            errorMessages={['No enviar vacío']}
            disabled={!showApiToken}
            value={showApiToken ? apiToken.value : filterApiToken(apiToken.value) || ''}
            onChange={(event) => onChangeApiToken(event.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleChangeShowApiToken}
                >
                  {showApiToken ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </div>
        <Button className="save_country_settings" onClick={onSave}>
          GUARDAR
        </Button>
      </div>
    </Paper>
  )
}

export default withStyles(settingsConfigStyles)(CountrySettings)
