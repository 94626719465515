import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const useStyles = makeStyles({
  dialogButton: {
    color: 'var(--var-color-primary-yellow)'
  },
  deleteIcon: {
    padding: '0 !important',
    color: 'var(--var-color-primary-black)'
  },
  container: {
    display: 'inline-flex'
  }
})

export default function ConfirmDialog(props) {
  const { title, onDelete } = props
  const [open, setOpen] = React.useState(false)
  const classes = useStyles()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div className={classes.container}>
      <IconButton onClick={handleClickOpen} className={classes.deleteIcon}>
        <DeleteIcon />
      </IconButton>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Confirmar</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {title}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className={classes.dialogButton}>
            CANCELAR
          </Button>
          <Button onClick={onDelete} className={classes.dialogButton}>
            ACEPTAR
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
