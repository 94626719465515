export default function FilterRolePermissions(resource, action, rol) {
  try {
    if (rol.permission.length > 0) {
      const builtInPermission = rol.permission.find(rolePermission => rolePermission.resource === '*')
      if (builtInPermission) return true
      const permission = rol.permission.find(rolePermission => rolePermission.resource === resource)
      if (permission) {
        if (permission.action === '*') return true
        else return permission.action === action
      } else return false
    } else return rol.built_in
  } catch (error) {
    console.log(error)
    return false
  }
}