import ApiClientSingleton from '../api-client'

const kioskApiInstance = ApiClientSingleton.getKioskApiInstance()

const fetchSettings = async (payload) => {
  const { params, user } = payload
  const uri = '/api/v1/countries/show_settings'
  const headers = { Authorization: `Bearer ${user}` }
  const { data } = await kioskApiInstance.get(uri, { params, headers })
  return data
}

const createSettings = async (payload) => {
  const { params, user } = payload
  const uri = '/api/v1/countries/create_settings'
  const headers = { Authorization: `Bearer ${user}` }
  const { data } = await kioskApiInstance.post(uri, params, { headers })
  return data
}

const updateSettings = async (payload) => {
  const { user } = payload
  const uri = '/api/v1/countries/update_settings'
  const headers = { Authorization: `Bearer ${user}` }
  const body = { settings: payload.settings }
  const { status } = await kioskApiInstance.put(uri, body, { headers })
  return status
}

export { fetchSettings, createSettings, updateSettings }
