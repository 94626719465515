
// Dependences
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { doFetchKioskTransactions } from '../../actions/kiosk'
import { getLoginToken } from '../../selectors/user'
import { IN_SERVICE, OUT_OF_SERVICE, WARNING } from '../../constants/Constants'

// Styles
import { kioskListStyles } from './kiosklist.styles'
import './kiosklist.sass'

// Custom components
import Header from '../../components/header/Header'
import Sidebar from '../../components/sidebar/Sidebar'
import KioskListRow from '../../components/KioskListRow/KioskListRow'

// UI Components
import { withStyles, Input, InputAdornment, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core'
import TablePagination from '@material-ui/core/TablePagination'
import Search from '@material-ui/icons/Search'

class KioskList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      query: '',
      rowsPerPage: 10,
      currentPage: 0
    }
  }

  onShowKioskDetail = (kiosk) => {
    const { country, city, location } = this.props.location.state.data
    this.props.history.push('/kiosk-detail', { data: { location: location, city: city, country: country, kiosk: kiosk } })
  }

  onChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value })
  }

  onChangePage = (event, page) => {
    this.setState({ currentPage: page })
  }

  onChangeQuery = (event) => {
    this.setState({ query: event.target.value })
  }

  statusToString = (status) => {
    switch (status) {
      case IN_SERVICE:
        return 'En servicio'
      case OUT_OF_SERVICE:
        return 'Sin servicio'
      case WARNING:
        return 'En advertencia'
      default:
        return 'Total kioscos'
    }
  }

  render () {
    const { country, city, kiosks, searchCriteriaKey, status } = this.props.location.state.data

    let listItems
    let filteredKiosks
    if (kiosks) {
      const currentPageIndex = this.state.currentPage * this.state.rowsPerPage
      const pageEndIndex = currentPageIndex + this.state.rowsPerPage
      filteredKiosks = kiosks.filter((kiosk) => {
        if (this.state.query.length > 0) {
          if (searchCriteriaKey === 'city') {
            return kiosk.city_name.toLowerCase().includes(this.state.query.toLowerCase())
          } else if (searchCriteriaKey === 'location') {
            return kiosk.location_name.toLowerCase().includes(this.state.query)
          }
        }
        return true
      })
      listItems = filteredKiosks.slice(currentPageIndex, pageEndIndex).map((kiosk) => {
        return (<KioskListRow
          key = {kiosk.id}
          kiosk = {kiosk}
          countryName = {country.name}
          onDetail = {this.onShowKioskDetail}
        />
        )
      })
    }
    let links
    if (city) {
      links = [
        { title: 'Países',
          onClick: () => {
            this.props.history.go(-3)
          }
        },
        { title: country.name,
          onClick: () => {
            this.props.history.go(-2)
          }
        },
        { title: city.name,
          onClick: () => {
            this.props.history.goBack()
          }
        }]
    } else {
      links = [
        { title: 'Países',
          onClick: () => {
            this.props.history.go(-2)
          }
        },
        { title: country.name,
          onClick: () => {
            this.props.history.goBack()
          }
        }]
    }

    return (
      <div className="page-transactions">
        <Header links={links} currentPage={this.statusToString(status)}/>
        <Sidebar level={1} country={country}/>
        <div className={this.props.classes.content}>
          <Input className="find-field"
            placeholder={`Buscar por nombre de ${searchCriteriaKey === 'city' ? `ciudad` : `teatro`}`}
            value={this.state.query}
            onChange={this.onChangeQuery}
            disableUnderline={true} startAdornment={
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            }/>
          <div className={this.props.classes.tableTransactionsContainer}>
            <Table padding='dense' className={this.props.classes.tableTransactions}>
              <TableHead>
                <TableRow>
                  <TableCell>Ciudad</TableCell>
                  <TableCell>Teatro</TableCell>
                  <TableCell>Kioscos</TableCell>
                  <TableCell>Tipo</TableCell>
                  <TableCell>Área</TableCell>
                  <TableCell>Estado</TableCell>
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
              </TableHead>
              {<TableBody>{listItems}</TableBody>}
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={(filteredKiosks && filteredKiosks.length) || 0}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.currentPage}
              onChangePage={this.onChangePage}
              onChangeRowsPerPage={this.onChangeRowsPerPage}
              labelRowsPerPage='Filas por página'
              backIconButtonProps={{
                'aria-label': 'Anterior'
              }}
              nextIconButtonProps={{
                'aria-label': 'Siguiente'
              }}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: getLoginToken(state),
    kiosk: state.kiosk
  }
}

const mapDispatchToProps = {
  doFetchKioskTransactions
}

export default withStyles(kioskListStyles)(connect(mapStateToProps, mapDispatchToProps)(KioskList))
