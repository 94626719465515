import { AREA_CREATE, AREA_CREATE_SUCCESS, AREA_CREATE_ERROR, CLEAR_REQUEST_STATUS, FETCH_AREAS, FETCH_AREAS_SUCCESS, FETCH_AREAS_ERROR, DELETE_AREA_ERROR, DELETE_AREA_SUCCESS, DELETE_AREA, UPDATE_AREA_ERROR, UPDATE_AREA_SUCCESS, UPDATE_AREA } from '../constants/ActionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case AREA_CREATE:
      return {
        ...state,
        loading: true,
        area: action.payload
      }
    case AREA_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        area: action.payload,
        success: true,
        message: 'Area creada exitosamente'
      }
    case AREA_CREATE_ERROR:
      return {
        ...state,
        loading: false,
        area: action.payload,
        error: true,
        message: 'Error al intentar crear área'
      }
    case FETCH_AREAS:
      return {
        ...state,
        loading: true,
      }
    case FETCH_AREAS_SUCCESS:
      return {
        ...state,
        loading: false,
        areas: action.payload
      }
    case FETCH_AREAS_ERROR:
      return {
        ...state,
        loading: false,
        areas: [],
        error: true,
        message: 'Error buscando areas'
      }
    case UPDATE_AREA:
      return {
        ...state,
        loading: true
      }
    case UPDATE_AREA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        message: 'Area editada correctamente'
      }
    case UPDATE_AREA_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: 'Error editando el area'
      }
    case DELETE_AREA:
      return {
        ...state,
        loading: true,
      }
    case DELETE_AREA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        message: 'Area eliminada correctamente'
      }
    case DELETE_AREA_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: 'Error eliminando area'
      }
    case CLEAR_REQUEST_STATUS:
      return {
        ...state,
        success: false,
        error: false,
        message: null
      }
    default:
      return state
  }
}
