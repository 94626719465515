import { USER_LOGGED_IN, COUNTRIES_STATUS_FETCH, COUNTRIES_STATUS_FETCH_SUCCESS, LOADING, CLEAR_REQUEST_STATUS, COUNTRIES_STATUS_FETCH_ERROR } from '../constants/ActionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case USER_LOGGED_IN:
      if (action.user.id === undefined) {
        return {
          ...state,
          countries: {},
          loading: false
        }
      } else {
        return state
      }
    case COUNTRIES_STATUS_FETCH:
      return action.payload
    case COUNTRIES_STATUS_FETCH_SUCCESS:
      return {
        ...state,
        countries: action.payload,
        loading: false
      }
    case COUNTRIES_STATUS_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: 'Error al obtener lista de países'
      }
    case LOADING:
      return {
        ...state,
        loading: true
      }
    case CLEAR_REQUEST_STATUS:
      return {
        ...state,
        success: false,
        error: false,
        message: null
      }
    case 'SAVE_SELECTED_COUNTRY':
      return {
        ...state,
        selectedCountry: action.payload
      }
    default:
      return state
  }
}
