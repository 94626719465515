// Dependences
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  doFetchSettings,
  doCreateSettings,
  doUpdateSettings
} from '../../actions/setting'
import {
  doFetchFormats,
  doSelectFormat,
  doUpdateFormat,
  doDeleteFormat
} from '../../actions/format'
import {
  doFetchBanners,
  doChangeBanners,
  doMarkBannerForDeletion,
  doDeleteBanner
} from '../../actions/banner'
import { doGetAllVersions, doSendForcedUpdate } from '../../actions/update'
import { doFetchCities } from '../../actions/city'
import { doFetchLocations } from '../../actions/location'
import { doCreateArea, doFetchAreas, doDeleteArea, doUpdateArea } from '../../actions/area'
import { doClearRequestStatus } from '../../actions/clearStatus'
import { doUpdateCompany } from '../../actions/company'
import { getLoginToken } from '../../selectors/user'
import { companyId } from '../../selectors/company'
import { getSuccess, getError, getMessage } from '../../selectors/settings'
import {
  getFormats,
  getDefaultVoucher,
  getCustomVouchers,
  getVoucherCategoryId
} from '../../selectors/format'

import { doCheckUserPassword } from '../../actions/user'

// Custom components
import Header from './../../components/header/Header'
import Sidebar from './../../components/sidebar/Sidebar'
import SettingsSidebar from './../../components/sidebar/settings/SettingsSidebar'
import SettingsConfig from '../../components/SettingsConfig/SettingsConfig'
import FormatsConfig from '../../components/FormatsConfig/FormatsConfig'
import ChooseLanguage from '../../components/ChooseLanguage/ChooseLanguage'
import BannersConfig from '../../components/BannersConfig/BannersConfig'
import AreaConfig from '../../components/AreaConfig/AreaConfig'
import MySnackbar from '../../components/MySnackbar/MySnackbar'
import FormatPreview from '../../components/FormatPreview/FormatPreview'
import UpdateConfig from '../../components/UpdateConfig/UpdateConfig'
import CircularLoading from '../../components/Loading/CircularLoading'

// UI Components
import { Divider } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
// import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

// Styles
import './settings.sass'
import { settingsStyles } from './settings.styles'
import SettingsByCountry from '../SettingsByCountry/SettingsByCountry'
import CountrySettings from '../../components/CountrySettings/CountrySettings'
import FilterRolePermissions from '../../utils/FilterRolePermissions'

class Settings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      settings: {},
      pickedFiles: [],
      snackbarIsOpen: false,
      generalSettingsIsOpen: true,
      bannersSettingsIsOpen: false,
      areasSettingsIsOpen: false,
      languageSettingsIsOpen: false,
      formatsIsOpen: false,
      countrySettingsIsOpen: false,
      createDialogIsOpen: false,
      newFormatName: '',
      previewingFormat: null,
      formatPreviewIsOpen: false,
      updatesIsOpen: false,
      selectedFormat: null,
      configsIsOpen: false
    }
  }

  componentDidMount() {
    if (this.props.location.state.isPromotionsOnly) this.onShowBanners()
    else if (this.props.match.params.id) this.onShowCountrySettings()
    this.fetchSettings()
    const { level, country } = this.props.location.state
    if (level !== 0 && country) {
      this.fetchFormats()
    }
    this.fetchLocations()
    this.fetchCities()
    this.getVersions()
    this.fetchBanners()
    this.fetchAreas()
  }

  componentDidUpdate() {
    if (this.props.format.success) {
      const { level, country } = this.props.location.state
      if (level !== 0 && country) {
        this.fetchFormats()
      }
    }
  }

  handleDialogOpen = () => {
    this.setState({ createDialogIsOpen: true })
  }

  handleDialogClose = () => {
    this.setState({ createDialogIsOpen: false })
  }

  hideFormatPreview = () => {
    this.setState({ formatPreviewIsOpen: false })
  }

  onShowFormatPreview = (format) => {
    this.setState({ formatPreviewIsOpen: true, previewingFormat: format })
  }

  onSetSettings = (settings) => {
    this.props.doClearRequestStatus()
    const settingsToCreate = this.settingsToCreate(settings)
    const settingsToUpdate = this.settingsToUpdate(settings)
    if (settingsToCreate.length > 0) {
      this.createSettings(settingsToCreate)
    }
    if (settingsToUpdate.length > 0) {
      this.updateSettings(settingsToUpdate)
    }
  }

  settingsToCreate = (settings) => {
    return settings
      .filter((setting) => setting.id === undefined && setting.value !== null)
      .map((setting) => {
        return {
          name: setting.setting.name,
          value: `${setting.value}`,
          data_type: setting.setting.data_type
        }
      })
  }

  settingsToUpdate = (settings) => {
    return settings
      .filter((setting) => setting.id !== undefined)
      .map((setting) => {
        return { id: setting.id, value: `${setting.value}` }
      })
  }

  fetchSettings = () => {
    const params = this.paramsForRequest()
    this.props.doFetchSettings({
      data: { params: params, user: this.props.user }
    })
  }

  fetchFormats = () => {
    const countryId = this.props.location.state.country.id
    const params = { country_id: countryId, company_id: this.props.companyId }
    this.props.doFetchFormats({
      data: { params: params, user: this.props.user }
    })
  }

  createSettings = (settings) => {
    var params = this.paramsForRequest()
    params.settings = settings
    this.props.doCreateSettings({
      data: { params: params, user: this.props.user }
    })
  }

  updateSettings = (settings) => {
    var params = this.paramsForRequest()
    this.props.doUpdateSettings({
      data: { params: params, settings: settings, user: this.props.user }
    })
  }

  fetchLocations = () => {
    const countryId = this.props.location.state.country.id
    this.props.doFetchLocations({
      data: {
        countryId: countryId,
        user: this.props.user,
        companyId: this.props.companyId
      }
    })
  }

  fetchCities = () => {
    const { user, companyId, location } = this.props
    this.props.doFetchCities({
      data: {
        countryId: location.state.country.id,
        user: user,
        companyId: companyId
      }
    })
  }

  fetchBanners = () => {
    const params = this.paramsForRequest()
    params.country_id = this.props.location.state.country.id
    this.props.doFetchBanners({
      data: { params: params, user: this.props.user }
    })
  }

  fetchAreas = () => {
    this.props.doFetchAreas({
      data: {user: this.props.user, companyId: this.props.companyId}
    })
  }

  getVersions = () => {
    // const { companyId } = this.props
    this.props.doGetAllVersions({
      data: {
        company_id: '1',
        app_id: '1'
      }
    })
  }

  paramsForRequest = () => {
    const params = {}
    params.company_id = this.props.companyId
    if (this.props.location.state.level !== 0) {
      if (this.props.location.state.kiosk) {
        params.kiosk_id = this.props.location.state.kiosk.id
      } else if (this.props.location.state.location) {
        params.location_id = this.props.location.state.location.id
      } else if (this.props.location.state.country) {
        params.country_id = this.props.location.state.country.id
      }
    }
    return params
  }

  changeBanners = (body) => {
    const countryId = this.props.location.state.country.id
    body.params.country_id = countryId
    this.props.doChangeBanners({
      data: {
        params: body.params,
        scopes: body.scopes,
        company_id: this.props.companyId,
        user: this.props.user
      }
    })
  }

  onCreateArea = (area) => {
    this.props.doClearRequestStatus()
    this.props.doCreateArea({
      data: {
        area,
        user: this.props.user,
        companyId: this.props.companyId 
      }
    })
  }

  onUpdateArea = (area) => {
    this.props.doClearRequestStatus()
    this.props.doUpdateArea({
      data: {
        area,
        user: this.props.user,
        companyId: this.props.companyId 
      }
    })
  }

  onDeleteArea = (areaId) => {
    this.props.doClearRequestStatus()
    this.props.doDeleteArea({
      areaId,
      user: this.props.user,
      companyId: this.props.companyId 
    })
  }

  onPickFile = (file, image, section, index, id) => {
    this.setState((state) => {
      const pickedFiles = [
        ...state.pickedFiles,
        { file: file, image: image.src, section: section, index: index, id: id }
      ]
      return {
        pickedFiles
      }
    })
  }

  deleteBanner = (id) => {
    const countryId = this.props.location.state.country.id
    this.props.doDeleteBanner({
      data: {
        country_id: countryId,
        id: id,
        company_id: this.props.companyId,
        user: this.props.user
      }
    })
  }

  onSaveBannerChanges = (body) => {
    this.changeBanners(body)
  }

  handleChange = (event) => {
    this.setState({ selectedModule: event.target.value })
  }

  selectedModule = () => {
    if (!this.state.selectedModule) {
      return this.props.locale.locales[0]
    } else {
      const selectedModule = this.props.locale.locales.filter((module) => {
        return module.id === this.state.selectedModule
      })[0]
      return selectedModule
    }
  }

  handleCloseSnackBar = () => {
    this.props.doClearRequestStatus()
  }

  onChangeFormatName = (event) => {
    this.setState({ newFormatName: event.target.value })
  }

  onNewFormat = () => {
    this.setState({ selectedFormat: null })
    this.handleDialogOpen()
  }

  onEditFormatName = (format) => {
    this.handleDialogOpen()
    this.setState({ selectedFormat: format })
  }

  onUpdateFormatName = () => {
    const params = {
      name: this.state.newFormatName,
      content: this.state.selectedFormat.content,
      description: this.state.selectedFormat.description
    }
    this.props.doUpdateFormat({
      data: {
        id: this.state.selectedFormat.id,
        params: params,
        user: this.props.user
      }
    })
    this.handleDialogClose()
  }

  goToNewFormat = () => {
    if (this.state.newFormatName !== '') {
      this.handleDialogClose()
      let format = this.props.defaultVoucher
      if (!format) {
        format = {
          printable_category_id: this.props.voucherCategoryId,
          kiosks_ids: [],
          content: '[]',
          name: 'Default voucher',
          description: 'Default voucher'
        }
      } else {
        format.name = this.state.newFormatName
        format.description = this.state.newFormatName
      }
      format.id = null
      this.props.doSelectFormat({ format: format })
      const { country, aLocation } = this.props.location.state
      this.props.history.push('/formats/new-format', {
        country: country,
        aLocation: aLocation
      })
    }
  }

  onEditFormat = (format) => {
    this.props.doSelectFormat({ format: format })
    const { country, aLocation } = this.props.location.state
    this.props.history.push('/formats/new-format', {
      country: country,
      aLocation: aLocation
    })
  }

  onDeleteFormat = (format) => {
    const countryId = this.props.location.state.country.id
    const params = { country_id: countryId, company_id: this.props.companyId }
    this.props.doDeleteFormat({
      data: { params: params, user: this.props.user, id: format.id }
    })
  }

  onShowGeneralSettings = () => {
    this.setState({
      generalSettingsIsOpen: true,
      bannersSettingsIsOpen: false,
      areasSettingsIsOpen: false,
      countrySettingsIsOpen: false,
      languageSettingsIsOpen: false,
      formatsIsOpen: false,
      updatesIsOpen: false,
      configsIsOpen: false
    })
    setTimeout(() => {
      this.props.doClearRequestStatus()
    }, 5)
  }

  onShowBanners = () => {
    this.setState({
      bannersSettingsIsOpen: true,
      generalSettingsIsOpen: false,
      areasSettingsIsOpen: false,
      countrySettingsIsOpen: false,
      languageSettingsIsOpen: false,
      formatsIsOpen: false,
      updatesIsOpen: false,
      configsIsOpen: false
    })
    this.fixScrollView()
  }

  onShowAreas = () => {
    this.setState({
      areasSettingsIsOpen: true,
      generalSettingsIsOpen: false,
      bannersSettingsIsOpen: false,
      countrySettingsIsOpen: false,
      languageSettingsIsOpen: false,
      formatsIsOpen: false,
      updatesIsOpen: false,
      configsIsOpen: false
    })
  }
  onShowUpdates = () => {
    this.setState({
      areasSettingsIsOpen: false,
      generalSettingsIsOpen: false,
      bannersSettingsIsOpen: false,
      countrySettingsIsOpen: false,
      languageSettingsIsOpen: false,
      formatsIsOpen: false,
      updatesIsOpen: true,
      configsIsOpen: false
    })
  }
  onShowConfigs = () => {
    this.setState({
      areasSettingsIsOpen: false,
      generalSettingsIsOpen: false,
      bannersSettingsIsOpen: false,
      countrySettingsIsOpen: false,
      languageSettingsIsOpen: false,
      formatsIsOpen: false,
      updatesIsOpen: false,
      configsIsOpen: true
    })
  }

  onShowLanguage = () => {
    this.setState({
      languageSettingsIsOpen: true,
      generalSettingsIsOpen: false,
      areasSettingsIsOpen: false,
      countrySettingsIsOpen: false,
      bannersSettingsIsOpen: false,
      formatsIsOpen: false,
      updatesIsOpen: false
    })
  }

  onShowFormats = () => {
    this.setState({
      areasSettingsIsOpen: false,
      generalSettingsIsOpen: false,
      bannersSettingsIsOpen: false,
      countrySettingsIsOpen: false,
      languageSettingsIsOpen: false,
      formatsIsOpen: true,
      updatesIdOpen: false
    })
  }

  onShowCountrySettings = () => {
    this.setState({
      areasSettingsIsOpen: false,
      generalSettingsIsOpen: false,
      bannersSettingsIsOpen: false,
      countrySettingsIsOpen: true,
      languageSettingsIsOpen: false,
      formatsIsOpen: false,
      updatesIsOpen: false
    })
  }

  fixScrollView = () => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 0)
  }

  render() {
    const {
      banner,
      setting,
      format,
      locations,
      area,
      location,
      success,
      error,
      city,
      message,
      classes,
      formats,
      defaultVoucher,
      customVouchers,
      userRole
    } = this.props
    const {
      generalSettingsIsOpen,
      bannersSettingsIsOpen,
      areasSettingsIsOpen,
      languageSettingsIsOpen,
      formatsIsOpen,
      countrySettingsIsOpen,
      previewingFormat,
      formatPreviewIsOpen,
      updatesIsOpen,
      configsIsOpen
    } = this.state
    // const { country, kiosk, aLocation } = location.state
    const { country, kiosk } = location.state
    var filteredSettings = setting.settings
    const isInRoot = this.props.location.state.level === 0
    if (((!country && !kiosk) || isInRoot) && setting.settings) {
      filteredSettings = setting.settings.filter((setting) => {
        return setting.country_id === null && setting.kiosk_id === null
      })
    }
    if (kiosk && setting.settings) {
      filteredSettings = setting.settings.filter((setting) => {
        return setting.kiosk_id !== null
      })
    }
    const level = location.state.level
    const openSnackbar = success || error

    const onSendUpdate = (data) => {
      console.log(data)
    }

    const requestsLoading = setting.loading || city.loading

    return (
      <>
        <div className="page-settings">
          {previewingFormat && (
            <FormatPreview
              isOpen={formatPreviewIsOpen}
              format={JSON.parse(previewingFormat.content)}
              onClose={this.hideFormatPreview}
            />
          )}
          <Dialog
            open={this.state.createDialogIsOpen}
            onClose={this.handleDialogClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              {this.state.selectedFormat
                ? 'Editar Voucher'
                : 'Crear voucher personalizado'}
            </DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="Nombre del Voucher"
                type="text"
                fullWidth
                variant="outlined"
                id="mui-theme-provider-outlined-input"
                value={this.state.newFormatName}
                onChange={this.onChangeFormatName}
                className={classes.dialogTextField}
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused
                  }
                }}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline
                  }
                }}
              />
            </DialogContent>
            <Divider />
            <DialogActions>
              <Button
                onClick={this.handleDialogClose}
                className={classes.dialogButton}
              >
                Cancelar
              </Button>
              <Button
                onClick={
                  this.state.selectedFormat
                    ? this.onUpdateFormatName
                    : this.goToNewFormat
                }
                className={classes.dialogButton}
              >
                {this.state.selectedFormat ? 'Guardar' : 'Crear'}
              </Button>
            </DialogActions>
          </Dialog>
          <Header currentPage={`Configuraciones`} />
          <Sidebar level={level} country={country} />
          <div className={classes.content}>
            <MySnackbar
              open={openSnackbar}
              variant={
                openSnackbar ? (success ? 'success' : 'error') : 'default'
              }
              onClose={this.handleCloseSnackBar}
              message={openSnackbar ? message : ''}
            />
            {this.props.match.params.id && (
              <SettingsByCountry
                userRole={userRole}
                countryName={country.name}
                onShowBanners={this.onShowBanners}
                onShowLanguage={this.onShowLanguage}
                onShowFormats={this.onShowFormats}
                uniqPermission={this.props.location.state.uniqPermission}
                onShowCountrySettings={this.onShowCountrySettings}
              />
            )}
            {!this.props.match.params.id && (
              <SettingsSidebar
                level={level}
                userRole={userRole}
                onShowGeneralSettings={this.onShowGeneralSettings}
                onShowAreas={this.onShowAreas}
                onShowUpdates={this.onShowUpdates}
                onShowConfigs={this.onShowConfigs}
                uniqPermission={this.props.location.state.hasUpdatePermissionOnly}
              />
            )}
            <div className={classes.column}>
              {countrySettingsIsOpen && (
                <CountrySettings
                  settings={filteredSettings}
                  onSaveCountrySettings={this.onSetSettings}
                />
              )}
              {generalSettingsIsOpen && (
                <SettingsConfig
                  shouldShowExtras={kiosk === null || kiosk === undefined}
                  headerText={'Estado del kiosco'}
                  loading={setting.loading}
                  settings={filteredSettings}
                  onSetSettings={this.onSetSettings}
                />
              )}
              {bannersSettingsIsOpen &&
                FilterRolePermissions('promotion', 'see', userRole) && (
                  <BannersConfig
                    userRole={userRole}
                    loading={banner.loading}
                    pickedFiles={this.state.pickedFiles}
                    banners={banner.banners}
                    cities={city.cities}
                    onDeleteBanner={this.deleteBanner}
                    onPickFile={this.onPickFile}
                    onSaveBanner={this.onSaveBannerChanges}
                  />
                )}
              {areasSettingsIsOpen && (
                <AreaConfig
                  loading={area.loading}
                  user={this.props.user}
                  companyId={this.props.companyId}
                  locations={locations.locations}
                  onCreateArea={this.onCreateArea}
                  onUpdateArea={this.onUpdateArea}
                  onDeleteArea={this.onDeleteArea}
                />
              )}
              {languageSettingsIsOpen && <ChooseLanguage country={country} />}
              {formatsIsOpen && level !== 0 && country && (
                <FormatsConfig
                  loading={format.loading}
                  formats={formats}
                  customVouchers={customVouchers}
                  defaultVoucher={defaultVoucher}
                  onNewFormat={this.onNewFormat}
                  onShowFormat={this.onShowFormatPreview}
                  onDeleteFormat={this.onDeleteFormat}
                  onRenameFormat={this.onEditFormatName}
                  onEditFormat={this.onEditFormat}
                />
              )}
              {updatesIsOpen && !this.props.match.params.id && (
                <UpdateConfig
                  versions={this.props.versions}
                  onCreateUpdate={this.onCreateUpdate}
                  onSendUpdate={(data) => {
                    onSendUpdate(data)
                  }}
                  updateCompany={(event) => this.props.doUpdateCompany(event)}
                  sendForcedUpdate={(event) =>
                    this.props.doSendForcedUpdate(event)
                  }
                  dailyUpdateTime={this.props.company.daily_update_time}
                  companyId={this.props.companyId}
                  checkPassword={this.props.doCheckUserPassword}
                  user={this.props.userData}
                  userValidate={this.props.userValidate}
                />
              )}
              {configsIsOpen && ''}
            </div>
          </div>
        </div>
        <CircularLoading isLoading={requestsLoading} />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: getLoginToken(state),
    userRole: state.user.role,
    userData: state.user.data,
    userValidate: state.user.check.status,
    companyId: companyId(state),
    setting: state.setting,
    format: state.format,
    formats: getFormats(state.format),
    defaultVoucher: getDefaultVoucher(state.format),
    customVouchers: getCustomVouchers(state.format),
    voucherCategoryId: getVoucherCategoryId(state.format),
    city: state.city,
    locations: state.location,
    area: state.area,
    banner: state.banner,
    success: getSuccess(state),
    error: getError(state),
    message: getMessage(state),
    versions: state.update.versions,
    company: state.company
  }
}

const mapDispatchToProps = {
  doFetchSettings,
  doCreateSettings,
  doUpdateSettings,
  doFetchLocations,
  doCreateArea,
  doFetchAreas,
  doUpdateArea,
  doDeleteArea,
  doFetchCities,
  doFetchBanners,
  doChangeBanners,
  doClearRequestStatus,
  doMarkBannerForDeletion,
  doUpdateFormat,
  doFetchFormats,
  doDeleteFormat,
  doSelectFormat,
  doDeleteBanner,
  doGetAllVersions,
  doUpdateCompany,
  doSendForcedUpdate,
  doCheckUserPassword
}

export default withStyles(settingsStyles)(
  connect(mapStateToProps, mapDispatchToProps)(Settings)
)
