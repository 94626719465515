import {
  CREATE_FORMAT,
  UPDATE_FORMAT,
  DELETE_FORMAT,
  FORMATS_FETCH,
  CREATE_FORMAT_SUCCESS,
  UPDATE_FORMAT_SUCCESS,
  DELETE_FORMAT_SUCCESS,
  FORMATS_FETCH_SUCCESS,
  CREATE_FORMAT_ERROR,
  UPDATE_FORMAT_ERROR,
  DELETE_FORMAT_ERROR,
  FORMATS_FETCH_ERROR,
  PRINTABLE_COMPONENTS_FETCH,
  PRINTABLE_COMPONENTS_FETCH_SUCCESS,
  PRINTABLE_COMPONENTS_FETCH_ERROR,
  MOVE_FORMAT_COMPONENT,
  ADD_FORMAT_COMPONENT,
  REMOVE_FORMAT_COMPONENT,
  SELECT_FORMAT,
  CHANGE_FORMAT_CONTENT,
  CHANGE_FORMAT_COMPONENT_STYLES
} from '../constants/ActionTypes'

export const doCreateFormat = payload => ({ type: CREATE_FORMAT, payload })
export const doUpdateFormat = payload => ({ type: UPDATE_FORMAT, payload })
export const doDeleteFormat = payload => ({ type: DELETE_FORMAT, payload })
export const doFetchFormats = payload => ({ type: FORMATS_FETCH, payload })
export const doCreateFormatSuccess = payload => ({ type: CREATE_FORMAT_SUCCESS, payload })
export const doUpdateFormatSuccess = payload => ({ type: UPDATE_FORMAT_SUCCESS, payload })
export const doDeleteFormatSuccess = payload => ({ type: DELETE_FORMAT_SUCCESS, payload })
export const doFetchFormatsSuccess = payload => ({ type: FORMATS_FETCH_SUCCESS, payload })
export const doCreateFormatError = payload => ({ type: CREATE_FORMAT_ERROR, payload })
export const doUpdateFormatError = payload => ({ type: UPDATE_FORMAT_ERROR, payload })
export const doDeleteFormatError = payload => ({ type: DELETE_FORMAT_ERROR, payload })
export const doFetchFormatsError = payload => ({ type: FORMATS_FETCH_ERROR, payload })
export const doFetchPrintableComponents = payload => ({ type: PRINTABLE_COMPONENTS_FETCH, payload })
export const doFetchPrintableComponentsSuccess = payload => ({ type: PRINTABLE_COMPONENTS_FETCH_SUCCESS, payload })
export const doFetchPrintableComponentsError = payload => ({ type: PRINTABLE_COMPONENTS_FETCH_ERROR, payload })
export const doSelectFormat = payload => ({ type: SELECT_FORMAT, payload })
export const doChangeFormatContent = payload => ({ type: CHANGE_FORMAT_CONTENT, payload })
export const doMoveFormatComponent = payload => ({ type: MOVE_FORMAT_COMPONENT, payload })
export const doAddFormatComponent = payload => ({ type: ADD_FORMAT_COMPONENT, payload })
export const doRemoveFormatComponent = payload => ({ type: REMOVE_FORMAT_COMPONENT, payload })
export const doChangeFormatComponentStylesContent = payload => ({ type: CHANGE_FORMAT_COMPONENT_STYLES, payload })
