import React from 'react';
import PropTypes from 'prop-types'
import { ListItemIcon, Paper, Typography } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import './CountryInfo.sass'

export default function CountryDialog(props) {
  const { company } = props
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function CustomDialog() {
    if (open) {
      return (
        <div className="customDialogContainer" onClick={handleClose}>
          <Paper className="paper">
            <div className="countryContainer">
              <img
                className="countryImage"
                src={company.image_url || 'https://via.placeholder.com/50'}
                alt="" />
              <Typography className="countryName">
                { company.name }
              </Typography>
            </div>
            <Typography className="countryLabel">
              País
            </Typography>
            <Typography className="country">
              { company.name }
            </Typography>
          </Paper>
        </div>
      )
    } else return null
  }

  return (
    <div>
      <ListItemIcon onClick={handleClickOpen}>
        <Info />
      </ListItemIcon>
      <CustomDialog />
    </div>
  );
}

CountryDialog.propTypes = {
  company: PropTypes.oneOfType([PropTypes.object, PropTypes.node])
}
