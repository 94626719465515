/* eslint-disable camelcase */
// Dependences
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { doResetUserPassword } from '../../actions/user'
import { doClearRequestStatus } from '../../actions/clearStatus'

// UI Components
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import LinearIndeterminate from '../../components/Loading/LinearIndeterminate'
import MySnackbar from '../../components/MySnackbar/MySnackbar'

// Styles and assets
import './resetpassword.sass'
import { resetPasswordStyles } from './resetpassword.styles'
import logo from './../../assets/images/logo.png'

import { getError, getMessage, getSuccess } from '../../selectors/user'

class ResetPassword extends Component {
  constructor () {
    super()
    this.state = {
      email: ''
    }
  }

  componentDidMount () {
    this.props.doClearRequestStatus()
  }

  handleEmail = (event) => {
    this.setState({ email: event.target.value })
  }

  onLogin = () => {
    this.props.history.push('/login')
  }

  onResetPassword = () => {
    this.props.doClearRequestStatus()
    this.props.doResetUserPassword({ data: { email: this.state.email } })
  }

  handleCloseSnackBar = () => {
    this.props.doClearRequestStatus()
  }

  render () {
    const { error, message, classes, isLoading, success } = this.props
    const shouldShowForm = !success

    const { email } = this.state
    return (
      <div className="page-reset">
        <MySnackbar
          open={error}
          variant={error ? 'error' : 'default'}
          onClose={this.handleCloseSnackBar}
          message= {message || ''}
        />
        <div className="page-reset__container">
          <img alt='' src={logo} className="logo" />
          <Card className="reset-card">
            <CardContent>
              <Typography variant="h5" className="reset-title">
                {shouldShowForm ? 'Restablecer contraseña' : '¡Email enviado!'}
              </Typography>
              <Typography variant="subtitle2" className="reset-subtitle">
                {shouldShowForm ? 'Para restablecer contraseña, proporcione su correo electrónico'
                  : 'Las instrucciones para restablecer la contraseña se han enviado a la dirección de correo electrónico principal de su cuenta.'
                }
              </Typography>
              {shouldShowForm && <div>
                <TextField label="Correo electrónico" margin="normal" className="reset-input" value={email} onChange={this.handleEmail} />
                <Button variant="contained" classes={{ root: classes.root }} className={classes.submit} onClick={this.onResetPassword}>Enviar</Button>
              </div>}
              <Typography className="reset-footer" variant="subtitle1" onClick={this.onLogin}>
                {shouldShowForm ? 'Iniciar sesión con tu cuenta de correo electrónico'
                  : 'Regresar a la página principal'}
              </Typography>
            </CardContent>
            { isLoading && <LinearIndeterminate/>}
          </Card>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    success: getSuccess(state),
    error: getError(state),
    message: getMessage(state),
    isLoading: state.user.loading
  }
}

const mapDispatchToProps = {
  doResetUserPassword,
  doClearRequestStatus
}

export default withStyles(resetPasswordStyles)(connect(mapStateToProps, mapDispatchToProps)(ResetPassword))
