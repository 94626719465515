
// Dependences
import React, { Component } from 'react'
import { sortBy } from 'lodash'

// Styles
import { transactionStyles } from './transactions.styles'
import './transactions.sass'

// Custom components
import TransactionRow from '../TransactionRow/TransactionRow'

// UI Components
import { Input, InputAdornment, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import TablePagination from '@material-ui/core/TablePagination'
import Search from '@material-ui/icons/Search'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TransactionDetail from '../TransactionDetail/TransactionDetail'

const SORTS = {
  NONE: list => sortBy(list, 'id'),
  CUSTOMER: list => sortBy(list, 'customer'),
  CREATED_AT: list => sortBy(list, 'created_at'),
  DURATION: list => sortBy(list, 'duration'),
  OPERATION: list => sortBy(list, 'operation'),
  FRANCHISE: list => sortBy(list, 'franchise'),
  TOTAL: list => sortBy(list, 'total')
}

class Transactions extends Component {
  constructor () {
    super()
    this.state = {
      query: '',
      rowsPerPage: 10,
      openDialog: false,
      transaction: undefined,
      currentPage: 0,
      order: 'asc',
      orderBy: 'NONE'
    }
  }

  onChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value })
  }

  onChangePage = (event, page) => {
    this.setState({ currentPage: page })
  }

  onChangeQuery = (event) => {
    this.setState({ query: event.target.value })
  }

  onShowTransactionDetail = (transaction) => {
    this.setState({ openDialog: true, transaction })
  }

  handleRequestSort = (property) => () => {
    const orderBy = property
    let order = 'desc'
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }
    this.setState({ order, orderBy })
  }

  handleCloseDialog = () => {
    this.setState({ openDialog: false, transaction: undefined })
  }

  render () {
    const { order, orderBy } = this.state
    const { transactions } = this.props
    const { country } = this.props
    let listItems
    let filteredTransactions
    if (transactions) {
      const currentPageIndex = this.state.currentPage * this.state.rowsPerPage
      const pageEndIndex = currentPageIndex + this.state.rowsPerPage
      filteredTransactions = transactions.filter((transaction) => {
        if (this.state.query.length > 0) {
          return transaction.customer.toLowerCase().includes(this.state.query.toLowerCase())
        }
        return true
      })
      const sortedTransactions = SORTS[orderBy](filteredTransactions)
      const reversedTransactions = order === 'desc' ? sortedTransactions.reverse() : sortedTransactions
      listItems = reversedTransactions.slice(currentPageIndex, pageEndIndex).map((transaction) => {
        return (<TransactionRow
          key = {transaction.id}
          transaction = {transaction}
          countryName = {country.name}
          onDetail = {this.onShowTransactionDetail}
        />
        )
      })
    }
    return (
      <>
        <div className="page-transactions">
          <div className={this.props.classes.content}>
            <div className={this.props.classes.tableContainer}>
              <Input className="find-field" placeholder="Buscar por nombre de usuario"
                value={this.state.query}
                onChange={this.onChangeQuery}
                disableUnderline={true} startAdornment={
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                }/>
              <div className={this.props.classes.tableTransactionsContainer}>
                <Table padding='dense' className={this.props.classes.tableTransactions}>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={orderBy === 'CUSTOMER'}
                          direction={order}
                          onClick={this.handleRequestSort('CUSTOMER')}
                        >
                        Usuario
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={orderBy === 'CREATED_AT'}
                          direction={order}
                          onClick={this.handleRequestSort('CREATED_AT')}
                        >
                        Fecha y hora
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={orderBy === 'DURATION'}
                          direction={order}
                          onClick={this.handleRequestSort('DURATION')}
                        >
                        Tiempo
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={orderBy === 'OPERATION'}
                          direction={order}
                          onClick={this.handleRequestSort('OPERATION')}
                        >
                        Operación
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={orderBy === 'FRANCHISE'}
                          direction={order}
                          onClick={this.handleRequestSort('FRANCHISE')}
                        >
                        M. de pago
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={orderBy === 'TOTAL'}
                          direction={order}
                          onClick={this.handleRequestSort('TOTAL')}
                        >
                        Valor
                        </TableSortLabel>
                      </TableCell>
                      {/* <TableCell>Items</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>{listItems}</TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={(filteredTransactions && filteredTransactions.length) || 0}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.currentPage}
                  onChangePage={this.onChangePage}
                  onChangeRowsPerPage={this.onChangeRowsPerPage}
                  labelRowsPerPage='Filas por página'
                  backIconButtonProps={{
                    'aria-label': 'Anterior'
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'Siguiente'
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {this.state.openDialog && this.state.transaction && (
          <TransactionDetail
            onCloseDialog={this.handleCloseDialog}
            countryName={country.name}
            open={this.state.openDialog}
            transaction={this.state.transaction}
          />
        )}
      </>
    )
  }
}

export default withStyles(transactionStyles)(Transactions)
