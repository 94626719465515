export const settingsConfigStyles = {
  container: {
    display: 'flex'
  },
  paper: {
    backgroundColor: '#fff !important',
    margin: '5px',
    height: 'fit-content',
    width: '100%'
  },
  moneyType: {
    width: '50%',
    backgroundColor: '#fff !important',
    height: 'fit-content',
    padding: '25px',
    margin: '5px'
  },
  containerGroup: {
    padding: '25px'
  },
  inputConfig: {
    width: '15%'
  },
  selectConfig: {
    width: '100%',
    height: '55px',
    backgroundColor: '#fff !important'
  },
  btnLink: {
    color: '#efa91b',
    fontWeight: 400
  }
}
