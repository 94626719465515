import {
  BANNERS_FETCH,
  CHANGE_BANNERS,
  BANNERS_FETCH_SUCCESS,
  CHANGE_BANNERS_SUCCESS,
  CHANGE_BANNERS_ERROR,
  CLEAR_REQUEST_STATUS,
  BANNERS_FETCH_ERROR,
  MARK_BANNER_FOR_DELETION,
  DELETE_BANNER_ERROR,
  DELETE_BANNER_SUCCESS,
  DELETE_BANNER
} from '../constants/ActionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case CHANGE_BANNERS:
      return {
        ...state,
        loading: true
      }
    case BANNERS_FETCH:
      return {
        ...state,
        loading: true
      }
    case CHANGE_BANNERS_SUCCESS:
      return {
        ...state,
        banner: action.payload,
        loading: false,
        success: true,
        message: 'Banners Modificados Exitosamente'
      }
    case CHANGE_BANNERS_ERROR:
      return {
        ...state,
        loading: false,
        banner: action.payload,
        error: true,
        message: 'Error Modificando Banners'
      }
    case BANNERS_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        banner: action.payload,
        error: true,
        message: 'Error obteniendo banners'
      }
    case BANNERS_FETCH_SUCCESS:
      return {
        ...state,
        banners: action.payload,
        loading: false
      }
    case DELETE_BANNER:
      return {
        ...state,
        loading: true
      }
    case DELETE_BANNER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        message: 'Banner Eliminado Exitosamente'
      }
    case DELETE_BANNER_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: 'Error Eliminando El Banner'
      }
    case CLEAR_REQUEST_STATUS:
      return {
        ...state,
        success: false,
        error: false,
        message: null
      }
    case MARK_BANNER_FOR_DELETION:
      return {
        ...state,
        banners: state.banners.map((banner) => {
          if (banner.id === action.payload) {
            return { ...banner, toDelete: true }
          }
          return banner
        })
      }
    default:
      return state
  }
}
