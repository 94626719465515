import axios from 'axios'
import env from '../config'

const buildClient = (baseUrl = null) => {
  /**
   * Axios basic configuration
   * Some general configuration can be added like timeout, headers, params etc. More details can be found on https://github.com/axios/axios
   * */
  const options = {
    baseURL: baseUrl,
    timeout: 2000 * 30,
    headers: {
      'Content-Type': 'application/json',
      // 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      // 'Access-Control-Allow-Headers':
      //   'Origin, X-Requested-With, Content-Type, Accept, Authorization, User',
      // 'Access-Control-Allow-Origin': '*'
    }
  }

  /**
   * Creating the instance of Axios
   * It is because, in large scale application we may need to consume APIs from more than single server,
   * So, may need to create multiple http client with different config
   * Only this client will be used rather than axios in the application
   **/
  const httpClient = axios.create(options)

  /**
   * Auth interceptors
   * @description Configuration related to AUTH token can be done in interceptors.
   * Currenlty it is just doing nothing but idea to to show the capability of axios and its interceptors
   * In future, interceptors can be created into separate files and consumed into multiple http clients
   * @param {*} config
   */
  const authInterceptor = (config) => {
    /** add auth token */
    return config
  }

  const loggerInterceptor = (config) => {
    /** Add logging here */
    return config
  }

  /** Adding the request interceptors */
  httpClient.interceptors.request.use(authInterceptor)
  httpClient.interceptors.request.use(loggerInterceptor)

  /** Adding the response interceptors */
  httpClient.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      /** Do something with response error */
      return Promise.reject(error)
    }
  )
  return httpClient
}

class ApiClient {
  constructor(url) {
    this.client = buildClient(url)
  }

  get(url, conf = {}) {
    return this.client
      .get(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  }

  delete(url, conf = {}) {
    return this.client
      .delete(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  }

  head(url, conf = {}) {
    return this.client
      .head(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  }

  options(url, conf = {}) {
    return this.client
      .options(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  }

  post(url, data = {}, conf = {}) {
    return this.client
      .post(url, data, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  }

  put(url, data = {}, conf = {}) {
    return this.client
      .put(url, data, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  }

  patch(url, data = {}, conf = {}) {
    return this.client
      .patch(url, data, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  }
}

const ApiClientSingleton = (function () {
  let kioskApiInstance
  let updatesApiInstance
  let socketApiInstance

  function createInstance(url) {
    let object = new ApiClient(url)
    return object
  }

  return {
    getKioskApiInstance: function () {
      if (!kioskApiInstance) {
        kioskApiInstance = createInstance(env.config.API_URL)
      }
      return kioskApiInstance
    },
    getUpdatesApiInstance: function () {
      if (!updatesApiInstance) {
        updatesApiInstance = createInstance(env.config.UPDATES_API_URL)
      }
      return updatesApiInstance
    },
    getSocketApiInstance: function () {
      if (!socketApiInstance) {
        socketApiInstance = createInstance(env.config.UPDATES_SOCKET_API_URL)
      }
      return socketApiInstance
    }
  }
})()

export default ApiClientSingleton
