// Dependences
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'

// UI Components
import ChooseCompanyCard from '../ChooseCompanyCard/ChooseCompanyCard'
// Styles and assets
import './choosecompanygrid.sass'
import { ChooseCompanyGridStyles } from './choosecompanygrid.styles'

class ChooseCompanyGrid extends Component {
  render() {
    const { companies, classes } = this.props
    return (
      <div className={classes.gridContainer}>
        {companies &&
          companies.map((company, index) => (
            <div key={index} className={classes.gridItem}>
              <ChooseCompanyCard
                avatarSrc={company.image_url}
                companyName={company.name}
                btnLabel={'Ingresar'}
                onBtnClick={this.handleClick.bind(this, company)}
              ></ChooseCompanyCard>
            </div>
          ))}
      </div>
    )
  }
  handleClick = (company) => {
    this.props.handleChooseCompany(company)
  }
}
export default withStyles(ChooseCompanyGridStyles)(ChooseCompanyGrid)
