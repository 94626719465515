/* eslint-disable camelcase */
// Dependences
import React from 'react'

// UI components
import { withStyles, Link } from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

// Styles and assets
import { locationRowStyles } from './locationrow.styles'

const LocationRow = props => {
  const { location, onDetail } = props
  const { in_service, out_of_service, warning, name, total_kiosks } = location

  const handleDetails = () => {
    onDetail(location)
  }

  return (
    <TableRow>
      <TableCell onClick={handleDetails}><Link>{name}</Link></TableCell>
      <TableCell>{total_kiosks}</TableCell>
      <TableCell>{in_service}</TableCell>
      <TableCell>{out_of_service}</TableCell>
      <TableCell>{warning}</TableCell>
    </TableRow>
  )
}

export default withStyles(locationRowStyles)(LocationRow)
