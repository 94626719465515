import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CreateIcon from '@material-ui/icons/Create';
import { IconButton } from '@material-ui/core';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog'

export default function AreaTable(props) {
  const { areas, onDeleteArea, onEditArea } = props

  function handleDeleteArea(event, areaId) {
    if (event) onDeleteArea(areaId)
  }

  function handleEditArea(event, area) {
    if (event) onEditArea(area)
  }

  return (
    <>
      <Paper elevation={3}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Área</TableCell>
              <TableCell align="right">Ubicaciónes</TableCell>
              <TableCell align="right">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {areas.map((area) => (
              <TableRow key={area.id}>
                <TableCell component="th" scope="row">
                  {area.name}
                </TableCell>
                <TableCell align="right">{area.description}</TableCell>
                <TableCell align="right">
                  <IconButton>
                    <ConfirmDialog
                      title="Estas seguro de elimiar esta Área"
                      onDelete={event => handleDeleteArea(event, area.id)}
                    />
                  </IconButton>
                  <IconButton> <CreateIcon onClick={event => handleEditArea(event, area)} /> </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </>
  );
}
