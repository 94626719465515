import React, { Component } from 'react'
import { connect } from 'react-redux'
import { doClearRequestStatus } from '../../actions/clearStatus'
// Custom components
import Header from '../../components/header/Header'
import Sidebar from '../../components/sidebar/Sidebar'
import ConfirmDialog from '../../components/ConfirmDialog/ConfirmDialog'
import Button from '@material-ui/core/Button'
import TablePagination from '@material-ui/core/TablePagination'
import Paper from '@material-ui/core/Paper'
import EditIcon from '@material-ui/icons/Edit'
import EyeIcon from '@material-ui/icons/Visibility'

import {
  withStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core'

import { companyId } from '../../selectors/company'
import { getLoginToken } from '../../selectors/user'

import CircularLoading from '../../components/Loading/CircularLoading'
import MySnackbar from '../../components/MySnackbar/MySnackbar'

import './rolePermission.sass'
import { RolePermissionStyles } from './rolePermission.style'

import {
  doGetRolesPermissions,
  doDeleteRolesPermissions
} from '../../actions/rolePermission'

class RolePermission extends Component {
  constructor(props) {
    super(props)
    this.state = {
      query: '',
      rowsPerPage: 10,
      currentPage: 0
    }
  }

  onChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value })
  }

  onChangePage = (event, page) => {
    this.setState({ currentPage: page })
  }

  onChangeQuery = (event) => {
    this.setState({ query: event.target.value })
  }

  componentDidMount = () => {
    this.props.doClearRequestStatus()
    this.allRoles()
  }

  allRoles = () => {
    this.props.doGetRolesPermissions({ companyId: this.props.companyId })
  }

  handleCloseSnackBar = () => {
    this.props.doClearRequestStatus()
  }

  deleteRol = (id) => {
    this.props.doDeleteRolesPermissions({
      companyId: this.props.companyId,
      id,
      userId: this.props.userId
    })
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.success !== prevProps.success && this.props.success) {
      setTimeout(() => {
        this.props.doClearRequestStatus()
      }, 3500)
    }
  }

  render() {
    const { classes, rolesPermissions } = this.props

    const filteredRoles = []
    const loading = false
    let listRoles
    if (rolesPermissions) {
      listRoles = rolesPermissions.map((role, index) => {
        return (
          <TableRow key={index}>
            <TableCell>{role.name}</TableCell>
            <TableCell>{role.description}</TableCell>
            <TableCell>
              {!role.built_in ? (
                <>
                  <ConfirmDialog
                    title="¿Deseas eliminar el rol?"
                    onDelete={() => this.deleteRol(role.id)}
                  />
                  <EditIcon
                    onClick={() => {
                      this.props.history.push(
                        `/roles-permissions/edit-role/${role.id}`
                      )
                    }}
                  />
                </>
              ) : (
                <>
                  <EyeIcon
                    onClick={() => {
                      this.props.history.push(
                        `/roles-permissions/edit-role/${role.id}`
                      )
                    }}
                  />
                </>
              )}
            </TableCell>
          </TableRow>
        )
      })
    } else {
      listRoles = (
        <TableRow key={1}>
          <TableCell>Sin registros</TableCell>
        </TableRow>
      )
    }
    const openSnackbar = this.props.error || this.props.success
    return (
      <div className="page-users">
        <MySnackbar
          open={openSnackbar}
          variant={
            openSnackbar ? (this.props.error ? 'error' : 'success') : 'default'
          }
          onClose={this.handleCloseSnackBar}
          message={openSnackbar ? this.props.message : ''}
        />
        <Header currentPage={'Lista de Roles y permisos'} />
        <Sidebar level={0} />
        <Button
          variant="contained"
          className="users__button"
          onClick={() => this.props.history.push('/roles-permissions/new-role')}
        >
          NUEVO ROL
        </Button>
        <div className={this.props.classes.content}>
          {loading ? (
            <CircularLoading isLoading={loading} />
          ) : (
            <Paper className={classes.paper}>
              <div className={classes.tableWrapper}>
                <Table className={classes.table} padding="dense">
                  <TableHead>
                    <TableRow>
                      <TableCell>Rol</TableCell>
                      <TableCell>Descripcion</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{listRoles}</TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={(filteredRoles && filteredRoles.length) || 0}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.currentPage}
                  onChangePage={this.onChangePage}
                  onChangeRowsPerPage={this.onChangeRowsPerPage}
                  labelRowsPerPage="Filas por página"
                  backIconButtonProps={{
                    'aria-label': 'Anterior'
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'Siguiente'
                  }}
                />
              </div>
            </Paper>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyId: companyId(state),
    userId: getLoginToken(state),
    error: state.rolePermission.error,
    loading: state.rolePermission.loading,
    rolesPermissions: state.rolePermission.roles,
    message: state.rolePermission.message,
    success: state.rolePermission.success
  }
}

const mapDispatchToProps = {
  doGetRolesPermissions,
  doDeleteRolesPermissions,
  doClearRequestStatus
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(RolePermissionStyles)(RolePermission))
