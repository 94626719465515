import ApiClientSingleton from '../api-client'

const kioskApiInstance = ApiClientSingleton.getKioskApiInstance()

const fetchFormats = async (payload) => {
  const { params, user } = payload
  const uri = '/api/v1/printable_formats'
  const headers = { Authorization: `Bearer ${user}` }
  const { data } = await kioskApiInstance.get(uri, { params, headers })
  return data
}

const createFormat = async (payload) => {
  const { params, user } = payload
  const uri = '/api/v1/printable_formats'
  const headers = { Authorization: `Bearer ${user}` }
  const { data } = await kioskApiInstance.post(uri, params, { headers })
  return data
}

const updateFormat = async (payload) => {
  const { id, params, user } = payload
  const uri = `/api/v1/printable_formats/${id}`
  const headers = { Authorization: `Bearer ${user}` }
  const { status } = await kioskApiInstance.put(uri, params, { headers })
  return status
}

const deleteFormat = async (payload) => {
  const { id, user } = payload
  const uri = `/api/v1/printable_formats/${id}`
  const headers = { Authorization: `Bearer ${user}` }
  const { status } = await kioskApiInstance.delete(uri, { headers })
  return status
}

const fetchPrintableComponents = async (payload) => {
  const { user, params } = payload
  const uri = '/api/v1/printable_components'
  const headers = { Authorization: `Bearer ${user}` }
  const { data } = await kioskApiInstance.get(uri, { params, headers })
  return data
}

export {
  fetchFormats,
  createFormat,
  updateFormat,
  deleteFormat,
  fetchPrintableComponents
}
