import React, { Component } from 'react'

class WithLongPress extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isActive: false,
      timeoutDeactivated: props.timeoutDeactivated || false,
      delay: props.delay || 500
    }
  }
  handlePress = () => {
    if (this.state.timeoutDeactivated) {
      this.setState({ isActive: true })
    } else {
      this.pressTimer = setTimeout(() => {
        this.setState({ isActive: true })
      }, this.state.delay)
    }
  }

  handleRelease = () => {
    this.setState({ isActive: false })
    clearTimeout(this.pressTimer)
  }

  handleMouseUp = () => {
    clearTimeout(this.pressTimer)
  }

  render () {
    return <div
      onTouchStart={this.handlePress}
      onTouchEnd={this.handleRelease}
      onMouseDown={this.handlePress}
      onMouseUp={this.handleMouseUp}
      onMouseLeave={this.handleRelease}>
      {this.props.children(this.state.isActive)}
    </div>
  }
}

export default WithLongPress
