import { call, put } from 'redux-saga/effects'
import {
  doFetchCitiesSuccess,
  doFetchCitiesError,
  doFetchCitiesByCountryIdSuccess,
  doFetchCitiesByCountryIdError,
  doFetchCitiesAndLocationsSuccess,
  doFetchCitiesAndLocationsError
} from '../actions/city'
import { fetchCities, fetchCitiesByCountryId, fetchCitiesAndLocations } from '../api/city'
import { doLoading } from '../actions/loading'

function * handleFetchCities (action) {
  try {
    yield put(doLoading())
    const result = yield call(fetchCities, action.payload.data)
    if (Array.isArray(result)) {
      yield put(doFetchCitiesSuccess(result))
    } else {
      yield put(doFetchCitiesError())
    }
  } catch (error) {
    yield put(doFetchCitiesError(error))
  }
}

function * handleFetchCitiesByCountryId (action) {
  try {
    yield put(doLoading())
    const result = yield call(fetchCitiesByCountryId, action.payload.data)
    if (Array.isArray(result.cities)) {
      yield put(doFetchCitiesByCountryIdSuccess(result))
    } else {
      yield put(doFetchCitiesByCountryIdError())
    }
  } catch (error) {
    yield put(doFetchCitiesByCountryIdError(error))
  }
}

function * handleFetchCitiesAndLocations (action) {
  try {
    yield put(doLoading())
    const result = yield call(fetchCitiesAndLocations, action.payload.data)
    if (Array.isArray(result)) {
      yield put(doFetchCitiesAndLocationsSuccess(result))
    } else {
      yield put(doFetchCitiesAndLocationsError())
    }
  } catch (error) {
    yield put(doFetchCitiesAndLocationsError(error))
  }
}

export { handleFetchCities, handleFetchCitiesByCountryId, handleFetchCitiesAndLocations }
