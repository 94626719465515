export const transactionRowStyles = {
  btnDetails: {
    border: '1px solid #7c7c7c',
    fontSize: '11px'
  },
  cellWarn: {
    color: 'var(--var-color-primary-yellow)'
  },
  cellError: {
    color: 'var(--var-color-primary-red)'
  },
  cellDefault: {
    color: 'var(--var-color-primary-black)'
  }
}
