// Dependences
import React from 'react'
import { kioskStatus } from '../../utils/utils'

// UI components
import { withStyles } from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'

// Styles and assets
import { kioskListRowStyles } from './kiosklistrow.styles'

const KioskListRow = props => {
  const { kiosk, onDetail } = props

  const handleDetails = () => {
    onDetail(kiosk)
  }

  return (
    <TableRow>
      <TableCell>{kiosk.city_name}</TableCell>
      <TableCell>{kiosk.location_ame}</TableCell>
      <TableCell>{kiosk.name}</TableCell>
      <TableCell>{kiosk.type}</TableCell>
      <TableCell>{kiosk.area_name}</TableCell>
      <TableCell>{kioskStatus(kiosk.status)}</TableCell>
      <TableCell><Button className={props.classes.btnDetails} onClick={handleDetails}>Detalles</Button></TableCell>
    </TableRow>
  )
}

export default withStyles(kioskListRowStyles)(KioskListRow)
