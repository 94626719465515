import React from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import { Select, MenuItem, FormControl } from '@material-ui/core'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import EditIcon from '@material-ui/icons/Edit'

import './locationdetaildialog.sass'

const LocationDetailDialog = props => {
  const { isedit, cities, data, cityToEdit } = props

  const [city, setCity] = React.useState(cities[0].id)
  const [name, setName] = React.useState('')
  const [address, setAddress] = React.useState('')
  const [code, setCode] = React.useState('')

  const [open, setOpen] = React.useState(false)

  function DialogTitleLabel () {
    let title
    if (isedit) {
      title = 'Editar Ubicación'
    } else {
      title = 'Nueva Ubicación'
    }
    return <MuiDialogTitle disableTypography className="dialog_title">
      <Typography variant="h6">{title}</Typography>
      {handleClose ? (
        <IconButton className="button_close" aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  }

  function ButtonEditOrCreate () {
    if (isedit) {
      return <EditIcon onClick={handleCreateLocation}></EditIcon>
    }
    return <Button className="add_location" onClick={handleCreateLocation}>Nueva Ubicación</Button>
  }

  const listCities = cities.map((city) => {
    return <MenuItem value={city.id} key={city.id}>{city.name}</MenuItem>
  })

  const handleChangeCity = (value) => {
    setCity(value)
  }

  const handleChangeName = (value) => {
    setName(value)
  }

  const handleChangeAddress = (value) => {
    setAddress(value)
  }

  const handleChangeCode = (value) => {
    setCode(value)
  }

  const handleCreateLocation = () => {
    setOpen(true)
    if (isedit) {
      setCity(cityToEdit)
      setCode(data.code)
      setName(data.name)
      setAddress(data.address)
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleEvent = () => {
    const body = {
      name: name,
      address: address,
      code: code,
      city_id: city
    }
    if (isedit) {
      props.updateLocation(body)
    } else {
      props.createLocation(body)
    }
  }

  const validation = name === '' || code === '' || address === ''

  return (
    <div>
      <ButtonEditOrCreate></ButtonEditOrCreate>
      <Dialog
        open={ open }
        onClose={ handleClose }
        aria-labelledby="form-dialog-title"
      >
        <DialogTitleLabel></DialogTitleLabel>
        <DialogContent>
          <FormControl variant="outlined">
            <Select label="Ciudad" input={ <OutlinedInput name='selectedCity' id='city-select'/> }
              value = { city || cities[0].id }
              onChange = { event => handleChangeCity(event.target.value) }>
              { listCities }
            </Select>
            <div className="form_container">
              <TextField
                required
                id="code"
                label="Código"
                variant="outlined"
                onChange = { event => handleChangeCode(event.target.value) }
                defaultValue={ code }
                fullWidth
              />
              <TextField
                required
                id="name"
                label="Nombre de la ubicación"
                variant="outlined"
                onChange = { event => handleChangeName(event.target.value) }
                defaultValue={ name }
                fullWidth
              />
            </div>
            <TextField
              required
              id="address"
              label="Dirección"
              variant="outlined"
              onChange = { event => handleChangeAddress(event.target.value) }
              defaultValue={ address }
              fullWidth
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={ handleClose } color="primary">
            Cancelar
          </Button>
          <Button disabled = { validation } onClick={ handleEvent } color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default (LocationDetailDialog)
