import { call, put } from 'redux-saga/effects'
import {
  doFetchLocalesSuccess,
  doUpdateLocaleSuccess,
  doFetchLocalesError,
  doUpdateLocaleError
} from '../actions/locale'
import { fetchLocales, updateLocale } from '../api/locale'

function* handleFetchLocales(action) {
  try {
    const result = yield call(fetchLocales, action.payload.data)
    if (Array.isArray(result)) {
      yield put(doFetchLocalesSuccess(result))
    } else {
      yield put(doFetchLocalesError())
    }
  } catch (error) {
    yield put(doFetchLocalesError(error))
  }
}

function* handleUpdateLocale(action) {
  try {
    const result = yield call(updateLocale, action.payload.data)
    if (result.status === 200) {
      yield put(doUpdateLocaleSuccess(action.payload.data))
    } else {
      yield put(doUpdateLocaleError())
    }
  } catch (error) {
    yield put(doUpdateLocaleError(error))
  }
}

export { handleFetchLocales, handleUpdateLocale }
