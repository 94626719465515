import {
  GET_ALL_ROLE_PERMISSION,
  GET_ALL_ROLE_PERMISSION_SUCCESS,
  GET_ALL_ROLE_PERMISSION_ERROR,
  GET_ROLE_PERMISSION,
  GET_ROLE_PERMISSION_SUCCESS,
  GET_ROLE_PERMISSION_ERROR,
  CREATE_ROLE_PERMISSION,
  CREATE_ROLE_PERMISSION_SUCCESS,
  CREATE_ROLE_PERMISSION_ERROR,
  UPDATE_ROLE_PERMISSION,
  UPDATE_ROLE_PERMISSION_SUCCESS,
  UPDATE_ROLE_PERMISSION_ERROR,
  DELETE_ROLE_PERMISSION,
  DELETE_ROLE_PERMISSION_SUCCESS,
  DELETE_ROLE_PERMISSION_ERROR,
  GET_PERMISSION,
  GET_PERMISSION_SUCCESS,
  GET_PERMISSION_ERROR,
  CLEAR_REQUEST_STATUS
} from '../constants/ActionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_ROLE_PERMISSION:
      return {
        ...state,
        loading: true,
        error: false,
        message: ''
      }
    case GET_ALL_ROLE_PERMISSION_SUCCESS:
      return {
        ...state,
        roles: action.payload.roles,
        loading: false,
        error: false,
        message: ''
      }
    case GET_ALL_ROLE_PERMISSION_ERROR:
      return {
        ...state,
        roles: [],
        loading: false,
        error: true,
        message: 'Error al cargar los roles y permisos'
      }
    case GET_ROLE_PERMISSION:
      return {
        ...state,
        loading: true,
        error: false,
        message: '',
        editRolePermission: action.payload
      }
    case GET_ROLE_PERMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        message: '',
        role: action.payload
      }
    case GET_ROLE_PERMISSION_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: 'Error al cargar el rol',
        role: {}
      }
    case CREATE_ROLE_PERMISSION:
      return {
        ...state,
        loading: true,
        error: false,
        message: ''
      }
    case CREATE_ROLE_PERMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        message: 'Se creo exitosamente el rol',
        success: true,
        role: action.payload
      }
    case CREATE_ROLE_PERMISSION_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: 'Error al intentar crear el rol',
        role: {}
      }
    case UPDATE_ROLE_PERMISSION:
      return {
        ...state,
        loading: true,
        error: false,
        message: ''
      }
    case UPDATE_ROLE_PERMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        message: 'Se ha actualizado exitosamente el rol',
        role: action.payload
      }
    case UPDATE_ROLE_PERMISSION_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: 'Error al intentar actualizar el rol',
        role: {}
      }
    case DELETE_ROLE_PERMISSION:
      const roles = state.roles.filter((role) => {
        return role.id !== action.payload.id
      })
      return {
        ...state,
        roles,
        loading: true,
        error: false,
        message: ''
      }
    case DELETE_ROLE_PERMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        message: 'Se ha eliminado exitosamente el rol'
      }
    case DELETE_ROLE_PERMISSION_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: 'Error al intentar eliminar el rol',
        role: {}
      }
    case GET_PERMISSION:
      return {
        ...state,
        loading: true,
        error: false,
        message: ''
      }
    case GET_PERMISSION_SUCCESS:
      return {
        ...state,
        permissions: action.payload,
        loading: false,
        error: false,
        message: ''
      }
    case GET_PERMISSION_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: 'Error al intentar eliminar el rol',
        role: {}
      }
    case CLEAR_REQUEST_STATUS:
      return {
        ...state,
        success: false,
        error: false,
        message: null
      }
    default:
      return state
  }
}
