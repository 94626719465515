import { call, put } from 'redux-saga/effects'
import { doCreateSettingsSuccess, doFetchSettingsSuccess, doFetchSettingsError,
  doUpdateSettingsSuccess, doCreateSettingsError, doUpdateSettingsError, doFetchSettings } from '../actions/setting'
import { fetchSettings, createSettings, updateSettings } from '../api/setting'

function * handleFetchSettings (action) {
  try {
    const result = yield call(fetchSettings, action.payload.data)
    if (result) {
      yield put(doFetchSettingsSuccess(result))
    } else {
      yield put(doFetchSettingsError())
    }
  } catch (error) {
    yield put(doFetchSettingsError(error))
  }
}

function * handleCreateSettings (action) {
  try {
    const result = yield call(createSettings, action.payload.data)
    if (result) {
      yield put(doFetchSettings({ data: { params: action.payload.data.params, user: action.payload.data.user } }))
      yield put(doCreateSettingsSuccess(result))
    } else {
      yield put(doCreateSettingsError())
    }
  } catch (error) {
    yield put(doCreateSettingsError(error))
  }
}

function * handleUpdateSettings (action) {
  try {
    const result = yield call(updateSettings, action.payload.data)
    if (result === 204) {
      yield put(doFetchSettings({ data: { params: action.payload.data.params, user: action.payload.data.user } }))
      yield put(doUpdateSettingsSuccess(result))
    } else {
      yield put(doUpdateSettingsError())
    }
  } catch (error) {
    yield put(doUpdateSettingsError(error))
  }
}

export {
  handleFetchSettings,
  handleCreateSettings,
  handleUpdateSettings
}
