import {
  CREATE_COMPANY_CONFIGURATIONS,
  UPDATE_COMPANY_CONFIGURATIONS,
  FETCH_COMPANY_CONFIGURATIONS,
  CREATE_COMPANY_CONFIGURATIONS_SUCCESS,
  UPDATE_COMPANY_CONFIGURATIONS_SUCCESS,
  FETCH_COMPANY_CONFIGURATIONS_SUCCESS,
  CREATE_COMPANY_CONFIGURATIONS_ERROR,
  UPDATE_COMPANY_CONFIGURATIONS_ERROR,
  FETCH_COMPANY_CONFIGURATIONS_ERROR
} from '../constants/ActionTypes'

export const doCreateConfigurations = payload => ({ type: CREATE_COMPANY_CONFIGURATIONS, payload })
export const doUpdateConfigurations = payload => ({ type: UPDATE_COMPANY_CONFIGURATIONS, payload })
export const doFetchConfigurations = payload => ({ type: FETCH_COMPANY_CONFIGURATIONS, payload })
export const doCreateConfigurationsSuccess = payload => ({ type: CREATE_COMPANY_CONFIGURATIONS_SUCCESS, payload })
export const doUpdateConfigurationsSuccess = payload => ({ type: UPDATE_COMPANY_CONFIGURATIONS_SUCCESS, payload })
export const doFetchConfigurationsSuccess = payload => ({ type: FETCH_COMPANY_CONFIGURATIONS_SUCCESS, payload })
export const doCreateConfigurationsError = payload => ({ type: CREATE_COMPANY_CONFIGURATIONS_ERROR, payload })
export const doUpdateConfigurationsError = payload => ({ type: UPDATE_COMPANY_CONFIGURATIONS_ERROR, payload })
export const doFetchConfigurationsError = payload => ({ type: FETCH_COMPANY_CONFIGURATIONS_ERROR, payload })
