const getSuccess = state => state.locale.success
const getError = state => state.locale.error
const getMessage = state => {
  return state.locale.message
    ? state.locale.message : null
}

export {
  getSuccess,
  getError,
  getMessage
}
