import ApiClientSingleton from '../api-client'

const kioskApiInstance = ApiClientSingleton.getKioskApiInstance()

const getAllRolesPermissions = async (payload) => {
  const { companyId, userId } = payload
  const uri = `/api/v1/companies/${companyId}/roles-scopes`
  const headers = { Authorization: `Bearer ${userId}` }
  const { data } = await kioskApiInstance.get(uri, { headers })
  return data
}

const getRolePermission = async (payload) => {
  const { companyId, Id, userId } = payload
  const uri = `/api/v1/companies/${companyId}/roles/${Id}`
  const headers = { Authorization: `Bearer ${userId}` }
  const { data } = await kioskApiInstance.get(uri, { headers })
  return data
}

const createRolePermission = async (payload) => {
  const { companyId, userId } = payload
  const uri = `/api/v1/companies/${companyId}/roles`
  const headers = { Authorization: `Bearer ${userId}` }
  const body = {
    name: payload.name,
    description: payload.description,
    permission_ids: payload.permissions_ids
  }
  const { data } = await kioskApiInstance.post(uri, body, { headers })
  return data
}

const updateRolePermission = async (payload) => {
  const { companyId, roleId, userId } = payload
  const uri = `/api/v1/companies/${companyId}/roles/${roleId}`
  const headers = { Authorization: `Bearer ${userId}` }
  const body = {
    name: payload.name,
    description: payload.description,
    permission_ids: payload.permissions_ids
  }
  const { data } = await kioskApiInstance.put(uri, body, { headers })
  return data
}

const deleteRolePermission = async (payload) => {
  const { companyId, id, userId } = payload
  const uri = `/api/v1/companies/${companyId}/roles/${id}`
  const headers = { Authorization: `Bearer ${userId}` }
  const { data } = await kioskApiInstance.delete(uri, { headers })
  return data
}

const getPermissions = async (payload) => {
  const { user } = payload
  const uri = `/api/v1/permissions`
  const headers = { Authorization: `Bearer ${user}` }
  const { data } = await kioskApiInstance.get(uri, { headers })
  return data
}

export {
  getAllRolesPermissions,
  getRolePermission,
  createRolePermission,
  updateRolePermission,
  deleteRolePermission,
  getPermissions
}
