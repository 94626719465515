import { takeEvery, all } from 'redux-saga/effects'
import {
  LOGIN_FETCH,
  USERS_FETCH,
  NEW_USER_FETCH,
  CREATE_USER,
  INACTIVE_USER,
  COUNTRIES_STATUS_FETCH,
  CITIES_STATUS_FETCH,
  CITIES_STATUS_BY_COUNTRY_ID,
  KIOSK_TRANSACTIONS_FETCH,
  LOCALES_FETCH,
  UPDATE_LOCALE,
  CREATE_SETTINGS,
  UPDATE_SETTINGS,
  SETTINGS_FETCH,
  LOCATIONS_FETCH,
  AREA_CREATE,
  STATISTICS_FETCH,
  COUNTRIES_COUNT_FETCH,
  BANNERS_FETCH,
  CHANGE_BANNERS,
  RESET_USER_PASSWORD,
  UPDATE_USER_PASSWORD,
  DELETE_KIOSK,
  CREATE_COMPANY_CONFIGURATIONS,
  UPDATE_COMPANY_CONFIGURATIONS,
  FETCH_COMPANY_CONFIGURATIONS,
  FORMATS_FETCH,
  UPDATE_FORMAT,
  CREATE_FORMAT,
  CREATE_USER_PASSWORD,
  CITIES_STATUS_AND_LOCATIONS,
  KIOSK_DEVICES_FETCH,
  COMPANY_FETCH,
  COMPANY_UPDATE,
  PRINTABLE_COMPONENTS_FETCH,
  DELETE_FORMAT,
  LOCATIONS_CREATE,
  LOCATIONS_UPDATE,
  KIOSK_BY_LOCATION_FETCH,
  DELETE_BANNER,
  GET_ALL_VERSIONS,
  GET_ALL_ROLE_PERMISSION,
  GET_ROLE_PERMISSION,
  CREATE_ROLE_PERMISSION,
  UPDATE_ROLE_PERMISSION,
  DELETE_ROLE_PERMISSION,
  GET_PERMISSION,
  SEND_FORCED_UPDATE,
  CHECK_USER_PASSWORD,
  USER_LOGIN,
  FETCH_KIOSKS_INFO,
  UPDATE_USER_DATA,
  PROFILE_UPDATE_PASSWORD,
  FETCH_AREAS,
  ASSIGN_LOCATIONS,
  DELETE_AREA,
  UPDATE_AREA,
  FETCH_COUNTRY_LOCATIONS,
  FETCH_EMPLOYEE,
  UPDATE_EMPLOYEE,
  FETCH_KIOSKS_BY_ID
} from '../constants/ActionTypes'
import {
  handleFetchLogin,
  handleFetchUsers,
  handleResetPassword,
  handleUpdatePassword,
  handleCreatePassword,
  handleFetchNewUser,
  handleCreateUser,
  handleInactiveUser,
  handleCheckUserPassword,
  handleUserLogin,
  handleUpdateUserData,
  handleProfileUpdatePassword,
  handleFetchEmployee,
  handleUpdateEmployee
} from './user'
import { handleFetchCountries } from './country'
import {
  handleFetchCities,
  handleFetchCitiesByCountryId,
  handleFetchCitiesAndLocations
} from './city'
import {
  handleFetchKioskTransactions,
  handleDeleteKiosk,
  handleFetchKioskDevices,
  handleFetchKiosksInfo,
  handleFindKiosk
} from './kiosk'
import { handleFetchLocales, handleUpdateLocale } from './locale'
import {
  handleCreateSettings,
  handleUpdateSettings,
  handleFetchSettings
} from './setting'
import {
  handleCreateConfigurations,
  handleUpdateConfigurations,
  handleFetchConfigurations
} from './configuration'
import {
  handleFetchLocations,
  handleFetchStatistics,
  handleCreateLocation,
  handleUpdateLocation,
  handleKioskByLocation,
  handleFetchCountryLocations
} from './location'
import { handleAssignLocations, handleCreateArea, handleDeleteArea, handleFetchAreas, handleUpdateArea } from './area'
import {
  handleFetchBanners,
  handleChangeBanners,
  handleDeleteBanner
} from './banner'
import {
  handleFetchCountriesCount,
  handleFetchCompany,
  handleUpdateCompany, handleFetchCompanyRole
} from './company'
import {
  handleCreateFormat,
  handleUpdateFormat,
  handleDeleteFormat,
  handleFetchFormats,
  handleFetchPrintableComponents
} from './format'
import {
  handleGetAllRolesPermissions,
  handleGetRolePermission,
  handleCreateRolesPermissions,
  handleUpdateRolesPermissions,
  handleDeleteRolesPermissions,
  handleGetPermissions
} from './rolePermission'
import { handleGetAllVersions, handleSendForcedUpdate } from './update'
function* watchAll() {
  yield all([
    takeEvery(LOGIN_FETCH, handleFetchLogin),
    takeEvery(USER_LOGIN, handleUserLogin),
    takeEvery(USERS_FETCH, handleFetchUsers),
    takeEvery(NEW_USER_FETCH, handleFetchNewUser),
    takeEvery(CREATE_USER, handleCreateUser),
    takeEvery(INACTIVE_USER, handleInactiveUser),
    takeEvery(COUNTRIES_STATUS_FETCH, handleFetchCountries),
    takeEvery(CITIES_STATUS_FETCH, handleFetchCities),
    takeEvery(CITIES_STATUS_BY_COUNTRY_ID, handleFetchCitiesByCountryId),
    takeEvery(CITIES_STATUS_AND_LOCATIONS, handleFetchCitiesAndLocations),
    takeEvery(KIOSK_TRANSACTIONS_FETCH, handleFetchKioskTransactions),
    takeEvery(KIOSK_BY_LOCATION_FETCH, handleKioskByLocation),
    takeEvery(LOCALES_FETCH, handleFetchLocales),
    takeEvery(UPDATE_LOCALE, handleUpdateLocale),
    takeEvery(CREATE_SETTINGS, handleCreateSettings),
    takeEvery(UPDATE_SETTINGS, handleUpdateSettings),
    takeEvery(FETCH_COMPANY_CONFIGURATIONS, handleFetchConfigurations),
    takeEvery(CREATE_COMPANY_CONFIGURATIONS, handleCreateConfigurations),
    takeEvery(UPDATE_COMPANY_CONFIGURATIONS, handleUpdateConfigurations),
    takeEvery(SETTINGS_FETCH, handleFetchSettings),
    takeEvery(LOCATIONS_FETCH, handleFetchLocations),
    takeEvery(LOCATIONS_CREATE, handleCreateLocation),
    takeEvery(LOCATIONS_UPDATE, handleUpdateLocation),
    takeEvery(AREA_CREATE, handleCreateArea),
    takeEvery(FETCH_AREAS, handleFetchAreas),
    takeEvery(ASSIGN_LOCATIONS, handleAssignLocations),
    takeEvery(UPDATE_AREA, handleUpdateArea),
    takeEvery(DELETE_AREA, handleDeleteArea),
    takeEvery(FETCH_COUNTRY_LOCATIONS, handleFetchCountryLocations),
    takeEvery(CHANGE_BANNERS, handleChangeBanners),
    takeEvery(DELETE_BANNER, handleDeleteBanner),
    takeEvery(BANNERS_FETCH, handleFetchBanners),
    takeEvery(RESET_USER_PASSWORD, handleResetPassword),
    takeEvery(UPDATE_USER_PASSWORD, handleUpdatePassword),
    takeEvery(CREATE_USER_PASSWORD, handleCreatePassword),
    takeEvery(STATISTICS_FETCH, handleFetchStatistics),
    takeEvery(COUNTRIES_COUNT_FETCH, handleFetchCountriesCount),
    takeEvery(DELETE_KIOSK, handleDeleteKiosk),
    takeEvery(FORMATS_FETCH, handleFetchFormats),
    takeEvery(UPDATE_FORMAT, handleUpdateFormat),
    takeEvery(CREATE_FORMAT, handleCreateFormat),
    takeEvery(DELETE_FORMAT, handleDeleteFormat),
    takeEvery(KIOSK_DEVICES_FETCH, handleFetchKioskDevices),
    takeEvery(COMPANY_FETCH, handleFetchCompany),
    takeEvery(COMPANY_UPDATE, handleUpdateCompany),
    takeEvery(PRINTABLE_COMPONENTS_FETCH, handleFetchPrintableComponents),
    takeEvery(GET_ALL_VERSIONS, handleGetAllVersions),
    takeEvery(GET_ALL_ROLE_PERMISSION, handleGetAllRolesPermissions),
    takeEvery(GET_ROLE_PERMISSION, handleGetRolePermission),
    takeEvery(CREATE_ROLE_PERMISSION, handleCreateRolesPermissions),
    takeEvery(UPDATE_ROLE_PERMISSION, handleUpdateRolesPermissions),
    takeEvery(DELETE_ROLE_PERMISSION, handleDeleteRolesPermissions),
    takeEvery(GET_PERMISSION, handleGetPermissions),
    takeEvery(SEND_FORCED_UPDATE, handleSendForcedUpdate),
    takeEvery(CHECK_USER_PASSWORD, handleCheckUserPassword),
    takeEvery(FETCH_KIOSKS_INFO, handleFetchKiosksInfo),
    takeEvery('FETCH_COMPANY_ROLE', handleFetchCompanyRole),
    takeEvery(UPDATE_USER_DATA, handleUpdateUserData),
    takeEvery(PROFILE_UPDATE_PASSWORD, handleProfileUpdatePassword),
    takeEvery(FETCH_EMPLOYEE, handleFetchEmployee),
    takeEvery(UPDATE_EMPLOYEE, handleUpdateEmployee),
    takeEvery(FETCH_KIOSKS_BY_ID, handleFindKiosk)
  ])
}

export default watchAll
