import { sidebarWidth } from '../sidebar/Sidebar'
export const languageStyles = {
  content: {
    padding: '20px',
    marginLeft: sidebarWidth,
    width: '100%' - sidebarWidth
  },
  chooseModule: {
    width: '500px'
  },
  labelInput: {
    color: '#fff'
  },
  saveButton: {
    background: '#efa817',
    height: '30px',
    color: '#fff',
    fontWeight: 'bold',
    '&:hover': {
      background: '#efa817'
    },
    boxShadow: 'none'
  },
  textConfig: {
    fontSize: '20px',
    paddingTop: '15px',
    margin: '0 20px'
  },
  subtitleConfig: {
    color: '#9da0af',
    fontSize: '14px',
    margin: '0 20px'
  },
  textModules: {
    margin: '5px 20px',
    fontWeight: 'bold'
  }
}
