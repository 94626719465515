// Dependences
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

// UI Components
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography/Typography'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import CircularLoading from '../Loading/CircularLoading'

//  Components
import AreaDetail from '../AreaDetail/AreaDetail'
import AreaTable from '../AreaTable/AreaTable'

import './areaconfig.sass'
import { FormControl, MenuItem, Select } from '@material-ui/core'
import { doFetchCountryLocations } from '../../actions/location'
import { doClearRequestStatus } from '../../actions/clearStatus'

export default function AreaConfig(props) {
  const { user, locations, companyId, onCreateArea, onDeleteArea, onUpdateArea } = props
  const { areas } = useSelector(state => state.area)
  const { countries } = useSelector(state => state.country)
  const { countryLocations } = useSelector(state => state.location)

  const [open, setOpen] = React.useState(false)
  const [selectedArea, setSelectedArea] = React.useState(undefined)
  const [countrySelected, setCountrySelected] = React.useState(countries[0].id)
  const dispatch = useDispatch()
  
  React.useEffect(() => {
    dispatch(doFetchCountryLocations({
      countryId: countries[0].id,
      user,
      companyId
    }))
  }, [])

  const handleCreateArea = (area) => {
    onCreateArea(area)
    setOpen(false)
  }

  const handleUpdateArea = (area) => {
    onUpdateArea(area)
    setOpen(false)
  }

  const handleClickOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setSelectedArea(undefined)
    setOpen(false);
  }

  function RetrieveAreaDetail() {
    return (
      <AreaDetail
        open={open}
        area={selectedArea}
        onCreateArea={handleCreateArea}
        onUpdateArea={handleUpdateArea}
        onClose={handleClose}
        locations={countryLocations || locations}>
      </AreaDetail>
    )
  }

  function handleChangeCountrySelected(countryId) {
    dispatch(doClearRequestStatus())
    dispatch(doFetchCountryLocations({
      countryId,
      user,
      companyId
    }))
    setCountrySelected(countryId)
  }

  const listCountries = countries.map((country) => {
    return <MenuItem value={country.id} key={country.id}>{country.name}</MenuItem>
  })

  function HasMoreCountries() {
    if (countries.length > 1) {
      return (
        <FormControl variant="outlined">
          <Select
            value={countrySelected}
            onChange={event => handleChangeCountrySelected(event.target.value)}
            label="Age"
          >
            {listCountries}
          </Select>
        </FormControl>
      )
    } else return null
  }

  function handleDeteleArea(areaId) {
    onDeleteArea(areaId)
  }

  function handleEditArea(area) {
    setSelectedArea(area)
    handleClickOpen()
  }

  return (
    <>
      <Paper>
        <div>
          <div className="createArea">
            <div>
              <Typography>
                Defina los espacios físicos dentro del establecimiento donde estará ubicado el kiosco.
              </Typography>
              <HasMoreCountries />
            </div>
            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
              <AddIcon /> AGREGAR
            </Button>
          </div>
          <RetrieveAreaDetail />
          <AreaTable onEditArea={handleEditArea} onDeleteArea={handleDeteleArea} areas={areas} />
        </div>
      </Paper>
      <CircularLoading isLoading={false} />
    </>
  )
}
