import { sidebarWidth } from './../../components/sidebar/Sidebar'
export const dashboardGridStyles = {
  content: {
    padding: '20px',
    marginLeft: sidebarWidth,
    width: '100%' - sidebarWidth
  },
  textInfo: {
    marginLeft: '10px'
  }
}
