/* eslint-disable camelcase */
import {
  IN_SERVICE,
  OUT_OF_SERVICE,
  WARNING,
  POS_TYPE,
  SCREEN_TYPE,
  TRANSACTION_STATUS
} from '../constants/Constants'

const groupedKiosksCountByStatus = (kiosksStatusesCount) => {
  try {
    return kiosksStatusesCount.reduce(
      (item, { in_service, out_of_service, warning }) => ({
        in_service: (item.in_service || 0) + in_service,
        out_of_service: (item.out_of_service || 0) + out_of_service,
        warning: (item.warning || 0) + warning
      }),
      {}
    )
  } catch (error) {
    return { in_service: 0, out_of_service: 0, warning: 0 }
  }
}

const kioskStatus = (kioskStatusId) => {
  switch (kioskStatusId) {
    case OUT_OF_SERVICE:
      return 'Sin servicio'
    case IN_SERVICE:
      return 'En servicio'
    case WARNING:
      return 'En advertencia'
    default:
      return 'Undefined'
  }
}

const kioskType = (kioskTypeId) => {
  switch (kioskTypeId) {
    case POS_TYPE:
      return 'POS'
    case SCREEN_TYPE:
      return 'SCREEN'
    default:
      return 'Undefined'
  }
}

function filterApiToken(value) {
  if (value) {
    const newValue = value.substring(0, 6) + '***' + value.substring(value.length - 6) 
    return newValue
  }
  return ''
}

function transactionOperation(operation) {
  switch (operation) {
    case TRANSACTION_STATUS.NOT_PAYED:
      return 'No Pagada'
    case TRANSACTION_STATUS.INCOMPLETE:
      return 'Incompleta'
    case TRANSACTION_STATUS.COMPLETED:
      return 'Completa'
    case TRANSACTION_STATUS.NOT_PRINTED:
      return 'No Impresa'
    default:
      return 'Desconocido'
  }
}

export { groupedKiosksCountByStatus, kioskStatus, kioskType, filterApiToken, transactionOperation }
