// Dependences
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography/Typography'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import CircularLoading from '../Loading/CircularLoading'
import BannerDetailDialog from './BannerDetail'
import BannerTable from './BannerTable'

import './bannersconfig.sass'
import { bannersConfigStyles } from './bannersconfig.styles'
import FilterRolePermissions from '../../utils/FilterRolePermissions'

const BannersConfig = (props) => {
  const {
    onPickFile,
    banners,
    cities,
    onSaveBanner,
    onDeleteBanner,
    loading,
    userRole
  } = props
  const [value, setValue] = React.useState(0)
  const [screenText, setScreenText] = React.useState('')
  const [screenValue, setScreenValue] = React.useState(1)

  const returnIndex = (value) => {
    return value
  }
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const onPick = (file, image, section, index, id) => {
    onPickFile(file, image, section, index, id)
  }
  const onSave = (body) => {
    onSaveBanner(body)
  }
  const onDelete = (id) => {
    onDeleteBanner(id)
  }

  const returnscreenText = () => {
    switch (value) {
      case 0:
        setScreenText('VERTICAL')
        setScreenValue(2)
        break
      case 1:
        setScreenText('RP7')
        setScreenValue(1)
        break
      default:
        setScreenText('RP9')
        setScreenValue(3)
        break
    }
  }

  const screenName = (value) => {
    switch (value) {
      case 0:
        return 'Pantalla vertical'
      case 1:
        return 'Pantalla RP7'
      default:
        return 'Pantalla RP9'
    }
  }

  function filterScreen(screenType, section) {
    const screenBanners = banners
      ? banners.filter((banner) => banner.screen_type === screenType)
      : []
    return screenBanners.filter((banner) => banner.section === section)
  }

  function Expansion(props) {
    const { title, section } = props
    returnscreenText()
    return (
      <ExpansionPanel key={Math.random()}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography> {title} </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails key={Math.random()} className="expansion_body">
          {FilterRolePermissions('promotion', 'create', userRole) && (
            <BannerDetailDialog
              cities={cities}
              screenType={screenText}
              screenValue={screenValue}
              onSave={onSave}
              section={section}
              onPickFile={onPick}
            ></BannerDetailDialog>
          )}
          <BannerTable
            userRole={userRole}
            key={Math.random()}
            cities={cities}
            deleteBanner={onDelete}
            screenValue={screenValue}
            onSave={onSave}
            section={section}
            onPick={onPick}
            tableData={filterScreen(screenValue, section)}
            screenText={screenText}
          ></BannerTable>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }

  function TabPanel(props) {
    const { value, index, ...other } = props

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <div>
            <Expansion title="Banner Protector de Pantalla" section={1} />
            <Expansion title="Banner cartelera" section={2} />
            <Expansion title="Banner confiteria" section={3} />
          </div>
        )}
      </div>
    )
  }
  return (
    <div>
      <Paper>
        <Typography className="banner_title" variant="h6">
          Configuración de banners promocionales
        </Typography>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label={screenName(0)} {...returnIndex(0)} />
          <Tab label={screenName(1)} {...returnIndex(1)} />
          <Tab label={screenName(2)} {...returnIndex(2)} />
        </Tabs>
        <TabPanel value={value} index={0} dir={'x'} />
        <TabPanel value={value} index={1} dir={'x'} />
        <TabPanel value={value} index={2} dir={'x'} />
      </Paper>
      <CircularLoading isLoading={loading} />
    </div>
  )
}

export default withStyles(bannersConfigStyles)(BannersConfig)
