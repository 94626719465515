// Dependences
import React from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core'

// UI Components
import Typography from '@material-ui/core/Typography'
import FilterRolePermissions from '../../../utils/FilterRolePermissions'

// Styles and assets
function SettingsSidebar(props) {
  const {
    classes,
    onShowGeneralSettings,
    onShowUpdates,
    userRole,
    onShowAreas,
    hasUpdatePermissionOnly
  } = props
  const [generalStyle, setGeneralStyle] = React.useState(classes.selected)
  const [updatesStyle, setUpdatesStyle] = React.useState(classes.notSelected)
  const [areaStyles, setAreaStyles] = React.useState(classes.notSelected)

  React.useEffect(() => {
    if (!hasUpdatePermissionOnly) onShowGeneralSettings()
  }, [classes])

  function generalSettings(event) {
    if (event) {
      setGeneralStyle(classes.selected)
      setUpdatesStyle(classes.notSelected)
      setAreaStyles(classes.notSelected)
      onShowGeneralSettings()
    }
  }

  function updateSettings(event) {
    if (event) {
      setUpdatesStyle(classes.selected)
      setGeneralStyle(classes.notSelected)
      setAreaStyles(classes.notSelected)
      onShowUpdates()
    }
  }

  function areaSettings(event) {
    if (event) {
      setAreaStyles(classes.selected)
      setUpdatesStyle(classes.notSelected)
      setGeneralStyle(classes.notSelected)
      onShowAreas()
    }
  }

  React.useEffect(() => {
    if (hasUpdatePermissionOnly) updateSettings(updateSettings)
  }, [hasUpdatePermissionOnly])

  return (
    <div>
      <Typography variant="subtitle2" gutterBottom>
        Estas serán tus configuraciones por defecto, se aplicara a todos los
        kioscos de la compañía
      </Typography>
      <div className={classes.container}>
        <Typography
          className={generalStyle}
          onClick={(event) => generalSettings(event)}
        >
          Estado del kiosco
        </Typography>
        {/* TODO: Add area permissions and validate views */}
        {(FilterRolePermissions('area', 'see', userRole)) && (
          <Typography
            className={areaStyles}
            onClick={(event) => areaSettings(event)}
          >
            Areas
          </Typography>
        )}
        {(FilterRolePermissions('update', 'see', userRole)) && (
          <Typography
            className={updatesStyle}
            onClick={(event) => updateSettings(event)}
          >
            Actualización del sistema
          </Typography>
        )}
      </div>
    </div>
  )
}

export const sidebarWidth = 260

const settingsSidebarStyles = {
  sidebar: {
    position: 'fixed',
    backgroundColor: '#fff',
    width: sidebarWidth
  },
  button: {
    width: sidebarWidth,
    margin: 'auto'
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '53%',
    margin: '1em 0'
  },
  selected: {
    color: '#000000',
    textTransform: 'uppercase',
    fontWeight: '600',
    fontSize: '0.8em',
    opacity: '1'
  },
  notSelected: {
    color: '#3F3F3F',
    fontWeight: '600',
    fontSize: '0.8em',
    textTransform: 'uppercase',
    opacity: '0.5',
    cursor: 'pointer'
  }
}

export default withRouter(withStyles(settingsSidebarStyles)(SettingsSidebar))
