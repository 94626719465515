import ApiClientSingleton from '../api-client'

const updatesApiInstance = ApiClientSingleton.getUpdatesApiInstance()
const socketApiInstance = ApiClientSingleton.getSocketApiInstance()

const getAllVersions = async (payload) => {
  const { company_id: companyId, app_id: appID } = payload
  const uri = `/updates/all-versions?company_id=${companyId}&app_id=${appID}`
  const { data } = await updatesApiInstance.get(uri)
  return data
}

const sendForcedUpdate = async (payload) => {
  const { content, kioskIds } = payload
  const uri = '/send-notification'
  const body = { content: content, kioskIds: kioskIds }
  const { data } = await socketApiInstance.post(uri, body)
  return data
}

export { getAllVersions, sendForcedUpdate }
