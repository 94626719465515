/* eslint-disable camelcase */
// Dependences
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { doUpdateUserPassword } from '../../actions/user'
import { doClearRequestStatus } from '../../actions/clearStatus'

// UI Components
import { withStyles } from '@material-ui/core/styles'
import MySnackbar from '../../components/MySnackbar/MySnackbar'
import ChangePasswordForm from '../../components/ChangePasswordForm/ChangePasswordForm'
import ChangedPasswordCard from '../../components/ChangedPasswordCard/ChangedPasswordCard'

// Styles and assets
import './updatepassword.sass'
import { updatePasswordStyles } from './updatepassword.styles'
import logo from './../../assets/images/logo.png'

import { getError, getMessage, getSuccess } from '../../selectors/user'
import queryString from 'query-string'

class UpdatePassword extends Component {
  constructor () {
    super()
    this.state = {
      passwordMismatch: false
    }
  }

  componentDidMount () {
    this.props.doClearRequestStatus()
  }

  onLogin = () => {
    this.props.history.push('/login')
  }

  onSubmit = (newPassword, passwordConfirmation, email, token) => {
    if (newPassword === passwordConfirmation) {
      this.props.doClearRequestStatus()
      this.props.doUpdateUserPassword({ data: { email: email, password: newPassword, token: token } })
    } else {
      this.setState({ passwordMismatch: true })
    }
  }

  handleCloseSnackBar = () => {
    this.setState({ passwordMismatch: false })
    this.props.doClearRequestStatus()
  }

  render () {
    const { email, token } = queryString.parse(this.props.location.search)

    const { error, message, isLoading, success } = this.props
    const shouldShowForm = !success

    return (
      <div className="page-update">
        <MySnackbar
          open={error || this.state.passwordMismatch}
          variant={error || this.state.passwordMismatch ? 'error' : 'default'}
          onClose={this.handleCloseSnackBar}
          message= {message || 'Confirmación de contraseña no coincide'}
        />
        <div className="page-update__container">
          <img alt='' src={logo} className="logo" />
          {shouldShowForm
            ? <ChangePasswordForm
              title = {'Restablecer contraseña'}
              subtitle = {'Ingresa tu nueva contraseña'}
              buttonTitle = {'Actualizar'}
              footer = {'Iniciar sesión con tu cuenta de correo electrónico'}
              onFooterClick={this.onLogin}
              isLoading = {isLoading}
              email = {email}
              token = {token}
              onSubmit = {this.onSubmit}
              isTokenRequired = {true}
            />
            : <ChangedPasswordCard
              title = {'¡Contraseña actualizada correctamente!'}
              subtitle = {'Ya puedes iniciar sesión con tu nueva contraseña'}
              footer = {'Regresar a la página principal'}
              onFooterClick={this.onLogin}
              isLoading = {isLoading}
            />}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    success: getSuccess(state),
    error: getError(state),
    message: getMessage(state),
    isLoading: state.user.loading
  }
}

const mapDispatchToProps = {
  doUpdateUserPassword,
  doClearRequestStatus
}

export default withStyles(updatePasswordStyles)(connect(mapStateToProps, mapDispatchToProps)(UpdatePassword))
