const getSuccess = state => state.format.success
const getError = state => state.format.error
const getMessage = state => {
  return state.format.message
    ? state.format.message : null
}

const getFormats = format => {
  if (categoriesAreValid(format)) {
    return format.categories.filter((category) => {
      return !category.name.toLowerCase().includes('voucher')
    }).flatMap((category) => {
      return category.printable_formats
    })
  }
  return []
}

const getDefaultVoucher = format => {
  if (categoriesAreValid(format)) {
    return format.categories.filter((category) => {
      return category.name.toLowerCase().includes('voucher')
    }).flatMap((category) => {
      return category.printable_formats.filter((format) => {
        return format.kiosks_ids.length === 0
      })
    })[0]
  }
  return null
}

const getVoucherCategoryId = format => {
  if (categoriesAreValid(format)) {
    return format.categories.filter((category) => {
      return category.name.toLowerCase().includes('voucher')
    }).map((category) => {
      return category.id
    })[0]
  }
  return 0
}

const getCustomVouchers = format => {
  if (categoriesAreValid(format)) {
    return format.categories.filter((category) => {
      return category.name.toLowerCase().includes('voucher')
    }).flatMap((category) => {
      return category.printable_formats.filter((format) => {
        return format.kiosks_ids.length >= 0 && !format.name.includes('Default Voucher')
      })
    })
  }
  return []
}

const categoriesAreValid = (format) => {
  if (format.categories) {
    if (format.categories != null && format.categories.length > 0) {
      return true
    }
  }
  return false
}

export {
  getSuccess,
  getError,
  getMessage,
  getFormats,
  getDefaultVoucher,
  getCustomVouchers,
  getVoucherCategoryId
}
