import { CREATE_COMPANY_CONFIGURATIONS, UPDATE_COMPANY_CONFIGURATIONS, FETCH_COMPANY_CONFIGURATIONS, CLEAR_REQUEST_STATUS,
  UPDATE_COMPANY_CONFIGURATIONS_ERROR, FETCH_COMPANY_CONFIGURATIONS_ERROR, CREATE_COMPANY_CONFIGURATIONS_SUCCESS,
  UPDATE_COMPANY_CONFIGURATIONS_SUCCESS, FETCH_COMPANY_CONFIGURATIONS_SUCCESS, CREATE_COMPANY_CONFIGURATIONS_ERROR } from '../constants/ActionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case CREATE_COMPANY_CONFIGURATIONS:
      return {
        ...state,
        loading: true
      }
    case UPDATE_COMPANY_CONFIGURATIONS:
      return {
        ...state,
        loading: true
      }
    case FETCH_COMPANY_CONFIGURATIONS:
      return action.payload
    case CREATE_COMPANY_CONFIGURATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        message: 'Configuraciones modificadas exitosamente'
      }
    case CREATE_COMPANY_CONFIGURATIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: 'Error al intentar crear configuración'
      }
    case UPDATE_COMPANY_CONFIGURATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        message: 'Configuraciones modificadas exitosamente'
      }
    case UPDATE_COMPANY_CONFIGURATIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: 'Error al intentar actualizar configuraciones'
      }
    case FETCH_COMPANY_CONFIGURATIONS_SUCCESS:
      return {
        ...state,
        configurations: action.payload,
        loading: false
      }
    case FETCH_COMPANY_CONFIGURATIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: 'Error al obtener configuraciones'
      }
    case CLEAR_REQUEST_STATUS:
      return {
        ...state,
        success: false,
        error: false,
        message: null
      }
    default:
      return state
  }
}
