import ApiClientSingleton from '../api-client'

const kioskApiInstance = ApiClientSingleton.getKioskApiInstance()

const fetchLocales = async (payload) => {
  const { countryId, user } = payload
  const uri = `/api/v1/locales/all?country_id=${countryId}`
  const headers = { Authorization: `Bearer ${user}` }
  const { data } = await kioskApiInstance.get(uri, { headers })
  return data
}

const updateLocale = async (payload) => {
  const { user, locale, newValue } = payload
  const uri = '/api/v1/locales/update'
  const headers = { Authorization: `Bearer ${user}` }
  const body = { id: locale.id, value: newValue }
  const { data } = await kioskApiInstance.put(uri, body, { headers })
  return data
}

export { fetchLocales, updateLocale }
