import React from 'react'
import { Paper, Typography, FormControl, TextField, MenuItem } from '@material-ui/core'

const NewUserForm = ({ classes, user, roles, onClick, isUpdating }) =>
  <Paper className={classes.paper}>
    <div className="new_user__title-form">
      <Typography variant="h6" gutterBottom className={classes.newUserTitleForm} >
        Detalles del usuario
      </Typography>
    </div>
    <div className="new_user__content-form">
      <FormControl className={classes.formControl }>
        <TextField
          id="first_name"
          disabled={isUpdating}
          className={classes.inputFirstName}
          variant="outlined"
          type="text"
          label="Nombre"
          InputLabelProps={{
            classes: {
              root: classes.cssLabel,
              focused: classes.cssFocused
            }
          }}
          value={user.first_name}
          onChange={onClick('first_name')}
          InputProps={{
            classes: {
              root: classes.cssOutlinedInput,
              focused: classes.cssFocused,
              notchedOutline: classes.notchedOutline
            }
          }}
          required
        />
        <TextField
          id="last_name"
          disabled={isUpdating}
          className={classes.formControl}
          variant="outlined"
          type="text"
          label="Apellido"
          InputLabelProps={{
            classes: {
              root: classes.cssLabel,
              focused: classes.cssFocused
            }
          }}
          value={user.last_name}
          onChange={onClick('last_name')}
          InputProps={{
            classes: {
              root: classes.cssOutlinedInput,
              focused: classes.cssFocused,
              notchedOutline: classes.notchedOutline
            }
          }}
          required
        />
        <TextField
          id="email"
          disabled={isUpdating}
          className={classes.formControl}
          variant="outlined"
          type="text"
          label="Correo electronico"
          InputLabelProps={{
            classes: {
              root: classes.cssLabel,
              focused: classes.cssFocused
            }
          }}
          value={user.email}
          onChange={onClick('email')}
          InputProps={{
            classes: {
              root: classes.cssOutlinedInput,
              focused: classes.cssFocused,
              notchedOutline: classes.notchedOutline
            }
          }}
          required
        />
        <TextField
          id="phone"
          disabled={isUpdating}
          className={classes.formControl}
          variant="outlined"
          type="text"
          label="Teléfono"
          InputLabelProps={{
            classes: {
              root: classes.cssLabel,
              focused: classes.cssFocused
            }
          }}
          value={user.phone}
          onChange={onClick('phone')}
          InputProps={{
            classes: {
              root: classes.cssOutlinedInput,
              focused: classes.cssFocused,
              notchedOutline: classes.notchedOutline
            }
          }}
          required
        />
        <TextField
          id="rol_id-select"
          select
          label="Rol"
          InputLabelProps={{
            classes: {
              root: classes.cssLabel,
              focused: classes.cssFocused
            }
          }}
          className={classes.textField}
          value={user.role_id}
          onChange={onClick('role_id')}
          SelectProps={{
            MenuProps: {
              className: classes.menu
            }
          }}
          InputProps={{
            classes: {
              root: classes.cssOutlinedInput,
              focused: classes.cssFocused,
              notchedOutline: classes.notchedOutline
            }
          }}
          margin="normal"
          variant="outlined"
          required
        >
          {
            roles.map(role => {
              return (
                <MenuItem key={role.id} value={role.id}>
                {role.name}
                </MenuItem>
              )
            })
          }
        </TextField>
      </FormControl>
    </div>
  </Paper>

export default NewUserForm
