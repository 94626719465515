import { sidebarWidth } from '../../components/sidebar/Sidebar'
export const citiesStatusStyles = {
  content: {
    paddingTop: '50px',
    marginLeft: sidebarWidth,
    width: '100%' - sidebarWidth
  },
  btnConfig: {
    background: 'none'
  },
  btnLanguage: {
    background: 'none'
  },
  chooseCity: {
    width: '200px',
    display: 'inline'
  },
  textCities: {
    margin: '16px 20px',
    fontSize: '19px',
    display: 'inline-block',
    position: 'relative'
  },
  findField: {
    width: '98%',
    backgroundColor: '#efa817',
    margin: '10 px 0 0 0',
    border: 0,
    padding: '10px',
    color: '#fff'
  }
}
