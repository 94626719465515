/* eslint-disable camelcase */
// Dependences
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { doFetchLogin, doLoginUser } from '../../actions/user'
import { doClearRequestStatus } from '../../actions/clearStatus'
import { Redirect } from 'react-router-dom'
import { doFetchCompany, doFetchCompanyRole } from '../../actions/company'

// UI Components
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import { withStyles } from '@material-ui/core/styles'
import LinearIndeterminate from '../../components/Loading/LinearIndeterminate'
import MySnackbar from '../../components/MySnackbar/MySnackbar'

// Styles and assets
import './login.sass'
import { loginStyles } from './login.styles'
import logo from './../../assets/images/logo.png'
import logoSunDevs from './../../assets/images/logo-sundevs.png'

import {
  getError,
  getMessage,
  getLoginUserId,
  getLoginToken
} from '../../selectors/user'

import { version } from '../../../package.json'


class Login extends Component {
  constructor() {
    super()
    this.state = {
      email: '',
      password: ''
    }

    this.handleEmail = this.handleEmail.bind(this)
    this.handlePassword = this.handlePassword.bind(this)
    this.login = this.login.bind(this)
  }

  handleEmail = (event) => {
    this.setState({ email: event.target.value })
  }

  handlePassword = (event) => {
    this.setState({ password: event.target.value })
  }

  login = () => {
    this.props.doClearRequestStatus()
    const { email, password } = this.state
    this.props.doFetchLogin({
      data: { email: email, password: password, grant_type: 'password' }
    })
  }

  handleCloseSnackBar = () => {
    this.props.doClearRequestStatus()
  }

  onForgotPassword = () => {
    this.props.history.push('/reset-password')
  }

  componentDidUpdate() {
    if (
      this.props.loginToken !== undefined &&
      this.props.userId !== undefined &&
      !this.props.logged_in
    ) {
      this.props.doLoginUser({
        loginToken: this.props.loginToken,
        userId: this.props.userId
      })
    }
  }

  render() {
    const {
      logged_in,
      error,
      message,
      history,
      classes,
      isLoading,
      user
    } = this.props
    if (logged_in) {
      if (user.companies.length > 1) {
        history.push('/choose-company')
        return <Redirect to="/choose-company"></Redirect>
      } else {
        this.fetchCompany(user.companies[0])
        history.push('/dashboard')
        return <Redirect to="/dashboard"></Redirect>
      }
    } else {
      // TODO: manage error
    }
    const { email, password } = this.state
    return (
      <div className="page-login">
        <MySnackbar
          open={error}
          variant={error ? 'error' : 'default'}
          onClose={this.handleCloseSnackBar}
          message={message || ''}
        />
        <div className="page-login__container">
          <img alt="" src={logo} className="logo" />
          <Card className="login-card">
            <CardContent>
              <Typography variant="subtitle2" className="login-title">
                Iniciar sesión con tu cuenta
              </Typography>
              <TextField
                id="login-name-input"
                label="Correo electrónico"
                margin="normal"
                className="login-input"
                value={email}
                onChange={this.handleEmail}
              />
              <TextField
                id="login-password-input"
                label="Contraseña"
                margin="dense"
                className="login-input"
                type="password"
                value={password}
                onChange={this.handlePassword}
              />
              <Link
                className="login-forgot-password"
                onClick={this.onForgotPassword}
              >
                ¿Olvidaste tu contraseña?
              </Link>
              <Button
                id="login-button"
                variant="contained"
                className={classes.submit}
                fullWidth={true}
                onClick={this.login}
              >
                Iniciar sesión
              </Button>
            </CardContent>
            {isLoading && <LinearIndeterminate />}
          </Card>
          <div className="logo-sundevs">
            <img className="sundevs" src={logoSunDevs} alt="" />
            <Typography> From SunDevs </Typography>
            <Typography> Made in Colombia </Typography>
            <Typography> Version: {version} </Typography>
          </div>
        </div>
      </div>
    )
  }
  fetchCompany = (company) => {
    this.props.doFetchCompanyRole({ companyId: company.id, roleId: company.role.id })
    this.props.doFetchCompany({ companyId: company.id })
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.data,
    logged_in: state.logged_in,
    error: getError(state),
    message: getMessage(state),
    isLoading: state.user.loading,
    loginToken: getLoginToken(state),
    userId: getLoginUserId(state)
  }
}

const mapDispatchToProps = {
  doFetchLogin,
  doLoginUser,
  doFetchCompanyRole,
  doClearRequestStatus,
  doFetchCompany
}

export default withStyles(loginStyles)(
  connect(mapStateToProps, mapDispatchToProps)(Login)
)
