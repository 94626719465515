/* eslint-disable camelcase */
// Dependences
import React, { Component } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { connect } from 'react-redux'
import {
  doCreateFormat,
  doUpdateFormat,
  doFetchPrintableComponents,
  doChangeFormatContent,
  doAddFormatComponent,
  doRemoveFormatComponent,
  doMoveFormatComponent,
  doChangeFormatComponentStylesContent
} from '../../actions/format'
import { doClearRequestStatus } from '../../actions/clearStatus'
import { getLoginToken } from '../../selectors/user'
import {
  getSuccess,
  getError,
  getMessage,
  getDefaultVoucher
} from '../../selectors/format'
import { citiesForFormats } from '../../selectors/city'
import WithLongPress from '../../components/WithLongPress/WithLongPress'
import ToolTip from 'react-portal-tooltip'

// Custom components
import Header from '../../components/header/Header'
import Sidebar from '../../components/sidebar/Sidebar'
import MySnackbar from '../../components/MySnackbar/MySnackbar'
import WithId from '../../components/WithId/WithId'
import CircularLoading from '../../components/Loading/CircularLoading'

// UI Components
import {
  withStyles,
  Paper,
  Typography,
  Divider,
  Input,
  AppBar,
  Toolbar,
  Button,
  List,
  ListItem,
  Checkbox,
  Collapse
} from '@material-ui/core'
import {
  DragIndicator,
  FormatBold,
  FormatClear,
  FormatAlignCenter,
  FormatAlignLeft,
  ExpandMore,
  ExpandLess,
  FormatAlignRight
} from '@material-ui/icons'
// Styles
import './newformat.sass'
import { newFormatStyles } from './newformat.styles'
import { orange } from '@material-ui/core/colors'

const CHECK_TYPES = {
  CITY: 0,
  LOCATION: 1,
  KIOSK: 2,
  ALL: 3
}

const ConfigCheckbox = withStyles({
  root: {
    color: orange[300],
    '&$checked': {
      color: orange[500]
    }
  },
  checked: {}
})((props) => <Checkbox color="default" {...props} />)

const DraggableWithId = WithId(Draggable)

const textLine =
  '________________________________________________________________'
class NewFormat extends Component {
  constructor(props) {
    super(props)
    this.state = {
      identifier: null,
      checkedAll: false,
      cities: [],
      isStepOne: true
    }
  }

  createFormat = () => {
    const { country } = this.props.location.state
    const { kiosks_ids } = this.state
    const { format } = this.props
    var params = {
      name: format.name,
      content: format.content,
      description: format.name,
      kiosks_ids: kiosks_ids,
      country_id: country.id,
      printable_category_id: format.printable_category_id
    }
    // TODO make logic for identifier in creation
    const locations = this.formatLocations()
    if (locations.length > 0) {
      params.locations = locations
    }
    if (
      (locations.length > 0 && format.id === null) ||
      format.kiosks_ids.length === 0
    ) {
      this.props.doCreateFormat({
        data: { params: params, user: this.props.user }
      })
    } else {
      // TODO: Show error
    }
  }

  updateFormat = () => {
    // const { name, title, description, identifier, kiosks_ids } = this.state
    const { format } = this.props
    var params = {
      name: format.name,
      content: format.content,
      description: format.description
    }
    if (format.identifier) {
      params.identifier = format.identifier
    }
    const locations = this.formatLocations()
    if (locations.length > 0) {
      params.locations = locations
    }
    // TODO make logic for identifier in update
    // TODO: make logic for custom voucher
    // "locations": [
    //   {
    //     "id": 2,
    //     "kiosks_ids": [4]
    //   }
    // ]
    // kiosks_ids: kiosks_ids

    this.props.doUpdateFormat({
      data: { id: format.id, params: params, user: this.props.user }
    })
  }

  formatLocations = () => {
    var groupedLocations = []
    this.state.cities.forEach((city) => {
      groupedLocations.push(city.locations)
    })
    const locations = groupedLocations.flat()
    return locations
      .filter((location) => {
        return (
          location.kiosks.filter((kiosk) => {
            return kiosk.checked
          }).length > 0
        )
      })
      .map((location) => {
        return {
          id: location.id,
          kiosks_ids: location.kiosks.map((kiosk) => {
            return kiosk.id
          })
        }
      })
  }

  onChange = (event) => {
    const { name, value } = event.target
    this.setState({ [name]: value })
  }

  handleCloseSnackBar = () => {
    this.props.doClearRequestStatus()
  }

  onBack = () => {
    this.props.history.goBack()
  }

  handleFormatChange = (event) => {
    const { componentId, rowId, columnId } = event.target.dataset
    this.props.doChangeFormatContent({
      componentId: componentId,
      rowId: rowId,
      columnId: columnId,
      newValue: event.target.value
    })
  }

  onNext = () => {
    if (this.shouldSubmit()) {
      this.handleSubmit()
    } else if (this.state.isStepOne) {
      this.setState({ isStepOne: false })
    }
  }

  shouldSubmit = () => {
    return (
      (this.state.isStepOne &&
        this.props.format.kiosks_ids.length === 0 &&
        this.props.format.id !== null) ||
      !this.state.isStepOne ||
      !this.props.defaultVoucher
    )
  }

  onPrevious = () => {
    if (!this.state.isStepOne) {
      this.setState({ isStepOne: true })
    } else {
      this.onBack()
    }
  }

  handleSubmit = () => {
    const { format } = this.props
    if (format.id) {
      this.updateFormat()
    } else {
      this.createFormat()
    }
    setTimeout(() => {
      this.onBack()
    }, 1200)
  }

  componentDidMount() {
    const { format } = this.props
    if (format) {
      this.fetchPrintableComponents(format)
      this.setState({})
    }
  }

  static getDerivedStateFromProps(nextProps) {
    return { cities: nextProps.cities }
  }

  fetchPrintableComponents = (format) => {
    const categoryId = format.printable_category_id
    const params = { printable_category_id: categoryId }
    this.props.doFetchPrintableComponents({
      data: { params: params, user: this.props.user }
    })
  }

  handleChecked = (event, item, cityId, locationId, kioskId) => {
    event.stopPropagation()
    const { checkedAll, cities } = this.state
    this.setState({
      ...this.state,
      cities: cities
        .filter((city) => {
          city.locations = city.locations.filter((location) => {
            return location.kiosks.length > 0
          })
          return city.locations.length > 0
        })
        .map((city) => {
          if (city.id === cityId && item === CHECK_TYPES.CITY) {
            city.checked = !city.checked
          }

          city.checked = item === CHECK_TYPES.ALL ? !checkedAll : city.checked
          let checkedLocationsCount = 0
          city.locations = city.locations.map((location) => {
            if (location.id === locationId && item === CHECK_TYPES.LOCATION) {
              location.checked = !location.checked
            }
            location.checked =
              item === CHECK_TYPES.CITY ? city.checked : location.checked
            location.checked =
              item === CHECK_TYPES.ALL ? !checkedAll : location.checked
            let checkedKiosksCount = 0
            location.kiosks = location.kiosks.map((kiosk) => {
              if (kiosk.id === kioskId && item === CHECK_TYPES.KIOSK) {
                kiosk.checked = !kiosk.checked
              }
              kiosk.checked =
                item === CHECK_TYPES.CITY ? city.checked : kiosk.checked
              kiosk.checked =
                item === CHECK_TYPES.LOCATION ? location.checked : kiosk.checked
              kiosk.checked =
                item === CHECK_TYPES.ALL ? !checkedAll : kiosk.checked
              if (kiosk.checked) {
                checkedKiosksCount += 1
              }
              return kiosk
            })
            location.checked =
              checkedKiosksCount === location.kiosks.length &&
              checkedKiosksCount > 0
            location.indeterminate =
              checkedKiosksCount !== location.kiosks.length &&
              checkedKiosksCount > 0
            if (location.checked) {
              checkedLocationsCount += 1
            }
            return location
          })
          city.checked =
            checkedLocationsCount === city.locations.length &&
            checkedLocationsCount > 0
          city.indeterminate =
            checkedLocationsCount !== city.locations.length &&
            checkedLocationsCount > 0
          return city
        }),
      checkedAll: item === CHECK_TYPES.ALL ? !checkedAll : checkedAll
    })
  }

  toggleMenu = (menu, cityId, locationId) => () => {
    const cities = this.state.cities
    this.setState({
      ...this.state,
      cities: cities
        .filter((city) => {
          const locationsCity = city.locations.filter((location) => {
            return location.kiosks.length > 0
          })
          return locationsCity.length > 0
        })
        .map((city) => {
          if (menu === CHECK_TYPES.CITY) {
            if (city.id === cityId) {
              city.open = !city.open
            }
          } else {
            if (menu === CHECK_TYPES.LOCATION) {
              city.locations = city.locations.map((location) => {
                if (location.id === locationId) {
                  location.open = !location.open
                }
                return location
              })
            }
          }
          return city
        })
    })
  }

  onDragEnd = (result) => {
    if (!result.destination) {
      this.props.doRemoveFormatComponent({ result: result })
    } else {
      if (
        result.source.droppableId === 'droppable-components' &&
        result.destination.droppableId === 'droppable-format'
      ) {
        const component = this.props.components[result.source.index]
        this.props.doAddFormatComponent({
          result: result,
          component: component
        })
      } else if (
        result.source.droppableId === 'droppable-format' &&
        result.destination.droppableId === 'droppable-format'
      ) {
        this.props.doMoveFormatComponent({ result: result })
      }
    }
  }

  onChangeSize = (componentIndex, rowIndex, event) => {
    this.props.doChangeFormatComponentStylesContent({
      componentIndex: componentIndex,
      rowIndex: rowIndex,
      attributes: { size: event.target.value }
    })
  }

  onChangeFont = (componentIndex, rowIndex, weight) => {
    this.props.doChangeFormatComponentStylesContent({
      componentIndex: componentIndex,
      rowIndex: rowIndex,
      attributes: { weight: weight }
    })
  }

  onChangeAlignment = (componentIndex, rowIndex, align) => {
    this.props.doChangeFormatComponentStylesContent({
      componentIndex: componentIndex,
      rowIndex: rowIndex,
      attributes: { align: align }
    })
  }

  sizeFor = (size) => {
    return `${size}px`
  }

  fontFor = (weight) => {
    return weight === 'R'
      ? 'corporatemonocondensed'
      : weight === 'B'
      ? 'corporatecondensedbold'
      : weight === 'Bc'
      ? 'IDAutomationHC39MCode39Barcode'
      : 'corporatemonocondensed'
  }

  alignmentFor = (align) => {
    return align === 'R' ? 'right' : align === 'C' ? 'center' : 'left'
  }

  isATextLine = (value) => {
    return value.includes('______')
  }

  render() {
    const {
      success,
      error,
      message,
      classes,
      components,
      format,
      loading
    } = this.props
    const { country } = this.props.location.state

    const { checkedAll, cities } = this.state
    const openSnackbar = success || error
    let listComponents
    let listFormat
    let sizes = []
    for (let i = 2; i < 42; i += 2) {
      sizes.push(i)
    }
    const fontSizeOptions = sizes.map((size, index) => {
      return (
        <option key={index} value={size}>
          {size}
        </option>
      )
    })

    if (components) {
      listComponents = components.map((component, index) => {
        return (
          <Draggable
            key={component.id}
            draggableId={`component-${component.id}`}
            index={index}
          >
            {(provided) => (
              <div
                className={classes.componentVerticalSpacing}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <span>{component.label}</span>
              </div>
            )}
          </Draggable>
        )
      })

      listFormat = JSON.parse(format.content).map(
        (component, componentIndex) => {
          return (
            <WithLongPress key={component.position} timeoutDeactivated={true}>
              {(isActive) => (
                <React.Fragment>
                  <DraggableWithId
                    id={`format-component-${component.position}`}
                    key={component.position}
                    draggableId={`format-component-${component.position}`}
                    index={componentIndex}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        className="container-grid component-container"
                        {...provided.draggableProps}
                      >
                        <div className="container-grid component-rows-container">
                          {component.rows.map((row, rowIndex) => {
                            return (
                              <Droppable
                                droppableId={`${component.position}-${row.position}`}
                                type="droppable-row"
                                key={row.position}
                              >
                                {(provided) => (
                                  <div
                                    className="container-grid row-container"
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                  >
                                    {row.columns.map((column, columnIndex) => {
                                      return (
                                        <Draggable
                                          key={`${component.position}-${row.position}-${column.position}`}
                                          isDragDisabled={
                                            !component.is_editable
                                          }
                                          draggableId={`${component.position}-${row.position}-${column.position}`}
                                          index={columnIndex}
                                        >
                                          {(provided) => (
                                            <div
                                              className="container-grid column-item column-container"
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                            >
                                              {
                                                <Input
                                                  className={`${classes.formatInput}`}
                                                  style={{
                                                    fontFamily: this.fontFor(
                                                      row.weight
                                                    ),
                                                    fontSize: this.sizeFor(
                                                      row.size
                                                    )
                                                  }}
                                                  align="center"
                                                  fullWidth={true}
                                                  disableUnderline={true}
                                                  type="text"
                                                  readOnly={
                                                    !component.is_editable
                                                  }
                                                  placeholder={
                                                    component.is_editable
                                                      ? 'Insertar texto aquí'
                                                      : ''
                                                  }
                                                  name="name"
                                                  inputProps={{
                                                    'data-column-id': columnIndex,
                                                    'data-row-id': rowIndex,
                                                    'data-component-id': componentIndex,
                                                    style: {
                                                      textAlign: this.isATextLine(
                                                        column.value
                                                      )
                                                        ? this.alignmentFor('C')
                                                        : this.alignmentFor(
                                                            row.align
                                                          )
                                                    }
                                                  }}
                                                  onChange={
                                                    this.handleFormatChange
                                                  }
                                                  value={
                                                    this.isATextLine(
                                                      column.value
                                                    )
                                                      ? textLine
                                                      : column.value
                                                  }
                                                />
                                              }
                                              {component.is_editable && (
                                                <span
                                                  {...provided.dragHandleProps}
                                                  style={{
                                                    marginTop: '2px'
                                                  }}
                                                >
                                                  {/* {isActive &&
                                          <DragIndicator
                                            className={classes.dragStyle}
                                          />
                                        } */}
                                                </span>
                                              )}
                                            </div>
                                          )}
                                        </Draggable>
                                      )
                                    })}
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                            )
                          })}
                        </div>
                        <span
                          {...provided.dragHandleProps}
                          style={{
                            marginTop: '2px'
                          }}
                        >
                          {isActive && (
                            <DragIndicator className={classes.dragStyle} />
                          )}
                        </span>
                      </div>
                    )}
                  </DraggableWithId>
                  <ToolTip
                    active={isActive && component.is_editable}
                    position="right"
                    tooltipTimeout={200}
                    style={{
                      style: {
                        margin: '0 0 0 10px'
                      },
                      arrowStyle: {}
                    }}
                    parent={`#format-component-${component.position}`}
                  >
                    <FormatClear
                      onClick={() => this.onChangeFont(componentIndex, 0, 'R')}
                    />
                    <FormatBold
                      onClick={() => this.onChangeFont(componentIndex, 0, 'B')}
                    />
                    <select
                      className={classes.sizeSelect}
                      value={component.rows[0].size}
                      onChange={(event) =>
                        this.onChangeSize(componentIndex, 0, event)
                      }
                    >
                      {fontSizeOptions}
                    </select>
                    <FormatAlignCenter
                      onClick={() =>
                        this.onChangeAlignment(componentIndex, 0, 'C')
                      }
                    />
                    <FormatAlignLeft
                      onClick={() =>
                        this.onChangeAlignment(componentIndex, 0, 'L')
                      }
                    />
                    <FormatAlignRight
                      onClick={() =>
                        this.onChangeAlignment(componentIndex, 0, 'R')
                      }
                    />
                  </ToolTip>
                </React.Fragment>
              )}
            </WithLongPress>
          )
        }
      )
    }
    var listItems
    if (cities) {
      listItems = cities
        .filter((city) => {
          const locationsCity = city.locations.filter((location) => {
            return location.kiosks.length > 0
          })
          return locationsCity.length > 0
        })
        .map((city) => {
          return (
            <div key={city.id}>
              <ListItem
                button
                className={classes.listItem}
                onClick={this.toggleMenu(CHECK_TYPES.CITY, city.id)}
              >
                <ConfigCheckbox
                  checked={city.checked}
                  indeterminate={city.indeterminate}
                  onClick={(event) =>
                    this.handleChecked(event, CHECK_TYPES.CITY, city.id)
                  }
                />
                <Typography>{city.name}</Typography>
                {city.open ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={city.open} timeout="auto" unmountOnExit>
                <List>
                  {city.locations.map((location) => {
                    if (location.kiosks.length > 0) {
                      return (
                        <div key={location.id}>
                          <ListItem
                            button
                            className={`${classes.listItem} ${classes.nested}`}
                            onClick={this.toggleMenu(
                              CHECK_TYPES.LOCATION,
                              city.id,
                              location.id
                            )}
                          >
                            <ConfigCheckbox
                              checked={location.checked}
                              indeterminate={location.indeterminate}
                              onClick={(event) =>
                                this.handleChecked(
                                  event,
                                  CHECK_TYPES.LOCATION,
                                  city.id,
                                  location.id
                                )
                              }
                            />
                            {location.name}
                            {location.open ? <ExpandLess /> : <ExpandMore />}
                          </ListItem>
                          <Collapse
                            in={location.open}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List>
                              {location.kiosks.map((kiosk) => {
                                return (
                                  <div key={kiosk.id}>
                                    <ListItem
                                      button
                                      className={`${classes.listItem} ${classes.nestedKiosk}`}
                                    >
                                      <ConfigCheckbox
                                        checked={kiosk.checked}
                                        onClick={(event) =>
                                          this.handleChecked(
                                            event,
                                            CHECK_TYPES.KIOSK,
                                            city.id,
                                            location.id,
                                            kiosk.id
                                          )
                                        }
                                      />
                                      {kiosk.name}
                                    </ListItem>
                                  </div>
                                )
                              })}
                            </List>
                          </Collapse>
                        </div>
                      )
                    } else {
                      return <></>
                    }
                  })}
                </List>
              </Collapse>
              {!city.open && <Divider />}
            </div>
          )
        })
    }
    return (
      <>
      <div className="page-format">
        <Header currentPage={`Configuraciones`} />
        <Sidebar level={1} country={country} />
        <div className={classes.content}>
          <div className={classes.header}>
            <MySnackbar
              open={openSnackbar}
              variant={
                openSnackbar ? (success ? 'success' : 'error') : 'default'
              }
              onClose={this.handleCloseSnackBar}
              message={openSnackbar ? message : ''}
            />
          </div>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <div
              className={`container-grid ${
                this.state.isStepOne
                  ? 'format-main-container'
                  : 'main-container-next-tab'
              }`}
            >
              {this.state.isStepOne && (
                <div className="main-container-item">
                  <Paper
                    className={`${classes.paper} ${classes.componentsList}`}
                  >
                    <Typography variant="h6" align="center">
                      Componentes
                    </Typography>
                    <Divider className={classes.separator} />
                    {components && (
                      <Droppable
                        droppableId="droppable-components"
                        type="droppable-component"
                      >
                        {(provided) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {listComponents}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    )}
                  </Paper>
                </div>
              )}
              <div className="main-container-item">
                <div className={classes.formatFlexContainer}>
                  <Paper className={`${classes.paper} ${classes.format}`}>
                    <Droppable
                      droppableId="droppable-format"
                      type="droppable-component"
                    >
                      {(provided) => (
                        <div
                          className={
                            JSON.parse(format.content).length === 0
                              ? classes.emptyFormat
                              : ''
                          }
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {listFormat}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </Paper>
                </div>
              </div>
              {(format.id === null || format.kiosks_ids.length > 0) &&
                !this.state.isStepOne && (
                  <Paper className={`${classes.paper} main-container-item`}>
                    <Typography variant="h6">Asignar ubicaciones</Typography>
                    <ListItem className={classes.listItem}>
                      <ConfigCheckbox
                        checked={checkedAll}
                        onClick={(event) =>
                          this.handleChecked(event, CHECK_TYPES.ALL)
                        }
                        tabIndex={-1}
                        disableRipple
                      />
                      <Typography variant="subtitle2">
                        Asignar todas las ubicaciones
                      </Typography>
                    </ListItem>
                    <Divider />
                    <List>{listItems}</List>
                  </Paper>
                )}
            </div>
          </DragDropContext>
        </div>
        <AppBar position="static" className={classes.footbar}>
          <Toolbar className="header__toolbar">
            <Button
              variant="text"
              className={classes.backButton}
              onClick={this.onPrevious}
            >
              Regresar
            </Button>
            <Button
              variant="contained"
              className={classes.saveButton}
              onClick={this.onNext}
            >
              {this.shouldSubmit() ? 'Guardar' : 'Continuar'}
            </Button>
          </Toolbar>
        </AppBar>
      </div>
      <CircularLoading isLoading={loading} />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: getLoginToken(state),
    success: getSuccess(state),
    error: getError(state),
    loading: state.format.loading,
    message: getMessage(state),
    components: state.format.components,
    format: state.format.selectedFormat,
    cities: citiesForFormats(state.city, state.format.selectedFormat),
    defaultVoucher: getDefaultVoucher(state.format)
  }
}

const mapDispatchToProps = {
  doCreateFormat,
  doUpdateFormat,
  doClearRequestStatus,
  doFetchPrintableComponents,
  doMoveFormatComponent,
  doAddFormatComponent,
  doRemoveFormatComponent,
  doChangeFormatContent,
  doChangeFormatComponentStylesContent
}

export default withStyles(newFormatStyles)(
  connect(mapStateToProps, mapDispatchToProps)(NewFormat)
)
