import {
  AREA_CREATE,
  AREA_CREATE_SUCCESS,
  AREA_CREATE_ERROR,
  FETCH_AREAS,
  FETCH_AREAS_SUCCESS,
  FETCH_AREAS_ERROR,
  ASSIGN_LOCATIONS,
  DELETE_AREA,
  DELETE_AREA_ERROR,
  DELETE_AREA_SUCCESS,
  UPDATE_AREA,
  UPDATE_AREA_SUCCESS,
  UPDATE_AREA_ERROR
} from '../constants/ActionTypes'

export const doCreateArea = payload => ({ type: AREA_CREATE, payload })
export const doCreateAreaSuccess = payload => ({ type: AREA_CREATE_SUCCESS, payload })
export const doCreateAreaError = payload => ({ type: AREA_CREATE_ERROR, payload })

export const doFetchAreas = payload => ({ type: FETCH_AREAS, payload })
export const doFetchAreasSuccess = payload => ({ type: FETCH_AREAS_SUCCESS, payload })
export const doFetchAreasError = payload => ({ type: FETCH_AREAS_ERROR, payload })

export const doAssignLocations = payload => ({ type: ASSIGN_LOCATIONS, payload })

export const doDeleteArea = payload => ({ type: DELETE_AREA, payload })
export const doDeleteAreaSuccess = payload => ({ type: DELETE_AREA_SUCCESS, payload })
export const doDeleteAreaError = payload => ({ type: DELETE_AREA_ERROR, payload })

export const doUpdateArea = payload => ({ type: UPDATE_AREA, payload })
export const doUpdateAreaSuccess = payload => ({ type: UPDATE_AREA_SUCCESS, payload })
export const doUpdateAreaError = payload => ({ type: UPDATE_AREA_ERROR, payload })


