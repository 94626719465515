import { createStore, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import { createLogger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import storage from 'redux-persist/lib/storage'
// import devToolsEnhancer from 'remote-redux-devtools'
import reducer from '../reducers'
import rootSaga from '../sagas'
import { RESET_APP_STATE } from '../constants/ActionTypes'

const persistConfig = {
  key: 'root',
  storage
}

const logger = createLogger()
const saga = createSagaMiddleware()

const rootReducer = (state, action) => {
  if (action.type === RESET_APP_STATE) {
    storage.removeItem('persist:root')
    state = undefined
  }
  return reducer(state, action)
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const initialStore = {
  user: {}
}

let store = createStore(
  persistedReducer,
  initialStore,
  applyMiddleware(saga, logger)
)

let persistor = persistStore(store)
saga.run(rootSaga)

export { store, persistor }
