/* eslint-disable camelcase */
// Dependences
import React from 'react'

// UI components
import { withStyles } from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'
import Input from '@material-ui/core/Input'
import Edit from '@material-ui/icons/Edit'

// Styles and assets
import { localeRowStyles } from './localerow.styles'

const LocaleRow = (props) => {
  const { locale, localeNewValue, onUpdate, onEdit, onLocaleChange } = props
  const handleUpdate = () => {
    onUpdate(locale)
  }

  const handleEdit = () => {
    onEdit(locale)
  }

  const handleLocaleChange = (event) => {
    onLocaleChange(event.target.value)
  }

  return (
    <TableRow>
      <TableCell>{locale.value}</TableCell>
      <TableCell>{locale.key}</TableCell>
      <TableCell>
        {!locale.isEditing ? (
          locale.value
        ) : (
          <Input
            className={props.classes.editInput}
            disabled={false}
            value={localeNewValue || locale.value}
            onChange={handleLocaleChange}
            autoFocus={true}
          />
        )}
      </TableCell>
      <TableCell
        align="right"
        padding="dense"
        className={props.classes.tableColumn}
      >
        {locale.isEditing && (
          <Button className={props.classes.btnDetails} onClick={handleUpdate}>
            Actualizar
          </Button>
        )}
      </TableCell>
      <TableCell align="right" padding="dense">
        <Edit onClick={handleEdit} />
      </TableCell>
    </TableRow>
  )
}

export default withStyles(localeRowStyles)(LocaleRow)
