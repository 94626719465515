import React from 'react'
import { Route, Redirect, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import FilterRolePermissions from '../../utils/FilterRolePermissions'

function validateRolesAndPromotionsOnly(resource, userRole, selectedCountry, countries, firstCountry) {
  const router = useHistory()
  try {
    let hasCountrySettingsPermission = false
    let hasUpdatePermissionOnly = false
    let uniqPermission = ''
    let country = {}
    if (selectedCountry) country = selectedCountry
    else country = countries ? countries[0] : firstCountry
    if (userRole.permission.length === 1) {
      const permission = userRole.permission[0]
      switch (permission.resource) {
        case 'promotion':
          hasCountrySettingsPermission = true
          uniqPermission = 'isPromotionsOnly'
          break;
        case 'printable_format':
          hasCountrySettingsPermission = true
          uniqPermission = 'isPrintableFormatsOnly'
          break;
        case 'locale':
          hasCountrySettingsPermission = true
          uniqPermission = 'isLocalesOnly'
          break;
        case 'role':
          uniqPermission = 'isRolesOnly'
          break;
        case 'update':
          hasUpdatePermissionOnly = true
          uniqPermission = 'isUpdateOnly'
          break
        default:
          break;
      }
    }
    const currentUrl = window.location.href
    if (uniqPermission === 'isRolesOnly' && !currentUrl.includes('roles-permissions')) {
      router.push('roles-permissions')
      return true
    } else if (hasCountrySettingsPermission) {
      if (hasUpdatePermissionOnly) {
        router.push('settings', { country, level: 1, hasUpdatePermissionOnly })
        return true
      } else if (!currentUrl.includes('settings-by-country')) {
        const countryPath = `settings-by-country/${country.id}`
        router.push(countryPath, { country, level: 1, uniqPermission })
        return true
      } else return true
    } else return FilterRolePermissions(resource, 'see', userRole) ? true : <Redirect to="/" />
  } catch (error) {
    console.log(error)
    return FilterRolePermissions(resource, 'see', userRole) ? true : <Redirect to="/" />
  }
}

export default function PrivateRoute ({ component: Component, resource, ...rest }) {
  const isLoggedIn = useSelector(state => state.logged_in)
  const userRole = useSelector(state => state.user.role)
  const { countries, selectedCountry } = useSelector(state => state.country)
  const firstCountry = useSelector(state => state.company.first_country)
  if (resource) {
    const shouldGoNextRoute = validateRolesAndPromotionsOnly(resource, userRole, selectedCountry, countries, firstCountry)
    if (shouldGoNextRoute) return (<Route {...rest} render={props => isLoggedIn ?
          (<Component {...props} {...rest} />) : (<Redirect to="/login" />)
        }/>
      )
  } else {
    return (<Route {...rest} render={props => isLoggedIn ?
        (<Component {...props} {...rest} />) : (<Redirect to="/login" />)
      }/>
    )
  }
}

