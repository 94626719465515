export const IN_SERVICE = 1
export const OUT_OF_SERVICE = 0
export const WARNING = 2
export const ALL_STATUSES = -1
export const POS_TYPE = 1
export const SCREEN_TYPE = 2
export const SETTINGS_DATA_TYPES = {
  NUMBER: 0,
  STRING: 1,
  BOOLEAN: 2,
  OBJECT: 3,
  ARRAY: 4
}
export const CURRENCY_CODES = [
  {
    countryName: 'Honduras',
    languajeCode: 'es-HN',
    currency: 'HNL',
    currencySymbol: null
  },
  {
    countryName: 'Guatemala',
    languajeCode: 'es-GT',
    currency: 'GTQ',
    currencySymbol: null
  },
  {
    countryName: 'Curacao',
    languajeCode: null,
    currency: 'ANG',
    currencySymbol: 'ƒ'
  },
  {
    countryName: 'Costa Rica',
    languajeCode: 'es-CR',
    currency: 'CRC',
    currencySymbol: null
  },
  {
    countryName: 'Panamá',
    languajeCode: 'es-PA',
    currency: 'PAB',
    currencySymbol: null
  },
  {
    countryName: 'Nicaragua',
    languajeCode: 'es-NI',
    currency: 'NIO',
    currencySymbol: null
  },
  {
    countryName: 'El Salvador',
    languajeCode: 'es-SV',
    currency: 'SVC',
    currencySymbol: null
  },
  {
    countryName: 'Colombia',
    languajeCode: 'es-CO',
    currency: 'COP',
    currencySymbol: null
  },
  {
    countryName: 'Perú',
    languajeCode: 'es-PE',
    currency: 'PEN',
    currencySymbol: null
  },
  {
    countryName: 'Bolivia',
    languajeCode: 'qu-BO',
    currency: 'BOB',
    currencySymbol: null
  },
  {
    countryName: 'Chile',
    languajeCode: 'es-CL',
    currency: 'CLP',
    currencySymbol: null
  },
  {
    countryName: 'Ecuador',
    languajeCode: 'es-EC',
    currency: 'USD',
    currencySymbol: null
  },
  {
    countryName: 'Paraguay',
    languajeCode: 'es-PY',
    currency: 'PYG',
    currencySymbol: null
  },
  {
    countryName: 'Argentina',
    languajeCode: 'es-AR',
    currency: 'ARS',
    currencySymbol: null
  },
  {
    countryName: 'Uruguay',
    languajeCode: 'es-UY',
    currency: 'UYU',
    currencySymbol: null
  }
]
export const PROMOTION_SECTION = {
  HOMESCREEN: 1,
  BILLBOARD: 2,
  CONCESSIONS: 3,
  PICKUPTICKETS: 4
}
export const TRANSACTION_STATUS = {
  NOT_PRINTED: 3,
  COMPLETED: 2,
  INCOMPLETE: 1,
  NOT_PAYED: 0
}
export const ITEM_TYPES = {
  TICKET: 1,
  CONCESSIONS: 2,
  BOOKING_FEE: 3
}
