import { combineReducers } from 'redux'
import { USER_LOGGED_IN } from '../constants/ActionTypes'
import user from './user'
import country from './country'
import city from './city'
import kiosk from './kiosk'
import locale from './locale'
import setting from './setting'
import configuration from './configuration'
import location from './location'
import area from './area'
import banner from './banner'
import company from './company'
import format from './format'
import update from './update'
import rolePermission from './rolePermission'

const auth = (state = false, action) => {
  switch (action.type) {
    case USER_LOGGED_IN:
      return !state
    default:
      return state
  }
}

export default combineReducers({
  user,
  logged_in: auth,
  country,
  city,
  kiosk,
  locale,
  setting,
  location,
  area,
  banner,
  company,
  configuration,
  format,
  update,
  rolePermission
})
