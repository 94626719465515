import { CREATE_SETTINGS, UPDATE_SETTINGS, SETTINGS_FETCH, CLEAR_REQUEST_STATUS,
  UPDATE_SETTINGS_ERROR, SETTINGS_FETCH_ERROR,
  CREATE_SETTINGS_SUCCESS, UPDATE_SETTINGS_SUCCESS, SETTINGS_FETCH_SUCCESS, CREATE_SETTINGS_ERROR } from '../constants/ActionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case CREATE_SETTINGS:
      return {
        ...state,
        loading: true
      }
    case UPDATE_SETTINGS:
      return {
        ...state,
        loading: true
      }
    case SETTINGS_FETCH:
      return action.payload
    case CREATE_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        message: 'Settings modificados exitosamente'
      }
    case CREATE_SETTINGS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: 'Error al intentar crear settings'
      }
    case UPDATE_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        message: 'Settings modificados exitosamente'
      }
    case UPDATE_SETTINGS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: 'Error al intentar actualizar settings'
      }
    case SETTINGS_FETCH_SUCCESS:
      return {
        ...state,
        settings: action.payload,
        loading: false
      }
    case SETTINGS_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: 'Error al obtener settings'
      }
    case CLEAR_REQUEST_STATUS:
      return {
        ...state,
        success: false,
        error: false,
        message: null
      }
    default:
      return state
  }
}
