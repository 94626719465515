export const HeaderBarStyles = {
  navbar: {
    backgroundColor: '#fff',
    width: '100%',
    marginLeft: '0',
    boxShadow: '2px 2px 10px 3px #ccc',
    position: 'relative'
  },
  logout: {
    color: '#000',
    fontSize: '12px',
    '&:hover': {
      color: '#EFA817'
    }
  },
  logo: {
    width: '100px',
    margin: '10px'
  }
}
