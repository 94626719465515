import { sidebarWidth } from '../../components/sidebar/Sidebar'
export const RolePermissionStyles = {
  content: {
    padding: '130px 20px 20px 20px',
    marginLeft: sidebarWidth,
    width: '100%' - sidebarWidth
  },
  textInfo: {
    marginLeft: '10px'
  },
  table: {
    minWidth: 50
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  paper: {
    marginTop: 0
  }
}
