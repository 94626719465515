import ApiClientSingleton from '../api-client'

const kioskApiInstance = ApiClientSingleton.getKioskApiInstance()

const fetchKioskTransactions = async (payload) => {
  const { companyId, kioskId, user } = payload
  const uri = `/api/v1/companies/${companyId}/kiosks/${kioskId}/transactions`
  const headers = { Authorization: `Bearer ${user}` }
  const { data } = await kioskApiInstance.get(uri, { headers })
  return data
}

const fetchKioskDevices = async (payload) => {
  const { kioskId, user } = payload
  const uri = `/api/v1/device?kiosk_id=${kioskId}`
  const headers = { Authorization: `Bearer ${user}` }
  const { data } = await kioskApiInstance.get(uri, { headers })
  return data
}

const deleteKiosk = async (payload) => {
  const { companyId, kioskId } = payload
  const uri = `/api/v1/companies/${companyId}/kiosks/${kioskId}`
  const { status } = await kioskApiInstance.delete(uri)
  return status
}

const fetchKiosksInfo = async (payload) => {
  const { companyId, user } = payload
  const uri = `/api/v1/companies/${companyId}/kiosks`
  const headers = { Authorization: `Bearer ${user}` }
  const { data } = await kioskApiInstance.get(uri, { headers })
  return data
}

const fetchKioskById = async (payload) => {
  const { kioskId, companyId } = payload
  const uri = `/api/v1/companies/${companyId}/kiosks/${kioskId}`
  const { data } = await kioskApiInstance.get(uri)
  return data
}

export {
  fetchKioskTransactions,
  fetchKioskDevices,
  deleteKiosk,
  fetchKiosksInfo,
  fetchKioskById
}
