import { sidebarWidth } from './../../components/sidebar/Sidebar'

export const userProfileStyles = {
  content: {
    padding: '115px 20px 20px 20px',
    marginLeft: sidebarWidth
  },
  content_paper: {
    padding: '2em 1em 1em'
  },
  user_info: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '4em',
    marginBottom: '2em'
  },
  user_avatar: {
    width: '2em',
    height: '2em',
    fontSize: '5em',
    backgroundColor: 'var(--var-color-primary-yellow)',
    color: 'var(--var-color-primary-white)'
  },
  user_data: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginLeft: '4em',
    fontWeight: 'bold'
  },
  user_forms: {
    display: 'flex'
  },
  data_form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: '0.5em'
  },
  data_subtitle: {
    color: 'var(--var-color-primary-yellow)'
  },
  cssFocused: {},
  notchedOutline: {},
  cssLabel: {
    '&$cssFocused': {
      color: '#EFA91B'
    }
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: '#EFA91B'
    },
    textAlign: 'left'
  },
  input_margin: {
    margin: '1em 0em'
  },
  divider: {
    width: '100%'
  },
  save_button: {
    marginTop: '1em',
    width: '7em',
    float: 'right',
    alignSelf: 'flex-end',
    backgroundColor: 'var(--var-color-primary-yellow)',
    color: 'var(--var-color-primary-white)'
  }
}
