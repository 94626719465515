import {
  GET_ALL_VERSIONS,
  GET_ALL_VERSIONS_SUCCESS,
  GET_ALL_VERSIONS_ERROR,
  SEND_FORCED_UPDATE,
  SEND_FORCED_UPDATE_SUCCESS,
  SEND_FORCED_UPDATE_ERROR
} from '../constants/ActionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_VERSIONS:
      return {
        ...state,
        loading: false,
        success: false
      }
    case GET_ALL_VERSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: false,
        versions: action.payload
      }
    case GET_ALL_VERSIONS_ERROR:
      return {
        success: false,
        versions: []
      }
    case SEND_FORCED_UPDATE:
      return {
        ...state,
        loading: false,
        success: false,
        message: null
      }
    case SEND_FORCED_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        message: 'Se ha enviado la actualizacion forzada'
      }
    case SEND_FORCED_UPDATE_ERROR:
      return {
        ...state,
        success: false
      }
    default:
      return state
  }
}
