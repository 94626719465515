export const bannerCellStyles = {
  btnDetails: {
    border: '1px solid #9f9f9f',
    color: '#737373'
  },
  containerBordered: {
    borderStyle: 'dashed',
    borderWidth: '1.2px',
    height: '10em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4
  },
  childrenContainer: {
    height: '100%',
    textAlign: 'center',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 4
  },
  emptyBoxContainer: {
    width: '100%',
    height: '100%',
    marginTop: '10%',
    marginBottom: '10%'
    // position: 'relative'
  },
  addIcon: {
    color: '#EFA817'
  },
  bannerImg: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    maxWidth: '230px',
    maxHeight: '150px',
    width: 'auto',
    height: 'auto'
  },
  btnDelete: {
    backgroundColor: '#EFA817',
    width: '100%',
    position: 'absolute',
    bottom: 0,
    padding: 5,
    color: '#fff',
    fontSize: 12
  },
  click_to_select: {
    color: '#EFA817'
  }
}
