import React from 'react'
import { FormControl, TextField } from '@material-ui/core'

const NewRoleForm = ({ classes, changeField, dataRole }) => {
  return (
    <div className="new_user__content-form">
      <FormControl className={classes.formControl}>
        <TextField
          id="role_name"
          className={classes.inputFirstName}
          variant="outlined"
          value={dataRole.role_name ? dataRole.role_name : ''}
          type="text"
          label="Nombre del rol"
          onChange={(event) => {
            changeField(event)
          }}
          InputLabelProps={{
            classes: {
              root: classes.cssLabel,
              focused: classes.cssFocused
            }
          }}
          InputProps={{
            classes: {
              root: classes.cssOutlinedInput,
              focused: classes.cssFocused,
              notchedOutline: classes.notchedOutline
            }
          }}
          required
        />
        <TextField
          id="role_description"
          className={classes.formControl}
          variant="outlined"
          value={dataRole.role_description ? dataRole.role_description : ''}
          type="text"
          label="Descripcion del rol"
          onChange={(event) => {
            changeField(event)
          }}
          InputLabelProps={{
            classes: {
              root: classes.cssLabel,
              focused: classes.cssFocused
            }
          }}
          InputProps={{
            classes: {
              root: classes.cssOutlinedInput,
              focused: classes.cssFocused,
              notchedOutline: classes.notchedOutline
            }
          }}
          required
        />
      </FormControl>
    </div>
  )
}

export default NewRoleForm
