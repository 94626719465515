import {
  COUNTRIES_STATUS_FETCH,
  COUNTRIES_STATUS_FETCH_SUCCESS,
  COUNTRIES_STATUS_FETCH_ERROR
} from '../constants/ActionTypes'

export const doFetchCountries = payload => ({ type: COUNTRIES_STATUS_FETCH, payload })
export const doFetchCountriesSuccess = payload => ({ type: COUNTRIES_STATUS_FETCH_SUCCESS, payload })
export const doFetchCountriesError = payload => ({ type: COUNTRIES_STATUS_FETCH_ERROR, payload })
export const doSaveSelectedCountry = payload => ({ type: 'SAVE_SELECTED_COUNTRY', payload })
