import ApiClientSingleton from '../api-client'

const kioskApiInstance = ApiClientSingleton.getKioskApiInstance()

const fetchCountries = async (user, companyId) => {
  const uri = `/api/v1/companies/${companyId}/countries-detail`
  const headers = { Authorization: `Bearer ${user}` }
  const { data } = await kioskApiInstance.get(uri, { headers })
  return data
}

export { fetchCountries }
