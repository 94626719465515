export const USER_LOGGED_IN = 'USER_LOGGED_IN'
export const USER_LOGIN = 'USER_LOGIN'
export const LOGIN_FETCH = 'LOGIN_FETCH'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const USERS_FETCH = 'USERS_FETCH'
export const USERS_FETCH_SUCCESS = 'USERS_FETCH_SUCCESS'
export const USERS_FETCH_ERROR = 'USERS_FETCH_ERROR'
export const NEW_USER_FETCH = 'NEW_USER_FETCH'
export const NEW_USER_FETCH_SUCCESS = 'NEW_USER_FETCH_SUCCESS'
export const NEW_USER_FETCH_ERROR = 'NEW_USER_FETCH_ERROR'
export const FETCH_EMPLOYEE = 'FETCH_EMPLOYEE'
export const FETCH_EMPLOYEE_SUCCESS = 'FETCH_EMPLOYEE_SUCCESS'
export const FETCH_EMPLOYEE_ERROR = 'FETCH_EMPLOYEE_ERROR'
export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE'
export const UPDATE_EMPLOYEE_SUCCESS = 'UPDATE_EMPLOYEE_SUCCESS'
export const UPDATE_EMPLOYEE_ERROR = 'UPDATE_EMPLOYEE_ERROR'
export const END_REQUEST = 'END_REQUEST'
export const CREATE_USER = 'CREATE_USER'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR'
export const INACTIVE_USER = 'INACTIVE_USER'
export const INACTIVE_USER_SUCCESS = 'INACTIVE_USER_SUCCESS'
export const INACTIVE_USER_ERROR = 'INACTIVE_USER_ERROR'
export const RESET_USER_PASSWORD = 'RESET_USER_PASSWORD'
export const RESET_USER_PASSWORD_SUCCESS = 'RESET_USER_PASSWORD_SUCCESS'
export const RESET_USER_PASSWORD_ERROR = 'RESET_USER_PASSWORD_ERROR'
export const UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD'
export const UPDATE_USER_PASSWORD_SUCCESS = 'UPDATE_USER_PASSWORD_SUCCESS'
export const UPDATE_USER_PASSWORD_ERROR = 'UPDATE_USER_PASSWORD_ERROR'
export const CREATE_USER_PASSWORD = 'CREATE_USER_PASSWORD'
export const COUNTRIES_STATUS_FETCH = 'COUNTRIES_STATUS_FETCH'
export const COUNTRIES_STATUS_FETCH_SUCCESS = 'COUNTRIES_STATUS_FETCH_SUCCESS'
export const COUNTRIES_STATUS_FETCH_ERROR = 'COUNTRIES_STATUS_FETCH_ERROR'
export const CITIES_STATUS_FETCH = 'CITIES_STATUS_FETCH'
export const CITIES_STATUS_FETCH_SUCCESS = 'CITIES_STATUS_FETCH_SUCCESS'
export const CITIES_STATUS_FETCH_ERROR = 'CITIES_STATUS_FETCH_ERROR'
export const CITIES_STATUS_BY_COUNTRY_ID = 'CITIES_STATUS_BY_COUNTRY_ID'
export const CITIES_STATUS_BY_COUNTRY_ID_SUCCESS =
  'CITIES_STATUS_BY_COUNTRY_ID_SUCCESS'
export const CITIES_STATUS_BY_COUNTRY_ID_ERROR =
  'CITIES_STATUS_BY_COUNTRY_ID_ERROR'
export const CITIES_STATUS_AND_LOCATIONS = 'CITIES_STATUS_AND_LOCATIONS'
export const CITIES_STATUS_AND_LOCATIONS_SUCCESS =
  'CITIES_STATUS_AND_LOCATIONS_SUCCESS'
export const CITIES_STATUS_AND_LOCATIONS_ERROR =
  'CITIES_STATUS_AND_LOCATIONS_ERROR'
export const KIOSK_BY_LOCATION_FETCH = 'KIOSK_BY_LOCATION_FETCH'
export const KIOSK_BY_LOCATION_SUCCESS = 'KIOSK_BY_LOCATION_SUCCESS'
export const KIOSK_BY_LOCATION_ERROR = 'KIOSK_BY_LOCATION_ERROR'
export const KIOSK_TRANSACTIONS_FETCH = 'KIOSK_TRANSACTIONS_FETCH'
export const KIOSK_TRANSACTIONS_FETCH_SUCCESS =
  'KIOSK_TRANSACTIONS_FETCH_SUCCESS'
export const FETCH_COUNTRY_LOCATIONS = 'FETCH_COUNTRY_LOCATIONS'
export const FETCH_COUNTRY_LOCATIONS_SUCCESS = 'FETCH_COUNTRY_LOCATIONS_SUCCESS'
export const FETCH_COUNTRY_LOCATIONS_ERROR = 'FETCH_COUNTRY_LOCATIONS_ERROR'
export const KIOSK_TRANSACTIONS_FETCH_ERROR = 'KIOSK_TRANSACTIONS_FETCH_ERROR'
export const KIOSK_DEVICES_FETCH = 'KIOSK_DEVICES_FETCH'
export const KIOSK_DEVICES_FETCH_SUCCESS = 'KIOSK_DEVICES_FETCH_SUCCESS'
export const KIOSK_DEVICES_FETCH_ERROR = 'KIOSK_DEVICES_FETCH_ERROR'
export const LOCALES_FETCH = 'LOCALES_FETCH'
export const LOCALES_FETCH_SUCCESS = 'LOCALES_FETCH_SUCCESS'
export const LOCALES_FETCH_ERROR = 'LOCALES_FETCH_ERROR'
export const UPDATE_LOCALE = 'UPDATE_LOCALE'
export const UPDATE_LOCALE_SUCCESS = 'UPDATE_LOCALE_SUCCESS'
export const UPDATE_LOCALE_ERROR = 'UPDATE_LOCALE_ERROR'
export const CREATE_SETTINGS = 'CREATE_SETTINGS'
export const CREATE_SETTINGS_SUCCESS = 'CREATE_SETTINGS_SUCCESS'
export const CREATE_SETTINGS_ERROR = 'CREATE_SETTINGS_ERROR'
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS'
export const UPDATE_SETTINGS_SUCCESS = 'UPDATE_SETTINGS_SUCCESS'
export const UPDATE_SETTINGS_ERROR = 'UPDATE_SETTINGS_ERROR'
export const SETTINGS_FETCH = 'SETTINGS_FETCH'
export const SETTINGS_FETCH_SUCCESS = 'SETTINGS_FETCH_SUCCESS'
export const SETTINGS_FETCH_ERROR = 'SETTINGS_FETCH_ERROR'
export const LOCATIONS_FETCH = 'LOCATIONS_FETCH'
export const LOCATIONS_CREATE = 'LOCATIONS_CREATE'
export const LOCATIONS_UPDATE = 'LOCATIONS_UPDATE'
export const LOCATIONS_UPDATE_ERROR = 'LOCATIONS_UPDATE_ERROR'
export const LOCATIONS_CREATE_ERROR = 'LOCATIONS_CREATE_ERROR'
export const LOCATIONS_CREATE_SUCCESS = 'LOCATIONS_CREATE_SUCCESS'
export const LOCATIONS_UPDATE_SUCCESS = 'LOCATIONS_UPDATE_SUCCESS'
export const LOCATIONS_FETCH_SUCCESS = 'LOCATIONS_FETCH_SUCCESS'
export const LOCATIONS_FETCH_ERROR = 'LOCATIONS_FETCH_ERROR'
export const AREA_CREATE = 'AREA_CREATE'
export const AREA_CREATE_SUCCESS = 'AREA_CREATE_SUCCESS'
export const AREA_CREATE_ERROR = 'AREA_CREATE_ERROR'
export const FETCH_AREAS = 'FETCH_AREAS'
export const FETCH_AREAS_SUCCESS = 'FETCH_AREAS_SUCCESS'
export const FETCH_AREAS_ERROR = 'FETCH_AREAS_ERROR'
export const ASSIGN_LOCATIONS = 'ASSIGN_LOCATIONS'
export const ASSIGN_LOCATIONS_SUCCESS = 'ASSIGN_LOCATIONS_SUCCESS'
export const ASSIGN_LOCATIONS_ERROR = 'ASSIGN_LOCATIONS_ERROR'
export const DELETE_AREA = 'DELETE_AREA'
export const DELETE_AREA_SUCCESS = 'DELETE_AREA_SUCCESS'
export const DELETE_AREA_ERROR = 'DELETE_AREA_ERROR'
export const UPDATE_AREA = 'UPDATE_AREA'
export const UPDATE_AREA_SUCCESS = 'UPDATE_AREA_SUCCESS'
export const UPDATE_AREA_ERROR = 'UPDATE_AREA_ERROR'
export const CHANGE_BANNERS = 'CHANGE_BANNERS'
export const CHANGE_BANNERS_SUCCESS = 'CHANGE_BANNERS_SUCCESS'
export const CHANGE_BANNERS_ERROR = 'CHANGE_BANNERS_ERROR'
export const BANNERS_FETCH = 'BANNERS_FETCH'
export const BANNERS_FETCH_SUCCESS = 'BANNERS_FETCH_SUCCESS'
export const BANNERS_FETCH_ERROR = 'BANNERS_FETCH_ERROR'
export const DELETE_BANNER = 'DELETE_BANNER'
export const DELETE_BANNER_SUCCESS = 'DELETE_BANNER_SUCCESS'
export const DELETE_BANNER_ERROR = 'DELETE_BANNER_ERROR'
export const STATISTICS_FETCH = 'STATISTICS_FETCH'
export const STATISTICS_FETCH_SUCCESS = 'STATISTICS_FETCH_SUCCESS'
export const STATISTICS_FETCH_ERROR = 'STATISTICS_FETCH_ERROR'
export const LOADING = 'LOADING'
export const CLEAR_REQUEST_STATUS = 'CLEAR_REQUEST_STATUS'
export const MARK_BANNER_FOR_DELETION = 'MARK_BANNER_FOR_DELETION'
export const COUNTRIES_COUNT_FETCH = 'COUNTRIES_COUNT_FETCH'
export const COUNTRIES_COUNT_FETCH_SUCCESS = 'COUNTRIES_STATUS_COUNT_SUCCESS'
export const COUNTRIES_COUNT_FETCH_ERROR = 'COUNTRIES_COUNT_FETCH_ERROR'
export const DELETE_KIOSK = 'DELETE_KIOSK'
export const DELETE_KIOSK_SUCCESS = 'DELETE_KIOSK_SUCCESS'
export const DELETE_KIOSK_ERROR = 'DELETE_KIOSK_ERROR'
export const FETCH_COMPANY_CONFIGURATIONS = 'FETCH_COMPANY_CONFIGURATIONS'
export const FETCH_COMPANY_CONFIGURATIONS_SUCCESS =
  'FETCH_COMPANY_CONFIGURATIONS_SUCCESS'
export const FETCH_COMPANY_CONFIGURATIONS_ERROR =
  'FETCH_COMPANY_CONFIGURATIONS_ERROR'
export const CREATE_COMPANY_CONFIGURATIONS = 'CREATE_COMPANY_CONFIGURATIONS'
export const CREATE_COMPANY_CONFIGURATIONS_SUCCESS =
  'CREATE_COMPANY_CONFIGURATIONS_SUCCESS'
export const CREATE_COMPANY_CONFIGURATIONS_ERROR =
  'CREATE_COMPANY_CONFIGURATIONS_ERROR'
export const UPDATE_COMPANY_CONFIGURATIONS = 'UPDATE_COMPANY_CONFIGURATIONS'
export const UPDATE_COMPANY_CONFIGURATIONS_SUCCESS =
  'UPDATE_COMPANY_CONFIGURATIONS_SUCCESS'
export const UPDATE_COMPANY_CONFIGURATIONS_ERROR =
  'UPDATE_COMPANY_CONFIGURATIONS_ERROR'
export const CREATE_FORMAT = 'CREATE_FORMAT'
export const CREATE_FORMAT_SUCCESS = 'CREATE_FORMAT_SUCCESS'
export const CREATE_FORMAT_ERROR = 'CREATE_FORMAT_ERROR'
export const UPDATE_FORMAT = 'UPDATE_FORMAT'
export const UPDATE_FORMAT_SUCCESS = 'UPDATE_FORMAT_SUCCESS'
export const UPDATE_FORMAT_ERROR = 'UPDATE_FORMAT_ERROR'
export const DELETE_FORMAT = 'DELETE_FORMAT'
export const DELETE_FORMAT_SUCCESS = 'DELETE_FORMAT_SUCCESS'
export const DELETE_FORMAT_ERROR = 'DELETE_FORMAT_ERROR'
export const FORMATS_FETCH = 'FORMATS_FETCH'
export const FORMATS_FETCH_SUCCESS = 'FORMATS_FETCH_SUCCESS'
export const FORMATS_FETCH_ERROR = 'FORMATS_FETCH_ERROR'
export const RESET_APP_STATE = 'RESET_APP_STATE'
export const COMPANY_FETCH = 'COMPANY_FETCH'
export const COMPANY_FETCH_SUCCESS = 'COMPANY_FETCH_SUCCESS'
export const COMPANY_FETCH_ERROR = 'COMPANY_FETCH_ERROR'
export const COMPANY_UPDATE = 'COMPANY_UPDATE'
export const COMPANY_UPDATE_SUCCESS = 'COMPANY_UPDATE_SUCCESS'
export const COMPANY_UPDATE_ERROR = 'COMPANY_UPDATE_ERROR'
export const PRINTABLE_COMPONENTS_FETCH = 'PRINTABLE_COMPONENTS_FETCH'
export const PRINTABLE_COMPONENTS_FETCH_SUCCESS =
  'PRINTABLE_COMPONENTS_FETCH_SUCCESS'
export const PRINTABLE_COMPONENTS_FETCH_ERROR =
  'PRINTABLE_COMPONENTS_FETCH_ERROR'
export const SELECT_FORMAT = 'SELECT_FORMAT'
export const MOVE_FORMAT_COMPONENT = 'MOVE_FORMAT_COMPONENT'
export const ADD_FORMAT_COMPONENT = 'ADD_FORMAT_COMPONENT'
export const REMOVE_FORMAT_COMPONENT = 'REMOVE_FORMAT_COMPONENT'
export const CHANGE_FORMAT_COMPONENT_STYLES = 'CHANGE_FORMAT_COMPONENT_STYLES'
export const CHANGE_FORMAT_CONTENT = 'CHANGE_FORMAT_CONTENT'
export const GET_ALL_VERSIONS = 'GET_ALL_VERSIONS'
export const GET_ALL_VERSIONS_SUCCESS = 'GET_ALL_VERSIONS_SUCCESS'
export const GET_ALL_VERSIONS_ERROR = 'GET_ALL_VERSIONS_ERROR'
export const GET_ROLE_PERMISSION = 'GET_ROLE_PERMISSION'
export const GET_ROLE_PERMISSION_SUCCESS = 'GET_ROLE_PERMISSION_SUCCESS'
export const GET_ROLE_PERMISSION_ERROR = 'GET_ROLE_PERMISSION_ERROR'
export const GET_ALL_ROLE_PERMISSION = 'GET_ALL_ROLE_PERMISSION'
export const GET_ALL_ROLE_PERMISSION_SUCCESS = 'GET_ALL_ROLE_PERMISSION_SUCCESS'
export const GET_ALL_ROLE_PERMISSION_ERROR = 'GET_ALL_ROLE_PERMISSION_ERROR'
export const CREATE_ROLE_PERMISSION = 'CREATE_ROLE_PERMISSION'
export const CREATE_ROLE_PERMISSION_SUCCESS = 'CREATE_ROLE_PERMISSION_SUCCESS'
export const CREATE_ROLE_PERMISSION_ERROR = 'CREATE_ROLE_PERMISSION_ERROR'
export const UPDATE_ROLE_PERMISSION = 'UPDATE_ROLE_PERMISSION'
export const UPDATE_ROLE_PERMISSION_SUCCESS = 'UPDATE_ROLE_PERMISSION_SUCCESS'
export const UPDATE_ROLE_PERMISSION_ERROR = 'UPDATE_ROLE_PERMISSION_ERROR'
export const DELETE_ROLE_PERMISSION = 'DELETE_ROLE_PERMISSION'
export const DELETE_ROLE_PERMISSION_SUCCESS = 'DELETE_ROLE_PERMISSION_SUCCESS'
export const DELETE_ROLE_PERMISSION_ERROR = 'DELETE_ROLE_PERMISSION_ERROR'
export const GET_PERMISSION = 'GET_PERMISSION'
export const GET_PERMISSION_SUCCESS = 'GET_PERMISSION_SUCCESS'
export const GET_PERMISSION_ERROR = 'GET_PERMISSION_ERROR'
export const SEND_FORCED_UPDATE = 'SEND_FORCED_UPDATE'
export const SEND_FORCED_UPDATE_SUCCESS = 'SEND_FORCED_UPDATE_SUCCESS'
export const SEND_FORCED_UPDATE_ERROR = 'SEND_FORCED_UPDATE_ERROR'
export const CHECK_USER_PASSWORD = 'CHECK_USER_PASSWORD'
export const CHECK_USER_PASSWORD_SUCCESS = 'CHECK_USER_PASSWORD_SUCCESS'
export const CHECK_USER_PASSWORD_ERROR = 'CHECK_USER_PASSWORD_ERROR'
export const FETCH_KIOSKS_INFO = 'FETCH_KIOSKS_INFO'
export const FETCH_KIOSKS_INFO_SUCCESS = 'FETCH_KIOSKS_INFO_SUCCESS'
export const FETCH_KIOSKS_INFO_ERROR = 'FETCH_KIOSKS_INFO_ERROR'
export const FETCH_KIOSKS_BY_ID = 'FETCH_KIOSKS_BY_ID'
export const FETCH_KIOSKS_BY_ID_SUCCESS = 'FETCH_KIOSKS_BY_ID_SUCCESS'
export const FETCH_KIOSKS_BY_ID_ERROR = 'FETCH_KIOSKS_BY_ID_ERROR'
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA'
export const UPDATE_USER_DATA_SUCCESS = 'UPDATE_USER_DATA_SUCCESS'
export const UPDATE_USER_DATA_ERROR = 'UPDATE_USER_DATA_ERROR'
export const PROFILE_UPDATE_PASSWORD = 'PROFILE_UPDATE_PASSWORD'
export const PROFILE_UPDATE_PASSWORD_SUCCESS = 'PROFILE_UPDATE_PASSWORD_SUCCESS'
export const PROFILE_UPDATE_PASSWORD_ERROR = 'PROFILE_UPDATE_PASSWORD_ERROR'
