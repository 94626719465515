/* eslint-disable camelcase */
// Dependences
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getLoginToken } from '../../selectors/user'
import { IN_SERVICE, OUT_OF_SERVICE, WARNING } from '../../constants/Constants'
import { sortBy } from 'lodash'

// Utils
import { groupedKiosksCountByStatus, kioskType } from '../../utils/utils.js'

// Custom components
import Header from '../../components/header/Header'
import Sidebar from '../../components/sidebar/Sidebar'
import KioskCountCard from '../../components/KioskCountCard/KioskCountCard'
import LocationRow from '../../components/LocationRow/LocationRow'

// UI Components
import { withStyles } from '@material-ui/core'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import Search from '@material-ui/icons/Search'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TablePagination from '@material-ui/core/TablePagination'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'

// Styles and assets
import './citystatus.sass'
import { cityStatusStyles } from './citystatus.styles'
import allKiosks from './../../assets/images/icon-all.svg'
import statusWithService from './../../assets/images/icon-service.svg'
import statusWarning from './../../assets/images/icon-warning.svg'
import statusWithoutService from './../../assets/images/icon-without.svg'

const SORTS = {
  NONE: list => sortBy(list, 'name'),
  TOTAL_COUNT: list => sortBy(list, 'total_kiosks'),
  IN_SERVICE_COUNT: list => sortBy(list, 'in_service'),
  OUT_OF_SERVICE_COUNT: list => sortBy(list, 'out_of_service'),
  WARNING_COUNT: list => sortBy(list, 'warning')
}

class CityStatus extends Component {
  constructor (props) {
    super(props)
    this.state = {
      query: '',
      rowsPerPage: 10,
      currentPage: 0,
      order: 'asc',
      orderBy: 'NONE'
    }
  }

  onChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value })
  }

  onChangePage = (event, page) => {
    this.setState({ currentPage: page })
  }

  onChangeQuery = (event) => {
    this.setState({ query: event.target.value })
  }

  onShowLocationDetail = (location) => {
    const { country, city } = this.props.location.state.data
    this.props.history.push('/kiosk-by-location', { data: { location: location, city: city, country: country } })
  }

  onListAllKiosks = () => {
    this.showKioskList()
  }

  onListInServiceKiosks = () => {
    this.showKioskList(IN_SERVICE)
  }

  onListOutOfServiceKiosks = () => {
    this.showKioskList(OUT_OF_SERVICE)
  }

  onListInWarningKiosks = () => {
    this.showKioskList(WARNING)
  }

  showKioskList = status => {
    const kiosks = this.kiosksByStatus(status)
    if (kiosks.length > 0) {
      const { country, city } = this.props.location.state.data
      this.props.history.push('/kiosk-list', { data: { country: country, city: city, kiosks: kiosks, searchCriteriaKey: 'location', status: status } })
    }
  }

  kiosksByStatus = status => {
    try {
      const { city } = this.props.location.state.data
      var kiosks = []
      city.locations.forEach(location => {
        location.area_has_locations.forEach(area => {
          area.active_kiosks.forEach(kiosk => {
            kiosk.city_name = city.name
            kiosk.location_name = location.name
            kiosk.area_name = area.name
            kiosk.type = kioskType(kiosk.kiosk_type)
            if (!status) {
              kiosks.push(kiosk)
            } else if (kiosk.status === status) {
              kiosks.push(kiosk)
            }
          })
        })
      })
      return kiosks
    } catch (error) {
      return []
    }
  }

  handleRequestSort = (property) => () => {
    const orderBy = property
    let order = 'desc'
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }
    this.setState({ order, orderBy })
  }

  render () {
    const { order, orderBy } = this.state
    const { city, country } = this.props.location.state.data
    const count = groupedKiosksCountByStatus(city.kiosk_status_count)
    const { in_service, out_of_service, warning } = count
    const currentPageIndex = this.state.currentPage * this.state.rowsPerPage
    const pageEndIndex = currentPageIndex + this.state.rowsPerPage
    const filteredLocations = city.locations.filter((location) => {
      if (this.state.query.length > 0) {
        return location.name.toLowerCase().includes(this.state.query.toLowerCase())
      }
      return true
    })
    const sortedLocations = SORTS[orderBy](filteredLocations)
    const reversedLocations = order === 'desc' ? sortedLocations.reverse() : sortedLocations
    const listItems = reversedLocations.slice(currentPageIndex, pageEndIndex).map((location) => {
      const count = groupedKiosksCountByStatus(location.kiosk_status_count)
      location.in_service = count.in_service
      location.out_of_service = count.out_of_service
      location.warning = count.warning
      return (<LocationRow
        key = {location.id}
        location = {location}
        onDetail = {this.onShowLocationDetail}
      />
      )
    })
    const links = [
      { title: 'Inicio',
        onClick: () => {
          this.props.history.go(-2)
        }
      },
      { title: country.name,
        onClick: () => {
          this.props.history.goBack()
        }
      }]
    return (
      <div className="page-status">
        <Header links={links} currentPage={city.name}/>
        <Sidebar level={2} country={country}/>
        <div className={this.props.classes.content}>
          <div className="kiosks-status">
            <KioskCountCard
              icon = {allKiosks}
              title = {'Total kioscos'}
              count = {country.total_kiosks}
              onClick = {this.onListAllKiosks}
            />
            <KioskCountCard
              icon = {statusWithService}
              title = {'En servicio'}
              count = {in_service}
              onClick = {this.onListInServiceKiosks}
            />
            <KioskCountCard
              icon = {statusWithoutService}
              title = {'Sin servicio'}
              count = {out_of_service}
              onClick = {this.onListOutOfServiceKiosks}
            />
            <KioskCountCard
              icon = {statusWarning}
              title = {'En advertencia'}
              count = {warning}
              onClick = {this.onListInWarningKiosks}
            />
          </div>
          <div className="list-kiosks">
            <Input className={this.props.classes.findField} placeholder="Buscar por nombre de teatro"
              value={this.state.query}
              onChange={this.onChangeQuery}
              disableUnderline={true} startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }/>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Ubicación</TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'TOTAL_COUNT'}
                      direction={order}
                      onClick={this.handleRequestSort('TOTAL_COUNT')}
                    >
                      Kioscos
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'IN_SERVICE_COUNT'}
                      direction={order}
                      onClick={this.handleRequestSort('IN_SERVICE_COUNT')}
                    >
                      En servicio
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'OUT_OF_SERVICE_COUNT'}
                      direction={order}
                      onClick={this.handleRequestSort('OUT_OF_SERVICE_COUNT')}
                    >
                      Sin servicio
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'WARNING_COUNT'}
                      direction={order}
                      onClick={this.handleRequestSort('WARNING_COUNT')}
                    >
                      En advertencia
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              {
                <TableBody>{listItems}</TableBody>
              }
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={(filteredLocations && filteredLocations.length) || 0}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.currentPage}
              onChangePage={this.onChangePage}
              onChangeRowsPerPage={this.onChangeRowsPerPage}
              labelRowsPerPage='Filas por página'
              backIconButtonProps={{
                'aria-label': 'Anterior'
              }}
              nextIconButtonProps={{
                'aria-label': 'Siguiente'
              }}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: getLoginToken(state)
  }
}

export default withStyles(cityStatusStyles)(connect(mapStateToProps)(CityStatus))
