// Dependences
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  doFetchConfigurations,
  doCreateConfigurations,
  doUpdateConfigurations
} from '../../actions/configuration'
import { doClearRequestStatus } from '../../actions/clearStatus'
import { getLoginToken } from '../../selectors/user'
import { companyId } from '../../selectors/company'
import {
  getSuccess,
  getError,
  getMessage
} from '../../selectors/configurations'

// Custom components
import Header from './../../components/header/Header'
import Sidebar from './../../components/sidebar/Sidebar'
import MySnackbar from '../../components/MySnackbar/MySnackbar'

// UI Components
import {
  withStyles,
  Paper,
  Typography,
  Divider,
  Switch
} from '@material-ui/core'
import { orange } from '@material-ui/core/colors'

// Styles
import './configurations.sass'
import { configurationStyles } from './configurations.styles'

const ConfigSwitch = withStyles({
  switchBase: {
    color: orange[300],
    '&$checked': {
      color: orange[500],
      '& + $bar': {
        backgroundColor: orange[500]
      }
    }
  },
  bar: {},
  checked: {},
  track: {}
})(Switch)

class Configurations extends Component {
  constructor(props) {
    super(props)
    this.state = {
      allowBillboardUpdate: { active: true, name: 'allow_billboard_update' },
      allowCurrencyUpdate: { active: true, name: 'allow_currency_update' },
      allowLocalesUpdate: { active: true, name: 'allow_locales_update' },
      shouldCustomizeVoucher: { active: true, name: 'should_customize_format' },
      shouldShowBannersInSection1: {
        active: true,
        name: 'should_show_banners_in_section_1'
      },
      shouldShowBannersInSection2: {
        active: true,
        name: 'should_show_banners_in_section_2'
      },
      shouldShowBannersInSection3: {
        active: true,
        name: 'should_show_banners_in_section_3'
      },
      shouldShowBannersInSection4: {
        active: true,
        name: 'should_show_banners_in_section_4'
      },
      shouldShowBannersInSection5: {
        active: true,
        name: 'should_show_banners_in_section_5'
      },
      shouldUseLocalizableText: {
        active: true,
        name: 'should_use_localizable_text'
      },
      shouldGetKiosksStatus: { active: true, name: 'should_get_kiosks_status' },
      snackbarIsOpen: false
    }
  }

  componentDidMount() {
    this.fetchConfigurations()
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.configuration.configurations !==
      this.props.configuration.configurations
    ) {
      const { configurations } = nextProps.configuration
      if (configurations) {
        configurations.forEach((configuration) => {
          if (configuration.name === 'allow_billboard_update') {
            this.setState({
              allowBillboardUpdate: {
                ...this.state.allowBillboardUpdate,
                active: configuration.active,
                id: configuration.id
              }
            })
          } else if (configuration.name === 'allow_currency_update') {
            this.setState({
              allowCurrencyUpdate: {
                ...this.state.allowCurrencyUpdate,
                active: configuration.active,
                id: configuration.id
              }
            })
          } else if (configuration.name === 'allow_locales_update') {
            this.setState({
              allowLocalesUpdate: {
                ...this.state.allowLocalesUpdate,
                active: configuration.active,
                id: configuration.id
              }
            })
          } else if (configuration.name === 'should_customize_format') {
            this.setState({
              shouldCustomizeVoucher: {
                ...this.state.shouldCustomizeVoucher,
                active: configuration.active,
                id: configuration.id
              }
            })
          } else if (
            configuration.name === 'should_show_banners_in_section_1'
          ) {
            this.setState({
              shouldShowBannersInSection1: {
                ...this.state.shouldShowBannersInSection1,
                active: configuration.active,
                id: configuration.id
              }
            })
          } else if (
            configuration.name === 'should_show_banners_in_section_2'
          ) {
            this.setState({
              shouldShowBannersInSection2: {
                ...this.state.shouldShowBannersInSection2,
                active: configuration.active,
                id: configuration.id
              }
            })
          } else if (
            configuration.name === 'should_show_banners_in_section_3'
          ) {
            this.setState({
              shouldShowBannersInSection3: {
                ...this.state.shouldShowBannersInSection3,
                active: configuration.active,
                id: configuration.id
              }
            })
          } else if (
            configuration.name === 'should_show_banners_in_section_4'
          ) {
            this.setState({
              shouldShowBannersInSection4: {
                ...this.state.shouldShowBannersInSection4,
                active: configuration.active,
                id: configuration.id
              }
            })
          } else if (
            configuration.name === 'should_show_banners_in_section_5'
          ) {
            this.setState({
              shouldShowBannersInSection5: {
                ...this.state.shouldShowBannersInSection5,
                active: configuration.active,
                id: configuration.id
              }
            })
          } else if (configuration.name === 'should_use_localizable_text') {
            this.setState({
              shouldUseLocalizableText: {
                ...this.state.shouldUseLocalizableText,
                active: configuration.active,
                id: configuration.id
              }
            })
          } else if (configuration.name === 'should_get_kiosks_status') {
            this.setState({
              shouldGetKiosksStatus: {
                ...this.state.shouldGetKiosksStatus,
                active: configuration.active,
                id: configuration.id
              }
            })
          }
        })
      }
    }
  }

  onSetConfigurations = (configurations) => {
    this.props.doClearRequestStatus()
    const configurationsToCreate = this.configurationsToCreate(configurations)
    const configurationsToUpdate = this.configurationsToUpdate(configurations)
    if (configurationsToCreate.length > 0) {
      this.createConfigurations(configurationsToCreate)
    }
    if (configurationsToUpdate.length > 0) {
      this.updateConfigurations(configurationsToUpdate)
    }
  }

  configurationsToCreate = (configurations) => {
    return configurations
      .filter(
        (configuration) =>
          configuration.id === undefined && configuration.active !== null
      )
      .map((configuration) => {
        return {
          name: configuration.name,
          active: configuration.active,
          description: configuration.description || ''
        }
      })
  }

  configurationsToUpdate = (configurations) => {
    return configurations
      .filter(
        (configuration) =>
          configuration.id !== undefined && configuration.active !== null
      )
      .map((configuration) => {
        return { id: configuration.id, active: configuration.active }
      })
  }

  fetchConfigurations = () => {
    const params = { company_id: this.props.companyId }
    this.props.doFetchConfigurations({
      data: { params: params, user: this.props.user }
    })
  }

  createConfigurations = (configurations) => {
    var params = { company_id: this.props.companyId }
    params.configurations = configurations
    this.props.doCreateConfigurations({
      data: { params: params, user: this.props.user }
    })
  }

  updateConfigurations = (configurations) => {
    var params = { company_id: this.props.companyId }
    this.props.doUpdateConfigurations({
      params: params,
      data: { configurations: configurations, user: this.props.user }
    })
  }

  handleChange = (event) => {
    const { name, checked } = event.target
    const configuration = this.state[name]
    configuration.active = checked
    this.setState({
      [name]: { ...this.state[name], active: checked, id: this.state[name].id }
    })
    this.onSetConfigurations([configuration])
  }

  handleCloseSnackBar = () => {
    this.props.doClearRequestStatus()
  }

  render() {
    const { success, error, message, classes } = this.props
    const openSnackbar = success || error
    return (
      <div className="page-settings">
        <Header currentPage={`Administrar preferencias`} />
        <Sidebar level={0} />
        <div className={classes.content}>
          <MySnackbar
            open={openSnackbar}
            variant={openSnackbar ? (success ? 'success' : 'error') : 'default'}
            onClose={this.handleCloseSnackBar}
            message={openSnackbar ? message : ''}
          />
          <Paper className={classes.container}>
            <Typography variant="subtitle1">
              Administre los páneles de configuraciones de kiosco que más se
              ajusten a sus necesidades
            </Typography>
            <Typography variant="h5">Configuración general</Typography>
            <div className="rows_container">
              <div className="rows_spacer">
                <div className="row">
                  <Typography variant="h6">
                    Consultar el estado del kiosco
                  </Typography>
                  <ConfigSwitch
                    checked={this.state.shouldGetKiosksStatus.active}
                    onChange={this.handleChange}
                    name="shouldGetKiosksStatus"
                  />
                </div>
                <div className="row">
                  <Typography variant="h6">Actualizar cartelera</Typography>
                  <ConfigSwitch
                    checked={this.state.allowBillboardUpdate.active}
                    onChange={this.handleChange}
                    name="allowBillboardUpdate"
                  />
                </div>
                <div className="row">
                  <Typography variant="h6">Tipo de moneda</Typography>
                  <ConfigSwitch
                    checked={this.state.allowCurrencyUpdate.active}
                    onChange={this.handleChange}
                    name="allowCurrencyUpdate"
                  />
                </div>
                <div className="row">
                  <Typography variant="h6">Actualizar locales</Typography>
                  <ConfigSwitch
                    checked={this.state.allowLocalesUpdate.active}
                    onChange={this.handleChange}
                    name="allowLocalesUpdate"
                  />
                </div>
                <div className="row">
                  <Typography variant="h6">Personalizar formatos</Typography>
                  <ConfigSwitch
                    checked={this.state.shouldCustomizeVoucher.active}
                    onChange={this.handleChange}
                    name="shouldCustomizeVoucher"
                  />
                </div>
              </div>
            </div>
            <Divider className={classes.separator} />
            <Typography variant="h5">
              Configuración de banners promocionales
            </Typography>
            <div className="row">
              <div>
                <Typography variant="h6">Inicio de sesión</Typography>
                <Typography variant="subtitle2">
                  Mostrar Imágenes cuando el kiosco no está en uso
                </Typography>
              </div>
              <ConfigSwitch
                checked={this.state.shouldShowBannersInSection1.active}
                onChange={this.handleChange}
                name="shouldShowBannersInSection1"
              />
            </div>
            <div className="row">
              <div>
                <Typography variant="h6">Cartelera</Typography>
                <Typography variant="subtitle2">
                  Mostrar imágenes en la sección de cartelera
                </Typography>
              </div>
              <ConfigSwitch
                checked={this.state.shouldShowBannersInSection2.active}
                onChange={this.handleChange}
                name="shouldShowBannersInSection2"
              />
            </div>
            <div className="row">
              <div>
                <Typography variant="h6">Confitería</Typography>
                <Typography variant="subtitle2">
                  Mostrar imágenes en la sección de confitería
                </Typography>
              </div>
              <ConfigSwitch
                checked={this.state.shouldShowBannersInSection3.active}
                onChange={this.handleChange}
                name="shouldShowBannersInSection3"
              />
            </div>
            <div className="row">
              <div>
                <Typography variant="h6">Pick up</Typography>
                <Typography variant="subtitle2">
                  Mostrar imágenes en la sección de pick up
                </Typography>
              </div>
              <ConfigSwitch
                checked={this.state.shouldShowBannersInSection4.active}
                onChange={this.handleChange}
                name="shouldShowBannersInSection4"
              />
            </div>
            <div className="row">
              <div>
                <Typography variant="h6">Activación de tarjetas</Typography>
                <Typography variant="subtitle2">
                  Mostrar imágenes en la sección de activación de tarjetas
                </Typography>
              </div>
              <ConfigSwitch
                checked={this.state.shouldShowBannersInSection5.active}
                onChange={this.handleChange}
                name="shouldShowBannersInSection5"
              />
            </div>
          </Paper>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: getLoginToken(state),
    companyId: companyId(state),
    configuration: state.configuration,
    success: getSuccess(state),
    error: getError(state),
    message: getMessage(state)
  }
}

const mapDispatchToProps = {
  doFetchConfigurations,
  doCreateConfigurations,
  doUpdateConfigurations,
  doClearRequestStatus
}

export default withStyles(configurationStyles)(
  connect(mapStateToProps, mapDispatchToProps)(Configurations)
)
