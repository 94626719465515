const getSuccess = state => state.location.success
const getError = state => state.location.error
const getMessage = state => {
  return state.location.message
    ? state.location.message : null
}

const getMesageLocation = state => {
  return state.location.message
    ? state.location.message : null
}
const getErrorLocation = state => state.location.error


export {
  getSuccess,
  getError,
  getMessage,
  getMesageLocation,
  getErrorLocation
}
