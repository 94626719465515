export const localeRowStyles = {
  btnDetails: {
    border: '1px solid #9f9f9f',
    color: '#737373',
    marginRight: '20px'
  },
  tableColumn: {
    width: '125px'
  },
  editInput: {
    fontSize: 13
  }
}
