import ApiClientSingleton from '../api-client'

const kioskApiInstance = ApiClientSingleton.getKioskApiInstance()

const fetchBanners = async (payload) => {
  const uri = `/api/v1/companies/${payload.params.company_id}/promotions?country_id=${payload.params.country_id}`
  const headers = { Authorization: `Bearer ${payload.user}` }
  const { data } = await kioskApiInstance.get(uri, { headers })
  return data
}

const createBanners = async (payload) => {
  const uri = `/api/v1/companies/${payload.company_id}/promotions`
  const headers = { Authorization: `Bearer ${payload.user}` }
  const formData = new FormData()
  const params = Object.entries(payload.params)
  for (const [key, value] of params) {
    if (key === 'city_ids' || key === 'location_ids' || key === 'kiosk_ids')
      formData.append(`${key}[]`, value)
    else formData.append(key, value)
  }
  const response = await kioskApiInstance.post(uri, formData, { headers })
  return response
}

const updateBanners = async (payload) => {
  const uri = `/api/v1/companies/${payload.company_id}/promotions/${payload.params.banner_id}`
  const headers = { Authorization: `Bearer ${payload.user}` }
  const formData = new FormData()
  const params = Object.entries(payload.params)
  for (const [key, value] of params) {
    if (key === 'city_ids' || key === 'location_ids' || key === 'kiosk_ids')
      formData.append(`${key}[]`, value)
    else formData.append(key, value)
  }
  const response = await kioskApiInstance.put(uri, formData, { headers })
  return response
}

const deleteBanners = async (payload) => {
  const uri = `/api/v1/companies/${payload.company_id}/promotions/${payload.id}`
  const headers = { Authorization: `Bearer ${payload.user}` }
  const response = await kioskApiInstance.delete(uri, { headers })
  return response
}

const associateScopes = async (payload) => {
  const uri = `/api/v1/companies/${payload.company_id}/promotions/${payload.id}/scopes`
  const headers = { Authorization: `Bearer ${payload.user}` }
  const response = await kioskApiInstance.post(uri, payload.scopes, { headers })
  return response
}

export {
  fetchBanners,
  createBanners,
  updateBanners,
  deleteBanners,
  associateScopes
}
