/* eslint-disable camelcase */
// Dependences
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography, Card, CardContent, Button } from '@material-ui/core'
import LinearIndeterminate from '../Loading/LinearIndeterminate'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'

// Styles
import './changepasswordform.sass'
import { changePasswordFormStyles } from './changepasswordform.styles'

class ChangePasswordForm extends Component {
  constructor () {
    super()
    this.state = {
      email: '',
      token: '',
      newPassword: '',
      passwordConfirmation: ''
    }
  }

  onChangeFormControl = event => {
    const { value, name } = event.target
    this.setState({
      [name]: value
    })
  }

  handleSubmit = event => {
    event.preventDefault()
    const { onSubmit, email, token } = this.props
    const { newPassword, passwordConfirmation } = this.state
    onSubmit(newPassword, passwordConfirmation, email || this.state.email, token || this.state.token)
  }

  render () {
    const { title, subtitle, footer, onFooterClick, isLoading, buttonTitle, classes, email, token, isTokenRequired } = this.props
    return (
      <Card className="update-card">
        <CardContent>
          <Typography variant="h5" className="update-title">
            {title}
          </Typography>
          <Typography variant="subtitle2" className="update-subtitle">
            {subtitle}
          </Typography>
          {<div>
            <ValidatorForm
              ref="form"
              onSubmit={this.handleSubmit}
              onError={errors => console.log(errors)}
            >
              {!email &&
                      <TextValidator
                        className='update-input'
                        label="Correo electrónico"
                        onChange={this.onChangeFormControl}
                        name="email"
                        value={this.state.email}
                        validators={['required']}
                        errorMessages={['No enviar vacío']}
                      />
              }
              {!token && isTokenRequired &&
                    <TextValidator
                      className='update-input'
                      label="Token de confirmación"
                      onChange={this.onChangeFormControl}
                      name="token"
                      value={this.state.token}
                      validators={['required']}
                      errorMessages={['No enviar vacío']}
                    />
              }
              <TextValidator
                className='update-input'
                label="Nueva contraseña"
                type='password'
                onChange={this.onChangeFormControl}
                name="newPassword"
                value={this.state.newPassword}
                validators={['required']}
                errorMessages={['No enviar vacío']}
              />
              <TextValidator
                className='update-input'
                label="Confirmación de contraseña"
                type='password'
                onChange={this.onChangeFormControl}
                name="passwordConfirmation"
                value={this.state.passwordConfirmation}
                validators={['required']}
                errorMessages={['No enviar vacío']}
              />
              <Button type='submit' variant="contained" classes={{ root: classes.root }} className={classes.submit}>{buttonTitle}</Button>
            </ValidatorForm>
          </div>}
          <Typography className="update-footer" variant="subtitle1" onClick={onFooterClick}>
            {footer}
          </Typography>
        </CardContent>
        { isLoading && <LinearIndeterminate/>}
      </Card>
    )
  }
}
export default withStyles(changePasswordFormStyles)(ChangePasswordForm)
