import { call, put } from 'redux-saga/effects'
import {
  doFetchCountriesCountSuccess,
  doFetchCountriesCountError,
  doFetchCompanySuccess,
  doFetchCompanyError,
  doUpdateCompanyError,
  doUpdateCompanySuccess, doFetchCompanyRoleSucces, doFetchCompanyRoleError
} from '../actions/company'
import {
  fetchCountriesCount,
  fetchCompany,
  updateCompany, fetchCompanyRole
} from '../api/company'
import { doLoading } from '../actions/loading'

function* handleFetchCountriesCount(action) {
  try {
    yield put(doLoading())
    const result = yield call(
      fetchCountriesCount,
      action.payload.user,
      action.payload.companyId
    )
    if (result.status === 200) {
      yield put(doFetchCountriesCountSuccess(result))
    } else {
      yield put(doFetchCountriesCountError())
    }
  } catch (error) {
    yield put(doFetchCountriesCountError(error))
  }
}

function* handleFetchCompany(action) {
  try {
    const result = yield call(fetchCompany, action.payload.companyId)
    if (result.status === 200) {
      yield put(doFetchCompanySuccess(result))
    } else {
      yield put(doFetchCompanyError())
    }
  } catch (error) {
    yield put(doFetchCompanyError(error))
  }
}

function* handleUpdateCompany(action) {
  try {
    const result = yield call(updateCompany, action.payload)
    yield put(doUpdateCompanySuccess(result))
  } catch (error) {
    yield put(doUpdateCompanyError(error))
  }
}

function* handleFetchCompanyRole(action) {
  try {
    const result = yield call(fetchCompanyRole, action.payload)
    if (result) yield put(doFetchCompanyRoleSucces(result.data))
  } catch (error) {
    yield put(doFetchCompanyRoleError(error))
  }
}

export { handleFetchCountriesCount, handleFetchCompany, handleUpdateCompany, handleFetchCompanyRole }
