import { call, put } from 'redux-saga/effects'
import { doCreateConfigurationsSuccess, doFetchConfigurationsSuccess, doFetchConfigurationsError,
  doUpdateConfigurationsSuccess, doCreateConfigurationsError, doUpdateConfigurationsError, doFetchConfigurations } from '../actions/configuration'
import { fetchConfigurations, createConfigurations, updateConfigurations } from '../api/configuration'

function * handleFetchConfigurations (action) {
  try {
    const result = yield call(fetchConfigurations, action.payload.data)
    if (result) {
      yield put(doFetchConfigurationsSuccess(result))
    } else {
      yield put(doFetchConfigurationsError())
    }
  } catch (error) {
    yield put(doFetchConfigurationsError(error))
  }
}

function * handleCreateConfigurations (action) {
  try {
    const result = yield call(createConfigurations, action.payload.data)
    if (result.success) {
      const params = { company_id: action.payload.data.params.company_id }
      yield put(doFetchConfigurations({ data: { params: params, user: action.payload.data.user } }))
      yield put(doCreateConfigurationsSuccess(result))
    } else {
      yield put(doCreateConfigurationsError())
    }
  } catch (error) {
    console.log(error)
    yield put(doCreateConfigurationsError(error))
  }
}

function * handleUpdateConfigurations (action) {
  try {
    const result = yield call(updateConfigurations, action.payload.data)
    if (result === 204) {
      const params = { company_id: action.payload.params.company_id }
      yield put(doFetchConfigurations({ data: { params: params, user: action.payload.data.user } }))
      yield put(doUpdateConfigurationsSuccess(result))
    } else {
      yield put(doUpdateConfigurationsError())
    }
  } catch (error) {
    yield put(doUpdateConfigurationsError(error))
  }
}

export {
  handleFetchConfigurations,
  handleCreateConfigurations,
  handleUpdateConfigurations
}
