// Dependences
import React from 'react'
import { withStyles } from '@material-ui/core/styles'

// Custom components
import CountrySummaryCard from './../../components/CountrySummaryCard/CountrySummaryCard'

// Styles
import './dashboardgrid.sass'
import { dashboardGridStyles } from './dashboardgrid.styles'

const DashboardGrid = props => {
  const { countries, showStatus } = props
  return (
    <div className="page-dashboard__grid-container">
      {countries && countries.map((item) => {
        return <CountrySummaryCard
          key={item.id}
          countrySummary={item}
          onListDetails={showStatus}/>
      })}
    </div>
  )
}

export default withStyles(dashboardGridStyles)(DashboardGrid)
