import { call, put } from 'redux-saga/effects'
import {
  doGetRolesPermissionsSuccess,
  doGetRolesPermissionsError,
  doGetRolePermissionSuccess,
  doGetRolePermissionError,
  doCreateRolesPermissionsSuccess,
  doCreateRolesPermissionsError,
  doUpdateRolesPermissionsSuccess,
  doUpdateRolesPermissionsError,
  doDeleteRolesPermissionsSuccess,
  doDeleteRolesPermissionsError,
  doGetPermissionsSuccess,
  doGetPermissionsError
} from '../actions/rolePermission'
import {
  getAllRolesPermissions,
  getRolePermission,
  createRolePermission,
  updateRolePermission,
  deleteRolePermission,
  getPermissions
} from '../api/rolePermission'

function* handleGetAllRolesPermissions(action) {
  try {
    const result = yield call(getAllRolesPermissions, action.payload)
    if (Array.isArray(result.roles)) {
      yield put(doGetRolesPermissionsSuccess(result))
    } else {
      yield put(doGetRolesPermissionsError())
    }
  } catch (error) {
    yield put(doGetRolesPermissionsError())
  }
}

function* handleGetRolePermission(action) {
  try {
    const result = yield call(getRolePermission, action.payload)
    yield put(doGetRolePermissionSuccess(result))
  } catch (error) {
    yield put(doGetRolePermissionError())
  }
}

function* handleCreateRolesPermissions(action) {
  try {
    const result = yield call(createRolePermission, action.payload)
    yield put(doCreateRolesPermissionsSuccess(result))
  } catch (error) {
    yield put(doCreateRolesPermissionsError())
  }
}

function* handleUpdateRolesPermissions(action) {
  try {
    const result = yield call(updateRolePermission, action.payload)
    yield put(doUpdateRolesPermissionsSuccess(result))
  } catch (error) {
    yield put(doUpdateRolesPermissionsError())
  }
}

function* handleDeleteRolesPermissions(action) {
  try {
    const result = yield call(deleteRolePermission, action.payload)

    yield put(doDeleteRolesPermissionsSuccess(result))
  } catch (error) {
    yield put(doDeleteRolesPermissionsError())
  }
}

function* handleGetPermissions(action) {
  try {
    const result = yield call(getPermissions, action)
    if (Array.isArray(result)) {
      yield put(doGetPermissionsSuccess(result))
    } else {
      yield put(doGetPermissionsError())
    }
  } catch (error) {
    yield put(doGetPermissionsError())
  }
}

export {
  handleGetAllRolesPermissions,
  handleGetRolePermission,
  handleCreateRolesPermissions,
  handleUpdateRolesPermissions,
  handleDeleteRolesPermissions,
  handleGetPermissions
}
