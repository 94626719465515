import { call, put } from 'redux-saga/effects'
import {
  doCreateAreaSuccess,
  doCreateAreaError,
  doFetchAreasSuccess,
  doFetchAreasError,
  doFetchAreas,
  doAssignLocations,
  doDeleteAreaSuccess,
  doDeleteAreaError,
  doUpdateAreaSuccess,
  doUpdateAreaError
} from '../actions/area'
import { createArea, fetchAreas, asociateLocations, updateArea, deleteArea } from '../api/area'

function * handleAssignLocations (action) {
  try {
    const result = yield call(asociateLocations, action.payload)
    if (result) {
      yield put(doFetchAreas({ data: { companyId: action.payload.companyId, user: action.payload.user } }))
    }
  } catch (error) {
    yield put(doCreateAreaError(error))
  }
}

function * handleCreateArea (action) {
  try {
    const { companyId, user } = action.payload.data
    const result = yield call(createArea, action.payload.data)
    if (result.area) {
      const body = {
        area_id: result.area.id,
        location_ids: action.payload.data.area.locations
      }
      const payload = { body, companyId, user }
      yield put(doAssignLocations(payload))
      yield put(doCreateAreaSuccess(result))
    }
  } catch (error) {
    yield put(doCreateAreaError(error))
  }
}

function * handleFetchAreas (action) {
  try {
    const result = yield call(fetchAreas, action.payload.data)
    yield put(doFetchAreasSuccess(result))
  } catch (error) {
    yield put(doFetchAreasError(error))
  }
}

function * handleUpdateArea (action) {
  try {
    const { companyId, user } = action.payload.data
    const result = yield call(updateArea, action.payload.data)
    if (result) {
      const body = {
        area_id: result.id,
        location_ids: action.payload.data.area.locations
      }
      const payload = { body, companyId, user }
      yield put(doAssignLocations(payload))
      yield put(doUpdateAreaSuccess(result))
    }
  } catch (error) {
    yield put(doUpdateAreaError(error))
  }
}

function * handleDeleteArea (action) {
  try {
    const result = yield call(deleteArea, action.payload)
    yield put(doDeleteAreaSuccess(result))
    yield put(doFetchAreas({ data: { companyId: action.payload.companyId, user: action.payload.user } }))
  } catch (error) {
    yield put(doDeleteAreaError(error))
  }
}

export {
  handleCreateArea,
  handleFetchAreas,
  handleAssignLocations,
  handleDeleteArea,
  handleUpdateArea
}
