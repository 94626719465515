/* eslint-disable camelcase */
// Dependences
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography/Typography'
import { Table, TableHead, TableBody, TableCell, TableRow, Button, MenuItem, Menu } from '@material-ui/core'
import { Add, Edit, Visibility, MoreVert } from '@material-ui/icons'

import CircularLoading from '../Loading/CircularLoading'

import './formatsconfig.sass'
import { formatsConfigStyles } from './formatsconfig.styles'

// UI Components
class FormatsConfig extends Component {
  constructor (props) {
    super(props)
    this.state = {
      optionsMenuAnchorEl: null,
      selectedFormat: null
    }
  }

  handleEditFormat = (format) => {
    this.props.onEditFormat(format)
    this.hideOptions()
  }

  handleDeleteFormat = (format) => {
    this.props.onDeleteFormat(format)
    this.hideOptions()
  }

  handleRenameFormat = (format) => {
    this.props.onRenameFormat(format)
    this.hideOptions()
  }

  showOptions = (event, format) => {
    this.setState({ optionsMenuAnchorEl: event.currentTarget, selectedFormat: format })
  }

  hideOptions = () => {
    this.setState({ optionsMenuAnchorEl: null, selectedFormat: null })
  }

  handleShowFormat = (format) => {
    this.props.onShowFormat(format)
    this.hideOptions()
  }

  render () {
    const { loading, classes, formats, defaultVoucher, customVouchers, onNewFormat } = this.props
    const { optionsMenuAnchorEl, selectedFormat } = this.state
    var listFormats
    var listCustomVouchers = []
    if (formats) {
      listFormats = formats.map((format, index) => {
        return <TableRow key={index}>
          <TableCell>{format.name}</TableCell>
          <TableCell align="right">
            <Visibility onClick={() => this.handleShowFormat(format)}></Visibility>
            <Edit className="formats__table-edit" onClick={() => this.handleEditFormat(format)}></Edit>
          </TableCell>
        </TableRow>
      })
    }

    if (customVouchers) {
      listCustomVouchers = customVouchers.map((format, index) => {
        return <TableRow key={index}>
          <TableCell onClick={() => this.handleEditFormat(format)}>{format.name}</TableCell>
          <TableCell><span/></TableCell>
          <TableCell><span/></TableCell>
          <TableCell align="right">
            <MoreVert onClick={(event) => this.showOptions(event, format)}/>
          </TableCell>
        </TableRow>
      })
    }

    return (
      <div>
        <Menu
          id="simple-menu"
          anchorEl={optionsMenuAnchorEl}
          keepMounted
          open={Boolean(optionsMenuAnchorEl)}
          onClose={() => this.hideOptions()}
        >
          <MenuItem onClick={() => this.handleEditFormat(selectedFormat)}>Editar</MenuItem>
          <MenuItem onClick={() => this.handleRenameFormat(selectedFormat)}>Renombrar</MenuItem>
          <MenuItem onClick={() => this.handleShowFormat(selectedFormat)}>Previsualizar</MenuItem>
          <MenuItem onClick={() => this.handleDeleteFormat(selectedFormat)}>Eliminar</MenuItem>
        </Menu>
        <Paper className={classes.container}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">Formatos</Typography>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            {
              <TableBody>{listFormats}</TableBody>
            }
          </Table>
        </Paper>
        <Paper className={classes.container}>
          <div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">Vouchers</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      className={classes.btnNew}
                      variant="outlined"
                      onClick={onNewFormat}>
                    Crear
                      <Add />
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
              {defaultVoucher &&
              <TableBody>
                <TableRow>
                  <TableCell>{defaultVoucher.name}</TableCell>
                  <TableCell align="right">
                    <Visibility onClick={() => this.handleShowFormat(defaultVoucher)}></Visibility>
                    <Edit className="formats__table-edit" onClick={() => this.handleEditFormat(defaultVoucher)}></Edit>
                  </TableCell>
                </TableRow>
              </TableBody>
              }
            </Table>
            {listCustomVouchers.length > 0 &&
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Ubicación</TableCell>
                  <TableCell>Kioscos</TableCell>
                  <TableCell align="right"><span/></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{listCustomVouchers}</TableBody>
            </Table>
            }
          </div>
        </Paper>
        <CircularLoading isLoading={loading} />
      </div>
    )
  }
}

export default withStyles(formatsConfigStyles)(FormatsConfig)
