import React from 'react'
import {
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  Switch,
  withStyles
} from '@material-ui/core'
import { orange } from '@material-ui/core/colors'

const ConfigSwitch = withStyles({
  switchBase: {
    color: orange[300],
    '&$checked': {
      color: orange[500],
      '& + $bar': {
        backgroundColor: orange[500]
      }
    }
  },
  bar: {},
  checked: {}
})(Switch)

class NewRoleList extends React.Component {
  constructor() {
    super()
    this.state = {
      open: 0
    }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick = (item) => {
    this.setState({ open: item })
  }

  render() {
    const { permissions } = this.props
    let arrSelectedPermissions = this.props.selectedPermissions
      ? this.props.selectedPermissions
      : []
    if (permissions) {
      return (
        <List
          component="nav"
          subheader={
            <ListSubheader component="div" className="new_user__title-list">
              <Typography
                variant="h6"
                gutterBottom
                className={this.props.classes.newUserTitleForm}
              >
                Permisos
              </Typography>
            </ListSubheader>
          }
          className={this.props.classes.root}
        >
          {permissions.map((permission) => (
            <div key={permission.id}>
              <ListItem>
                <ListItemText inset primary={permission.name} />
                <ConfigSwitch
                  disabled={this.props.blockSwitch}
                  checked={arrSelectedPermissions.indexOf(permission.id) !== -1}
                  onClick={(event) => {
                    this.props.checkPermission(event, permission.id)
                  }}
                />
              </ListItem>
              <Divider />
            </div>
          ))}
        </List>
      )
    } else {
      return ''
    }
  }
}

export default NewRoleList
