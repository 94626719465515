import { sidebarWidth } from '../../components/sidebar/Sidebar'

export const kioskListStyles = {
  content: {
    marginLeft: sidebarWidth + 20,
    marginTop: '20px',
    marginRight: '20px',
    width: '100%' - sidebarWidth,
    backgroundColor: '#fff'
  },
  tableTransactions: {
    minWidth: 50
    // overflowX: 'auto',
    // width: '80%'
  },
  tableTransactionsContainer: {
    overflowX: 'auto'
  }
}
