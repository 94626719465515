// Dependences
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { Input, Dialog, DialogContent, DialogActions } from '@material-ui/core/'
import Divider from '@material-ui/core/Divider'

import './formatpreview.sass'
import { formatPreviewStyles } from './formatpreview.styles'

// UI Components
const textLine = '________________________________________________________________'
const FormatPreview = props => {
  const { classes, isOpen, onClose, format } = props

  const handleDialogClose = () => {
    onClose()
  }

  const sizeFor = size => {
    return `${size}px`
  }

  const fontFor = weight => {
    return weight === 'R'
      ? 'corporatemonocondensed' : weight === 'B'
        ? 'corporatecondensedbold' : weight === 'Bc'
          ? 'IDAutomationHC39MCode39Barcode' : 'corporatemonocondensed'
  }

  const alignmentFor = align => {
    return align === 'R' ? 'right' : align === 'C' ? 'center' : 'left'
  }

  const isATextLine = (value) => {
    return value.includes('______')
  }

  const listFormat = () => {
    return format.map((component, index) => {
      return <div
        className='container-grid component-container'
        key={index}
      >
        <div className="container-grid component-rows-container">
          {component.rows.map((row, index) => {
            return <div
              className="container-grid row-container"
              key={index}
            >
              {row.columns.map((column, index) => {
                return <div
                  className='container-grid column-item column-container'
                  key={index}
                >
                  {<Input
                    className={`${classes.formatInput}`}
                    style={{
                      fontFamily: fontFor(row.weight),
                      fontSize: sizeFor(row.size)
                    }}
                    align="center"
                    fullWidth={true}
                    disableUnderline={true}
                    type="text"
                    readOnly={true}
                    name="name"
                    inputProps={{
                      style: { textAlign: isATextLine(column.value)
                        ? alignmentFor('C')
                        : alignmentFor(row.align) }
                    }}
                    defaultValue={isATextLine(column.value)
                      ? textLine : column.value} />
                  }
                </div>
              })
              }
            </div>
          })
          }
        </div>
      </div>
    })
  }
  return (
    <Dialog open={isOpen} onClose={handleDialogClose} aria-labelledby="form-dialog-title">
      <DialogContent className={classes.dialogContent}>
        {listFormat()}
      </DialogContent>
      <Divider/>
      <DialogActions>
        <Button onClick={handleDialogClose} className={classes.dialogButton}>
            Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default withStyles(formatPreviewStyles)(FormatPreview)
