import { sidebarWidth } from './../../components/sidebar/Sidebar'
export const NewUserStyles = {
  content: {
    padding: '20px',
    marginLeft: sidebarWidth,
    width: '100%' - sidebarWidth
  },
  formControl: {
    margin: '8px',
    marginLeft: '0',
    marginRight: '0',
    width: '100%'
  },
  inputFirstName: {
    marginLeft: '0',
    marginRight: '0',
    width: '100%'
  },
  newUserForm: {
    width: '50%'
  },
  newUserTitleForm: {
    textAlign: 'left'
  },
  paper: {
    textAlign: 'center',
    color: '#000000'
  },
  textInfo: {
    marginLeft: '10px'
  },
  cssFocused: {},
  notchedOutline: {},
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: '#EFA91B'
    },
    textAlign: 'left'
  },
  cssLabel: {
    '&$cssFocused': {
      color: '#EFA91B'
    }
  },
  checkBox: {
    color: '#EFA91B !important'
  },
  internalList: {
    paddingLeft: '30px'
  },
  newUserContainer: {
    marginTop: '3em'
  }
}
