import { sidebarWidth } from './../../components/sidebar/Sidebar'
export const settingsStyles = {
  content: {
    padding: '90px 20px 20px 20px',
    marginLeft: sidebarWidth,
    width: '100%' - sidebarWidth,
    display: 'flex',
    flexDirection: 'column'
  },
  column: {
    maxWidth: '100%',
  },
  chooseModule: {
    width: '500px'
  },
  labelInput: {
    color: '#fff'
  },
  saveButton: {
    background: '#efa817',
    height: '30px',
    color: '#fff',
    fontWeight: 'bold',
    '&:hover': {
      background: '#efa817'
    },
    boxShadow: 'none'
  },
  dialogButton: {
    color: '#efa817',
    background: '#fff',
    boxShadow: 'none'
  },
  cssFocused: {},
  notchedOutline: {},
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: '#000'
    },
    textAlign: 'left'
  },
  cssLabel: {
    '&$cssFocused': {
      color: '#000'
    }
  },
  textConfig: {
    fontSize: '20px',
    paddingTop: '15px',
    margin: '0 20px'
  },
  subtitleConfig: {
    color: '#9da0af',
    fontSize: '14px',
    margin: '0 20px'
  },
  textModules: {
    margin: '5px 20px',
    fontWeight: 'bold'
  }
}
