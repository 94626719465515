import React from 'react'
import { withRouter } from 'react-router-dom'
import { Typography, Button } from '@material-ui/core'
import logo from '../../assets/images/logo.png'
import './ErrorBoundary.sass'

class ErrorBoundary extends React.Component {
  state = {
    hasError: false
  }

  static getDerivedStateFromError(error) {
    console.log(error)
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo)
  }

  handleHome = () => {
    this.props.history.push('/')
    this.setState({
      hasError: false
    })
  }

  render() {
    const { hasError } = this.state
    const { children } = this.props
    if (hasError) {
      return (
        <div className="flex_container page_background">
          <img className="icon" src={logo} alt="logo_kiosk" />
          <Typography className="text_color" variant="h2">
            Error interno del servidor
          </Typography>
          <br />
          <Typography className="text_color" variant="h4">
            Ha ocurrido un error inesperado y no fue posible completar tu
            solicitud.
          </Typography>
          <br />
          <Button
            className="home_button"
            variant="contained"
            onClick={this.handleHome}
          >
            Regresar al home
          </Button>
        </div>
      )
    }
    return children
  }
}

export default withRouter(ErrorBoundary)
