/* eslint-disable camelcase */
// Dependences
import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import Check from '@material-ui/icons/Check'
import Close from '@material-ui/icons/Close'
import Warning from '@material-ui/icons/Warning'
import CountryFlagByName from './../../assets/images/countries/countriesFlags'
import IconButton from '@material-ui/core/IconButton'
import SettingsIcon from '@material-ui/icons/SettingsApplications'

// Utils
import { groupedKiosksCountByStatus } from '../../utils/utils.js'

// Styles
import './countrysummarycard.sass'
import { countrySummaryCardStyles } from './countrysummarycard.styles'
import { useSelector } from 'react-redux'
import FilterRolePermissions from '../../utils/FilterRolePermissions'

function CountrySummaryCard (props) {
  const { countrySummary } = props
  const { role } = useSelector(state => state.user)
  const router = useHistory()

  const flag = CountryFlagByName(countrySummary.name)
  const count = groupedKiosksCountByStatus(countrySummary.kiosk_status_count)
  const { in_service, out_of_service, warning } = count

  function handleDetails() {
    props.onListDetails(props.countrySummary)
  }

  return (
    <div className="page-dashboard__grid-item">
      <div className="page-dashboard__country-info">
        <img
          src={flag.length > 0 ? flag : 'https://via.placeholder.com/50'}
          alt=""
          className="page-dashboard__flag-icon"
        />
        <Typography className={props.classes.textInfo}>
          {props.countrySummary.name}
        </Typography>
        {FilterRolePermissions('setting', 'see', role) && (
          <IconButton
            className="icon_button"
            aria-label="setting"
            size="small"
            onClick={() =>
              router.push(`/settings-by-country/${countrySummary.id}`, {
                country: countrySummary,
                level: 1
              })
            }
          >
            <SettingsIcon fontSize="inherit" />
          </IconButton>
        )}
      </div>
      <div className="page-dashboard__card-header">
        <span>Total kioscos</span>
        <span>{countrySummary.total_kiosks}</span>
      </div>
      <div className="page-dashboard__card-title">
        <span>Estado kioscos</span>
      </div>
      <div className="page-dashboard__card-list">
        <div className="page-dashboard__card-list-item">
          <Check className={props.classes.inServiceIcon} />
          <div>En servicio</div>
          <div>{in_service}</div>
        </div>
        <div className="page-dashboard__card-list-item">
          <Close className={props.classes.outOfServiceIcon} />
          <div>Sin servicio</div>
          <div>{out_of_service}</div>
        </div>
        <div className="page-dashboard__card-list-item">
          <Warning className={props.classes.warningIcon} />
          <div>En advertencia</div>
          <div>{warning}</div>
        </div>
        <div className="page-dashboard__card-footer">
          <button type="button" onClick={handleDetails}>
            INGRESAR
          </button>
        </div>
      </div>
    </div>
  )
}

export default withStyles(countrySummaryCardStyles)(CountrySummaryCard)
