import React, { Component } from 'react'
import { connect } from 'react-redux'

import { doClearRequestStatus } from '../../actions/clearStatus'
import { withStyles } from '@material-ui/core/styles'
import Header from '../../components/header/Header'
import Sidebar from '../../components/sidebar/Sidebar'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import { NewRolePermissionStyle } from './newrolepermission.style'
import './newrolepermission.sass'
import {
  doCreateRolesPermissions,
  doGetPermissions,
  doGetRolePermission,
  doUpdateRolesPermissions
} from '../../actions/rolePermission'
import { companyId } from '../../selectors/company'
import { getLoginToken } from '../../selectors/user'

import NewRoleForm from '../../components/NewRoleForm/NewRoleForm'
import NewRoleList from '../../components/NewRoleList/NewRoleList'
import MySnackbar from '../../components/MySnackbar/MySnackbar'

class NewRolePermission extends Component {
  constructor(props) {
    super(props)
    this.state = {
      role: {
        role_name: '',
        role_description: ''
      },
      permissions: [],
      built_in: false,
      isEdit: false
    }
  }

  componentDidMount() {
    this.props.doClearRequestStatus()
    this.props.doGetPermissions()
    const paramId = this.props.match.params
    if (paramId.id) {
      this.setState((prevState) => ({ ...prevState, isEdit: true }))
      const data = {
        Id: paramId.id,
        companyId: this.props.companyId,
        user: this.props.userId
      }
      this.props.doGetRolePermission(data)
    }
  }

  componentDidUpdate = (prevProps) => {
    if (
      this.props.rolePermission &&
      prevProps.rolePermission !== this.props.rolePermission
    ) {
      this.prepareDataEdit()
    }

    if (this.props.success !== prevProps.success && this.props.success) {
      setTimeout(() => {
        this.props.history.push('/roles-permissions')
      }, 1000)
    }
  }

  handleSaveUser = async () => {
    const { companyId, userId } = this.props
    const paramId = this.props.match.params
    if (paramId.id) {
      const data = {
        name: this.state.role.role_name,
        description: this.state.role.role_description,
        permissions_ids: this.state.permissions,
        companyId,
        userId,
        roleId: paramId.id
      }

      this.props.doUpdateRolesPermissions(data)
    } else {
      const data = {
        name: this.state.role.role_name,
        description: this.state.role.role_description,
        permissions_ids: this.state.permissions,
        companyId,
        userId
      }

      this.props.doCreateRolesPermissions(data)
    }
  }

  changeValueField = (event) => {
    this.setState({
      role: { ...this.state.role, [event.target.id]: event.target.value }
    })
  }

  checkPermission = (event, id) => {
    if (!this.state.built_in) {
      if (event.target.checked) {
        const permissions = this.state.permissions
        permissions.push(id)
        this.setState({ ...this.state, permissions })
      } else {
        const permissions = this.state.permissions.filter((permission) => {
          return id !== permission
        })
        this.setState({ ...this.state, permissions })
      }
    }
  }

  handleCloseSnackBar = () => {
    this.props.doClearRequestStatus()
  }

  prepareDataEdit = () => {
    if (this.props.rolePermission && this.state.role.role_name === '') {
      this.setState({
        role: {
          role_name: this.props.rolePermission.name,
          role_description: this.props.rolePermission.description
        },
        permissions: this.props.rolePermission.permission.map((permission) => {
          return permission.id
        }),
        built_in: this.props.rolePermission.built_in
      })
    }
  }

  render() {
    const { permissions } = this.props
    const openSnackbar = this.props.error || this.props.success
    const invalidForm =
      this.state.role.role_name === '' ||
      this.state.role.role_description === ''

    return (
      <div className="page-new-user">
        <MySnackbar
          open={openSnackbar}
          variant={
            openSnackbar ? (this.props.error ? 'error' : 'success') : 'default'
          }
          onClose={this.handleCloseSnackBar}
          message={openSnackbar ? this.props.message : ''}
        />
        <Header
          currentPage={
            this.state.isEdit
              ? 'Lista de Roles / Actualizar Rol'
              : 'Lista de Roles / Nuevo Rol'
          }
        />
        <Sidebar level={0} />
        <div className={this.props.classes.content}>
          <Grid container spacing={24}>
            <Grid item xs={12} sm={12}>
              <Paper className={this.props.classes.paper}>
                <NewRoleForm
                  classes={this.props.classes}
                  dataRole={this.state.role}
                  changeField={(event) => {
                    this.changeValueField(event)
                  }}
                />
                <NewRoleList
                  classes={this.props.classes}
                  permissions={permissions}
                  selectedPermissions={this.state.permissions}
                  blockSwitch={this.state.built_in}
                  checkPermission={(event, id) =>
                    this.checkPermission(event, id)
                  }
                />
                <div className="role__container_buttons">
                  <Button
                    className="role__button_cancel"
                    onClick={() =>
                      this.props.history.push('/roles-permissions')
                    }
                  >
                    Cancelar
                  </Button>
                  {!this.state.built_in ? (
                    <Button
                      disabled={invalidForm}
                      variant="contained"
                      className="role__button"
                      onClick={() => this.handleSaveUser()}
                    >
                      {this.state.isEdit ? 'ACTUALIZAR ROL' : 'CREAR ROL'}
                    </Button>
                  ) : (
                    ''
                  )}
                </div>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyId: companyId(state),
    userId: getLoginToken(state),
    endRequest: state.endRequest,
    permissions: state.rolePermission.permissions,
    rolePermission: state.rolePermission.role,
    error: state.rolePermission.error,
    success: state.rolePermission.success,
    message: state.rolePermission.message
  }
}

const mapDispatchToProps = {
  doCreateRolesPermissions,
  doGetPermissions,
  doClearRequestStatus,
  doGetRolePermission,
  doUpdateRolesPermissions
}

export default withStyles(NewRolePermissionStyle)(
  connect(mapStateToProps, mapDispatchToProps)(NewRolePermission)
)
