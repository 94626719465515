/* eslint-disable camelcase */
// Dependences
import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import {
  doFetchSettings,
  doCreateSettings,
  doUpdateSettings
} from '../../actions/setting'
import {
  doFetchKioskTransactions,
  doDeleteKiosk,
  doFetchKioskById
} from '../../actions/kiosk'
import { getLoginToken } from '../../selectors/user'
import { companyId } from '../../selectors/company'
import { doClearRequestStatus } from '../../actions/clearStatus'
import { getError, getMessage, getSuccess } from '../../selectors/transaction'
import { kioskStatus, filterApiToken } from '../../utils/utils'

import { withStyles, Button, Paper, Typography, Modal, OutlinedInput, InputAdornment, IconButton } from '@material-ui/core'

// Custom components
import Header from './../../components/header/Header'
import Sidebar from './../../components/sidebar/Sidebar'
import RoomTwoTone from '@material-ui/icons/RoomTwoTone'
import Transactions from './../../components/Transactions/Transactions'
import MySnackbar from '../../components/MySnackbar/MySnackbar'
import CircularLoading from '../../components/Loading/CircularLoading'
import kioskImage from './../../assets/images/img_screen_POS.png'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'

import { SETTINGS_DATA_TYPES } from '../../constants/Constants'

// Styles
import './kioskdetail.sass'
import { kioskDetailStyles } from './kioskdetail.styles'
import Divider from '@material-ui/core/Divider'
import FilterRolePermissions from '../../utils/FilterRolePermissions'
import { Visibility, VisibilityOff } from '@material-ui/icons'

class KioskDetail extends Component {
  constructor() {
    super()
    this.state = {
      terminalIds: {
        value: '[]',
        setting: { name: 'terminal_ids', data_type: SETTINGS_DATA_TYPES.ARRAY }
      },
      clientId: {
        value: null,
        setting: { name: 'client_id', data_type: SETTINGS_DATA_TYPES.STRING }
      },
      apiToken: {
        value: null,
        setting: {
          name: 'connect_api_token',
          data_type: SETTINGS_DATA_TYPES.STRING
        }
      },
      showApiToken: false,
      openModal: false,
      kiosk: {}
    }
  }

  componentDidMount() {
    this.props.doClearRequestStatus()
    this.fetchKioskById()
    this.fetchSettings()
    this.fetchKioskTransactions()
    this.fixScrollView()
  }

  fixScrollView = () => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 0)
  }

  settingByName = (name) => {
    var settingByName = {}
    const { settings } = this.props.setting
    settings.forEach((setting) => {
      if (setting.name === name) {
        settingByName = setting
      }
    })
    return settingByName
  }

  onChangeTerminalId = (event) => {
    const terminalIds = this.settingByName('terminal_ids')
    var terminalIdsItems = JSON.parse(terminalIds.value)
    terminalIdsItems.forEach((terminalId) => {
      if (terminalId.name === event.target.name) {
        terminalId.value = event.target.value
      }
    })
    terminalIdsItems = JSON.stringify(terminalIdsItems)
    this.setState({
      terminalIds: {
        ...this.state.terminalIds,
        value: terminalIdsItems,
        id: terminalIds.id
      }
    })
  }

  onChangeClientId = (event) => {
    const clientId = this.settingByName('client_id')
    this.setState({
      clientId: {
        ...this.state.clientId,
        value: event.target.value,
        id: clientId.id
      }
    })
  }

  onChangeApiToken = (event) => {
    const apiToken = this.settingByName('connect_api_token')
    this.setState({
      apiToken: {
        ...this.state.apiToken,
        value: event.target.value,
        id: apiToken.id
      }
    })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const { terminalIds, clientId, apiToken } = this.state
    const settings = [terminalIds, clientId, apiToken]
    this.onSetSettings(settings)
  }

  onSetSettings = (settings) => {
    this.props.doClearRequestStatus()
    const settingsToCreate = this.settingsToCreate(settings)
    const settingsToUpdate = this.settingsToUpdate(settings)
    if (settingsToCreate.length > 0) {
      this.createSettings(settingsToCreate)
    }
    if (settingsToUpdate.length > 0) {
      this.updateSettings(settingsToUpdate)
    }
  }

  settingsToCreate = (settings) => {
    return settings
      .filter((setting) => setting.id === undefined && setting.value !== null)
      .map((setting) => {
        return {
          name: setting.setting.name,
          value: `${setting.value}`,
          data_type: setting.setting.data_type
        }
      })
  }

  settingsToUpdate = (settings) => {
    return settings
      .filter((setting) => setting.id !== undefined)
      .map((setting) => {
        return { id: setting.id, value: `${setting.value}` }
      })
  }

  createSettings = (settings) => {
    const kioskId = this.props.location.state.data.kiosk.id
    const params = { company_id: this.props.companyId, kiosk_id: kioskId }
    params.settings = settings
    this.props.doCreateSettings({
      data: { params: params, user: this.props.user }
    })
  }

  updateSettings = (settings) => {
    const kioskId = this.props.location.state.data.kiosk.id
    const params = { company_id: this.props.companyId, kiosk_id: kioskId }
    this.props.doUpdateSettings({
      data: { params: params, settings: settings, user: this.props.user }
    })
  }

  fetchSettings = () => {
    const kioskId = this.props.location.state.data.kiosk.id
    const params = { company_id: this.props.companyId, kiosk_id: kioskId }
    this.props.doFetchSettings({
      data: { params: params, user: this.props.user }
    })
  }

  handleKioskSettings = () => {
    const { kiosk, country } = this.props.location.state.data
    this.props.history.push('/settings', { kiosk: kiosk, country: country })
  }

  handleKioskDeletion = () => {
    this.handleOpen()
  }

  deleteKiosk = () => {
    const { user } = this.props
    const kioskId = this.props.location.state.data.kiosk.id
    this.props.doDeleteKiosk({
      data: { kioskId: kioskId, user: user, companyId: this.props.companyId }
    })
    setTimeout(() => {
      this.props.history.goBack()
    }, 1000)
    this.handleClose()
  }

  handleCloseSnackBar = () => {
    this.props.doClearRequestStatus()
  }

  fetchKioskTransactions = () => {
    const kioskId = this.props.location.state.data.kiosk.id
    this.props.doFetchKioskTransactions({
      data: {
        companyId: this.props.companyId,
        kioskId: kioskId,
        user: this.props.user
      }
    })
  }

  fetchKioskById = () => {
    const kioskId = this.props.location.state.data.kiosk.id
    this.props.doFetchKioskById({
        data: {
          kioskId: kioskId,
          companyId: this.props.companyId
        }
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.kioskDetail !== this.props.kioskDetail) {
      this.setState({ kiosk: nextProps.kioskDetail })
    }
    if (nextProps.setting.settings !== this.props.setting.settings) {
      const { settings } = nextProps.setting
      if (settings) {
        settings.forEach((setting) => {
          if (setting.name === 'terminal_ids') {
            this.setState({
              terminalIds: {
                ...this.state.terminalIds,
                value: setting.value,
                id: setting.id
              }
            })
          } else if (setting.name === 'client_id') {
            this.setState({
              clientId: {
                ...this.state.clientId,
                value: setting.value,
                id: setting.id
              }
            })
          } else if (setting.name === 'connect_api_token') {
            this.setState({
              apiToken: {
                ...this.state.apiToken,
                value: setting.value,
                id: setting.id
              }
            })
          }
        })
      }
    }
  }

  handleOpen = () => {
    this.setState({ openModal: true })
  }

  handleClose = () => {
    this.setState({ openModal: false })
  }

  getHoursDiff = (initialDate, finalDate) => {
    if (!initialDate || !finalDate) {
      return 0
    } else {
      const initial = moment(initialDate)
      const final = moment(finalDate)
      const result =  final.diff(initial, 'hours')
      return result < 0 ? 0 : result
    }
  }
  handleChangeShowApiToken = () => {
    this.setState({ showApiToken: !this.state.showApiToken })
  }

  render() {
    const { country, city, location } = this.props.location.state.data
    const kiosk = this.state.kiosk
    const { classes, kiosks, success, error, message, userRole } = this.props
    const openSnackbar = success || error

    const links = [
      {
        title: 'Inicio',
        onClick: () => {
          this.props.history.push('/dashboard')
        }
      },
      {
        title: country.name,
        onClick: () => {
          this.props.history.push('/country-status', { country: country })
        }
      },
      {
        title: city.name,
        onClick: () => {
          this.props.history.push('/city-status', {
            data: { city: city, country: country }
          })
        }
      },
      {
        title: location.name,
        onClick: () => {
          this.props.history.push('/kiosk-by-location', {
            data: { city: city, country: country, location: location }
          })
        }
      }
    ]
    var listTerminalIds
    if (this.state.terminalIds.value) {
      listTerminalIds = JSON.parse(this.state.terminalIds.value).map(
        (terminalId, index) => {
          return (
            <div className="form-group" key={terminalId.name}>
              <small className="form-input-subtitle">
                Terminal ID {index + 1}
              </small>
              <TextValidator
                className={classes.selectConfig}
                variant="outlined"
                name={terminalId.name}
                onChange={this.onChangeTerminalId}
                value={terminalId.value || ''}
                validators={['required']}
                errorMessages={['No enviar vacío']}
              />
            </div>
          )
        }
      )
    }

    var listDevices
    if (kiosk) {
      if (kiosk.devices) {
        listDevices = kiosk.devices.map((device) => {
          return (
            <div className="kiosk_detail_row">
              <Typography>
                {device.name} {device.brand}
              </Typography>
              <Typography>
                {device.state === 1 ? 'Con conexión' : 'Sin conexión'}
              </Typography>
            </div>
          )
        })
      }
    }

    return (
      <div className="page-kiosk">
        <MySnackbar
          open={openSnackbar}
          variant={openSnackbar ? (success ? 'success' : 'error') : 'default'}
          onClose={this.handleCloseSnackBar}
          message={openSnackbar ? message : ''}
        />
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.openModal}
          onClose={this.handleClose}
        >
          <div className={classes.modal}>
            <h2 id="simple-modal-title">Eliminar Kiosco</h2>
            <p id="simple-modal-description">
              Está a punto de eliminar el kiosco de esta ubicación, al aceptar
              se perderá toda la información y configuraciones establecidas.
              <br />
              <br />
              ¿Desea continuar?
              <Divider className={classes.separator} />
              <div className="form-button-container">
                <Button className={classes.btnLink} onClick={this.handleClose}>
                  Cancelar
                </Button>
                <Button className={classes.btnLink} onClick={this.deleteKiosk}>
                  Aceptar
                </Button>
              </div>
            </p>
          </div>
        </Modal>
        <Header links={links} currentPage={kiosk ? kiosk.name : ''} />
        <Sidebar level={2} country={country} />
        <div className={classes.content}>
          <div className="page_header_container">
            <div className="page_header_container__left_content">
              <RoomTwoTone className={classes.locationIcon} />
              <div>
                <Typography className={classes.mediumFont}>
                  {location.name}
                </Typography>
                <Typography>{kiosk ? kiosk.area_name : ''}</Typography>
              </div>
            </div>
            <Button
              variant="contained"
              className="config__button"
              onClick={this.handleKioskSettings}
            >
              Configuraciones
            </Button>
          </div>
          <div className="kiosk-info">
            <Paper className={classes.kioskDetailContainer}>
              <div className="kiosk_detail_left_side">
                <div>
                  <div className="kiosk_detail_row">
                    <Typography>Estado general</Typography>
                    <Typography>
                      {kioskStatus(kiosk ? kiosk.status : 0).toUpperCase()}
                    </Typography>
                  </div>
                  {listDevices}
                  <div className="kiosk_detail_row">
                    <Typography>Puesta en marcha</Typography>
                    <Typography>
                      {moment(kiosk ? kiosk.started_date : '').format('D/MM/YYYY')}
                    </Typography>
                  </div>
                  <div className="kiosk_detail_row">
                    <Typography>Horas en funcionamiento continuo</Typography>
                    <Typography>
                      {(kiosk && this.getHoursDiff(
                        kiosk.started_date,
                        kiosk.last_status_date
                      ))}{' '}
                      horas
                    </Typography>
                  </div>
                  <div className="kiosk_detail_row">
                    <Typography>Versión del software</Typography>
                    <Typography>{kiosk ? kiosk.version : ''}</Typography>
                  </div>
                  <div className="kiosk_detail_row">
                    <Typography>Área</Typography>
                    <Typography>{kiosk ? kiosk.area_name : ''}</Typography>
                  </div>
                </div>
                <div></div>
                <Divider className={classes.separator} />
                {FilterRolePermissions('kiosk', 'delete', userRole) && (
                  <Button
                    variant="contained"
                    className="delete__button"
                    onClick={this.handleKioskDeletion}
                  >
                    Eliminar Kiosco
                  </Button>
                )}
              </div>
              <img src={kioskImage} alt="" className={classes.kioskImage} />
            </Paper>
            <Paper className={classes.mainPaper}>
              <Typography variant="h6" className={classes.kioskSettingsTitle}>
                Configuraciones extras
              </Typography>
              <ValidatorForm
                ref="form"
                onSubmit={this.handleSubmit}
                onError={(errors) => console.log(errors)}
              >
                {listTerminalIds}
                <div className="form-group">
                  <small className="form-input-subtitle">Client ID</small>
                  <TextValidator
                    className={classes.selectConfig}
                    variant="outlined"
                    onChange={this.onChangeClientId}
                    value={this.state.clientId.value || ''}
                    validators={['required']}
                    errorMessages={['No enviar vacío']}
                  />
                </div>
                <div className="form-group">
                  <small className="form-input-subtitle">API Token</small>
                  <OutlinedInput
                    type="text"
                    className={classes.selectConfig}
                    validators={['required']}
                    errorMessages={['No enviar vacío']}
                    disabled={!this.state.showApiToken}
                    value={this.state.showApiToken ? this.state.apiToken.value : filterApiToken(this.state.apiToken.value) || ''}
                    onChange={(event) => this.onChangeApiToken(event)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleChangeShowApiToken}
                        >
                          {this.state.showApiToken ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </div>
                <div className="form-button-container">
                  {FilterRolePermissions('kiosk', 'update', userRole) && (
                    <Button
                      type="submit"
                      color="primary"
                      autoFocus
                      className={classes.btnLink}
                      fullWidth={false}
                    >
                      GUARDAR
                    </Button>
                  )}
                </div>
              </ValidatorForm>
            </Paper>
            {kiosks.loading ? (
              <CircularLoading isLoading={kiosks.loading} />
            ) : (
              <Paper className={classes.mainPaper}>
                <Transactions
                  transactions={kiosks.transactions}
                  country={country}
                />
              </Paper>
            )}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: getLoginToken(state),
    userRole: state.user.role,
    setting: state.setting,
    companyId: companyId(state),
    kiosks: state.kiosk,
    kioskDetail: state.kiosk.kioskDetail,
    success: getSuccess(state),
    error: getError(state),
    message: getMessage(state)
  }
}

const mapDispatchToProps = {
  doFetchKioskTransactions,
  doClearRequestStatus,
  doDeleteKiosk,
  doFetchSettings,
  doCreateSettings,
  doUpdateSettings,
  doFetchKioskById
}

export default withStyles(kioskDetailStyles)(
  connect(mapStateToProps, mapDispatchToProps)(KioskDetail)
)
