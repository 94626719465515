import {
  KIOSK_TRANSACTIONS_FETCH,
  KIOSK_TRANSACTIONS_FETCH_SUCCESS,
  KIOSK_TRANSACTIONS_FETCH_ERROR,
  KIOSK_DEVICES_FETCH,
  KIOSK_DEVICES_FETCH_SUCCESS,
  KIOSK_DEVICES_FETCH_ERROR,
  DELETE_KIOSK,
  DELETE_KIOSK_SUCCESS,
  DELETE_KIOSK_ERROR,
  FETCH_KIOSKS_INFO,
  FETCH_KIOSKS_INFO_SUCCESS,
  FETCH_KIOSKS_INFO_ERROR,
  FETCH_KIOSKS_BY_ID,
  FETCH_KIOSKS_BY_ID_ERROR,
  FETCH_KIOSKS_BY_ID_SUCCESS
} from '../constants/ActionTypes'

export const doFetchKioskTransactions = payload => ({ type: KIOSK_TRANSACTIONS_FETCH, payload })
export const doFetchKioskTransactionsSuccess = payload => ({ type: KIOSK_TRANSACTIONS_FETCH_SUCCESS, payload })
export const doFetchKioskTransactionsError = payload => ({ type: KIOSK_TRANSACTIONS_FETCH_ERROR, payload })
export const doFetchKioskDevices = payload => ({ type: KIOSK_DEVICES_FETCH, payload })
export const doFetchKioskDevicesSuccess = payload => ({ type: KIOSK_DEVICES_FETCH_SUCCESS, payload })
export const doFetchKioskDevicesError = payload => ({ type: KIOSK_DEVICES_FETCH_ERROR, payload })
export const doDeleteKiosk = payload => ({ type: DELETE_KIOSK, payload })
export const doDeleteKioskSuccess = payload => ({ type: DELETE_KIOSK_SUCCESS, payload })
export const doDeleteKioskError = payload => ({ type: DELETE_KIOSK_ERROR, payload })
export const doFetchKiosksInfo = payload => ({ type: FETCH_KIOSKS_INFO, payload })
export const doFetchKiosksInfoSuccess = payload => ({ type: FETCH_KIOSKS_INFO_SUCCESS, payload })
export const doFetchKiosksInfoError = payload => ({ type: FETCH_KIOSKS_INFO_ERROR, payload })

export const doFetchKioskById = payload => ({ type: FETCH_KIOSKS_BY_ID, payload })
export const doFetchKioskByIdSuccess = payload => ({ type: FETCH_KIOSKS_BY_ID_SUCCESS, payload })
export const doFetchKioskByIdError = payload => ({ type: FETCH_KIOSKS_BY_ID_ERROR, payload })
