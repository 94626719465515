// Dependences
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

// Actions - Selectors
import { setUser, doResetAppState } from '../../actions/user'
import { doFetchCompany, doFetchCompanyRole } from '../../actions/company'
// UI Components
import HeaderBar from '../../components/HeaderBar/HeaderBar'
import MySnackbar from '../../components/MySnackbar/MySnackbar'
import { withStyles } from '@material-ui/core/styles'
import logoHeader from './../../assets/images/logo-header.png'
import ChooseCompanyGrid from '../../components/ChooseCompanyGrid/ChooseCompanyGrid'
import Typography from '@material-ui/core/Typography'

// Styles and assets
import './choosecompany.sass'
import { choosecompanyStyles } from './choosecompany.styles'
import { getError, getMessage } from '../../selectors/user'

class ChooseCompany extends Component {
  constructor() {
    super()
    this.state = {}
  }
  render() {
    const {
      error,
      message,
      classes,
      user: { companies },
      user
    } = this.props

    return (
      <div className="page-companyChoose">
        <MySnackbar
          open={error}
          variant={error ? 'error' : 'default'}
          onClose={this.handleCloseSnackBar}
          message={message || ''}
        />
        <HeaderBar
          className="page-companyChoose__header"
          logoHeader={logoHeader}
          onLogout={this.handleLogout}
          btnRightLabel="Cerrar Sessión"
        />
        <div className="page-companyChoose__container">
          <Typography className={classes.title}>
            Hola, {user.first_name}
          </Typography>
          <Typography className={classes.subTitle}>
            Bienvenido al administrador de kiosco, por favor selecciona una
            compañia para continuar
          </Typography>
          <ChooseCompanyGrid
            companies={companies}
            handleChooseCompany={this.handleChooseCompany}
          ></ChooseCompanyGrid>
        </div>
      </div>
    )
  }
  handleCloseSnackBar = () => {
    this.props.doClearRequestStatus()
  }
  handleLogout = () => {
    this.props.setUser({})
    this.props.doResetAppState()
    this.props.history.push('/login')
  }
  handleChooseCompany = (company) => {

    this.fetchCompany(company)
    this.props.history.push('/dashboard')
    return <Redirect to="/dashboard"></Redirect>
  }
  fetchCompany = (company) => {
    this.props.doFetchCompanyRole({ companyId: company.id, roleId: company.role.id })
    this.props.doFetchCompany({ companyId: company.id })
  }
}

const mapDispacthToProps = {
  setUser,
  doFetchCompany,
  doFetchCompanyRole,
  doResetAppState
}

const mapStateToProps = (state) => {
  return {
    user: state.user.data,
    logged_in: state.logged_in,
    error: getError(state),
    message: getMessage(state),
    isLoading: state.user.loading
  }
}

export default withStyles(choosecompanyStyles)(
  connect(mapStateToProps, mapDispacthToProps)(ChooseCompany)
)
