const getSuccess = (state) =>
  state.area.success ||
  state.banner.success ||
  state.setting.success ||
  state.update.success ||
  state.format.success ||
  state.company.success
const getError = (state) =>
  state.area.error ||
  state.banner.error ||
  state.setting.error ||
  state.update.error ||
  state.format.error ||
  state.company.error
const getMessage = (state) => {
  return state.area.message
    ? state.area.message
    : state.banner.message
    ? state.banner.message
    : state.format.message
    ? state.format.message
    : state.setting.message
    ? state.setting.message
    : state.update.message
    ? state.update.message
    : state.company.message
    ? state.company.message
    : null
}

export { getSuccess, getError, getMessage }
