import { sidebarWidth } from '../../components/sidebar/Sidebar'
export const configurationStyles = {
  content: {
    padding: '90px 20px 20px 20px',
    marginLeft: sidebarWidth,
    width: '100%' - sidebarWidth
  },
  textInfo: {
    marginLeft: '10px'
  },
  separator: {
    margin: '20px -20px'
  },
  container: {
    padding: '20px',
    margin: '0px 120px'
  }
}
