import ApiClientSingleton from '../api-client'

const kioskApiInstance = ApiClientSingleton.getKioskApiInstance()

const fetchCountriesCount = async (user, companyId) => {
  const uri = `/api/v1/companies/countries_count?company_id=${companyId}`
  const headers = { Authorization: `Bearer ${user}` }
  const { data } = await kioskApiInstance.get(uri, { headers })
  return data
}

const fetchCompany = async (companyId) => {
  const uri = `/api/v1/companies?id=${companyId}`
  const { data } = await kioskApiInstance.get(uri)
  return data
}

const updateCompany = async (payload) => {
  const uri = `/api/v1/companies/${payload.companyId}`
  let formData = new FormData()
  formData.append('daily_update_time', payload.selectedHour)
  const { data } = await kioskApiInstance.put(uri, formData)
  return data
}

const fetchCompanyRole = async (payload) => {
  const uri = `/api/v1/companies/${payload.companyId}/roles/${payload.roleId}`
  const response = await kioskApiInstance.get(uri)
  return response
}

export { fetchCountriesCount, fetchCompany, updateCompany, fetchCompanyRole }
