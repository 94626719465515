export const choosecompanyStyles = {
  title: {
    fontSize: '1.5em',
    padding: 15
  },
  subTitle: {
    fontSize: '1em',
    textAlign: 'center',
    margin: '0 25% 2% 25%',
    maxWidth: '50%',
    padding: 15
  }
}
