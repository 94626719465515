// Dependences
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import CountryFlagByName from './../../assets/images/countries/countriesFlags'
import { firstCountry, hasOnlyOneCountry } from '../../selectors/company'

// UI Components
import BarChart from '@material-ui/icons/BarChart'
import Person from '@material-ui/icons/Person'
import { Drawer, withStyles, Typography } from '@material-ui/core'
import DashboardIcon from '@material-ui/icons/Dashboard'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import DvrIcon from '@material-ui/icons/Dvr'
import RoomIcon from '@material-ui/icons/Room'
import SettingsIcon from '@material-ui/icons/SettingsApplications'
import ViewListIcon from '@material-ui/icons/ViewList'
import List from '@material-ui/core/List'
import Link from '@material-ui/core/Link'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ExitToApp from '@material-ui/icons/ExitToApp'
import { setUser, doResetAppState } from '../../actions/user'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
// Styles and assets
import logoHeader from './../../assets/images/logo-header.png'
import CountryDialog from '../CountryInfo/CountryInfo'
import FilterRolePermissions from '../../utils/FilterRolePermissions'

class Sidebar extends Component {
  handleLogout = () => {
    this.props.setUser({})
    this.props.doResetAppState()
    this.props.history.push('/login')
  }

  render() {
    const {
      level,
      hasOnlyOneCountry,
      userRole,
      country,
      company,
      firstCountry
    } = this.props
    var settingsTabTitle
    if (level === 0 || (level === 1 && hasOnlyOneCountry)) {
      settingsTabTitle = 'Configuraciones de kioskos'
    } else {
      settingsTabTitle = 'Configuraciones'
    }
    var currentCountry = country
    if (!currentCountry) {
      currentCountry = firstCountry
    }
    const flag = CountryFlagByName(currentCountry ? currentCountry.name : '')
    const companyImageUrl = company.image_url || ''

    const goHome = () => {
      if (hasOnlyOneCountry) {
        this.props.history.push('/dashboard')
      } else {
        this.props.history.push('/country-status', { country: currentCountry })
      }
    }

    const goSelectCompany = () => {
      this.props.history.push('/choose-company')
    }

    const logOutButton = hasOnlyOneCountry
      ? this.props.classes.buttonCountry
      : this.props.classes.button

    return (
      <Drawer variant="permanent">
        <List component="nav" className={this.props.classes.sidebar}>
          <div className="page-dashboard__logo">
            <Link>
              <img
                alt=""
                src={logoHeader}
                className={this.props.classes.logoSidebar}
              />
            </Link>
          </div>
          {(level === 0 || (level === 1 && hasOnlyOneCountry)) && (
            <ListItem button className={this.props.classes.countryBackground}>
              <ListItemIcon>
                <img
                  onClick={hasOnlyOneCountry ? goHome : null}
                  src={
                    companyImageUrl.length > 0
                      ? companyImageUrl
                      : 'https://via.placeholder.com/50'
                  }
                  alt=""
                  className={this.props.classes.companyIcon}
                />
              </ListItemIcon>
              <ListItemText
                primary={<span onClick={hasOnlyOneCountry ? goHome : null}>{company.name}</span>}
                secondary={
                  <span onClick={goSelectCompany}>Cambiar de compañia</span>
                }
              />
              <CountryDialog company={company} />
            </ListItem>
          )}
          {level !== 0 && !hasOnlyOneCountry && (
            <ListItem
              onClick={() => this.props.history.push('/dashboard')}
              className={this.props.classes.countryCell}
            >
              <ListItemIcon>
                <ArrowBackIcon className={this.props.classes.backIcon} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography className={this.props.classes.countryName}>
                    {country ? country.name : ''}
                  </Typography>
                }
              />
              <img
                src={flag.length > 0 ? flag : 'https://via.placeholder.com/50'}
                alt=""
                className={this.props.classes.flagIcon}
              />
            </ListItem>
          )}
          {level === 0 && !hasOnlyOneCountry && (
            <ListItem
              button
              onClick={() => this.props.history.push('/dashboard')}
            >
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
          )}
          {(level === 1 || hasOnlyOneCountry) && (
            <ListItem button onClick={goHome}>
              <ListItemIcon>
                <BarChart />
              </ListItemIcon>
              <ListItemText primary="Inicio" />
            </ListItem>
          )}
          {(level !== 0 || hasOnlyOneCountry) && (
            <div>
              {(FilterRolePermissions('location', 'see', userRole) ||
                FilterRolePermissions('kiosk', 'see', userRole)) && (
                <ListItem
                  button
                  onClick={() =>
                    this.props.history.push('/cities-status', {
                      country: currentCountry
                    })
                  }
                >
                  <ListItemIcon>
                    <RoomIcon />
                  </ListItemIcon>
                  <ListItemText primary="Ubicaciones" />
                </ListItem>
              )}
              {(FilterRolePermissions('kiosk', 'see', userRole)) && (
                <ListItem
                  button
                  onClick={() =>
                    this.props.history.push('/kiosks-status', {
                      country: currentCountry
                    })
                  }
                >
                  <ListItemIcon>
                    <ViewListIcon />
                  </ListItemIcon>
                  <ListItemText primary="Kioscos" />
                </ListItem>
              )}
            </div>
          )}
          {(level === 0 || (level === 1 && hasOnlyOneCountry)) &&
            (FilterRolePermissions('user', 'see', userRole)) && (
              <ListItem button onClick={() => this.props.history.push('/users')}>
                <ListItemIcon>
                  <Person />
                </ListItemIcon>
                <ListItemText primary="Usuarios" />
              </ListItem>
          )}
          {(level === 0 || (level === 1 && hasOnlyOneCountry)) &&
            (FilterRolePermissions('configuration', 'see', userRole)) && (
            <ListItem
              button
              onClick={() => this.props.history.push('/configurations')}
            >
              <ListItemIcon>
                <DvrIcon />
              </ListItemIcon>
              <ListItemText primary="Preferencias" />
            </ListItem>
          )}
          {(level === 0 || (level === 1 && hasOnlyOneCountry)) && (
            <ExpansionPanel
              className={this.props.classes.expansion}
              defaultExpanded={true}
            >
              <ExpansionPanelSummary
                className={this.props.classes.summary}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <SettingsIcon className={this.props.classes.expansionIcon} />
                <Typography className={this.props.classes.expansionText}>
                  {' '}
                  Administrador
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div className={this.props.classes.expansionDetailContainer}>
                  {(level === 0 || level === 1) &&
                    (FilterRolePermissions('setting', 'see', userRole)) && (
                    <ListItem
                      id="list-item-settings"
                      button
                      onClick={() =>
                        this.props.history.push('/settings', {
                          country: currentCountry,
                          level: level
                        })
                      }
                    >
                      <ListItemText primary="Configuraciones generales" />
                    </ListItem>
                  )}
                  {hasOnlyOneCountry && (FilterRolePermissions('setting', 'see', userRole)) && (
                    <ListItem
                      button
                      onClick={() =>
                        this.props.history.push(
                          `/settings-by-country/${currentCountry.id}`,
                          {
                            country: currentCountry,
                            level: level
                          }
                        )
                      }
                    >
                      <ListItemText primary={settingsTabTitle} />
                    </ListItem>
                  )}
                  {(level === 0 || level === 1) &&
                    (FilterRolePermissions('role', 'see', userRole)) && (
                    <ListItem
                      button
                      onClick={() =>
                        this.props.history.push('/roles-permissions', {
                          country: currentCountry,
                          level: level
                        })
                      }
                    >
                      <ListItemText primary="Roles y permisos" />
                    </ListItem>
                  )}
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          )}
          <ListItem button onClick={this.handleLogout} className={logOutButton}>
            <ListItemIcon>
              <ExitToApp className={this.props.classes.rightIcon} />
            </ListItemIcon>
            <ListItemText primary="Cerrar sesión" />
          </ListItem>
        </List>
      </Drawer>
    )
  }
}

export const sidebarWidth = 370
const sidebarStyles = {
  sidebar: {
    backgroundColor: '#fff',
    width: sidebarWidth,
    height: '100%'
  },
  userInfo: {
    backgroundColor: '#EFA817',
    marginBottom: '5px'
  },
  editIcon: {
    color: '#fff'
  },
  textInfo: {
    color: '#FFFFFF !important',
    fontWeight: 'Bold',
    marginLeft: '10px',
    fontSize: '17px'
  },
  button: {
    position: 'fixed',
    width: sidebarWidth,
    marginTop: 'auto',
    bottom: '0'
  },
  buttonCountry: {
    position: 'inherit',
    width: sidebarWidth,
    marginTop: 'auto',
    bottom: '0'
  },
  expansion: {
    boxShadow: 'none',
    margin: '0'
  },
  summary: {
    paddingLeft: '1em'
  },
  expansionIcon: {
    fontSize: '24px',
    color: ' #737373',
    margin: '0 1.3em 0 0'
  },
  expansionText: {
    fontSize: '1rem'
  },
  expansionDetailContainer: {
    width: '100%'
  },
  logoSidebar: {
    width: '150px',
    margin: '10px'
  },
  flagIcon: {
    borderRadius: '8px',
    width: '40px',
    height: '40px',
    border: '1px solid #fff'
  },
  companyIcon: {
    borderRadius: '8px',
    width: '40px',
    height: '40px',
    border: '1px solid #000'
  },
  countryCell: {
    backgroundColor: '#000'
  },
  countryName: {
    display: 'flex',
    justifyContent: 'flex-end',
    color: '#ffffff !important',
    fontSize: '15px'
  },
  backIcon: {
    color: '#ffffff !important'
  },
  countryBackground: {
    backgroundColor: 'var(--var-color-primary-grey)'
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.data,
    userRole: state.user.role,
    company: state.company,
    firstCountry: firstCountry(state),
    hasOnlyOneCountry: hasOnlyOneCountry(state)
  }
}

const mapDispacthToProps = {
  setUser,
  doResetAppState
}

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withRouter(withStyles(sidebarStyles)(Sidebar)))
