import {
  LOCATIONS_FETCH,
  LOCATIONS_FETCH_SUCCESS,
  STATISTICS_FETCH,
  STATISTICS_FETCH_SUCCESS,
  STATISTICS_FETCH_ERROR,
  CLEAR_REQUEST_STATUS,
  LOCATIONS_FETCH_ERROR,
  LOCATIONS_CREATE_SUCCESS,
  LOCATIONS_UPDATE_SUCCESS,
  LOCATIONS_CREATE_ERROR,
  LOCATIONS_UPDATE_ERROR,
  LOCATIONS_CREATE,
  LOCATIONS_UPDATE,
  LOADING,
  KIOSK_BY_LOCATION_FETCH,
  KIOSK_BY_LOCATION_SUCCESS,
  KIOSK_BY_LOCATION_ERROR,
  FETCH_COUNTRY_LOCATIONS_ERROR,
  FETCH_COUNTRY_LOCATIONS_SUCCESS,
  FETCH_COUNTRY_LOCATIONS
} from '../constants/ActionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case LOCATIONS_FETCH:
      return state
    case LOCATIONS_FETCH_SUCCESS:
      return {
        ...state,
        locations: action.payload,
        loading: false,
        error: false,
        success: true
      }
    case LOCATIONS_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: 'Error al obtener ubicaciones'
      }
    case STATISTICS_FETCH:
      return state
    case STATISTICS_FETCH_SUCCESS:
      return {
        ...state,
        statistics: action.payload.statistics
      }
    case STATISTICS_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: 'Error al obtener estadísticas',
        statistics: null
      }
    case LOCATIONS_CREATE:
      return action.payload
    case LOCATIONS_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        location: action.payload,
        success: true,
        message: 'Ubicación creada exitosamente'
      }
    case LOCATIONS_CREATE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: 'Error al crear ubicación'
      }
    case LOCATIONS_UPDATE:
      return {
        ...state,
        loading: false,
        success: true,
        message: 'Ubicación modificada exitosamente'
      }
    case LOCATIONS_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case LOCATIONS_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: 'Error al editar ubicación'
      }
    case KIOSK_BY_LOCATION_FETCH:
      return action.payload
    case KIOSK_BY_LOCATION_SUCCESS:
      return {
        ...state,
        kioskByLocation: action.payload,
        loading: false
      }
    case KIOSK_BY_LOCATION_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: 'Error al obtener ubicaciones'
      }
    case FETCH_COUNTRY_LOCATIONS:
      return {
        ...state,
        loading: true
      }
    case FETCH_COUNTRY_LOCATIONS_SUCCESS:
      return {
        ...state,
        countryLocations: action.payload,
        loading: false,
        error: false,
      }
    case FETCH_COUNTRY_LOCATIONS_ERROR:
      return {
        ...state,
        loading: false,
        countryLocations: [],
        error: true,
        message: 'Error buscando ubicaciones del pais'
      }
    case LOADING:
      return {
        ...state,
        loading: true
      }
    case CLEAR_REQUEST_STATUS:
      return {
        ...state,
        success: false,
        error: false,
        message: null
      }
    default:
      return state
  }
}
