import {
  LOCATIONS_FETCH,
  LOCATIONS_FETCH_SUCCESS,
  LOCATIONS_FETCH_ERROR,
  STATISTICS_FETCH,
  STATISTICS_FETCH_SUCCESS,
  STATISTICS_FETCH_ERROR,
  LOCATIONS_CREATE,
  LOCATIONS_UPDATE,
  LOCATIONS_CREATE_ERROR,
  LOCATIONS_UPDATE_ERROR,
  LOCATIONS_CREATE_SUCCESS,
  LOCATIONS_UPDATE_SUCCESS,
  KIOSK_BY_LOCATION_FETCH,
  KIOSK_BY_LOCATION_SUCCESS,
  KIOSK_BY_LOCATION_ERROR,
  FETCH_COUNTRY_LOCATIONS,
  FETCH_COUNTRY_LOCATIONS_SUCCESS,
  FETCH_COUNTRY_LOCATIONS_ERROR
} from '../constants/ActionTypes'

export const doFetchLocations = payload => ({ type: LOCATIONS_FETCH, payload })
export const doCreateLocation = payload => ({ type: LOCATIONS_CREATE, payload })
export const doUpdateLocation = payload => ({ type: LOCATIONS_UPDATE, payload })
export const doCreateLocationError = payload => ({ type: LOCATIONS_CREATE_ERROR, payload })
export const doUpdateLocationError = payload => ({ type: LOCATIONS_UPDATE_ERROR, payload })
export const doCreateLocationSuccess = payload => ({ type: LOCATIONS_CREATE_SUCCESS, payload })
export const doUpdateLocationSuccess = payload => ({ type: LOCATIONS_UPDATE_SUCCESS, payload })
export const doFetchLocationsSuccess = payload => ({ type: LOCATIONS_FETCH_SUCCESS, payload })
export const doFetchLocationsError = payload => ({ type: LOCATIONS_FETCH_ERROR, payload })
export const doFetchStatistics = payload => ({ type: STATISTICS_FETCH, payload })
export const doFetchStatisticsSuccess = payload => ({ type: STATISTICS_FETCH_SUCCESS, payload })
export const doFetchStatisticsError = payload => ({ type: STATISTICS_FETCH_ERROR, payload })

export const doFetchKioksByLocation = payload => ({ type: KIOSK_BY_LOCATION_FETCH, payload })
export const doFetchKioksByLocationSuccess = payload => ({ type: KIOSK_BY_LOCATION_SUCCESS, payload })
export const doFetchKioksByLocationError = payload => ({ type: KIOSK_BY_LOCATION_ERROR, payload })

export const doFetchCountryLocations = payload => ({ type: FETCH_COUNTRY_LOCATIONS, payload })
export const doFetchCountryLocationsSuccess = payload => ({ type: FETCH_COUNTRY_LOCATIONS_SUCCESS, payload })
export const doFetchCountryLocationsError = payload => ({ type: FETCH_COUNTRY_LOCATIONS_ERROR, payload })