const hasOnlyOneCountry = (state) => state.company.countries_count === 1

const firstCountry = (state) => state.company.first_country

const company = (state) => state.company

const companyId = (state) => state.company.companyId

const companyName = (state) => state.company.name

export { hasOnlyOneCountry, firstCountry, company, companyId, companyName }
