import { call, put } from 'redux-saga/effects'
import {
  doFetchKioskTransactionsSuccess,
  doFetchKioskTransactionsError,
  doDeleteKioskSuccess,
  doDeleteKioskError,
  doFetchKioskDevicesError,
  doFetchKioskDevicesSuccess,
  doFetchKiosksInfoSuccess,
  doFetchKiosksInfoError,
  doFetchKioskByIdError,
  doFetchKioskByIdSuccess
} from '../actions/kiosk'
import {
  fetchKioskTransactions,
  deleteKiosk,
  fetchKioskDevices,
  fetchKiosksInfo,
  fetchKioskById
} from '../api/kiosk'
import { doLoading } from '../actions/loading'

function * handleFetchKioskTransactions (action) {
  try {
    yield put(doLoading())
    const result = yield call(fetchKioskTransactions, action.payload.data)
    if (Array.isArray(result)) {
      yield put(doFetchKioskTransactionsSuccess(result))
    } else {
      yield put(doFetchKioskTransactionsError())
    }
  } catch (error) {
    yield put(doFetchKioskTransactionsError())
  }
}

function * handleFetchKioskDevices (action) {
  try {
    const result = yield call(fetchKioskDevices, action.payload.data)
    if (result.success) {
      yield put(doFetchKioskDevicesSuccess(result))
    } else {
      yield put(doFetchKioskDevicesError())
    }
  } catch (error) {
    yield put(doFetchKioskDevicesError())
  }
}

function * handleDeleteKiosk (action) {
  try {
    yield put(doLoading())
    const result = yield call(deleteKiosk, action.payload.data)
    if (result !== 204) {
      yield put(doDeleteKioskError())
    } else {
      yield put(doDeleteKioskSuccess())
    }
  } catch (error) {
    yield put(doDeleteKioskError())
  }
}

function* handleFindKiosk(action) {
  try {
    yield put(doLoading())
    const result = yield call(fetchKioskById, action.payload.data)
    if (result) {
      yield put(doFetchKioskByIdSuccess(result))
    } else {
      yield put(doFetchKioskByIdError(result))
    }
  } catch (error) {
    yield put(doFetchKioskByIdError(error))
  }
}

function* handleFetchKiosksInfo(action) {
  try {
    yield put(doLoading())
    const result = yield call(fetchKiosksInfo, action.payload.data)
    if (result) {
      yield put(doFetchKiosksInfoSuccess(result))
    } else {
      yield put(doFetchKiosksInfoError(result))
    }
  } catch (error) {
    yield put(doFetchKiosksInfoError(error))
  }
}


export {
  handleFetchKioskTransactions,
  handleFetchKioskDevices,
  handleDeleteKiosk,
  handleFetchKiosksInfo,
  handleFindKiosk
}
