export const formatsConfigStyles = {
  container: {
    backgroundColor: '#fff !important',
    margin: '15px'
  },
  inputConfig: {
    width: '15%'
  },
  selectConfig: {
    width: '100%',
    height: '55px',
    backgroundColor: '#fff !important'
  },
  btnLink: {
    color: '#efa91b',
    fontWeight: 400
  },
  btnNew: {
    padding: '5px 10px',
    // borderColor: '#ffffff',
    // color: '#ffffff',
    textTransform: 'none'
  }
}
