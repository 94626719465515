import { sidebarWidth } from '../../components/sidebar/Sidebar'
export const newFormatStyles = {
  content: {
    padding: '90px 20px 90px 20px',
    marginLeft: sidebarWidth
  },
  column: {
    flex: '100%',
    maxWidth: '100%',
    padding: '0 0 0 280px'
  },
  chooseModule: {
    width: '500px'
  },
  labelInput: {
    color: '#fff'
  },
  saveButton: {
    background: '#efa817',
    color: '#fff',
    '&:hover': {
      background: '#efa817'
    },
    boxShadow: 'none',
    position: 'absolute',
    right: '10px'
  },
  backButton: {
    color: '#EFA817',
    position: 'absolute',
    right: '130px'
  },
  textConfig: {
    fontSize: '20px',
    paddingTop: '15px',
    margin: '0 20px'
  },
  subtitleConfig: {
    color: '#9da0af',
    fontSize: '14px',
    margin: '0 20px'
  },
  caption: {
    margin: '0px 80px'
  },
  header: {
    display: 'flex',
    flexDirection: 'row'
  },
  btnLink: {
    color: '#efa91b',
    fontWeight: 400
  },
  paper: {
    padding: '15px'
  },
  componentsList: {
    overflow: 'auto',
    maxHeight: '75%',
    position: 'fixed',
    paddingLeft: '20px',
    paddingRight: '20px'
  },
  format: {
    padding: '35px 25px',
    width: '50%'
  },
  emptyFormat: {
    padding: '35px 25px',
    minHeight: '350px'
  },
  componentVerticalSpacing: {
    margin: '25px 0px'
  },
  formatVerticalSpacing: {
    margin: '5px 0px'
  },
  separator: {
    margin: '20px -15px'
  },
  selectConfig: {
    width: '100%',
    height: '55px',
    backgroundColor: '#fff !important'
  },
  checkBox: {
    color: '#efa817',
    '&$checked': {
      color: '#efa817'
    }
  },
  checked: {},
  formatFontRegular: {
    fontFamily: 'corporatemonocondensed'
  },
  formatFontBold: {
    fontFamily: 'corporatecondensedbold'
  },
  qrFont: {
    fontFamily: 'IDAutomationHC39MCode39Barcode'
  },
  formatInput: {
    margin: '1px',
    minHeight: '12px'
  },
  formatItem: {
    margin: '1px',
    minHeight: '12px'
  },
  dragStyle: {
    width: '18px'
  },
  sizeSelect: {
    border: 'none',
    alignSelf: 'center',
    position: 'relative',
    bottom: 6
  },
  footbar: {
    backgroundColor: '#fff',
    boxShadow: '2px 2px 10px 3px #ccc',
    position: 'fixed',
    bottom: 0
  },
  listItem: {
    padding: 0
  },
  nested: {
    paddingLeft: 20
  },
  nestedKiosk: {
    paddingLeft: 40
  },
  formatFlexContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}
