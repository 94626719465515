import {
  GET_ALL_VERSIONS,
  GET_ALL_VERSIONS_SUCCESS,
  GET_ALL_VERSIONS_ERROR,
  SEND_FORCED_UPDATE,
  SEND_FORCED_UPDATE_SUCCESS,
  SEND_FORCED_UPDATE_ERROR
} from '../constants/ActionTypes'

export const doGetAllVersions = (payload) => ({
  type: GET_ALL_VERSIONS,
  payload
})
export const doGetAllVersionsSuccess = (payload) => ({
  type: GET_ALL_VERSIONS_SUCCESS,
  payload
})
export const doGetAllVersionsError = (payload) => ({
  type: GET_ALL_VERSIONS_ERROR,
  payload
})
export const doSendForcedUpdate = (payload) => ({
  type: SEND_FORCED_UPDATE,
  payload
})
export const doSendForcedUpdateSuccess = (payload) => ({
  type: SEND_FORCED_UPDATE_SUCCESS,
  payload
})
export const doSendForcedUpdateError = (payload) => ({
  type: SEND_FORCED_UPDATE_ERROR,
  payload
})
