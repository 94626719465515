const getSuccess = state => state.kiosk.success || state.setting.success
const getError = state => state.kiosk.error || state.setting.error
const getMessage = state => {
  return state.kiosk.message
    ? state.kiosk.message : state.setting.message
      ? state.setting.message : null
}

export {
  getError,
  getMessage,
  getSuccess
}
