export const transactionStyles = {
  content: {
    marginRight: '20px',
    width: '100%'
  },
  tableContainer: {
    backgroundColor: '#fff'
  },
  tableTransactions: {
    minWidth: 50
    // overflowX: 'auto',
    // width: '80%'
  },
  tableTransactionsContainer: {
    overflowX: 'auto'
  }
}
