/* eslint-disable camelcase */
// Dependences
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'

// Custom components
import Header from './../../components/header/Header'
import Sidebar from './../../components/sidebar/Sidebar'
import KioskByLocationRow from '../../components/KioskByLocationRow/KioskByLocationRow'
import { sortBy } from 'lodash'
import moment from 'moment'

import { getLoginToken } from '../../selectors/user'
import { getSuccess, getError, getMessage } from '../../selectors/locations'
import {
  doFetchStatistics,
  doFetchKioksByLocation
} from '../../actions/location'

// UI Components
import {
  withStyles,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TablePagination,
  Button
} from '@material-ui/core'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { Line } from 'react-chartjs-2'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import RoomTwoTone from '@material-ui/icons/RoomTwoTone'

// Styles and assets
import { kioskByLocationStyles } from './kioskbylocation.styles'
import './kioskbylocation.sass'

// Utils
import { companyId } from '../../selectors/company'
import FilterRolePermissions from '../../utils/FilterRolePermissions'

const SORTS = {
  NONE: (list) => sortBy(list, 'name'),
  STATUS: (list) => sortBy(list, 'status'),
  STATUS_DATE: (list) => sortBy(list, 'status'),
  AREA: (list) => sortBy(list, 'area_name')
}

const date_sort_asc = function (date1, date2) {
  if (moment(date1) > moment(date2)) return 1
  if (moment(date1) < moment(date2)) return -1
  return 0
}

const WEEK_FILTER = 7
const MONTH_FILTER = 30
const HALF_YEAR_FILTER = 180

class KioskByLocation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rowsPerPage: 10,
      currentPage: 0,
      order: 'asc',
      orderBy: 'NONE',
      daysFilter: WEEK_FILTER,
      selectedKiosk: null
    }
  }

  fetchStatistics = (daysFilter) => {
    const { location } = this.props.location.state.data
    this.props.doFetchStatistics({
      data: {
        locationId: location.id,
        days: daysFilter,
        user: this.props.user,
        companyId: this.props.companyId
      }
    })
  }

  dataFrom = (statistics) => {
    const inServiceDates = statistics.in_service.map((data) => data.created_at)
    const outOfServiceDates = statistics.out_of_service.map(
      (data) => data.created_at
    )
    const warningDates = statistics.warning.map((data) => data.created_at)
    const joinDates = inServiceDates
      .concat(outOfServiceDates)
      .concat(warningDates)
    const labels = [...new Set(joinDates)].sort(date_sort_asc)
    // console.log(statistics.in_service.map(data => { return { x: data.created_at, y: data.count } }))
    let data = {
      labels: labels,
      datasets: [
        {
          label: 'En Servicio',
          fill: false,
          lineTension: 0,
          backgroundColor: 'rgb(28,171,38,0.4)',
          borderColor: 'rgb(28,171,38)',
          borderCapStyle: 'butt',
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgb(28,171,38)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgb(28,171,38)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: statistics.in_service.map((data) => {
            return { x: data.created_at, y: data.count }
          })
        },
        {
          label: 'Sin Servicio',
          fill: false,
          lineTension: 0,
          backgroundColor: 'rgb(231,36,66,0.4)',
          borderColor: 'rgb(231,36,66)',
          borderCapStyle: 'butt',
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgb(231,36,66)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgb(231,36,66)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: statistics.out_of_service.map((data) => {
            return { x: data.created_at, y: data.count }
          })
        },
        {
          label: 'Advertencia',
          fill: false,
          lineTension: 0,
          backgroundColor: 'rgb(239,168,23,0.4)',
          borderColor: 'rgb(239,168,23)',
          borderCapStyle: 'butt',
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgb(239,168,23)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgb(239,168,23)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: statistics.warning.map((data) => {
            return { x: data.created_at, y: data.count }
          })
        }
      ]
    }
    const unit =
      this.state.daysFilter === WEEK_FILTER
        ? 'day'
        : this.state.daysFilter === MONTH_FILTER
        ? 'week'
        : this.state.daysFilter === HALF_YEAR_FILTER
        ? 'month'
        : 'month'
    const options = {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              userCallback: function (label) {
                if (Math.floor(label) === label) {
                  return label
                }
              }
            }
          }
        ],
        xAxes: [
          {
            type: 'time',
            time: {
              unit: unit
            }
          }
        ]
      }
    }
    return { data: data, options: options }
  }

  onChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value })
  }

  onChangePage = (event, page) => {
    this.setState({ currentPage: page })
  }

  onChangeFilter = (event) => {
    const { value } = event.target
    this.setState({
      daysFilter: value
    })
    this.fetchStatistics(value)
  }

  componentDidMount() {
    this.fetchStatistics(WEEK_FILTER)
    this.fetchKiosk()
  }

  fetchKiosk() {
    const { location } = this.props.location.state.data
    this.props.doFetchKioksByLocation({
      data: {
        locationId: location.id,
        user: this.props.user,
        companyId: this.props.companyId
      }
    })
  }

  prepareKiosks() {
    try {
      const location = this.props.kioskByLocation
      var kiosks = []
      location.areas.forEach((area) => {
        area.kiosks.forEach((kiosk) => {
          kiosk.area_name = area.name
          kiosks.push(kiosk)
        })
      })
      return kiosks
    } catch (error) {
      return []
    }
  }

  handleRequestSort = (property) => () => {
    const orderBy = property
    let order = 'desc'
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }
    this.setState({ order, orderBy })
  }

  handleLocationSettings = () => {
    const { country, location } = this.props.location.state.data
    this.props.history.push('/settings', {
      country: country,
      aLocation: location
    })
  }

  onShowDetail = (kiosk, userRole) => {
    if (FilterRolePermissions('kiosk', 'see', userRole)) {
      const { country, city, location } = this.props.location.state.data
      this.props.history.push('/kiosk-detail', {
        data: { location: location, city: city, country: country, kiosk: kiosk }
      })
    }
  }

  render() {
    const { statistics, userRole } = this.props
    const { order, orderBy } = this.state
    const { country, city, location } = this.props.location.state.data
    const currentPageIndex = this.state.currentPage * this.state.rowsPerPage
    const pageEndIndex = currentPageIndex + this.state.rowsPerPage
    const sortedKiosks = SORTS[orderBy](this.prepareKiosks())
    const reversedKiosks =
      order === 'desc' ? sortedKiosks.reverse() : sortedKiosks
    const listItems = reversedKiosks
      .slice(currentPageIndex, pageEndIndex)
      .map((kiosk) => {
        return (
          <KioskByLocationRow
            key={kiosk.id}
            kiosk={kiosk}
            onDetail={kiosk => this.onShowDetail(kiosk, userRole)}
          />
        )
      })

    const links = [
      {
        title: 'Inicio',
        onClick: () => {
          this.props.history.push('/dashboard')
        }
      },
      {
        title: country.name,
        onClick: () => {
          this.props.history.push('/country-status', { country: country })
        }
      },
      {
        title: city.name,
        onClick: () => {
          this.props.history.push('/city-status', {
            data: { city: city, country: country }
          })
        }
      }
    ]
    return (
      <div className="page-kiosk">
        <Header links={links} currentPage={location.name} />
        <Sidebar level={1} country={country} />
        <div className={this.props.classes.content}>
          <div className="page_header_container">
            <div className="page_header_container__left_content">
              <RoomTwoTone className={this.props.classes.locationIcon} />
              <div>
                <Typography className={this.props.classes.mediumFont}>
                  {location.name}
                </Typography>
                <Typography>{city.name}</Typography>
              </div>
            </div>
            {(FilterRolePermissions('settings', 'see', userRole)) && (
              <Button
                variant="contained"
                className="config__button"
                onClick={this.handleLocationSettings}
              >
                Configuraciones
              </Button>
            )}
          </div>
          <div className={this.props.classes.kioskInfo}>
            <Grid container>
              <Grid item xs={12}>
                <Paper className={this.props.classes.paper}>
                  <div className="charts-header">
                    <Typography className={this.props.classes.subtitleCard}>
                      Resumen estado de kioscos
                    </Typography>
                    <FormControl
                      variant="outlined"
                      className={this.props.classes.chooseModule}
                    >
                      <InputLabel htmlFor="charts-header__days-select"></InputLabel>
                      <Select
                        input={<OutlinedInput />}
                        inputProps={{ id: 'charts-header__days-select' }}
                        value={this.state.daysFilter}
                        onChange={this.onChangeFilter}
                      >
                        <MenuItem value={WEEK_FILTER}>Hace 7 dias</MenuItem>
                        <MenuItem value={MONTH_FILTER}>Hace 1 mes</MenuItem>
                        <MenuItem value={HALF_YEAR_FILTER}>
                          Hace 6 meses
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  {statistics && (
                    <Line
                      data={this.dataFrom(statistics).data}
                      options={this.dataFrom(statistics).options}
                    />
                  )}
                </Paper>
              </Grid>
            </Grid>
            <Grid
              className={this.props.classes.kioskGrid}
              container
              spacing={10}
            >
              <Grid item xs={12}>
                <Paper className={this.props.classes.paper}>
                  <Typography className={this.props.classes.tableHeader}>
                    Kioscos {location.name}
                  </Typography>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Nombre del kiosco</TableCell>
                        <TableCell>
                          <TableSortLabel
                            active={orderBy === 'AREA'}
                            direction={order}
                            onClick={this.handleRequestSort('AREA')}
                          >
                            Área
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel
                            active={orderBy === 'STATUS'}
                            direction={order}
                            onClick={this.handleRequestSort('STATUS')}
                          >
                            Estado
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel
                            active={orderBy === 'STATUS_DATE'}
                            direction={order}
                            onClick={this.handleRequestSort('STATUS_DATE')}
                          >
                            Consulta de estado
                          </TableSortLabel>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {<TableBody>{listItems}</TableBody>}
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={(this.prepareKiosks() && this.prepareKiosks().length) || 0}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.currentPage}
                    onChangePage={this.onChangePage}
                    onChangeRowsPerPage={this.onChangeRowsPerPage}
                    labelRowsPerPage="Filas por página"
                    backIconButtonProps={{
                      'aria-label': 'Anterior'
                    }}
                    nextIconButtonProps={{
                      'aria-label': 'Siguiente'
                    }}
                  />
                </Paper>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: getLoginToken(state),
    userRole: state.user.role,
    statistics: state.location.statistics,
    kioskByLocation: state.location.kioskByLocation,
    success: getSuccess(state),
    companyId: companyId(state),
    error: getError(state),
    message: getMessage(state)
  }
}

const mapDispatchToProps = {
  doFetchStatistics,
  doFetchKioksByLocation
}

export default withStyles(kioskByLocationStyles)(
  connect(mapStateToProps, mapDispatchToProps)(KioskByLocation)
)
