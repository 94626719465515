import {
  COUNTRIES_COUNT_FETCH,
  COUNTRIES_COUNT_FETCH_SUCCESS,
  COUNTRIES_COUNT_FETCH_ERROR,
  COMPANY_FETCH,
  COMPANY_FETCH_SUCCESS,
  COMPANY_FETCH_ERROR,
  COMPANY_UPDATE,
  COMPANY_UPDATE_SUCCESS,
  COMPANY_UPDATE_ERROR
} from '../constants/ActionTypes'

export const doFetchCountriesCount = (payload) => ({
  type: COUNTRIES_COUNT_FETCH,
  payload
})
export const doFetchCountriesCountSuccess = (payload) => ({
  type: COUNTRIES_COUNT_FETCH_SUCCESS,
  payload
})
export const doFetchCountriesCountError = (payload) => ({
  type: COUNTRIES_COUNT_FETCH_ERROR,
  payload
})
export const doFetchCompany = (payload) => ({ type: COMPANY_FETCH, payload })
export const doFetchCompanySuccess = (payload) => ({
  type: COMPANY_FETCH_SUCCESS,
  payload
})
export const doFetchCompanyError = (payload) => ({
  type: COMPANY_FETCH_ERROR,
  payload
})
export const doFetchCompanyRole = (payload) => ({
  type: 'FETCH_COMPANY_ROLE',
  payload
})
export const doFetchCompanyRoleSucces = (payload) => ({
  type: 'FETCH_COMPANY_ROLE_SUCCES',
  payload
})
export const doFetchCompanyRoleError= (payload) => ({
  type: 'FETCH_COMPANY_ROLE_ERROR',
  payload
})
export const doUpdateCompany = (payload) => ({ type: COMPANY_UPDATE, payload })
export const doUpdateCompanySuccess = (payload) => ({
  type: COMPANY_UPDATE_SUCCESS,
  payload
})
export const doUpdateCompanyError = (payload) => ({
  type: COMPANY_UPDATE_ERROR,
  payload
})
