import { call, put } from 'redux-saga/effects'
import {
  doChangeBannersSuccess,
  doFetchBannersSuccess,
  doChangeBannersError,
  doFetchBannersError,
  doFetchBanners,
  doDeleteBannerSuccess,
  doDeleteBannerError
} from '../actions/banner'
import {
  fetchBanners,
  createBanners,
  updateBanners,
  deleteBanners,
  associateScopes
} from '../api/banner'

function* handleFetchBanners(action) {
  try {
    const result = yield call(fetchBanners, action.payload.data)
    if (result) {
      yield put(doFetchBannersSuccess(result))
    } else {
      yield put(doFetchBannersError())
    }
  } catch (error) {
    yield put(doFetchBannersError(error))
  }
}

function* handleDeleteBanner(action) {
  try {
    const result = yield call(deleteBanners, action.payload.data)
    if (result) {
      yield put(doDeleteBannerSuccess(result))
      yield put(
        doFetchBanners({
          data: {
            params: {
              company_id: action.payload.data.company_id,
              country_id: action.payload.data.country_id
            },
            user: action.payload.data.user
          }
        })
      )
    }
  } catch (error) {
    yield put(doDeleteBannerError(error))
  }
}

function* handleChangeBanners(action) {
  try {
    let result 
    if (action.payload.data.params.banner_id) result = yield call(updateBanners, action.payload.data)
    else result = yield call(createBanners, action.payload.data)
    if (result) {
      action.payload.data.id = result.data.id
      const scopes = yield call(associateScopes, action.payload.data)
      if (scopes) {
        yield put(doChangeBannersSuccess(result))
        yield put(
          doFetchBanners({
            data: {
              params: {
                company_id: action.payload.data.company_id,
                country_id: action.payload.data.params.country_id
              },
              user: action.payload.data.user
            }
          })
        )
      }
    } else {
      yield put(doChangeBannersError())
    }
  } catch (error) {
    yield put(doChangeBannersError(error))
  }
}

export {
  handleFetchBanners,
  handleChangeBanners,
  handleDeleteBanner
}
