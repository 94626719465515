const getSuccess = state => state.configuration.success
const getError = state => state.configuration.error
const getMessage = state => {
  return state.configuration.message
    ? state.configuration.message : null
}

export {
  getSuccess,
  getError,
  getMessage
}
