import React from 'react'

import MySnackbarContent from '../../components/MySnackbarContent/MySnackbarContent'
import Snackbar from '@material-ui/core/Snackbar'

const MySnackbar = (props) => {
  const { open, onClose, variant, message } = props
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      open={open}
    >
      <MySnackbarContent
        variant={variant}
        message={message}
        onClose={onClose}
      />
    </Snackbar>
  )
}

export default MySnackbar
