// Dependences
import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import PrivateRoute from './components/routing/PrivateRoute'

// Views
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary'
import ChooseCompany from './containers/ChooseCompany/ChooseCompany'
import Dashboard from './containers/Dashboard/Dashboard'
import Login from './containers/Login/Login'
import ResetPassword from './containers/ResetPassword/ResetPassword'
import UpdatePassword from './containers/UpdatePassword/UpdatePassword'
import CreatePassword from './containers/CreatePassword/CreatePassword'
import NewUser from './containers/NewUser/NewUser'
import NewFormat from './containers/NewFormat/NewFormat'
import CountryStatus from './containers/CountryStatus/CountryStatus'
import Settings from './containers/Settings/Settings'
import Configurations from './containers/Configurations/Configurations'
import CityStatus from './containers/CityStatus/CityStatus'
import Users from './containers/Users/Users'
import KioskByLocation from './containers/KioskByLocation/KioskByLocation'
import KioskList from './containers/KioskList/KioskList'
import CitiesStatus from './containers/CitiesStatus/CitiesStatus'
import KiosksStatus from './containers/KiosksStatus/KiosksStatus'
import KioskDetail from './containers/KioskDetail/KioskDetail'
import RolePermission from './containers/RolesPermissions/rolePermission'
import NewRolePermission from './containers/NewRolePermission/NewRolePermission'
import PageNotFound from './components/PageNotFound/PageNotFound'
import UserProfile from './containers/UserProfile/UserProfile'

const Routes = () => (
  <Router>
    <div>
      <ErrorBoundary>
        <Switch>
          <PrivateRoute path="/" component={Dashboard} exact />
          <Route path="/login" component={Login} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route path="/update-password" component={UpdatePassword} />
          <Route path="/create-password" component={CreatePassword} />
          <PrivateRoute path="/choose-company" component={ChooseCompany} />
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <PrivateRoute resource="setting" path="/settings" component={Settings} />
          <PrivateRoute resource="setting" path="/configurations" component={Configurations} />
          <PrivateRoute resource="location" path="/country-status" component={CountryStatus} />
          <PrivateRoute resource="location" path="/city-status" component={CityStatus} />
          <PrivateRoute resource="location" path="/cities-status" component={CitiesStatus} />
          <PrivateRoute resource="kiosk" path="/kiosks-status" component={KiosksStatus} />
          <PrivateRoute resource="kiosk" path="/kiosk-list" component={KioskList} />
          <PrivateRoute resource="user" exact path="/users" component={Users} />
          <PrivateRoute resource="user" exact path="/users/new-user" component={NewUser} />
          <PrivateRoute resource="user" exact path="/users/udate-user" component={NewUser} />
          <PrivateRoute resource="kiosk" path="/kiosk-by-location" component={KioskByLocation} />
          <PrivateRoute resource="kiosk" path="/kiosk-detail" component={KioskDetail} />
          <PrivateRoute resource="setting" exact path="/formats/new-format" component={NewFormat} />
          <PrivateRoute exact path="/profile" component={UserProfile} />
          <PrivateRoute
            exact
            path="/settings-by-country/:id"
            component={Settings}
          />
          <PrivateRoute
            exact
            resource="setting"
            path="/roles-permissions"
            component={RolePermission}
          />
          <PrivateRoute
            exact
            resource="setting"
            path="/roles-permissions/new-role"
            component={NewRolePermission}
          />
          <PrivateRoute
            exact
            resource="setting"
            path="/roles-permissions/edit-role/:id"
            component={NewRolePermission}
          />
          <Route component={PageNotFound} />
        </Switch>
      </ErrorBoundary>
    </div>
  </Router>
)

export default Routes
