import {
  COUNTRIES_COUNT_FETCH,
  COUNTRIES_COUNT_FETCH_SUCCESS,
  USER_LOGGED_IN,
  COMPANY_FETCH,
  COMPANY_FETCH_ERROR,
  COMPANY_FETCH_SUCCESS,
  COUNTRIES_COUNT_FETCH_ERROR,
  COMPANY_UPDATE,
  COMPANY_UPDATE_SUCCESS,
  COMPANY_UPDATE_ERROR,
  LOADING,
  CLEAR_REQUEST_STATUS
} from '../constants/ActionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case USER_LOGGED_IN:
      if (action.user.id === undefined) {
        return {
          ...state,
          countries_count: null,
          loading: false
        }
      } else {
        return state
      }
    case COUNTRIES_COUNT_FETCH:
      return { ...state, ...action.payload }
    case COUNTRIES_COUNT_FETCH_SUCCESS:
      return {
        ...state,
        countries_count: action.payload.count,
        first_country: action.payload.first_country,
        loading: false
      }
    case COUNTRIES_COUNT_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: 'Error al obtener conteo de países'
      }
    case COMPANY_FETCH:
      return { ...state, ...action.payload }
    case COMPANY_FETCH_SUCCESS:
      return {
        ...state,
        id: action.payload.company.id,
        name: action.payload.company.name,
        image_url: action.payload.company.image_url,
        daily_update_time: action.payload.company.daily_update_time,
        loading: false
      }
    case COMPANY_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: 'Error al obtener compañía'
      }
    case COMPANY_UPDATE:
      return { ...state, ...action.payload }
    case COMPANY_UPDATE_SUCCESS:
      return {
        ...state,
        error: false,
        message: 'Se ha actualizado correctamente',
        loading: false,
        success: true,
        daily_update_time: action.payload.selectedHour
      }

    case COMPANY_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: 'Error al actualizar la compañía'
      }
    case LOADING:
      return {
        ...state,
        loading: true
      }
    case CLEAR_REQUEST_STATUS:
      return {
        ...state,
        success: false,
        error: false,
        message: null
      }
    default:
      return state
  }
}
