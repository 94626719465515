import {
  USER_LOGGED_IN,
  LOGIN_FETCH,
  USERS_FETCH,
  END_REQUEST,
  NEW_USER_FETCH,
  CREATE_USER,
  INACTIVE_USER,
  LOGIN_ERROR,
  USERS_FETCH_SUCCESS,
  USERS_FETCH_ERROR,
  NEW_USER_FETCH_SUCCESS,
  NEW_USER_FETCH_ERROR,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  INACTIVE_USER_SUCCESS,
  INACTIVE_USER_ERROR,
  UPDATE_USER_PASSWORD,
  UPDATE_USER_PASSWORD_SUCCESS,
  UPDATE_USER_PASSWORD_ERROR,
  CREATE_USER_PASSWORD,
  RESET_USER_PASSWORD,
  RESET_USER_PASSWORD_SUCCESS,
  RESET_USER_PASSWORD_ERROR,
  RESET_APP_STATE,
  CHECK_USER_PASSWORD,
  CHECK_USER_PASSWORD_SUCCESS,
  CHECK_USER_PASSWORD_ERROR,
  LOGIN_SUCCESS,
  USER_LOGIN,
  UPDATE_USER_DATA,
  UPDATE_USER_DATA_SUCCESS,
  UPDATE_USER_DATA_ERROR,
  PROFILE_UPDATE_PASSWORD,
  PROFILE_UPDATE_PASSWORD_SUCCESS,
  PROFILE_UPDATE_PASSWORD_ERROR,
  FETCH_EMPLOYEE,
  FETCH_EMPLOYEE_SUCCESS,
  FETCH_EMPLOYEE_ERROR,
  UPDATE_EMPLOYEE_SUCCESS,
  UPDATE_EMPLOYEE_ERROR,
  UPDATE_EMPLOYEE
} from '../constants/ActionTypes'

export const setUser = (user) => ({ type: USER_LOGGED_IN, user })
export const doFetchLogin = (payload) => ({ type: LOGIN_FETCH, payload })
export const doLoginUser = (payload) => ({ type: USER_LOGIN, payload })
export const doLoginSuccess = (payload) => ({ type: LOGIN_SUCCESS, payload })
export const doLoginError = (payload) => ({ type: LOGIN_ERROR, payload })
export const doFetchUsers = (payload) => ({ type: USERS_FETCH, payload })
export const doFetchUsersSuccess = (payload) => ({
  type: USERS_FETCH_SUCCESS,
  payload
})
export const doFetchUsersError = (payload) => ({
  type: USERS_FETCH_ERROR,
  payload
})
export const doEndRequest = (payload) => ({ type: END_REQUEST, payload })
export const doFetchNewUser = (payload) => ({ type: NEW_USER_FETCH, payload })
export const doFetchNewUserSuccess = (payload) => ({
  type: NEW_USER_FETCH_SUCCESS,
  payload
})
export const doFetchNewUserError = (payload) => ({
  type: NEW_USER_FETCH_ERROR,
  payload
})
export const doFetchEmployee = (payload) => ({ type: FETCH_EMPLOYEE, payload })
export const doFetchEmployeeSuccess = (payload) => ({
  type: FETCH_EMPLOYEE_SUCCESS,
  payload
})
export const doFetchEmployeeError = (payload) => ({
  type: FETCH_EMPLOYEE_ERROR,
  payload
})
export const doUpdateEmployee = (payload) => ({ type: UPDATE_EMPLOYEE, payload })
export const doUpdateEmployeeSuccess = (payload) => ({
  type: UPDATE_EMPLOYEE_SUCCESS,
  payload
})
export const doUpdateEmployeeError = (payload) => ({
  type: UPDATE_EMPLOYEE_ERROR,
  payload
})
export const doCreateUser = (payload) => ({ type: CREATE_USER, payload })
export const doCreateUserSuccess = (payload) => ({
  type: CREATE_USER_SUCCESS,
  payload
})
export const doCreateUserError = (payload) => ({
  type: CREATE_USER_ERROR,
  payload
})
export const doInactiveUser = (payload) => ({ type: INACTIVE_USER, payload })
export const doInactiveUserSuccess = (payload) => ({
  type: INACTIVE_USER_SUCCESS,
  payload
})
export const doInactiveUserError = (payload) => ({
  type: INACTIVE_USER_ERROR,
  payload
})
export const doResetUserPassword = (payload) => ({
  type: RESET_USER_PASSWORD,
  payload
})
export const doResetUserPasswordSuccess = (payload) => ({
  type: RESET_USER_PASSWORD_SUCCESS,
  payload
})
export const doResetUserPasswordError = (payload) => ({
  type: RESET_USER_PASSWORD_ERROR,
  payload
})
export const doUpdateUserPassword = (payload) => ({
  type: UPDATE_USER_PASSWORD,
  payload
})
export const doUpdateUserPasswordSuccess = (payload) => ({
  type: UPDATE_USER_PASSWORD_SUCCESS,
  payload
})
export const doUpdateUserPasswordError = (payload) => ({
  type: UPDATE_USER_PASSWORD_ERROR,
  payload
})
export const doCreateUserPassword = (payload) => ({
  type: CREATE_USER_PASSWORD,
  payload
})
export const doResetAppState = (payload) => ({ type: RESET_APP_STATE, payload })
export const doCheckUserPassword = (payload) => ({
  type: CHECK_USER_PASSWORD,
  payload
})
export const doCheckUserPasswordSuccess = (payload) => ({
  type: CHECK_USER_PASSWORD_SUCCESS,
  payload
})
export const doCheckUserPasswordError = (payload) => ({
  type: CHECK_USER_PASSWORD_ERROR,
  payload
})
export const doUpdateUserData = (payload) => ({
  type: UPDATE_USER_DATA,
  payload
})
export const doUpdateUserDataSuccess = (payload) => ({
  type: UPDATE_USER_DATA_SUCCESS,
  payload
})
export const doUpdateUserDataError = (payload) => ({
  type: UPDATE_USER_DATA_ERROR,
  payload
})
export const doProfileUpdatePassword = (payload) => ({
  type: PROFILE_UPDATE_PASSWORD,
  payload
})
export const doProfileUpdatePasswordSuccess = (payload) => ({
  type: PROFILE_UPDATE_PASSWORD_SUCCESS,
  payload
})
export const doProfileUpdatePasswordError = (payload) => ({
  type: PROFILE_UPDATE_PASSWORD_ERROR,
  payload
})
