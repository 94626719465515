import React from 'react'
import { withStyles } from '@material-ui/core'

// UI Components
import Typography from '@material-ui/core/Typography'
import FilterRolePermissions from '../../utils/FilterRolePermissions'

function SettingsByCountry(props) {
  const {
    classes,
    countryName,
    onShowBanners,
    onShowLanguage,
    onShowFormats,
    onShowCountrySettings,
    uniqPermission,
    userRole
  } = props
  const [bannerStyle, setBannerStyle] = React.useState(classes.notSelected)
  const [languageStyle, setLanguageStyle] = React.useState(classes.notSelected)
  const [formatStyle, setFormatStyle] = React.useState(classes.notSelected)
  const [countryStyle, setCountryStyle] = React.useState(classes.selected)

  React.useEffect(() => {
    if (!uniqPermission || uniqPermission === '') onShowCountrySettings()
  }, [])

  function bannerSettings(event) {
    if (event) {
      setBannerStyle(classes.selected)
      setLanguageStyle(classes.notSelected)
      setFormatStyle(classes.notSelected)
      setCountryStyle(classes.notSelected)
      onShowBanners()
    }
  }
  function languageSettings(event) {
    if (event) {
      setLanguageStyle(classes.selected)
      setBannerStyle(classes.notSelected)
      setFormatStyle(classes.notSelected)
      setCountryStyle(classes.notSelected)
      onShowLanguage()
    }
  }
  function formatSettings(event) {
    if (event) {
      setFormatStyle(classes.selected)
      setBannerStyle(classes.notSelected)
      setLanguageStyle(classes.notSelected)
      setCountryStyle(classes.notSelected)
      onShowFormats()
    }
  }

  function countrySettings(event) {
    if (event) {
      setCountryStyle(classes.selected)
      setFormatStyle(classes.notSelected)
      setBannerStyle(classes.notSelected)
      setLanguageStyle(classes.notSelected)
      onShowCountrySettings()
    }
  }

  React.useEffect(() => {
    switch (uniqPermission) {
      case 'isPromotionsOnly':
        bannerSettings(uniqPermission)
        break;
      case 'isPrintableFormatsOnly':
        formatSettings(uniqPermission)
        break;
      case 'isLocalesOnly':
        languageSettings(uniqPermission)
        break;
      default:
        break;
    }
  }, [uniqPermission])

  return (
    <div>
      <Typography variant="subtitle1" gutterBottom>
        { countryName.toUpperCase() }
      </Typography>
      <Typography variant="subtitle2" gutterBottom>
        Estas serán tus configuraciones por defecto, se aplicara a todos los
        kioscos del país
      </Typography>
      <div className={classes.container}>
        {(FilterRolePermissions('settings', 'see', userRole)) && (
          <Typography
            className={countryStyle}
            onClick={(event) => countrySettings(event)}
          >
            GENERALES
          </Typography>
        )}
        {(FilterRolePermissions('promotion', 'see', userRole)) && (
          <Typography
            className={bannerStyle}
            onClick={(event) => bannerSettings(event)}
          >
            BANNERS PROMOCIONALES
          </Typography>
        )}
        {(FilterRolePermissions('locale', 'see', userRole)) && (
          <Typography
            className={languageStyle}
            onClick={(event) => languageSettings(event)}
          >
            LENGUAJE
          </Typography>
        )}
        {(FilterRolePermissions('printable_format', 'see', userRole)) && (
          <Typography
            className={formatStyle}
            onClick={(event) => formatSettings(event)}
          >
            VOUCHERS
          </Typography>
        )}
      </div>
    </div>
  )
}

export const sidebarWidth = 260
const settingsSidebarStyles = {
  sidebar: {
    position: 'fixed',
    backgroundColor: '#fff',
    width: sidebarWidth
  },
  button: {
    width: sidebarWidth,
    margin: 'auto'
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '53%',
    margin: '1em 0'
  },
  selected: {
    color: '#000000',
    textTransform: 'uppercase',
    fontWeight: '600',
    fontSize: '0.8em',
    opacity: '1'
  },
  notSelected: {
    color: '#3F3F3F',
    fontWeight: '600',
    fontSize: '0.8em',
    textTransform: 'uppercase',
    opacity: '0.5',
    cursor: 'pointer'
  }

}

export default withStyles(settingsSidebarStyles)(SettingsByCountry)


