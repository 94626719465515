import { call, put } from 'redux-saga/effects'
import {
  doFetchLocationsSuccess,
  doFetchStatisticsSuccess,
  doFetchStatisticsError,
  doUpdateLocationError,
  doCreateLocationSuccess,
  doUpdateLocationSuccess,
  doFetchKioksByLocationSuccess,
  doFetchKioksByLocationError, doCreateLocationError, doFetchCountryLocationsSuccess, doFetchCountryLocationsError
} from '../actions/location'
import { fetchCities } from '../api/city'
import {
  fetchLocations,
  fetchStatistics,
  createLocation,
  updateLocation,
  kioskByLocation,
  fetchCountryLocations
} from '../api/location'
import { doLoading } from '../actions/loading'
import { doFetchCitiesSuccess } from '../actions/city'

function* handleFetchLocations(action) {
  try {
    yield put(doLoading())
    const result = yield call(fetchLocations, action.payload.data)
    if (Array.isArray(result)) {
      yield put(doFetchLocationsSuccess(result))
    } else {
      console.log(result)
      // TODO: Validate error
    }
  } catch (error) {
    console.log(error)
  }
}

function* handleFetchStatistics(action) {
  try {
    yield put(doLoading())
    const result = yield call(fetchStatistics, action.payload.data)
    if (result.status === 200) {
      yield put(doFetchStatisticsSuccess(result))
    } else {
      yield put(doFetchStatisticsError())
    }
  } catch (error) {
    yield put(doFetchStatisticsError(error))
  }
}

function* handleCreateLocation(data) {
  try {
    yield put(doLoading())
    const result = yield call(createLocation, data)
    if (result.status === 200) {
      yield put(doCreateLocationSuccess(result))
    }
  } catch (error) {
    yield put(doCreateLocationError(error))
  }
}

function* handleUpdateLocation(data) {
  try {
    yield put(doLoading())
    const result = yield call(updateLocation, data)
    if (result.status === 200) {
      const cityData = {
        countryId: data.payload.countryId,
        user: data.payload.user
      }
      const cities = yield call(fetchCities, cityData)
      yield put(doFetchCitiesSuccess(cities))
      yield put(doUpdateLocationSuccess(result))
    }
  } catch (error) {
    yield put(doUpdateLocationError(error))
  }
}

function* handleKioskByLocation(action) {
  try {
    yield put(doLoading())
    const result = yield call(kioskByLocation, action.payload.data)
    if (result.id) {
      yield put(doFetchKioksByLocationSuccess(result))
    }
  } catch (error) {
    yield put(doFetchKioksByLocationError(error))
  }
}

function* handleFetchCountryLocations(action) {
  try {
    const result = yield call(fetchCountryLocations, action.payload)
    if (result) {
      yield put(doFetchCountryLocationsSuccess(result))
    }
  } catch (error) {
    yield put(doFetchCountryLocationsError(error))
  }
}

export {
  handleFetchLocations,
  handleFetchStatistics,
  handleCreateLocation,
  handleUpdateLocation,
  handleKioskByLocation,
  handleFetchCountryLocations
}
