import { sidebarWidth } from './../../components/sidebar/Sidebar'
export const countrySummaryCardStyles = {
  content: {
    padding: '20px',
    marginLeft: sidebarWidth,
    width: '100%' - sidebarWidth
  },
  textInfo: {
    marginLeft: '10px'
  },
  warningIcon: {
    width: '18px',
    color: '#EFA817'
  },
  inServiceIcon: {
    width: '18px',
    color: '#51D855'
  },
  outOfServiceIcon: {
    width: '18px',
    color: '#FF5E59'
  }
}
