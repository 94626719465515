/* eslint-disable camelcase */
// Dependences
import React, { Component } from 'react'
import DragAndDrop from '../DragAndDrop/DragAndDrop'
import config from './../../config'
// UI components
import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

// Styles and assets
import { bannerCellStyles } from './bannercell.styles'

const twoMegaBytes = 1024 * 1024 * 2

class BannerCell extends Component {
  constructor (props) {
    super(props)
    this.state = {
      hover: false
    }
  }
  handleDrop = (files) => {
    this.validateAndPick(files)
  }

  validateAndPick = (files) => {
    const { onPickFile } = this.props
    if (files.length === 1 && this.isImage(files[0])) {
      const file = files[0]
      if (this.fitSize(file)) {
        let reader = new FileReader()
        reader.onload = function () {
          var img = new Image()
          img.onload = function () {
            onPickFile(file, img)
          }
          img.src = reader.result
        }
        reader.readAsDataURL(file)
      }
    }
  }

  handleDelete = (event) => {
    event.stopPropagation()
    const { banner } = this.props
    this.props.onDeleteFile(banner.id)
  }

  isImage = (file) => {
    return file.type === 'image/jpeg' || file.type === 'image/png'
  }

  fitSize = (file) => {
    return file.size <= twoMegaBytes
  }

  handlePickFile = (event) => {
    event.stopPropagation()
    event.preventDefault()
    this.validateAndPick(event.target.files)
  }

  onMouseEnterHandler = () => {
    this.setState({
      hover: true
    })
  }
  onMouseLeaveHandler = () => {
    this.setState({
      hover: false
    })
  }

  render () {
    const { banner, pickedFile, classes } = this.props
    var bannerToDelete = false
    if (banner && banner.toDelete !== null) {
      bannerToDelete = banner.toDelete
    }
    const imageUrl = banner ? `${config.config.API_URL}${banner.image_url}` : ''
    const inputOpenFileRef = React.createRef()
    const shouldShowDeleteButton = this.state.hover && banner && !bannerToDelete
    return (
      <div className={((!banner && !pickedFile) || bannerToDelete) && classes.containerBordered}
        onMouseEnter={this.onMouseEnterHandler}
        onMouseLeave={this.onMouseLeaveHandler}
        onClick={() => { inputOpenFileRef.current.click() }}>
        <DragAndDrop handleDrop={this.handleDrop}>
          <div className={classes.childrenContainer}>
            {pickedFile
              ? <img src={ pickedFile.image } alt='' className={classes.bannerImg}/>
              : banner && !bannerToDelete
                ? <img alt='' src={ imageUrl } className={classes.bannerImg}/>
                : <div className={classes.emptyBoxContainer}>
                  <Typography variant="caption" gutterBottom>
                    Arrastrar el archivo aqui o
                    haga <Typography className={classes.click_to_select}>click para seleccionar</Typography>
                  </Typography>
                </div>
            }
            <input id="myInput"
              type="file"
              ref={inputOpenFileRef}
              style={{ display: 'none' }}
              onChange={this.handlePickFile}/>
            {shouldShowDeleteButton &&
        <div className={classes.btnDelete} onClick={this.handleDelete}>
          Eliminar Imagen
        </div>
            }
          </div>
        </DragAndDrop>
      </div>
    )
  }
}

export default withStyles(bannerCellStyles)(BannerCell)
