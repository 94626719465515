import {
  LOCALES_FETCH,
  UPDATE_LOCALE,
  LOCALES_FETCH_SUCCESS,
  LOCALES_FETCH_ERROR,
  UPDATE_LOCALE_ERROR,
  UPDATE_LOCALE_SUCCESS,
  CLEAR_REQUEST_STATUS,
  LOADING
} from '../constants/ActionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case LOCALES_FETCH:
      return state
    case UPDATE_LOCALE:
      return state
    case LOCALES_FETCH_SUCCESS:
      return {
        ...state,
        locales: action.payload,
        loading: false
      }
    case LOCALES_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: 'Error al obtener textos'
      }
    case UPDATE_LOCALE_SUCCESS:
      return {
        ...state,
        locales: state.locales.map((module) => {
          if (module.id === action.payload.module.id) {
            return {
              ...module,
              locales: module.locales.map((locale) => {
                if (locale.id === action.payload.locale.id) {
                  return {
                    ...locale,
                    value: action.payload.newValue,
                    isEditing: false
                  }
                }
                return locale
              })
            }
          }
          return module
        }),
        loading: false,
        success: true,
        message: 'Texto actualizado exitosamente'
      }
    case UPDATE_LOCALE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: 'Error al intentar actualizar texto'
      }
    case CLEAR_REQUEST_STATUS:
      return {
        ...state,
        success: false,
        error: false,
        message: null
      }
    case LOADING:
      return {
        ...state,
        loading: true
      }
    default:
      return state
  }
}
