import { sidebarWidth } from './../../components/sidebar/Sidebar'

export const kioskDetailStyles = {
  content: {
    marginLeft: sidebarWidth,
    width: '100%' - sidebarWidth,
    paddingTop: '60px'
  },
  mainPaper: {
    padding: '10px',
    margin: '10px'
  },
  modal: {
    position: 'absolute',
    width: '30%',
    backgroundColor: '#fff',
    padding: '10px 10px 0px 10px',
    top: '30%',
    left: '35%'
  },
  kioskDetailContainer: {
    padding: '10px',
    margin: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  locationIcon: {
    color: '#EFA817',
    marginRight: '10px'
  },
  kioskImage: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: '5%',
    height: '30%'
  },
  selectConfig: {
    width: '100%',
    height: '55px',
    backgroundColor: '#fff !important'
  },
  btnLink: {
    color: '#efa91b',
    fontWeight: 400
  },
  kioskSettingsTitle: {
    margin: '10px 0px'
  },
  separator: {
    margin: '20px 0px'
  }
}
