import React from 'react'
import { List, ListSubheader, ListItem, ListItemText, Checkbox, Collapse, Typography, Divider } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'

class NewUserList extends React.Component {
  constructor () {
    super()
    this.state = {
      open: 0
    }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick = item => {
    this.setState({ open: item })
  }

  render () {
    return (
      <List
        component="nav"
        subheader={
          <ListSubheader component="div" className="new_user__title-list">
            <Typography variant="h6" gutterBottom className={this.props.classes.newUserTitleForm} >
              Asignar ciudad y teatros
            </Typography>
          </ListSubheader>}
        className={this.props.classes.root} >
        <ListItem >
          <Checkbox
            checked={this.props.checkedAll}
            onChange={this.props.handleChecked('all')}
            tabIndex={-1}
            classes={{
              root: this.props.classes.checkBox
            }}
            disableRipple
          />
          <ListItemText inset primary="Asignar todas las ciudades y teatros" />
        </ListItem>
        <Divider />
        {this.props.cities.map(city => (
          <div key={`${city.name}_${city.id}`}>
            <ListItem key={`${city.name}_${city.id}`} button onClick={() => { this.setState({ [city.id]: !this.state[city.id] }) }} >
              <Checkbox
                checked={city.checked}
                onChange={this.props.handleChecked('city', city.id)}
                tabIndex={-1}
                classes={{
                  root: this.props.classes.checkBox
                }}
                disableRipple
              />
              <ListItemText inset primary={city.name} />
              {this.state[city.id] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Divider />
            <Collapse component="li" key={city.id} in={this.state[city.id]} timeout="auto" unmountOnExit>
              <List component="div" className={this.props.classes.internalList} disablePadding>
                {city.locations.map(theater => (
                  <ListItem key={`${theater.name}_${theater.id}`} button className={this.props.classes.nested}>
                    <Checkbox
                      checked={theater.checked}
                      onChange={this.props.handleChecked('theater', city.id, theater.id)}
                      tabIndex={-1}
                      classes={{
                        root: this.props.classes.checkBox
                      }}
                      disableRipple
                    />
                    <ListItemText inset primary={theater.name} />
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </div>
        ))}
      </List>
    )
  }
}

export default NewUserList
