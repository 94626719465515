/* eslint-disable camelcase */
// Dependences
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { doFetchCities, doFetchCitiesAndLocations } from '../../actions/city'
import { getLoginToken } from '../../selectors/user'
import { companyId } from '../../selectors/company'
import {
  getSuccess,
  getMesageLocation,
  getErrorLocation
} from '../../selectors/locations.js'
import { doClearRequestStatus } from '../../actions/clearStatus'
import { doCreateLocation, doUpdateLocation } from '../../actions/location'
import { getError, getMessage } from '../../selectors/city'
import { IN_SERVICE, OUT_OF_SERVICE, WARNING } from '../../constants/Constants'
import { sortBy } from 'lodash'

// Utils
import { groupedKiosksCountByStatus, kioskType } from '../../utils/utils.js'

// Custom components
import Header from '../../components/header/Header'
import Sidebar from '../../components/sidebar/Sidebar'
import LinkedLocationRow from '../../components/LinkedLocationRow/LinkedLocationRow'
import CircularLoading from '../../components/Loading/CircularLoading'
import MySnackbar from '../../components/MySnackbar/MySnackbar'
import LocationDetailDialog from '../../components/LocationDetailDialog/LocationDetailDialog'

// UI Components
import { withStyles, FormControl, Select, MenuItem } from '@material-ui/core'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Typography from '@material-ui/core/Typography'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import Search from '@material-ui/icons/Search'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TablePagination from '@material-ui/core/TablePagination'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'

// Styles and assets
import './citiesstatus.sass'
import { citiesStatusStyles } from './citiesstatus.styles'
import FilterRolePermissions from '../../utils/FilterRolePermissions'

const SORTS = {
  NONE: (list) => sortBy(list, 'name'),
  LOCATIONS_COUNT: (list) => sortBy(list, 'locations'),
  TOTAL_COUNT: (list) => sortBy(list, 'total_kiosks'),
  IN_SERVICE_COUNT: (list) => sortBy(list, 'in_service'),
  OUT_OF_SERVICE_COUNT: (list) => sortBy(list, 'out_of_service'),
  WARNING_COUNT: (list) => sortBy(list, 'warning')
}

class CitiesStatus extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedCity: null,
      query: '',
      rowsPerPage: 10,
      currentPage: 0,
      order: 'asc',
      orderBy: 'NONE'
    }
  }

  onChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value })
  }

  onChangePage = (event, page) => {
    this.setState({ currentPage: page })
  }

  onChangeQuery = (event) => {
    this.setState({ query: event.target.value })
  }

  onListAllKiosks = () => {
    this.showKioskList()
  }

  onListInServiceKiosks = () => {
    this.showKioskList(IN_SERVICE)
  }

  onListOutOfServiceKiosks = () => {
    this.showKioskList(OUT_OF_SERVICE)
  }

  onListInWarningKiosks = () => {
    this.showKioskList(WARNING)
  }

  showKioskList = (status) => {
    const kiosks = this.kiosksByStatus(status)
    if (kiosks.length > 0) {
      const country = this.props.location.state.country
      this.props.history.push('/kiosk-list', {
        data: {
          country: country,
          kiosks: kiosks,
          searchCriteriaKey: 'city',
          status: status
        }
      })
    }
  }

  kiosksByStatus = (status) => {
    try {
      const { cities } = this.props.city
      var kiosks = []
      cities.forEach((city) => {
        city.locations.forEach((location) => {
          location.area_has_locations.forEach((area) => {
            area.active_kiosks.forEach((kiosk) => {
              kiosk.city_name = city.name
              kiosk.location_name = location.name
              kiosk.area_name = area.name
              kiosk.type = kioskType(kiosk.kiosk_type)
              if (!status) {
                kiosks.push(kiosk)
              } else if (kiosk.status === status) {
                kiosks.push(kiosk)
              }
            })
          })
        })
      })
      return kiosks
    } catch (error) {
      return []
    }
  }

  componentDidMount() {
    this.props.doClearRequestStatus()
    if (this.props.city.cities) {
      this.fetchCities()
    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.success && this.props.success !== prevProps.success) {
      setTimeout(() => {
        window.location.reload()
      }, 500)
    }
  }

  fetchCities = () => {
    const { user, companyId, location } = this.props
    this.props.doFetchCitiesAndLocations({
      data: {
        countryId: location.state.country.id,
        user: user,
        companyId: companyId
      }
    })
  }

  onShowLocationDetail = (location) => {
    const { country } = this.props.location.state
    this.props.history.push('/kiosk-by-location', {
      data: { location: location, city: this.selectedCity(), country: country }
    })
  }

  handleRequestSort = (property) => () => {
    const orderBy = property
    let order = 'desc'
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }
    this.setState({ order, orderBy })
  }

  handleChange = (event) => {
    this.setState({ selectedCity: event.target.value })
  }

  selectedCity = () => {
    if (!this.state.selectedCity) {
      return this.props.city.cities[0]
    } else {
      const selectedCity = this.props.city.cities.filter((city) => {
        return city.id === this.state.selectedCity
      })[0]
      return selectedCity
    }
  }

  handleCloseSnackBar = () => {
    this.props.doClearRequestStatus()
  }

  handleCreateLocation = (params) => {
    this.props.doClearRequestStatus()
    const { user, location } = this.props
    const data = {
      params: params,
      user: user,
      countryId: location.state.country.id
    }
    data.params.company_id = this.props.companyId
    this.props.doCreateLocation(data)
  }

  handleUpdateLocation = (params, location_id) => {
    this.props.doClearRequestStatus()
    const { user, location } = this.props
    const data = {
      params: params,
      location_id: location_id,
      user: user,
      countryId: location.state.country.id
    }
    data.params.company_id = this.props.companyId
    data.params.location_id = location_id
    this.props.doUpdateLocation(data)
  }

  render() {
    const { success, errorLocation, messageLocation, userRole } = this.props
    const openSnackbar = success || errorLocation
    const { order, orderBy } = this.state
    const country = this.props.location.state.country
    const { loading, cities } = this.props.city
    const formData = { code: undefined, name: undefined, address: undefined }
    let filteredLocations
    let listCities
    let listLocations
    if (cities) {
      listCities = cities.map((city) => {
        return (
          <MenuItem value={city.id} key={city.id}>
            {city.name}
          </MenuItem>
        )
      })
      filteredLocations = this.selectedCity().locations.filter((location) => {
        if (this.state.query.length > 0) {
          return location.name
            .toLowerCase()
            .includes(this.state.query.toLowerCase())
        }
        return true
      })
      const currentPageIndex = this.state.currentPage * this.state.rowsPerPage
      const pageEndIndex = currentPageIndex + this.state.rowsPerPage
      const sortedLocations = SORTS[orderBy](filteredLocations)
      const reversedLocations =
        order === 'desc' ? sortedLocations.reverse() : sortedLocations
      listLocations = reversedLocations
        .slice(currentPageIndex, pageEndIndex)
        .map((location) => {
          const count = groupedKiosksCountByStatus(location.kiosk_status_count)
          location.in_service = count.in_service
          location.out_of_service = count.out_of_service
          location.warning = count.warning
          return (
            <LinkedLocationRow
              userRole={userRole}
              cities={cities}
              key={location.id}
              cityToEdit={this.state.selectedCity || cities[0].id}
              location={location}
              updateLocation={(data, location_id) =>
                this.handleUpdateLocation(data, location_id)
              }
              onDetail={this.onShowLocationDetail}
            />
          )
        })
    }

    const links = [
      {
        title: 'Inicio',
        onClick: () => {
          this.props.history.goBack()
        }
      }
    ]
    return (
      <div className="page-status">
        <MySnackbar
          open={openSnackbar}
          variant={openSnackbar ? (success ? 'success' : 'error') : 'default'}
          onClose={this.handleCloseSnackBar}
          message={openSnackbar ? messageLocation : ''}
        />
        <Header links={links} currentPage={country.name} />
        <Sidebar level={1} country={country} />
        <div className={this.props.classes.content}>
          {loading ? (
            <CircularLoading isLoading={loading} />
          ) : (
            <div className="page_container">
              <div className="switch_city_container">
                <div>
                  <Typography
                    align="center"
                    className={this.props.classes.textCities}
                  >
                    Ciudad
                  </Typography>
                  <FormControl
                    variant="outlined"
                    className={this.props.classes.chooseCity}
                  >
                    <Select
                      input={
                        <OutlinedInput name="selectedCity" id="city-select" />
                      }
                      value={this.state.selectedCity || cities[0].id}
                      onChange={this.handleChange}
                    >
                      {listCities}
                    </Select>
                  </FormControl>
                </div>
                <div>
                  {(FilterRolePermissions('location', 'create', userRole)) && (
                    <LocationDetailDialog
                      cities={cities}
                      isedit={false}
                      data={formData}
                      createLocation={(data) => this.handleCreateLocation(data)}
                    ></LocationDetailDialog>
                  )}
                </div>
              </div>
              <div className="list-kiosks">
                <Input
                  className={this.props.classes.findField}
                  placeholder="Buscar por nombre de teatro"
                  value={this.state.query}
                  onChange={this.onChangeQuery}
                  disableUnderline={true}
                  startAdornment={
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  }
                />
                <Table padding="dense">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <TableSortLabel
                          active={orderBy === 'LOCATIONS_COUNT'}
                          direction={order}
                          onClick={this.handleRequestSort('LOCATIONS_COUNT')}
                        >
                          Ubicaciones
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={orderBy === 'TOTAL_COUNT'}
                          direction={order}
                          onClick={this.handleRequestSort('TOTAL_COUNT')}
                        >
                          Kioscos
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={orderBy === 'IN_SERVICE_COUNT'}
                          direction={order}
                          onClick={this.handleRequestSort('IN_SERVICE_COUNT')}
                        >
                          En servicio
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={orderBy === 'OUT_OF_SERVICE_COUNT'}
                          direction={order}
                          onClick={this.handleRequestSort(
                            'OUT_OF_SERVICE_COUNT'
                          )}
                        >
                          Sin servicio
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={orderBy === 'WARNING_COUNT'}
                          direction={order}
                          onClick={this.handleRequestSort('WARNING_COUNT')}
                        >
                          En advertencia
                        </TableSortLabel>
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{listLocations}</TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={(filteredLocations && filteredLocations.length) || 0}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.currentPage}
                  onChangePage={this.onChangePage}
                  onChangeRowsPerPage={this.onChangeRowsPerPage}
                  labelRowsPerPage="Filas por página"
                  backIconButtonProps={{
                    'aria-label': 'Anterior'
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'Siguiente'
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: getLoginToken(state),
    userRole: state.user.role,
    companyId: companyId(state),
    city: state.city,
    success: getSuccess(state),
    messageLocation: getMesageLocation(state),
    errorLocation: getErrorLocation(state),
    error: getError(state),
    message: getMessage(state)
  }
}

const mapDispatchToProps = {
  doFetchCities,
  doClearRequestStatus,
  doFetchCitiesAndLocations,
  doCreateLocation,
  doUpdateLocation
}

export default withStyles(citiesStatusStyles)(
  connect(mapStateToProps, mapDispatchToProps)(CitiesStatus)
)
