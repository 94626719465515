import ApiClientSingleton from '../api-client'

const kioskApiInstance = ApiClientSingleton.getKioskApiInstance()

const fetchCities = async (payload) => {
  const uri = `/api/v1/companies/${payload.companyId}/countries/${payload.countryId}/cities-detail`
  const headers = { Authorization: `Bearer ${payload.user}` }
  const { data } = await kioskApiInstance.get(uri, { headers })
  return data
}

const fetchCitiesByCountryId = async (payload) => {
  const uri = `/api/v1/companies/${payload.companyId}/countries/${payload.countryId}`
  const headers = { Authorization: `Bearer ${payload.user}` }
  const { data } = await kioskApiInstance.get(uri, { headers })
  return data
}

const fetchCitiesAndLocations = async (payload) => {
  const uri = `/api/v1/companies/${payload.companyId}/countries/${payload.countryId}/cities`
  const headers = { Authorization: `Bearer ${payload.user}` }
  const { data } = await kioskApiInstance.get(uri, { headers })
  return data
}

export { fetchCities, fetchCitiesByCountryId, fetchCitiesAndLocations }
