/* eslint-disable camelcase */
// Dependences
import React from 'react'

// UI components
import { withStyles, Link } from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

// Styles and assets
import { linkedLocationRowStyles } from './linkedlocationrow.styles'

import LocationDetailDialog from '../../components/LocationDetailDialog/LocationDetailDialog'
import FilterRolePermissions from '../../utils/FilterRolePermissions'

const LinkedLocationRow = props => {
  const { location, onDetail, cities, cityToEdit, userRole } = props
  const { in_service, out_of_service, warning, name, total_kiosks } = location

  const handleDetails = () => {
    onDetail(location)
  }

  const formData = { name: location.name, code: location.code, address: location.address }
  return (
    <TableRow>
      <TableCell onClick={handleDetails}><Link>{name}</Link></TableCell>
      <TableCell>{total_kiosks}</TableCell>
      <TableCell>{in_service}</TableCell>
      <TableCell>{out_of_service}</TableCell>
      <TableCell>{warning}</TableCell>
      {(FilterRolePermissions('location', 'update', userRole)) && (
        <TableCell>
          <LocationDetailDialog
            cities = { cities }
            isedit = { true }
            data = { formData }
            cityToEdit = { cityToEdit }
            updateLocation = { (params) => props.updateLocation(params, location.id) }
          ></LocationDetailDialog>
        </TableCell>
      )}
    </TableRow>
  )
}

export default withStyles(linkedLocationRowStyles)(LinkedLocationRow)
