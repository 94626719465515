import React, { Component } from 'react'
import Routes from '../../routes'
import { withRouter } from 'react-router-dom'
import './App.css'

class App extends Component {
  render () {
    return (
      <Routes/>
    )
  }
}

export default withRouter(App)
