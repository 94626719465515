import {
  USER_LOGGED_IN,
  LOGIN_ERROR,
  CLEAR_REQUEST_STATUS,
  NEW_USER_FETCH_SUCCESS,
  RESET_USER_PASSWORD,
  RESET_USER_PASSWORD_ERROR,
  RESET_USER_PASSWORD_SUCCESS,
  UPDATE_USER_PASSWORD,
  UPDATE_USER_PASSWORD_ERROR,
  UPDATE_USER_PASSWORD_SUCCESS,
  INACTIVE_USER,
  INACTIVE_USER_ERROR,
  INACTIVE_USER_SUCCESS,
  USERS_FETCH,
  USERS_FETCH_ERROR,
  USERS_FETCH_SUCCESS,
  NEW_USER_FETCH_ERROR,
  CHECK_USER_PASSWORD,
  CHECK_USER_PASSWORD_SUCCESS,
  CHECK_USER_PASSWORD_ERROR,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  LOGIN_SUCCESS,
  UPDATE_USER_DATA,
  UPDATE_USER_DATA_SUCCESS,
  UPDATE_USER_DATA_ERROR,
  PROFILE_UPDATE_PASSWORD,
  PROFILE_UPDATE_PASSWORD_SUCCESS,
  PROFILE_UPDATE_PASSWORD_ERROR,
  FETCH_EMPLOYEE_ERROR,
  FETCH_EMPLOYEE_SUCCESS,
  FETCH_EMPLOYEE,
  UPDATE_EMPLOYEE,
  UPDATE_EMPLOYEE_SUCCESS,
  UPDATE_EMPLOYEE_ERROR
} from '../constants/ActionTypes'

const initialState = {
  check: {
    status: false
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGGED_IN:
      return {
        ...state,
        data: action.user,
        check: {
          status: false
        },
        loading: false
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        loginToken: action.payload.access_token,
        userId: action.payload.user_id
      }
    case LOGIN_ERROR:
      return {
        ...state,
        error: true,
        message:
          'Error al iniciar sesión, verifique los datos e intente nuevamente',
        loading: false,
        loginToken: undefined,
        userId: undefined
      }
    case USERS_FETCH:
      return {
        ...state,
        loading: true
      }
    case INACTIVE_USER:
      return {
        ...state
      }
    case INACTIVE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.filter((user) => user.id !== action.payload.data.id),
        loading: false,
        success: true,
        message: 'Usuario desactivado exitosamente'
      }
    case INACTIVE_USER_ERROR:
      return {
        ...state,
        loading: false,
        user: action.payload,
        error: true,
        message: 'Error al intentar desactivar usuario'
      }
    case RESET_USER_PASSWORD:
      return {
        ...state,
        loading: true
      }
    case RESET_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        message:
          'Correo con intrucciones para recuperar ha sido enviado exitosamente'
      }
    case RESET_USER_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        user: action.payload,
        error: true,
        message: 'Error al intentar enviar correo'
      }
    case UPDATE_USER_PASSWORD:
      return {
        ...state,
        loading: true
      }
    case UPDATE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        message: 'Contraseña actualizada exitosamente'
      }
    case UPDATE_USER_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        user: action.payload,
        error: true,
        message: 'Error al intentar actualizar contraseña'
      }
    case USERS_FETCH_SUCCESS:
      return {
        ...state,
        users: action.payload,
        loading: false
      }
    case USERS_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: 'Error al obtener usuarios'
      }
    case NEW_USER_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        endRequest: action.payload
      }
    case NEW_USER_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: 'Error al obtener roles y ubicaciones'
      }
    case CLEAR_REQUEST_STATUS:
      return {
        ...state,
        check: {
          status: false
        },
        success: false,
        error: false,
        updated: false,
        message: null
      }
    case CHECK_USER_PASSWORD:
      return {
        ...state,
        loading: true,
        check: {
          status: false
        }
      }
    case CHECK_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        check: {
          status: true
        },
        loading: false
      }
    case CHECK_USER_PASSWORD_ERROR:
      return {
        ...state,
        check: {
          status: false
        },
        loading: false,
        success: false,
        error: true,
        message: action.payload.message
      }
    case CREATE_USER:
      return {
        ...state,
        loading: true
      }
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        message: 'Se ha creado exitosamente el usuario.',
        error: false
      }
    case CREATE_USER_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: action.payload.message,
        error: true
      }
    case 'FETCH_COMPANY_ROLE_SUCCES':
      return {
        ...state,
        role: action.payload,
        loading: false,
        error: false
      }
    case 'FETCH_COMPANY_ROLE_ERROR':
      return {
        ...state,
        role: {},
        loading: false
      }
    case UPDATE_USER_DATA:
      return {
        ...state,
        loading: true
      }
    case UPDATE_USER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        updated: true,
        message: 'Información del usuario actualizada exitosamente'
      }
    case UPDATE_USER_DATA_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        updated: false,
        message: 'Ha ocurrido un error al actualizar la información del usuario'
      }
    case PROFILE_UPDATE_PASSWORD:
      return {
        ...state,
        loading: true
      }
    case PROFILE_UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        message: 'Contraseña actualizada exitosamente'
      }
    case PROFILE_UPDATE_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        message: 'Ha ocurrido un error al actualizar la contraseña del usuario'
      }
    case FETCH_EMPLOYEE:
      return {
        ...state,
        loading: true
      }
    case FETCH_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        employee: action.payload[0]
      }
    case FETCH_EMPLOYEE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: 'No se a encontrado un empleado ligado al usuario'
      }
    case UPDATE_EMPLOYEE:
      return {
        ...state,
        loading: true
      }
    case UPDATE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        successEmployee: true,
        error: false,
        message: 'Usuario actualizado correctamente'
      }
    case UPDATE_EMPLOYEE_ERROR:
      return {
        ...state,
        loading: false,
        successEmployee: false,
        error: true,
        message: 'Ocurrio un error editando el usuario'
      }
    default:
      return state
  }
}
