import {
  CREATE_ROLE_PERMISSION,
  CREATE_ROLE_PERMISSION_SUCCESS,
  CREATE_ROLE_PERMISSION_ERROR,
  GET_ALL_ROLE_PERMISSION,
  GET_ALL_ROLE_PERMISSION_SUCCESS,
  GET_ALL_ROLE_PERMISSION_ERROR,
  GET_ROLE_PERMISSION,
  GET_ROLE_PERMISSION_SUCCESS,
  GET_ROLE_PERMISSION_ERROR,
  UPDATE_ROLE_PERMISSION,
  UPDATE_ROLE_PERMISSION_SUCCESS,
  UPDATE_ROLE_PERMISSION_ERROR,
  DELETE_ROLE_PERMISSION,
  DELETE_ROLE_PERMISSION_SUCCESS,
  DELETE_ROLE_PERMISSION_ERROR,
  GET_PERMISSION,
  GET_PERMISSION_SUCCESS,
  GET_PERMISSION_ERROR
} from '../constants/ActionTypes'

export const doGetRolesPermissions = (payload) => ({
  type: GET_ALL_ROLE_PERMISSION,
  payload
})
export const doGetRolesPermissionsSuccess = (payload) => ({
  type: GET_ALL_ROLE_PERMISSION_SUCCESS,
  payload
})
export const doGetRolesPermissionsError = (payload) => ({
  type: GET_ALL_ROLE_PERMISSION_ERROR,
  payload
})
export const doGetRolePermission = (payload) => ({
  type: GET_ROLE_PERMISSION,
  payload
})
export const doGetRolePermissionSuccess = (payload) => ({
  type: GET_ROLE_PERMISSION_SUCCESS,
  payload
})
export const doGetRolePermissionError = (payload) => ({
  type: GET_ROLE_PERMISSION_ERROR,
  payload
})
export const doCreateRolesPermissions = (payload) => ({
  type: CREATE_ROLE_PERMISSION,
  payload
})
export const doCreateRolesPermissionsSuccess = (payload) => ({
  type: CREATE_ROLE_PERMISSION_SUCCESS,
  payload
})
export const doCreateRolesPermissionsError = (payload) => ({
  type: CREATE_ROLE_PERMISSION_ERROR,
  payload
})
export const doUpdateRolesPermissions = (payload) => ({
  type: UPDATE_ROLE_PERMISSION,
  payload
})
export const doUpdateRolesPermissionsSuccess = (payload) => ({
  type: UPDATE_ROLE_PERMISSION_SUCCESS,
  payload
})
export const doUpdateRolesPermissionsError = (payload) => ({
  type: UPDATE_ROLE_PERMISSION_ERROR,
  payload
})
export const doDeleteRolesPermissions = (payload) => ({
  type: DELETE_ROLE_PERMISSION,
  payload
})
export const doDeleteRolesPermissionsSuccess = (payload) => ({
  type: DELETE_ROLE_PERMISSION_SUCCESS,
  payload
})
export const doDeleteRolesPermissionsError = (payload) => ({
  type: DELETE_ROLE_PERMISSION_ERROR,
  payload
})
export const doGetPermissions = (payload) => ({
  type: GET_PERMISSION,
  payload
})
export const doGetPermissionsSuccess = (payload) => ({
  type: GET_PERMISSION_SUCCESS,
  payload
})
export const doGetPermissionsError = (payload) => ({
  type: GET_PERMISSION_ERROR,
  payload
})
