const getUserId = (userState) => userState.id
const getError = (state) => state.user.error
const getSuccess = (state) => state.user.success
const getMessage = (state) => {
  return state.user.message ? state.user.message : null
}
const getLoginToken = (state) => state.user.loginToken
const getLoginUserId = (state) => state.user.userId

export {
  getUserId,
  getError,
  getMessage,
  getSuccess,
  getLoginToken,
  getLoginUserId
}
