// Dependences
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { doFetchLocales, doUpdateLocale } from '../../actions/locale'
import { getLoginToken } from '../../selectors/user'
import { doClearRequestStatus } from '../../actions/clearStatus'
import { getError, getMessage, getSuccess } from '../../selectors/locale'

// Custom components
import CircularLoading from '../Loading/CircularLoading'
import LocaleRow from '../LocaleRow/LocaleRow'
import MySnackbar from '../MySnackbar/MySnackbar'

// UI Components
import Paper from '@material-ui/core/Paper'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import Search from '@material-ui/icons/Search'
import { withStyles, FormControl, Select, MenuItem } from '@material-ui/core'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

// Styles
import './chooselanguage.sass'
import { languageStyles } from './chooselanguage.styles'

class ChooseLanguage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedModule: null,
      isEditing: false,
      localeNewValue: null,
      query: ''
    }
  }

  onChangeQuery = (event) => {
    this.setState({ query: event.target.value })
  }

  componentDidMount() {
    this.props.doClearRequestStatus()
    this.fetchLocales()
  }

  fetchLocales = () => {
    const countryId = this.props.country.id
    this.props.doFetchLocales({
      data: { countryId: countryId, user: this.props.user }
    })
  }

  handleChange = (event) => {
    this.setState({ selectedModule: event.target.value })
  }

  onUpdateLocale = (locale) => {
    this.props.doClearRequestStatus()
    const newValue = this.state.localeNewValue
      ? this.state.localeNewValue
      : locale.value
    this.props.doUpdateLocale({
      data: {
        locale: locale,
        newValue: newValue,
        module: this.selectedModule(),
        user: this.props.user
      }
    })
    this.setState({ isEditing: false, localeNewValue: null })
  }

  onEditLocale = (locale) => {
    if (!this.state.isEditing) {
      locale.isEditing = true
      this.setState({ isEditing: true })
    } else {
      if (locale.isEditing) {
        locale.isEditing = false
        this.setState({ isEditing: false, localeNewValue: null })
      }
    }
  }

  onLocaleChange = (newValue) => {
    this.setState({ localeNewValue: newValue })
  }

  selectedModule = () => {
    if (!this.state.selectedModule) {
      return this.props.locale.locales[0]
    } else {
      const selectedModule = this.props.locale.locales.filter((module) => {
        return module.id === this.state.selectedModule
      })[0]
      return selectedModule
    }
  }

  handleCloseSnackBar = () => {
    this.props.doClearRequestStatus()
  }

  render() {
    const openSnackbar = this.props.success || this.props.error
    const country = this.props.country
    const { loading, locales } = this.props.locale
    let listModules
    let listLocales
    if (locales && locales.length > 0) {
      listModules = locales.map((module) => {
        return (
          <MenuItem value={module.id} key={module.id}>
            {module.name}
          </MenuItem>
        )
      })
      listLocales = this.selectedModule()
        .locales.filter((locale) => {
          if (this.state.query && this.state.query.length > 0) {
            return locale.value
              .toLowerCase()
              .includes(this.state.query.toLowerCase())
          }
          return true
        })
        .map((locale) => {
          return (
            <LocaleRow
              key={locale.id}
              locale={locale}
              localeNewValue={this.state.localeNewValue}
              onUpdate={this.onUpdateLocale}
              onEdit={this.onEditLocale}
              onLocaleChange={this.onLocaleChange}
            />
          )
        })
    }

    return (
      <Paper>
        <MySnackbar
          open={openSnackbar}
          variant={
            openSnackbar
              ? this.props.success
                ? 'success'
                : 'error'
              : 'default'
          }
          onClose={this.handleCloseSnackBar}
          message={openSnackbar ? this.props.message : ''}
        />
        <div>
          {locales && locales.length > 0 ? (
            <div className="page-language__container">
              <Typography className={this.props.classes.textConfig}>
                Configuracion de idioma - {country.name}
              </Typography>
              <Typography className={this.props.classes.subtitleConfig}>
                Los cambios se aplicaran para todas las ciudades y teatros de{' '}
                {country.name}
              </Typography>
              <Typography className={this.props.classes.textModules}>
                MÓDULOS
              </Typography>
              <div className="page-language__form-container">
                <FormControl
                  variant="outlined"
                  className={this.props.classes.chooseModule}
                >
                  <Select
                    input={
                      <OutlinedInput name="selectedModule" id="module-select" />
                    }
                    value={this.state.selectedModule || locales[0].id}
                    onChange={this.handleChange}
                  >
                    {listModules}
                  </Select>
                </FormControl>
              </div>
              <div>
                <Input
                  className="page-language__find-field"
                  placeholder="Buscar"
                  value={this.state.query}
                  onChange={this.onChangeQuery}
                  disableUnderline={true}
                  startAdornment={
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  }
                />
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>TEXTO POR DEFECTO</TableCell>
                      <TableCell>Nombre</TableCell>
                      <TableCell>TEXTO SUSTITUTO</TableCell>
                      <TableCell>&nbsp;</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{listLocales}</TableBody>
                </Table>
              </div>
            </div>
          ) : loading ? (
            <CircularLoading isLoading={loading} />
          ) : null}
        </div>
      </Paper>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: getLoginToken(state),
    locale: state.locale,
    error: getError(state),
    message: getMessage(state),
    success: getSuccess(state)
  }
}

const mapDispatchToProps = {
  doFetchLocales,
  doUpdateLocale,
  doClearRequestStatus
}

export default withStyles(languageStyles)(
  connect(mapStateToProps, mapDispatchToProps)(ChooseLanguage)
)
