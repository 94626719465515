import {
  KIOSK_TRANSACTIONS_FETCH,
  KIOSK_TRANSACTIONS_FETCH_SUCCESS,
  KIOSK_TRANSACTIONS_FETCH_ERROR,
  LOADING,
  CLEAR_REQUEST_STATUS,
  DELETE_KIOSK,
  DELETE_KIOSK_ERROR,
  DELETE_KIOSK_SUCCESS,
  KIOSK_DEVICES_FETCH,
  KIOSK_DEVICES_FETCH_SUCCESS,
  KIOSK_DEVICES_FETCH_ERROR,
  FETCH_KIOSKS_INFO,
  FETCH_KIOSKS_INFO_SUCCESS,
  FETCH_KIOSKS_INFO_ERROR,
  FETCH_KIOSKS_BY_ID,
  FETCH_KIOSKS_BY_ID_SUCCESS,
  FETCH_KIOSKS_BY_ID_ERROR
} from '../constants/ActionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case KIOSK_TRANSACTIONS_FETCH:
      return action.payload
    case KIOSK_TRANSACTIONS_FETCH_SUCCESS:
      return {
        ...state,
        transactions: action.payload,
        loading: false
      }
    case KIOSK_TRANSACTIONS_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: 'Error al obtener transacciones'
      }
    case KIOSK_DEVICES_FETCH:
      return action.payload
    case KIOSK_DEVICES_FETCH_SUCCESS:
      return {
        ...state,
        devices: action.payload.devices,
        loading: false
      }
    case KIOSK_DEVICES_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: 'Error al obtener dispositivos'
      }
    case DELETE_KIOSK:
      return action.payload
    case DELETE_KIOSK_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        message: 'Kiosco eliminado'
      }
    case DELETE_KIOSK_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: 'Error al eliminar kiosco'
      }
    case LOADING:
      return {
        ...state,
        loading: true
      }
    case CLEAR_REQUEST_STATUS:
      return {
        ...state,
        success: false,
        error: false,
        message: null
      }
    case FETCH_KIOSKS_INFO:
      return { ...state }
    case FETCH_KIOSKS_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        kiosksInfo: action.payload
      }
    case FETCH_KIOSKS_INFO_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: 'Error al obtener información de los kioscos'
      }
    case FETCH_KIOSKS_BY_ID:
      return {
        ...state,
        loading: true
      }
    case FETCH_KIOSKS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        kioskDetail: action.payload,
        error: false
      }
    case FETCH_KIOSKS_BY_ID_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: 'Error al obtener detalle del kiosk'
      }
    default:
      return state
  }
}
