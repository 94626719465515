import ApiClientSingleton from '../api-client'

const kioskApiInstance = ApiClientSingleton.getKioskApiInstance()

const fetchConfigurations = async (payload) => {
  const { params, user } = payload
  const uri = '/api/v1/companies/configurations'
  const headers = { Authorization: `Bearer ${user}` }
  const { data } = await kioskApiInstance.get(uri, { params, headers })
  return data
}

const createConfigurations = async (payload) => {
  const { params, user } = payload
  const uri = '/api/v1/companies/configurations'
  const headers = { Authorization: `Bearer ${user}` }
  const { data } = await kioskApiInstance.post(uri, params, { headers })
  return data
}

const updateConfigurations = async (payload) => {
  const uri = '/api/v1/companies/configurations'
  const headers = { Authorization: `Bearer ${payload.user}` }
  const body = { configurations: payload.configurations }
  const { status } = await kioskApiInstance.put(uri, body, { headers })
  return status
}

export { fetchConfigurations, createConfigurations, updateConfigurations }
