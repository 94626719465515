export const kioskByLocationRowStyles = {
  btnService: {
    border: '1px solid #75cc7b',
    backgroundColor: '#bde6bf',
    color: '#1cab26',
    fontSize: '10px',
    fontWeight: 'bold'
  },
  btnWithoutService: {
    border: '1px solid #e72441',
    backgroundColor: '#f18091',
    color: '#e72442',
    fontSize: '10px',
    fontWeight: 'bold'
  },
  btnWarning: {
    border: '1px solid #f3c15a',
    backgroundColor: '#f8da9d',
    color: '#655f52',
    fontSize: '10px',
    fontWeight: 'bold',
    width: '110px'
  },
  iconsContainer: {
    width: '80px'
  },
  iconAction: {
    color: '#8c8c8c',
    cursor: 'pointer'
  }
}
