/* eslint-disable camelcase */
// Dependences
import React from 'react'
import moment from 'moment'
import { kioskStatus } from '../../utils/utils'
import { IN_SERVICE, OUT_OF_SERVICE, WARNING } from '../../constants/Constants'

// UI components
import { withStyles, Link } from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'

// Styles and assets
import { kioskStatusRowStyles } from './kioskstatusrow.styles'

const KioskStatusRow = props => {
  const kioskStatusStyle = (kioskStatusId) => {
    switch (kioskStatusId) {
      case OUT_OF_SERVICE:
        return props.classes.btnWithoutService
      case IN_SERVICE:
        return props.classes.btnService
      case WARNING:
        return props.classes.btnWarning
      default:
        return props.classes.btnWithoutService
    }
  }

  const { kiosk, onDetail, shouldShowStatus } = props
  const { name, city_name, location_name, area_name, status } = kiosk
  const handleDetails = () => {
    onDetail(kiosk)
  }

  return (
    <TableRow>
      <TableCell onClick={handleDetails}><Link>{name}</Link></TableCell>
      <TableCell>{city_name}</TableCell>
      <TableCell>{location_name}</TableCell>
      <TableCell>{area_name}</TableCell>
      {shouldShowStatus &&
        <TableCell><Button className={kioskStatusStyle(status)}>{kioskStatus(status)}</Button></TableCell>
      }
      <TableCell>{kiosk.last_status_date ? moment(kiosk.last_status_date).format('D/MM/YYYY hh:mm a') : ''}</TableCell>
    </TableRow>
  )
}

export default withStyles(kioskStatusRowStyles)(KioskStatusRow)
