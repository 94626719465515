export const updateConfigStyles = {
  container: {
    backgroundColor: '#fff !important',
    margin: '15px'
  },
  containerGroup: {
    padding: '25px'
  },
  inputConfig: {
    width: '15%'
  },
  selectConfig: {
    width: '100%',
    height: '55px',
    backgroundColor: '#fff !important'
  },
  btnLink: {
    color: '#efa91b',
    fontWeight: 400
  },
  areaItem: {
    marginLeft: 12,
    fontWeight: 'bold'
  },
  separator: {
    margin: '20px 0px'
  },
  cssFocused: {},
  notchedOutline: {},
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: '#EFA91B'
    },
    textAlign: 'left'
  },
  cssLabel: {
    '&$cssFocused': {
      color: '#EFA91B'
    }
  }
}
