import { call, put } from 'redux-saga/effects'
import {
  setUser,
  doLoginError,
  doResetUserPasswordSuccess,
  doResetUserPasswordError,
  doUpdateUserPasswordError,
  doUpdateUserPasswordSuccess,
  doFetchNewUserSuccess,
  doInactiveUserSuccess,
  doInactiveUserError,
  doFetchUsersError,
  doFetchUsersSuccess,
  doFetchNewUserError,
  doCheckUserPasswordSuccess,
  doCheckUserPasswordError,
  doCreateUserSuccess,
  doLoginSuccess,
  doCreateUserError,
  doUpdateUserDataSuccess,
  doUpdateUserDataError,
  doProfileUpdatePasswordSuccess,
  doProfileUpdatePasswordError,
  doFetchEmployeeSuccess,
  doFetchEmployeeError,
  doUpdateEmployeeError,
  doUpdateEmployeeSuccess
} from '../actions/user'
import {
  fetchLogin,
  fetchUsers,
  fetchNewUser,
  validateToken,
  updatePassword,
  confirmUser,
  createUser,
  inactiveUser,
  requestResetPassword,
  checkUserPassword,
  loginUser,
  updateUserData,
  findEmployeeById,
  updateEmployee
} from '../api/user'
import { doLoading } from '../actions/loading'

function* handleFetchLogin(action) {
  try {
    yield put(doLoading())
    const result = yield call(fetchLogin, action.payload.data)
    if (result.access_token && result.user_id) {
      yield put(doLoginSuccess(result))
    } else {
      yield put(doLoginError(result))
    }
  } catch (error) {
    yield put(doLoginError(error))
  }
}

function* handleUserLogin(action) {
  try {
    const result = yield call(loginUser, action.payload)
    if (result.id) {
      yield put(setUser(result))
    } else {
      yield put(doLoginError(result))
    }
  } catch (error) {
    yield put(doLoginError(error))
  }
}

function* handleFetchUsers(action) {
  try {
    const result = yield call(fetchUsers, action.payload.data)
    if (result) {
      yield put(doFetchUsersSuccess(result))
    } else {
      yield put(doFetchUsersError(result))
    }
  } catch (error) {
    yield put(doFetchUsersError(error))
  }
}

function* handleFetchNewUser(action) {
  try {
    const result = yield call(
      fetchNewUser,
      action.payload.user,
      action.payload.data
    )
    if (result) {
      yield put(doFetchNewUserSuccess(result))
    } else {
      yield put(doFetchNewUserError(result))
    }
  } catch (error) {
    yield put(doFetchNewUserError(error))
  }
}
function* handleFetchEmployee(action) {
  try {
    const result = yield call(findEmployeeById, action.payload)
    if (result) {
      yield put(doFetchEmployeeSuccess(result))
    } else {
      yield put(doFetchEmployeeError(result))
    }
  } catch (error) {
    yield put(doFetchEmployeeError(error))
  }
}

function* handleUpdateEmployee(action) {
  try {
    const result = yield call(updateEmployee, action.payload)
    if (result) {
      yield put(doUpdateEmployeeSuccess(result))
    } else {
      yield put(doUpdateEmployeeError(result))
    }
  } catch (error) {
    yield put(doUpdateEmployeeError(error))
  }
}

function* handleCreateUser(action) {
  try {
    const result = yield call(
      createUser,
      action.payload.company,
      action.payload.user,
      action.payload.data
    )
    if (result.status === 200) {
      yield put(doCreateUserSuccess(result))
    } else {
      yield put(doCreateUserError(result))
    }
  } catch (error) {
    yield put(doCreateUserError(error))
  }
}

function* handleInactiveUser(action) {
  try {
    const result = yield call(
      inactiveUser,
      action.payload.user,
      action.payload.data
    )
    const response = { result: result, data: action.payload.data }
    yield put(doInactiveUserSuccess(response))
  } catch (error) {
    yield put(doInactiveUserError(error))
  }
}

function* handleResetPassword(action) {
  try {
    const result = yield call(requestResetPassword, action.payload.data)
    if (result.status === 200) {
      yield put(doResetUserPasswordSuccess(result))
    } else {
      yield put(doResetUserPasswordError())
    }
  } catch (error) {
    yield put(doResetUserPasswordError(error))
  }
}

function* handleUpdatePassword(action) {
  try {
    var result = yield call(validateToken, action.payload.data)
    if (result.status === 200) {
      result = yield call(updatePassword, action.payload.data)
      if (result.status === 200) {
        yield put(doUpdateUserPasswordSuccess(result))
      } else {
        yield put(doUpdateUserPasswordError())
      }
    } else {
      yield put(doUpdateUserPasswordError())
    }
  } catch (error) {
    yield put(doUpdateUserPasswordError(error))
  }
}

function* handleCreatePassword(action) {
  try {
    var result = yield call(confirmUser, action.payload.data)
    if (result.status === 200) {
      result = yield call(updatePassword, action.payload.data)
      if (result.status === 200) {
        yield put(doUpdateUserPasswordSuccess(result))
      } else {
        yield put(doUpdateUserPasswordError())
      }
    } else {
      yield put(doUpdateUserPasswordError())
    }
  } catch (error) {
    yield put(doUpdateUserPasswordError(error))
  }
}

function* handleCheckUserPassword(action) {
  try {
    yield put(doLoading())
    const result = yield call(checkUserPassword, action.payload.data)
    if (result.status === 200) {
      yield put(doCheckUserPasswordSuccess(result))
    } else {
      yield put(doCheckUserPasswordError(result))
    }
  } catch (error) {
    yield put(doCheckUserPasswordError(error))
  }
}

function* handleUpdateUserData(action) {
  try {
    yield put(doLoading())
    const result = yield call(
      updateUserData,
      action.payload.user,
      action.payload.data
    )
    if (result.status === 200) {
      yield put(doUpdateUserDataSuccess(result))
    } else {
      yield put(doUpdateUserPasswordError(result))
    }
  } catch (error) {
    yield put(doUpdateUserDataError(error))
  }
}

function* handleProfileUpdatePassword(action) {
  try {
    yield put(doLoading())
    const result = yield call(updatePassword, action.payload.data)
    if (result.status === 200) {
      yield put(doProfileUpdatePasswordSuccess(result))
    } else {
      yield put(doProfileUpdatePasswordError(result))
    }
  } catch (error) {
    yield put(doProfileUpdatePasswordError(error))
  }
}

export {
  handleFetchLogin,
  handleUserLogin,
  handleFetchUsers,
  handleFetchNewUser,
  handleCreateUser,
  handleInactiveUser,
  handleResetPassword,
  handleUpdatePassword,
  handleCreatePassword,
  handleCheckUserPassword,
  handleUpdateUserData,
  handleProfileUpdatePassword,
  handleFetchEmployee,
  handleUpdateEmployee
}
