import { call, put } from 'redux-saga/effects'
import {
  doCreateFormatSuccess,
  doFetchFormatsSuccess,
  doFetchFormatsError,
  doFetchFormats,
  doUpdateFormatSuccess,
  doCreateFormatError,
  doUpdateFormatError,
  doDeleteFormatSuccess,
  doDeleteFormatError,
  doFetchPrintableComponentsSuccess,
  doFetchPrintableComponentsError
} from '../actions/format'
import {
  fetchFormats,
  createFormat,
  updateFormat,
  deleteFormat,
  fetchPrintableComponents
} from '../api/format'

function* handleFetchFormats(action) {
  try {
    const result = yield call(fetchFormats, action.payload.data)
    if (result) {
      yield put(doFetchFormatsSuccess(result))
    } else {
      yield put(doFetchFormatsError())
    }
  } catch (error) {
    yield put(doFetchFormatsError(error))
  }
}

function* handleCreateFormat(action) {
  try {
    const result = yield call(createFormat, action.payload.data)
    if (result) {
      yield put(doCreateFormatSuccess(result))
    } else {
      yield put(doCreateFormatError())
    }
  } catch (error) {
    yield put(doCreateFormatError(error))
  }
}

function* handleUpdateFormat(action) {
  try {
    const result = yield call(updateFormat, action.payload.data)
    if (result === 200) {
      yield put(doUpdateFormatSuccess(result))
    } else {
      yield put(doUpdateFormatError())
    }
  } catch (error) {
    yield put(doUpdateFormatError(error))
  }
}

function* handleDeleteFormat(action) {
  try {
    const result = yield call(deleteFormat, action.payload.data)
    if (result === 204) {
      yield put(
        doFetchFormats({
          data: {
            params: action.payload.data.params,
            user: action.payload.data.user
          }
        })
      )
      yield put(doDeleteFormatSuccess(result))
    } else {
      yield put(doDeleteFormatError())
    }
  } catch (error) {
    yield put(doDeleteFormatError(error))
  }
}

function* handleFetchPrintableComponents(action) {
  try {
    const result = yield call(fetchPrintableComponents, action.payload.data)
    if (result) {
      yield put(doFetchPrintableComponentsSuccess(result))
    } else {
      yield put(doFetchPrintableComponentsError())
    }
  } catch (error) {
    yield put(doFetchPrintableComponentsError(error))
  }
}

export {
  handleFetchFormats,
  handleCreateFormat,
  handleUpdateFormat,
  handleDeleteFormat,
  handleFetchPrintableComponents
}
