import {
  BANNERS_FETCH,
  BANNERS_FETCH_SUCCESS,
  BANNERS_FETCH_ERROR,
  CHANGE_BANNERS,
  CHANGE_BANNERS_SUCCESS,
  CHANGE_BANNERS_ERROR,
  MARK_BANNER_FOR_DELETION,
  DELETE_BANNER_ERROR,
  DELETE_BANNER_SUCCESS,
  DELETE_BANNER
} from '../constants/ActionTypes'

export const doFetchBanners = payload => ({ type: BANNERS_FETCH, payload })
export const doFetchBannersSuccess = payload => ({ type: BANNERS_FETCH_SUCCESS, payload })
export const doFetchBannersError = payload => ({ type: BANNERS_FETCH_ERROR, payload })
export const doChangeBanners = payload => ({ type: CHANGE_BANNERS, payload })

export const doChangeBannersSuccess = payload => ({ type: CHANGE_BANNERS_SUCCESS, payload })
export const doChangeBannersError = payload => ({ type: CHANGE_BANNERS_ERROR, payload })
export const doMarkBannerForDeletion = payload => ({ type: MARK_BANNER_FOR_DELETION, payload })

export const doDeleteBanner = payload => ({ type: DELETE_BANNER, payload })
export const doDeleteBannerError = payload => ({ type: DELETE_BANNER_ERROR, payload })
export const doDeleteBannerSuccess = payload => ({ type: DELETE_BANNER_SUCCESS, payload })
