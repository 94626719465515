import React from 'react'
import { Button, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import logo from '../../assets/images/logo.png'

import './PageNotFound.sass'

export default function PageNotFound() {
  return (
    <div className="flex_container page_background">
      <img className="icon" src={logo} alt="logo_kiosk" />
      <Typography className="text_color" variant="h1">
        Error 404
      </Typography>
      <br />
      <Typography className="text_color" variant="h3">
        Página no encontrada
      </Typography>
      <br />
      <Button className="home_button" variant="contained">
        <Link className="home_button_text" to="/">Regresar al home</Link>
      </Button>
    </div>
  )
}
