export const ChooseCompanyGridStyles = {
  gridContainer: {
    padding: '0 200px',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: '2%'
  },
  gridItem: {
    gridAutoRows: 'auto'
  }
}
