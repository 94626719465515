import guatemalaFlag from './bg_flag_guatemala.png'
import boliviaFlag from './bg_flag_bolivia.png'
import colombiaFlag from './bg_flag_colombia.png'
import curacaoFlag from './bg_flag_curacao.png'
import elSalvadorFlag from './bg_flag_elsalvador.png'
import hondurasFlag from './bg_flag_honduras.png'
import panamaFlag from './bg_flag_panama.png'
import paraguayFlag from './bg_flag_paraguay.png'
import peruFlag from './bg_flag_peru.png'
import chileFlag from './bg_flag_chile.png'
import costaRicaFlag from './bg_flag_costa_rica.png'
import nicaraguaFlag from './bg_flag_nicaragua.png'

const CountriesFlags = () => {
  return [{ guatemala: guatemalaFlag },
    { colombia: colombiaFlag },
    { bolivia: boliviaFlag },
    { curacao: curacaoFlag },
    { elSalvador: elSalvadorFlag },
    { honduras: hondurasFlag },
    { panama: panamaFlag },
    { paraguay: paraguayFlag },
    { peru: peruFlag },
    { chile: chileFlag },
    { costaRica: costaRicaFlag },
    { nicaragua: nicaraguaFlag }
  ]
}

const CountryFlagByName = (name) => {
  const normalizedName = name.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  const countriesFlags = CountriesFlags()
  const countryFlag = countriesFlags.filter(flag =>
    Object.keys(flag)[0].toLowerCase().replace(' ', '') === normalizedName.toLowerCase().replace(' ', '')
  )[0]
  let flag
  if (countryFlag != null) {
    flag = countryFlag[Object.keys(countryFlag)[0]]
  } else {
    flag = ''
  }
  return flag
}

export default CountryFlagByName
