import {
  CITIES_STATUS_FETCH,
  CITIES_STATUS_BY_COUNTRY_ID,
  CITIES_STATUS_FETCH_SUCCESS,
  CITIES_STATUS_BY_COUNTRY_ID_SUCCESS,
  CLEAR_REQUEST_STATUS,
  CITIES_STATUS_BY_COUNTRY_ID_ERROR,
  CITIES_STATUS_FETCH_ERROR,
  CITIES_STATUS_AND_LOCATIONS,
  CITIES_STATUS_AND_LOCATIONS_SUCCESS,
  CITIES_STATUS_AND_LOCATIONS_ERROR,
  LOADING
} from '../constants/ActionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case CITIES_STATUS_FETCH:
      return action.payload
    case CITIES_STATUS_FETCH_SUCCESS:
      return {
        ...state,
        cities: action.payload,
        loading: false
      }
    case CITIES_STATUS_BY_COUNTRY_ID:
      return action.payload
    case CITIES_STATUS_BY_COUNTRY_ID_SUCCESS:
      return {
        ...state,
        cities: action.payload.cities,
        loading: false
      }
    case CITIES_STATUS_AND_LOCATIONS:
      return action.payload
    case CITIES_STATUS_AND_LOCATIONS_SUCCESS:
      return {
        ...state,
        cities: action.payload,
        loading: false
      }
    case CITIES_STATUS_FETCH_ERROR ||
      CITIES_STATUS_BY_COUNTRY_ID_ERROR ||
      CITIES_STATUS_AND_LOCATIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: 'Error al obtener ciudades'
      }
    case LOADING:
      return {
        ...state,
        loading: true
      }
    case CLEAR_REQUEST_STATUS:
      return {
        ...state,
        success: false,
        error: false,
        message: null
      }
    default:
      return state
  }
}
