/* eslint-disable camelcase */
// Dependences
import React from 'react'

// UI components
import { withStyles } from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

// Styles and assets
import { cityRowStyles } from './cityrow.styles'

const CityRow = props => {
  const { city } = props
  const { in_service, out_of_service, warning, name, total_kiosks } = city
  const locationsCount = city.locations != null ? city.locations.length : 0

  return (
    <TableRow>
      <TableCell>{name}</TableCell>
      <TableCell>{locationsCount}</TableCell>
      <TableCell>{total_kiosks}</TableCell>
      <TableCell>{in_service}</TableCell>
      <TableCell>{out_of_service}</TableCell>
      <TableCell>{warning}</TableCell>
    </TableRow>
  )
}

export default withStyles(cityRowStyles)(CityRow)
