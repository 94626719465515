// Dependences
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'

// UI Components
import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import Reorder from '@material-ui/icons/Reorder'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Breadcrumbs from '@material-ui/lab/Breadcrumbs'
import Link from '@material-ui/core/Link'
import { Avatar, ListItemText } from '@material-ui/core'
import NotificationsIcon from '@material-ui/icons/Notifications'

// Custom components
import { sidebarWidth } from './../sidebar/Sidebar'
import { withRouter } from 'react-router-dom'

class Header extends Component {
  handleEditUser = () => {
    this.props.history.push(`/profile`)
  }

  render() {
    const { links, currentPage, hasProfile = true } = this.props

    const getNameInitials = () => {
      const { first_name: firstName, last_name: lastName } = this.props.user
      const initials = `${firstName.charAt(0)}${lastName.charAt(0)}`
      return initials.toUpperCase()
    }

    return (
      <AppBar position="static" className={this.props.classes.navbar}>
        <Toolbar className="header__toolbar">
          <IconButton className={this.props.classes.reorder} aria-label="Menu">
            <Reorder onClick={this.onClick} />
          </IconButton>
          <Breadcrumbs aria-label="Breadcrumb">
            {links &&
              links.map((link) => {
                return (
                  <Link color="inherit" onClick={link.onClick}>
                    {link.title}
                  </Link>
                )
              })}
            <Typography color="textPrimary">{currentPage}</Typography>
          </Breadcrumbs>
          {hasProfile && (
            <div
              className={`${this.props.classes.container} ${this.props.classes.userPanel}`}
            >
              <IconButton>
                <NotificationsIcon />
              </IconButton>
              <div
                className={`${this.props.classes.container} ${this.props.classes.userInfo}`}
                onClick={this.handleEditUser}
              >
                <Avatar className={this.props.classes.avatarColor}>
                  {getNameInitials()}
                </Avatar>
                <ListItemText
                  primary={`${this.props.user.first_name} ${this.props.user.last_name}`}
                  secondary={this.props.user.email}
                />
              </div>
            </div>
          )}
        </Toolbar>
      </AppBar>
    )
  }
}

const headerStyles = {
  navbar: {
    backgroundColor: '#fff',
    width: '100%',
    marginLeft: sidebarWidth,
    boxShadow: '2px 2px 10px 3px #ccc',
    position: 'fixed'
  },
  title: {
    fontSize: '15px',
    flexGrow: 1
  },
  reorder: {
    color: '#000'
  },
  button: {
    marginTop: '8px !important',
    marginRight: '8px !important',
    color: '#FFFFFF',
    backgroundColor: '#EFA817',
    float: 'right'
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  userPanel: {
    position: 'fixed',
    right: '1.5em'
  },
  userInfo: {
    cursor: 'pointer',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: 'var(--var-color-primary-grey)',
      transition: '0.2s'
    }
  },
  avatarColor: {
    backgroundColor: 'var(--var-color-primary-yellow)',
    color: 'var(--var-color-primary-white)'
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.data
  }
}

export default connect(mapStateToProps)(
  withRouter(withStyles(headerStyles)(Header))
)
