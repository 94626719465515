import { CREATE_FORMAT, UPDATE_FORMAT, FORMATS_FETCH,
  UPDATE_FORMAT_ERROR, FORMATS_FETCH_ERROR,
  CREATE_FORMAT_SUCCESS, UPDATE_FORMAT_SUCCESS,
  FORMATS_FETCH_SUCCESS, CREATE_FORMAT_ERROR,
  PRINTABLE_COMPONENTS_FETCH, CLEAR_REQUEST_STATUS,
  PRINTABLE_COMPONENTS_FETCH_ERROR, CHANGE_FORMAT_CONTENT,
  PRINTABLE_COMPONENTS_FETCH_SUCCESS, MOVE_FORMAT_COMPONENT,
  ADD_FORMAT_COMPONENT, REMOVE_FORMAT_COMPONENT, SELECT_FORMAT,
  CHANGE_FORMAT_COMPONENT_STYLES,
  DELETE_FORMAT, DELETE_FORMAT_SUCCESS, DELETE_FORMAT_ERROR } from '../constants/ActionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case CREATE_FORMAT:
      return {
        ...state,
        loading: true
      }
    case UPDATE_FORMAT:
      return {
        ...state,
        loading: true
      }
    case DELETE_FORMAT:
      return {
        ...state,
        loading: true
      }
    case FORMATS_FETCH:
      return action.payload
    case CREATE_FORMAT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        message: 'Formato creado exitosamente'
      }
    case CREATE_FORMAT_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: 'Error al intentar crear formato'
      }
    case UPDATE_FORMAT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        message: 'Formato modificado exitosamente'
      }
    case UPDATE_FORMAT_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: 'Error al intentar actualizar formato'
      }
    case DELETE_FORMAT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        message: 'Formato eliminado exitosamente'
      }
    case DELETE_FORMAT_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: 'Error al intentar eliminar formato'
      }
    case FORMATS_FETCH_SUCCESS:
      return {
        ...state,
        categories: action.payload,
        loading: false
      }
    case FORMATS_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: 'Error al obtener formatos'
      }
    case CLEAR_REQUEST_STATUS:
      return {
        ...state,
        success: false,
        error: false,
        message: null
      }
    case PRINTABLE_COMPONENTS_FETCH:
      return state
    case PRINTABLE_COMPONENTS_FETCH_SUCCESS:
      return {
        ...state,
        components: action.payload,
        loading: false
      }
    case PRINTABLE_COMPONENTS_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: 'Error al obtener componentes'
      }
    case SELECT_FORMAT:
      return {
        ...state,
        selectedFormat: action.payload.format
      }
    case CHANGE_FORMAT_CONTENT:
      return {
        ...state,
        selectedFormat: { ...state.selectedFormat,
          content: updatedContent(action.payload, state.selectedFormat.content)
        }
      }
    case MOVE_FORMAT_COMPONENT:
      const { result } = action.payload
      const contentObject = JSON.parse(state.selectedFormat.content)
      if (result.type === 'droppable-component') {
        const updatedContent = reordered(
          contentObject,
          result.source.index,
          result.destination.index
        )
        const updatedContentString = JSON.stringify(updatedContent)
        return {
          ...state,
          selectedFormat: { ...state.selectedFormat,
            content: updatedContentString
          }
        }
      }
      if (result.type === 'droppable-row') {

      }
      if (result.destination.droppableId.includes('droppable-format')) {

      }
      if (result.destination.droppableId.includes('droppable-row')) {

      }
      return state
    case ADD_FORMAT_COMPONENT:
      const { component } = action.payload
      const resultToAdd = action.payload.result
      let contentObjectAdding = JSON.parse(state.selectedFormat.content)
      let position = 0
      contentObjectAdding.forEach(component => {
        position = component.position > position ? component.position : position
      })
      position += 1
      contentObjectAdding.splice(resultToAdd.destination.index, 0, componentFrom(component, position))
      const updatedContentString = JSON.stringify(contentObjectAdding)
      return {
        ...state,
        selectedFormat: { ...state.selectedFormat,
          content: updatedContentString
        }
      }
    case REMOVE_FORMAT_COMPONENT:
      if (action.payload.result.source.droppableId === 'droppable-format') {
        let contentObjectToRemove = JSON.parse(state.selectedFormat.content)
        contentObjectToRemove.splice(action.payload.result.source.index, 1)
        const updatedContentStringToRemove = JSON.stringify(contentObjectToRemove)
        return {
          ...state,
          selectedFormat: { ...state.selectedFormat,
            content: updatedContentStringToRemove
          }
        }
      } else {
        const columnIndexes = action.payload.result.draggableId.split('-')
        let contentObjectToRemove = JSON.parse(state.selectedFormat.content)
        contentObjectToRemove[columnIndexes[0]].rows[columnIndexes[1]].columns.splice(action.payload.result.source.index, 1)
        const updatedContentStringToRemove = JSON.stringify(contentObjectToRemove)
        return {
          ...state,
          selectedFormat: { ...state.selectedFormat,
            content: updatedContentStringToRemove
          }
        }
      }
    case CHANGE_FORMAT_COMPONENT_STYLES:
      return {
        ...state,
        selectedFormat: { ...state.selectedFormat,
          content: updatedStylesContent(action.payload, state.selectedFormat.content)
        }
      }
    default:
      return state
  }
}

const updatedContent = (payload, content) => {
  const { componentId, rowId, columnId, newValue } = payload
  let contentObject = JSON.parse(content)
  const column = contentObject[componentId].rows[rowId].columns[columnId]
  if (column.variable_key) {
    if (newValue.includes(`{{${column.variable_key}}}`)) {
      column.value = newValue
    }
  } else {
    column.value = newValue
  }
  contentObject[componentId].rows[rowId].columns[columnId] = column
  return JSON.stringify(contentObject)
}

const updatedStylesContent = (payload, content) => {
  const { componentIndex, rowIndex, attributes } = payload
  let contentObject = JSON.parse(content)
  const row = contentObject[componentIndex].rows[rowIndex]
  if (attributes.size) {
    row.size = attributes.size
  } else if (attributes.weight) {
    row.weight = attributes.weight
  } else if (attributes.align) {
    row.align = attributes.align
  }
  contentObject[componentIndex].rows[rowIndex] = row
  return JSON.stringify(contentObject)
}

const reordered = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

const componentFrom = (component, position) => {
  let componentValue = JSON.parse(component.default_value)
  let result = { rows: componentValue, position: position, is_editable: component.editable }
  return result
}
