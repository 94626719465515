export const ChooseCompanyCardStyles = {
  companyCard: {
    padding: 5,
    width: 220,
    height: 165,
    backgroundColor: 'var(--var-color-primary-white)',
    borderRadius: 10,
    textAlign: 'center',
    display: 'block'
  },
  cardTitle: {
    paddingTop: '5px',
    fontSize: 14
  },
  cardButton: {
    color: 'var(--var-color-primary-yellow)'
  },
  cardAvatar: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 60,
    height: 60,
    border: 'medium solid rgba(232, 240, 243, 0.5)'
  }
}
