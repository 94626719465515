import { call, put } from 'redux-saga/effects'
import { doFetchCountriesSuccess, doFetchCountriesError } from '../actions/country'
import { fetchCountries } from '../api/country'
import { doLoading } from '../actions/loading'

function * handleFetchCountries (action) {
  try {
    yield put(doLoading())
    const result = yield call(fetchCountries, action.payload.user, action.payload.companyId)
    if (Array.isArray(result)) {
      yield put(doFetchCountriesSuccess(result))
    } else {
      yield put(doFetchCountriesError())
    }
  } catch (error) {
    yield put(doFetchCountriesError(error))
  }
}

export {
  handleFetchCountries
}
