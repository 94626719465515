/* eslint-disable camelcase */
// Dependences
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography, Card, CardContent } from '@material-ui/core'
import LinearIndeterminate from '../../components/Loading/LinearIndeterminate'

// Styles
import './changedpasswordcard.sass'
import { changedPasswordCardStyles } from './changedpasswordcard.styles'

const ChangedPasswordCard = props => {
  const { title, subtitle, footer, onFooterClick, isLoading } = props

  return (
    <Card className="update-card">
      <CardContent>
        <Typography variant="h5" className="update-title">
          {title}
        </Typography>
        <Typography variant="subtitle2" className="update-subtitle">
          {subtitle}
        </Typography>
        <Typography className="update-footer" variant="subtitle1" onClick={onFooterClick}>
          {footer}
        </Typography>
      </CardContent>
      { isLoading && <LinearIndeterminate/>}
    </Card>
  )
}
export default withStyles(changedPasswordCardStyles)(ChangedPasswordCard)
