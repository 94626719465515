import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import SearchIcon from '@material-ui/icons/Search'
import DialogTitle from '@material-ui/core/DialogTitle'
import {
  Checkbox,
  InputAdornment,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography
} from '@material-ui/core'
import './areadetail.sass'

export default function AreaDetail(props) {
  const { locations, open, onClose, onCreateArea, onUpdateArea, area } = props
  const [areaName, setAreaName] = React.useState('')
  const [areaDescription, setAreaDescription] = React.useState('')
  const [detailLocations, setDetailLocations] = React.useState(locations)
  const [filterLocationName, setfilterLocationName] = React.useState('')
  const [selectedLocations, setSelectedLocations] = React.useState([])
  const [areaLocationsSelected, setAreaLocationsSelected] = React.useState([])
  const [allSelected, setAllSelected] = React.useState(false)
  const [isEdit, setIsEdit] = React.useState(false)
  const disabled = areaName === '' || selectedLocations.length === 0 || areaDescription === ''

  function handleClose() {
    setSelectedLocations([])
    setIsEdit(false)
    onClose(true)
  }

  React.useEffect(() => {
    if (area) {
      setAreaName(area.name)
      setAreaDescription(area.description)
      const areaLocations = []
      area.locations.map((location) => {
        return areaLocations.push(location.id)
      })
      setSelectedLocations(areaLocations)
      setAreaLocationsSelected(areaLocations)
      setIsEdit(true)
    }
  }, [area])

  function onChangeAreaName(value) {
    setAreaName(value)
  }

  function onChangeAreaDescription(value) {
    setAreaDescription(value)
  }

  function onChangeFilter(value) {
    if (value.length === 0) setDetailLocations(locations)
    else handleChangeFilter(value)
    setfilterLocationName(value)
  }

  function selectLocation(event, locationId) {
    if (event && locationId) {
      const newSelectedLocations = [...selectedLocations]
      if (newSelectedLocations.includes(locationId)) {
        const result = newSelectedLocations.find(
          (id) => id === locationId
        )
        const index = newSelectedLocations.indexOf(result)
        newSelectedLocations.splice(index, 1)
        setAllSelected(false)
      } else newSelectedLocations.push(locationId)
      setSelectedLocations(newSelectedLocations)
    }
  }

  function returnIndexKey(index) {
    return index + Math.random()
  }

  function handleCreateArea() {
    const newArea = {
      name: areaName,
      description: areaDescription,
      locations: selectedLocations
    }
    onCreateArea(newArea)
  }

  function handleUpdateArea() {
    const newArea = {
      id: area.id,
      name: areaName,
      description: areaDescription,
      locations: selectedLocations
    }
    onUpdateArea(newArea)
  }

  function handleChangeFilter(filter) {
    const newLocations = locations.filter((location) =>
      location.name.toUpperCase().match(filter.toUpperCase())
    )
    setDetailLocations(newLocations)
  }

  function selectAll(event) {
    if (event) {
      if (!allSelected) {
        const newLocations = []
        locations.map(location => {
          return newLocations.push(location.id)
        })
        setSelectedLocations(newLocations)
      } else setSelectedLocations([])
      setAllSelected(!allSelected)
    }
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
        <DialogTitle>Nueva Área</DialogTitle>
        <DialogContent className="dialogContent">
          <div className="inputsContainer"> 
            <TextField
              className="iputs"
              variant="outlined"
              onChange={(event) => onChangeAreaName(event.target.value)}
              name="AreaName"
              label="Área"
              placeholder="Nombre del Área"
              value={areaName || ''}
            />
            <TextField
              className="iputs"
              variant="outlined"
              onChange={(event) => onChangeAreaDescription(event.target.value)}
              name="AreaDescription"
              label="Descripción"
              placeholder="Descripción del Área"
              value={areaDescription || ''}
            />
          </div>
          <Typography>Asignar área a una o mas ubicación</Typography>
          <TextField
            className="field"
            variant="outlined"
            onChange={(event) => onChangeFilter(event.target.value)}
            name="Filter"
            placeholder="Buscar por nombre de ubicación"
            value={filterLocationName || ''}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
          <div className="lengthContainer">
            <Typography>Seleccionar Ubicaciónes</Typography>
            <Typography>{selectedLocations.length} Seleccionado</Typography>
          </div>
          <div className="locationsContainer">
            {!isEdit && (
              <ListItem
                dense
                button
                onClick={(event) => selectAll(event)}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    color="primary"
                    checked={allSelected}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText primary="Todas las ubicaciones" />
              </ListItem>
            )}
            {detailLocations.map((location, index) => {
              return (
                <ListItem
                  dense
                  button
                  key={returnIndexKey(index)}
                  onClick={(event) => selectLocation(event, location.id)}
                  disabled={isEdit && areaLocationsSelected.indexOf(location.id) !== -1 }
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      color="primary"
                      checked={selectedLocations.indexOf(location.id) !== -1}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText primary={`${location.name}`} />
                </ListItem>
              )
            })}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            CANCELAR
          </Button>
          <Button
            onClick={isEdit ? handleUpdateArea : handleCreateArea}
            color="primary"
            disabled={disabled}
            autoFocus
          >
            ACEPTAR
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
