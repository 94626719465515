import ApiClientSingleton from '../api-client'

const kioskApiInstance = ApiClientSingleton.getKioskApiInstance()

const createArea = async (payload) => {
  const uri = `/api/v1/companies/${payload.companyId}/areas`
  const body = {
    name: payload.area.name,
    description: payload.area.description
  }
  const headers = { Authorization: `Bearer ${payload.user}` }
  const { data } = await kioskApiInstance.post(uri, body, { headers })
  return data
}

const fetchAreas = async (payload) => {
  const uri = `/api/v1/companies/${payload.companyId}/areas?show_locations=true`
  const headers = { Authorization: `Bearer ${payload.user}` }
  const { data } = await kioskApiInstance.get(uri, { headers })
  return data
}

const asociateLocations = async (payload) => {
  const uri = `/api/v1/companies/${payload.companyId}/area_has_locations`
  const headers = { Authorization: `Bearer ${payload.user}` }
  const { data } = await kioskApiInstance.post(uri, payload.body, { headers })
  return data
}

const deleteArea = async (payload) => {
  const uri = `/api/v1/companies/${payload.companyId}/areas/${payload.areaId}`
  const headers = { Authorization: `Bearer ${payload.user}` }
  const { data } = await kioskApiInstance.delete(uri, { headers })
  return data
}

const updateArea = async (payload) => {
  const uri = `/api/v1/companies/${payload.companyId}/areas/${payload.area.id}`
  const body = {
    name: payload.area.name,
    description: payload.area.description
  }
  const headers = { Authorization: `Bearer ${payload.user}` }
  const { data } = await kioskApiInstance.put(uri, body, { headers })
  return data
}

export { createArea, fetchAreas, asociateLocations, deleteArea, updateArea }
