// Dependences
import React from 'react'
import moment from 'moment'

// UI components
import { withStyles, Link } from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'

import { IN_SERVICE, OUT_OF_SERVICE, WARNING } from '../../constants/Constants'
import { kioskStatus } from '../../utils/utils'
// Styles and assets
import { kioskByLocationRowStyles } from './kioskbylocationrow.styles'

const KioskByLocationRow = props => {
  const { kiosk, onDetail } = props

  const handleDetails = () => {
    onDetail(kiosk)
  }

  const kioskStatusStyle = (kioskStatusId) => {
    switch (kioskStatusId) {
      case OUT_OF_SERVICE:
        return props.classes.btnWithoutService
      case IN_SERVICE:
        return props.classes.btnService
      case WARNING:
        return props.classes.btnWarning
      default:
        return props.classes.btnWithoutService
    }
  }

  return (
    <TableRow>
      <TableCell onClick={handleDetails}><Link>{kiosk.name}</Link></TableCell>
      <TableCell>{kiosk.area_name}</TableCell>
      <TableCell><Button className={kioskStatusStyle(kiosk.status)}>{kioskStatus(kiosk.status)}</Button></TableCell>
      <TableCell>{kiosk.last_status_date ? moment(kiosk.last_status_date).format('D/MM/YYYY hh:mm a') : ''}</TableCell>
    </TableRow>
  )
}

export default withStyles(kioskByLocationRowStyles)(KioskByLocationRow)
