// Dependences
import React from 'react'

// Styles
function WithId (Component) {
  return function WihIdComponent ({ id, ...props }) {
    return (
      <div id={id}>
        <Component {...props} />
      </div>
    )
  }
}

export default WithId
