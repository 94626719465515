export const formatPreviewStyles = {
  formatInput: {
    margin: '1px',
    minHeight: '12px'

  },
  dialogButton: {
    color: '#efa817',
    background: '#fff',
    boxShadow: 'none'
  },
  dialogContent: {
    minWidth: 200
  }
}
