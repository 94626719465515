// Dependences
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'

// UI Components
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'

// Styles and assets
import './headerbar.sass'
import { HeaderBarStyles } from './headerbar.styles'

class HeaderBar extends Component {
  render() {
    return (
      <AppBar position="static" className={this.props.classes.navbar}>
        <Toolbar className="page-headerBar__toolbar">
          <div className="page-headerBar__logo">
            <img
              alt=""
              src={this.props.logoHeader}
              className={this.props.classes.logo}
            />
          </div>
          <Button
            className={this.props.classes.logout}
            aria-label="Menu"
            variant="text"
            size="small"
            onClick={this.props.onLogout}
          >
            <ExitToAppIcon />
            {this.props.btnRightLabel}
          </Button>
        </Toolbar>
      </AppBar>
    )
  }
}

export default withStyles(HeaderBarStyles)(HeaderBar)
