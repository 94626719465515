import React, { Component } from 'react'
import { connect } from 'react-redux'
import { doClearRequestStatus } from '../../actions/clearStatus'
import { getLoginToken } from '../../selectors/user'
import { companyId } from '../../selectors/company'
// Custom components
import Header from '../../components/header/Header'
import Sidebar from '../../components/sidebar/Sidebar'
import Button from '@material-ui/core/Button'
import TablePagination from '@material-ui/core/TablePagination'
import Paper from '@material-ui/core/Paper'
import {
  withStyles,
  Input,
  InputAdornment,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core'

import CircularLoading from '../../components/Loading/CircularLoading'
import MySnackbar from '../../components/MySnackbar/MySnackbar'
import UserRow from '../../components/UserRow/UserRow'
import Search from '@material-ui/icons/Search'

import './user.sass'
import { UsersStyles } from './user.styles'

import { doFetchUsers, doInactiveUser } from '../../actions/user'
import FilterRolePermissions from '../../utils/FilterRolePermissions'

class Users extends Component {
  constructor(props) {
    super(props)
    this.state = {
      query: '',
      rowsPerPage: 10,
      currentPage: 0
    }
  }

  onChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value })
  }

  onChangePage = (event, page) => {
    this.setState({ currentPage: page })
  }

  onChangeQuery = (event) => {
    this.setState({ query: event.target.value })
  }

  componentDidMount = () => {
    this.props.doClearRequestStatus()
    this.allUsers()
  }

  onDeactivate = (user) => {
    const { userId } = this.props
    this.props.doInactiveUser({
      user: userId,
      data: { id: user.id, company_id: this.props.companyId }
    })
  }

  allUsers = () => {
    const { companyId } = this.props
    this.props.doFetchUsers({ data: { companyId: companyId } })
  }

  handleCloseSnackBar = () => {
    this.props.doClearRequestStatus()
  }

  render() {
    const { classes, companyId } = this.props
    const { loading, users, userId } = this.props.user
    let listUsers
    let filteredUsers
    if (users) {
      const currentPageIndex = this.state.currentPage * this.state.rowsPerPage
      const pageEndIndex = currentPageIndex + this.state.rowsPerPage
      filteredUsers = users.filter((user) => {
        if (this.state.query.length > 0) {
          return `${user.first_name} ${user.last_name}`
            .toLowerCase()
            .includes(this.state.query.toLowerCase())
        }
        return true
      })
      listUsers = filteredUsers
        .slice(currentPageIndex, pageEndIndex)
        .map((user) => {
          if (user.id === userId) {
            return <UserRow key={user.id} user={user} companyId={companyId} />
          }
          return (
            <UserRow
              userRole={this.props.userRole}
              key={user.id}
              user={user}
              onDeactivate={this.onDeactivate}
              companyId={companyId}
            />
          )
        })
    }
    const openSnackbar = this.props.error || this.props.success
    return (
      <div className="page-users">
        <MySnackbar
          open={openSnackbar}
          variant={
            openSnackbar ? (this.props.error ? 'error' : 'success') : 'default'
          }
          onClose={this.handleCloseSnackBar}
          message={openSnackbar ? this.props.message : ''}
        />
        <Header currentPage={'Lista de usuarios'} />
        <Sidebar level={0} />
        {FilterRolePermissions('user', 'create', this.props.userRole) && (
          <Button
            variant="contained"
            className="users__button"
            onClick={() => this.props.history.push('/users/new-user')}
          >
            NUEVO USUARIO
          </Button>
        )}
        <div className={this.props.classes.content}>
          {loading ? (
            <CircularLoading isLoading={loading} />
          ) : (
            <Paper className={classes.paper}>
              <Input
                className="find-field"
                placeholder="Buscar por nombre de usuario"
                value={this.state.query}
                onChange={this.onChangeQuery}
                disableUnderline={true}
                startAdornment={
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                }
              />
              <div className={classes.tableWrapper}>
                <Table className={classes.table} padding="dense">
                  <TableHead>
                    <TableRow>
                      <TableCell>Nombre</TableCell>
                      <TableCell>Correo electrónico</TableCell>
                      <TableCell>Teléfono</TableCell>
                      <TableCell>Rol</TableCell>
                      <TableCell align="center">Ciudades</TableCell>
                      <TableCell align="center">Teatros</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>{listUsers}</TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={(filteredUsers && filteredUsers.length) || 0}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.currentPage}
                  onChangePage={this.onChangePage}
                  onChangeRowsPerPage={this.onChangeRowsPerPage}
                  labelRowsPerPage="Filas por página"
                  backIconButtonProps={{
                    'aria-label': 'Anterior'
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'Siguiente'
                  }}
                />
              </div>
            </Paper>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  userId: getLoginToken(state),
  companyId: companyId(state),
  user: state.user,
  loading: state.user.loading,
  error: state.user.error,
  message: state.user.message,
  success: state.user.success,
  userRole: state.user.role
})

const mapDispatchToProps = {
  doFetchUsers,
  doInactiveUser,
  doClearRequestStatus
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(UsersStyles)(Users))
