/* eslint-disable camelcase */
// Dependences
import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

// UI components
import { withStyles } from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog'
import EditIcon from '@material-ui/icons/Edit'

// Styles and assets
import { userRowStyles } from './userrow.styles'
import './userrow.sass'
import FilterRolePermissions from '../../utils/FilterRolePermissions'

const UserRow = ({ user, onDeactivate, companyId, userRole }) => {
  const router = useHistory()
  const role = () => {
    const company = user.companies.find((company) => company.id === companyId)
    if (company) return company.role
    return undefined
  }
  const handleDeactivate = () => {
    onDeactivate(user)
  }

  return (
    <TableRow key={user.id}>
      <TableCell component="th" scope="row">
        {`${user.first_name} ${user.last_name}`}
      </TableCell>
      <TableCell>{user.email}</TableCell>
      <TableCell>{`(${user.country_code})${user.phone}`}</TableCell>
      <TableCell align="center">{role() ? role().name : ''}</TableCell>
      <TableCell align="center">{user.total_assigned.cities}</TableCell>
      <TableCell align="center">{user.total_assigned.locations}</TableCell>
      <TableCell>
        {onDeactivate && (
          <div className="actions-container">
            {FilterRolePermissions('employee', 'update', userRole) && (
              <EditIcon
                onClick={() =>
                  router.push(`/users/udate-user`, { user: user, role: role() })
                }
              />
            )}
            {FilterRolePermissions('user', 'delete', userRole) && (
              <ConfirmDialog
                title="¿Deseas eliminar el usuario?"
                onDelete={handleDeactivate}
              />
            )}
          </div>
        )}
      </TableCell>
    </TableRow>
  )
}

UserRow.propTypes = {
  user: PropTypes.object.isRequired,
  onDeactivate: PropTypes.func,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired
}

export default withStyles(userRowStyles)(UserRow)
