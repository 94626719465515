import {
  LOCALES_FETCH,
  UPDATE_LOCALE,
  LOCALES_FETCH_SUCCESS,
  UPDATE_LOCALE_SUCCESS,
  LOCALES_FETCH_ERROR,
  UPDATE_LOCALE_ERROR
} from '../constants/ActionTypes'

export const doFetchLocales = payload => ({ type: LOCALES_FETCH, payload })
export const doUpdateLocale = payload => ({ type: UPDATE_LOCALE, payload })
export const doFetchLocalesSuccess = payload => ({ type: LOCALES_FETCH_SUCCESS, payload })
export const doUpdateLocaleSuccess = payload => ({ type: UPDATE_LOCALE_SUCCESS, payload })
export const doFetchLocalesError = payload => ({ type: LOCALES_FETCH_ERROR, payload })
export const doUpdateLocaleError = payload => ({ type: UPDATE_LOCALE_ERROR, payload })
