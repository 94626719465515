// Dependences
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { doFetchCountries, doSaveSelectedCountry } from '../../actions/country'
import { doFetchCountriesCount } from '../../actions/company'
import { doClearRequestStatus } from '../../actions/clearStatus'
import { getError, getMessage } from '../../selectors/country'
import { getLoginToken } from '../../selectors/user'
import {
  hasOnlyOneCountry,
  firstCountry,
  companyId
} from '../../selectors/company'
import { withStyles } from '@material-ui/core/styles'

// Custom components
import Header from './../../components/header/Header'
import Sidebar from './../../components/sidebar/Sidebar'
import DashboardGrid from './../../components/DashboardGrid/DashboardGrid'
import MySnackbar from '../../components/MySnackbar/MySnackbar'

// Styles
import './dashboard.sass'
import { dashboardStyles } from './dashboard.styles'
import CircularLoading from '../../components/Loading/CircularLoading'

class Dashboard extends Component {
  showStatus = (country) => {
    this.props.doSaveSelectedCountry(country)
    this.props.history.push('/country-status', { country: country })
  }

  componentDidMount() {
    this.props.doClearRequestStatus()
    this.fetchCountriesCount()
    this.fetchCountries()
  }

  fetchCountriesCount = () => {
    this.props.doFetchCountriesCount({
      user: this.props.user,
      companyId: this.props.companyId
    })
  }

  fetchCountries = () => {
    this.props.doFetchCountries({
      user: this.props.user,
      companyId: this.props.companyId
    })
  }

  handleCloseSnackBar = () => {
    this.props.doClearRequestStatus()
  }

  redirectToPromotions = (country, userRole, firstCountry) => {
    let isPromotionsOnly = false
    const countryInfo = country ? country.countries[0] : firstCountry
    if (userRole.permission.length === 1) {
      const permission = userRole.permission[0]
      if (permission.resource === 'promotion') isPromotionsOnly = true
    }
    if (isPromotionsOnly) {
      const countryPath = `settings-by-country/${countryInfo.id}`
      this.props.history.push(countryPath, {
        country: countryInfo,
        level: 1
      })
    } else this.showStatus(firstCountry)
  }

  render() {
    const { firstCountry, hasOnlyOneCountry, country, userRole, } = this.props
    const { loading, countries } = this.props.country
    if (!loading && hasOnlyOneCountry) {
      if (countries[0].id === firstCountry.id) {
        this.redirectToPromotions(country, userRole, firstCountry)
      }
    }
    return (
      <>
        <div className="page-dashboard">
          <MySnackbar
            open={this.props.error}
            variant={this.props.error ? 'error' : 'default'}
            onClose={this.handleCloseSnackBar}
            message={this.props.message || ''}
          />
          <Header currentPage={`Inicio`} />
          <Sidebar level={0} country={firstCountry} />
          <div className={this.props.classes.content}>
            <DashboardGrid countries={countries} showStatus={this.showStatus} />
          </div>
        </div>
        <CircularLoading isLoading={loading} />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: getLoginToken(state),
    userRole: state.user.role,
    companyId: companyId(state),
    hasOnlyOneCountry: hasOnlyOneCountry(state),
    firstCountry: firstCountry(state),
    country: state.country,
    error: getError(state),
    message: getMessage(state)
  }
}

const mapDispatchToProps = {
  doFetchCountries,
  doClearRequestStatus,
  doFetchCountriesCount,
  doSaveSelectedCountry
}

export default withStyles(dashboardStyles)(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
)
