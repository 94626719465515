import ApiClientSingleton from '../api-client'

const kioskApiInstance = ApiClientSingleton.getKioskApiInstance()

const fetchLogin = async (body) => {
  const uri = '/oauth/token'
  const { data } = await kioskApiInstance.post(uri, body)
  return data
}

const loginUser = async (payload) => {
  const uri = '/api/v1/oauth/login'
  const body = { user_id: `${payload.userId}` }
  const headers = { Authorization: `Bearer ${payload.loginToken}` }
  const { data } = await kioskApiInstance.post(uri, body, { headers })
  return data
}

const fetchUsers = async (payload) => {
  const uri = `/api/v1/companies/${payload.companyId}/users`
  const { data } = await kioskApiInstance.get(uri)
  return data
}

const fetchNewUser = async (user, payload) => {
  const uri = `/api/v1/companies/${payload.company}/roles-scopes`
  const { data } = await kioskApiInstance.get(uri)
  return data
}

const createUser = async (company, user, payload) => {
  const uri = `/api/v1/companies/${company}/users`
  const headers = { Authorization: `Bearer ${user}` }
  const { data } = await kioskApiInstance.post(uri, payload, { headers })
  return data
}

const findEmployeeById = async (payload) => {
  const uri = `/api/v1/companies/${payload.companyId}/employees?user_id=${payload.userId}`
  const { data } = await kioskApiInstance.get(uri)
  return data
}

const updateEmployee = async (payload) => {
  const uri = `/api/v1/companies/${payload.companyId}/employees/${payload.id}`
  const { data } = await kioskApiInstance.put(uri, payload.employee)
  return data
}

const inactiveUser = async (user, payload) => {
  const uri = '/api/v1/user/inactive'
  const headers = { Authorization: `Bearer ${user}` }
  const { data } = await kioskApiInstance.put(uri, payload, { headers })
  return data
}

const requestResetPassword = async (payload) => {
  const uri = '/api/v1/user/edit'
  const { data } = await kioskApiInstance.post(uri, payload)
  return data
}

const validateToken = async (payload) => {
  const uri = '/api/v1/user/find'
  const body = { reset_token: payload.token, email: payload.email }
  const { data } = await kioskApiInstance.post(uri, body)
  return data
}

const confirmUser = async (payload) => {
  const uri = '/api/v1/user/confirm'
  const body = { confirmation_token: payload.token, email: payload.email }
  const { data } = await kioskApiInstance.post(uri, body)
  return data
}

const updatePassword = async (params) => {
  const uri = '/api/v1/user/update_password'
  const body = { email: params.email, user: { password: params.password } }
  const { data } = await kioskApiInstance.post(uri, body)
  return data
}

const checkUserPassword = async (payload) => {
  const uri = '/api/v1/login'
  const { data } = await kioskApiInstance.post(uri, payload)
  return data
}

const updateUserData = async (user, payload) => {
  const uri = '/api/v1/user/update'
  const headers = { Authorization: `Bearer ${user}` }
  const { data } = await kioskApiInstance.post(uri, payload, { headers })
  return data
}

export {
  fetchLogin,
  loginUser,
  fetchUsers,
  fetchNewUser,
  createUser,
  inactiveUser,
  requestResetPassword,
  updatePassword,
  validateToken,
  confirmUser,
  checkUserPassword,
  updateUserData,
  findEmployeeById,
  updateEmployee
}
