// Dependences
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'

import './updateconfig.sass'
import Button from '@material-ui/core/Button'
import { updateConfigStyles } from './updateconfig.styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography/Typography'
import FormControl from '@material-ui/core/FormControl/FormControl'
import Select from '@material-ui/core/Select/Select'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import OutlinedInput from '@material-ui/core/OutlinedInput/OutlinedInput'
import Divider from '@material-ui/core/Divider'
import SelectLocationCheck from '../SelectLocationCheck/SelectLocationCheck'
import Modal from '@material-ui/core/Modal'
import TextField from '@material-ui/core/TextField'

// UI Components

class UpdateConfig extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedLocation: null,
      kiosksIds: [],
      selectedVersion: 'Ninguna',
      selectedHour: 'Ninguna',
      openModal: false,
      password: '',
      showError: false,
      isSendingRequestCheck: false
    }
  }

  onChangeFormControl = (event) => {
    const { value, name } = event.target
    this.setState({
      [name]: value
    })
  }

  onClickUpdateCompany = () => {
    const data = {
      selectedHour: this.state.selectedHour,
      companyId: this.props.companyId,
      user: this.props.user.id
    }
    this.props.updateCompany(data)
  }
  selectedLocation = () => {
    if (!this.state.selectedLocation) {
      return this.props.locations[0]
    } else {
      const selectedLocation = this.props.locations.filter((location) => {
        return location.id === this.state.selectedLocation
      })[0]
      return selectedLocation
    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.userValidate && !prevProps.userValidate) {
      this.sendForcedUpdate()
    }
  }

  openModal = () => {
    this.setState({ openModal: true })
    // this.sendForcedUpdate()
  }

  checkPassword = () => {
    this.setState({
      isSendingRequestCheck: true
    })
    const data = {
      data: { email: this.props.user.email, password: this.state.password }
    }
    this.props.checkPassword(data)
  }

  closeModal = () => {
    this.setState({ openModal: false, password: '', showError: false })
  }

  sendForcedUpdate = () => {
    const data = {
      kioskIds: this.state.kiosksIds,
      content: {
        action: 'update',
        url: this.state.selectedVersion
      },
      companyId: this.props.companyId,
      user: this.props.user.id
    }
    this.props.sendForcedUpdate(data)
    this.closeModal()
  }

  componentDidMount = () => {
    this.setState({ selectedHour: this.props.dailyUpdateTime })
  }

  render() {
    const { versions, classes } = this.props
    const showForcedUpdate = false
    const hoursUpdateAutomatic = []
    const versionsComboBox = []

    for (let index = 0; index < 24; index++) {
      for (let minutes = 0; minutes < 60; minutes += 15) {
        hoursUpdateAutomatic.push(
          <MenuItem
            key={`${index}-${minutes}`}
            value={`${index
              .toString()
              .padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`}
          >
            <em>{`${index
              .toString()
              .padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`}</em>
          </MenuItem>
        )
      }
    }

    versionsComboBox.push(
      <MenuItem key={-1} value="Ninguna">
        <em>Seleccione una opción</em>
      </MenuItem>
    )
    if (versions) {
      versions.map((version, index) => {
        versionsComboBox.push(
          <MenuItem key={index} value={version.url}>
            <em>{version.version}</em>
          </MenuItem>
        )
        return true
      })
    }

    const callbackCheck = (cities) => {
      const kiosksIds = []
      cities.map((city) => {
        city.locations.map((location) => {
          location.kiosks.map((kiosk) => {
            if (kiosk.checked) {
              kiosksIds.push(kiosk.id)
            }
            return true
          })
          return true
        })
        return true
      })
      this.setState({ kiosksIds })
    }
    return (
      <>
        <Paper className={classes.container}>
          <div className={classes.containerGroup}>
            <Typography variant="h6">Actualizacion Automatica</Typography>
            <Typography variant="subtitle1">
              Buscar actualizaciones todos los dias y actualizar a las:
            </Typography>
            <Divider className={classes.separator} />
            <div className="form-group">
              <small className="form-input-subtitle">Actualizar Kiosk</small>
              <FormControl
                variant="filled"
                className={this.props.classes.selectConfig}
              >
                <Select
                  input={<OutlinedInput name="selectedHour" labelWidth={0} />}
                  value={this.state.selectedHour}
                  onChange={(value) => {
                    this.setState({ selectedHour: value.target.value })
                  }}
                >
                  <MenuItem value="Ninguna">
                    <em>Seleccione una hora</em>
                  </MenuItem>
                  {hoursUpdateAutomatic}
                </Select>
              </FormControl>
            </div>
            <div className="button-container">
              <Button
                onClick={this.onClickUpdateCompany}
                color="primary"
                autoFocus
                className={this.props.classes.btnLink}
                fullWidth={false}
              >
                GUARDAR
              </Button>
            </div>
          </div>
        </Paper>
        <br />
        {showForcedUpdate ? (
          <Paper className={classes.container}>
            <div className={classes.containerGroup}>
              <Typography variant="h6">Actualizacion Forzada</Typography>
              <Typography variant="subtitle1">
                Actualizar el kiosko de forma forzada a la version:
              </Typography>
              <Divider className={classes.separator} />
              <div className="form-group">
                <small className="form-input-subtitle">Version</small>
                <FormControl
                  variant="filled"
                  className={this.props.classes.selectConfig}
                >
                  <Select
                    input={
                      <OutlinedInput name="selectedVersion" labelWidth={0} />
                    }
                    value={this.state.selectedVersion}
                    onChange={(value) => {
                      this.setState({ selectedVersion: value.target.value })
                    }}
                  >
                    {versionsComboBox}
                  </Select>
                </FormControl>
                <Typography variant="subtitle1">
                  Actualizar el kiosko de forma forzada a la version:
                </Typography>
                <SelectLocationCheck
                  updateKioskIds={(ids) => {
                    callbackCheck(ids)
                  }}
                  checkedIds={this.state.kiosksIds}
                />
              </div>
              <div className="button-container">
                <Button
                  onClick={() => this.openModal()}
                  color="primary"
                  autoFocus
                  className={this.props.classes.btnLink}
                  fullWidth={false}
                >
                  GUARDAR
                </Button>
              </div>
            </div>
          </Paper>
        ) : (
          ''
        )}

        <Modal
          open={this.state.openModal}
          onClose={() => {
            this.closeModal()
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className="container-modal">
            <h2>Confirmación de usuario</h2>
            <p>Ingresa tu contraseña para confirmar tu identidad.</p>
            <TextField
              label="Contraseña"
              margin="dense"
              className="login-input"
              type="password"
              value={this.state.password}
              onChange={(event) => {
                this.setState({ password: event.target.value })
              }}
            />
            {this.state.showError ? (
              <span>La contraseña ingresada no es correcta</span>
            ) : (
              ''
            )}
            <div className="container-buttons">
              <Button
                onClick={() => this.closeModal()}
                color="primary"
                autoFocus
                className={this.props.classes.btnLink}
                fullWidth={false}
              >
                CANCELAR
              </Button>
              <Button
                onClick={() => this.checkPassword()}
                color="primary"
                autoFocus
                className={this.props.classes.btnLink}
                fullWidth={false}
              >
                ACEPTAR
              </Button>
            </div>
          </div>
        </Modal>
      </>
    )
  }
}

export default withStyles(updateConfigStyles)(UpdateConfig)
