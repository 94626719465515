import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import { doClearRequestStatus } from '../../actions/clearStatus'
import { withStyles } from '@material-ui/core/styles'
import Header from '../../components/header/Header'
import Sidebar from '../../components/sidebar/Sidebar'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { NewUserStyles } from './newuser.styles'
import './newuser.sass'
import {
  doFetchNewUser,
  doCreateUser,
  doFetchEmployee,
  doUpdateEmployee
} from '../../actions/user'
import { getLoginToken } from '../../selectors/user'
import { companyId } from '../../selectors/company'
import MySnackbar from '../../components/MySnackbar/MySnackbar'
import NewUserForm from '../../components/NewUserForm/NewUserForm'
import NewUserList from '../../components/NewUserList/NewUserList'

class NewUser extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        role_id: 0,
        country_code: '+57',
        locations: []
      },
      isUpdating: false,
      roles: [],
      cities: [],
      checkedAll: false
    }
  }

  componentDidMount() {
    this.props.doClearRequestStatus()
    this.retrieveAllData()
  }

  componentDidUpdate(prevProps) {
    if (this.props.employee && this.props.employee !== prevProps.employee) {
      const assignedLocations = []
      this.props.employee.assigned_locations.forEach((location) => {
        assignedLocations.push(location.id)
      })
      this.setState((state) => ({
        user: { ...state.user, locations: assignedLocations }
      }))
      this.state.cities.forEach((city) => {
        city.locations.forEach((theater) => {
          if (assignedLocations.indexOf(theater.id) !== -1)
            theater.checked = true
        })
      })
      this.setState({ cities: this.state.cities })
    }

    if (this.props.success && this.props.success !== prevProps.success) {
      setTimeout(() => {
        this.props.history.push('/users')
      }, 3000)
    }
    if (
      this.props.successEmployee &&
      this.props.successEmployee !== prevProps.successEmployee
    ) {
      this.props.history.push('/users')
    }
  }

  retrieveAllData = () => {
    this.props.doFetchNewUser({
      user: this.props.user,
      data: { company: this.props.companyId }
    })
    setTimeout(() => {
      if (this.props.newUser.endRequest) {
        const cities = this.prepareData(this.props.newUser.endRequest.cities)
        if (this.props.location.state !== undefined) {
          const { user, role } = this.props.location.state
          this.props.doFetchEmployee({
            companyId: this.props.companyId,
            userId: user.id
          })
          this.setState({
            user: {
              first_name: user.first_name,
              last_name: user.last_name,
              email: user.email,
              phone: user.phone,
              role_id: role.id,
              country_code: '+57',
              locations: []
            }
          })
          this.setState({ isUpdating: true })
        }
        this.setState({
          roles: this.props.newUser.endRequest.roles,
          cities: cities
        })
      }
    }, 500)
  }

  prepareData = (cities) => {
    cities.forEach((city) => {
      city.checked = false
      city.locations.forEach((location) => {
        location.checked = false
      })
    })
    return cities
  }

  handleChange = (prop) => (event) => {
    const val = event.target.value
    this.setState((state) => ({ user: { ...state.user, [prop]: val } }))
  }

  handleChecked = (check, cityId, theaterId) => () => {
    if (check === 'all') {
      this.setState({ checkedAll: !this.state.checkedAll })
      this.state.cities.forEach((city) => {
        city.checked = !city.checked
        city.locations.forEach((theater) => {
          theater.checked = !theater.checked
        })
      })
      this.setState({ cities: this.state.cities })
    } else if (check === 'city') {
      this.state.cities.forEach((city) => {
        if (city.id === cityId) {
          city.checked = !city.checked
          city.locations.forEach((theater) => {
            theater.checked = !theater.checked
          })
        }
      })
      this.setState({ cities: this.state.cities })
    } else if (check === 'theater') {
      this.state.cities.forEach((city) => {
        if (city.id === cityId) {
          city.locations.forEach((theater) => {
            if (theater.id === theaterId) theater.checked = !theater.checked
          })
        }
      })
      this.setState({ cities: this.state.cities })
    }
  }

  getScopeLocations = () => {
    const locationsSelected = []
    this.state.cities.forEach((_city) => {
      _city.locations.forEach((_location) => {
        if (_location.checked) locationsSelected.push(_location.id)
      })
    })
    return locationsSelected
  }

  handleSaveUser = () => {
    const scopeLocations = this.getScopeLocations()
    this.setState(
      (state) => ({
        user: { ...state.user, locations: scopeLocations }
      }),
      () =>
        this.props.doCreateUser({
          company: this.props.companyId,
          user: this.props.user,
          data: this.state.user
        })
    )
  }

  handleUpdateEmployee = () => {
    const scopeLocations = this.getScopeLocations()
    this.props.doUpdateEmployee({
      companyId: this.props.companyId,
      id: this.props.employee.id,
      employee: {
        location_ids: scopeLocations,
        role_id: this.state.user.role_id
      }
    })
  }

  handleBackButton = () => {
    this.props.history.push('/users')
  }

  handleCloseSnackBar = () => {
    this.props.doClearRequestStatus()
  }

  render() {
    const invalidForm =
      this.state.user.first_name === '' ||
      this.state.user.last_name === '' ||
      this.state.user.email === '' ||
      this.state.user.phone === '' ||
      this.state.user.role_id === 0
    const openSnackbar = this.props.error || this.props.success
    const links = [
      {
        title: this.props.country ? this.props.country.name : 'Inicio',
        onClick: () => {
          if (this.props.country)
            this.props.history.push('/country-status', {
              country: this.props.country
            })
          else this.props.history.push('/dashboard')
        }
      },
      {
        title: 'Usuarios',
        onClick: () => this.props.history.goBack()
      }
    ]
    return (
      <div className="page-new-user">
        <MySnackbar
          open={openSnackbar}
          variant={
            openSnackbar ? (this.props.error ? 'error' : 'success') : 'default'
          }
          onClose={this.handleCloseSnackBar}
          message={openSnackbar ? this.props.message : ''}
        />
        <Header links={links} currentPage="Agregar Usuario" />
        <Sidebar level={0} />
        <div className={this.props.classes.content}>
          <Grid
            className={this.props.classes.newUserContainer}
            container
            spacing={24}
          >
            <Grid item xs={12} sm={6}>
              <NewUserForm
                isUpdating={this.state.isUpdating}
                classes={this.props.classes}
                user={this.state.user}
                roles={this.state.roles}
                onClick={this.handleChange}
              />
              <Button
                disabled={invalidForm}
                variant="contained"
                className={this.props.classes.button + ' New-user-save-button'}
                onClick={
                  this.state.isUpdating
                    ? this.handleUpdateEmployee
                    : this.handleSaveUser
                }
              >
                {this.state.isUpdating ? 'GUARDAR' : 'Agregar'}
              </Button>
              <Button
                className={'New-user-back-button'}
                onClick={this.handleBackButton}
              >
                Regresar
              </Button>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Paper className={this.props.classes.paper}>
                <NewUserList
                  userLocations={
                    this.state.isUpdating ? this.state.user.locations : []
                  }
                  classes={this.props.classes}
                  cities={this.state.cities}
                  checkedAll={this.state.checkedAll}
                  handleChecked={this.handleChecked}
                />
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: getLoginToken(state),
    companyId: companyId(state),
    newUser: state.user,
    endRequest: state.endRequest,
    error: state.user.error,
    message: state.user.message,
    success: state.user.success,
    country: state.country.selectedCountry,
    employee: state.user.employee,
    successEmployee: state.user.successEmployee
  }
}

const mapDispatchToProps = {
  doFetchNewUser,
  doCreateUser,
  doClearRequestStatus,
  doFetchEmployee,
  doUpdateEmployee
}

export default withRouter(
  withStyles(NewUserStyles)(
    connect(mapStateToProps, mapDispatchToProps)(NewUser)
  )
)
