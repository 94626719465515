import ApiClientSingleton from '../api-client'

const kioskApiInstance = ApiClientSingleton.getKioskApiInstance()

const fetchLocations = async (payload) => {
  const { companyId, countryId, user } = payload
  const uri = `/api/v1/companies/${companyId}/locations-nested?country_id=${countryId}`
  const headers = { Authorization: `Bearer ${user}` }
  const { data } = await kioskApiInstance.get(uri, { headers })
  return data
}
const fetchCountryLocations = async (payload) => {
  const { companyId, countryId, user } = payload
  const uri = `/api/v1/companies/${companyId}/locations?country_id=${countryId}`
  const headers = { Authorization: `Bearer ${user}` }
  const { data } = await kioskApiInstance.get(uri, { headers })
  return data
}

const createLocation = async ({ payload }) => {
  const { params, user } = payload
  const uri = `/api/v1/companies/${params.company_id}/locations`
  const headers = { Authorization: `Bearer ${user}` }
  const response = await kioskApiInstance.post(uri, params, { headers })
  return response
}

const updateLocation = async ({ payload }) => {
  const { params, user } = payload
  const uri = `/api/v1/companies/${params.company_id}/locations/${payload.location_id}`
  const headers = { Authorization: `Bearer ${user}` }
  const { data } = await kioskApiInstance.put(uri, params, { headers })
  return data
}

const fetchStatistics = async (payload) => {
  const { companyId, locationId, days, user } = payload
  const uri = `/api/v1/companies/${companyId}/kiosks/statistics?location_id=${locationId}&days=${days}`
  const headers = { Authorization: `Bearer ${user}` }
  const { data } = await kioskApiInstance.get(uri, { headers })
  return data
}

const kioskByLocation = async (payload) => {
  const { companyId, locationId, user } = payload
  const uri = `/api/v1/companies/${companyId}/locations/${locationId}/kiosks`
  const headers = { Authorization: `Bearer ${user}` }
  const { data } = await kioskApiInstance.get(uri, { headers })
  return data
}

export {
  fetchLocations,
  fetchStatistics,
  createLocation,
  updateLocation,
  kioskByLocation,
  fetchCountryLocations
}
