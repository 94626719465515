import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { doClearRequestStatus } from '../../actions/clearStatus'
import {
  doUpdateUserData,
  doFetchUsers,
  doProfileUpdatePassword,
  doCheckUserPassword
} from '../../actions/user'
import { companyId } from '../../selectors/company'
import {
  getLoginToken,
  getError,
  getSuccess,
  getMessage
} from '../../selectors/user'
// UI components
import {
  Avatar,
  Button,
  Paper,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core'
import MySnackbar from '../../components/MySnackbar/MySnackbar'
import CircularLoading from '../../components/Loading/CircularLoading'
import Header from '../../components/header/Header'
import Sidebar from '../../components/sidebar/Sidebar'

import { userProfileStyles } from './userProfile.style'

class UserProfile extends Component {
  constructor() {
    super()
    this.state = {
      first_name: '',
      last_name: '',
      phone: '',
      filled: false,
      current_password: '',
      new_password: '',
      confirm_password: '',
      passwordError: {
        error: false,
        message: ''
      }
    }
  }

  componentDidMount() {
    this.props.doClearRequestStatus()
    this.props.doFetchUsers({ data: { companyId: this.props.companyId } })
  }

  componentDidUpdate() {
    if (this.props.userData && !this.state.filled) {
      this.setState((state) => ({
        ...state,
        first_name: this.props.userData.first_name,
        last_name: this.props.userData.last_name,
        phone: this.props.userData.phone,
        filled: true
      }))
    }
    if (this.props.isUpdated) {
      this.props.userData.first_name = this.state.first_name
      this.props.userData.last_name = this.state.last_name
      this.props.userData.phone = this.state.phone
    }
    if (this.props.isValidPassword) {
      this.props.doClearRequestStatus()
      const payload = {
        data: {
          email: this.props.userData.email,
          password: this.state.new_password
        }
      }
      this.props.doProfileUpdatePassword(payload)
    }
  }

  render() {
    const {
      classes,
      isLoading,
      userData,
      userId,
      users,
      error,
      success,
      message,
      companyId
    } = this.props
    const openSnackbar = error || success
    const invalidUserDataForm =
      this.state.first_name === '' ||
      this.state.last_name === '' ||
      this.state.phone === ''
    const invalidPasswordForm =
      this.state.current_password === '' ||
      this.state.new_password === '' ||
      this.state.confirm_password === ''

    const getUserInitials = () => {
      const initials = `${userData.first_name.charAt(
        0
      )}${userData.last_name.charAt(0)}`
      return initials.toUpperCase()
    }

    const getUserRole = () => {
      if (users !== undefined) {
        const currentUser = users.find((_user) => _user.id === userId)
        const currentUserCompanies = currentUser ? currentUser.companies : []
        const currentCompany = currentUserCompanies.find(
          (_company) => _company.id === companyId
        )
        return currentCompany ? currentCompany.role.name : ''
      } else return ''
    }

    const handleChange = (event) => {
      const { id, value } = event.target
      this.setState((state) => ({
        ...state,
        [id]: value,
        passwordError: {
          error: false,
          message: ''
        }
      }))
    }

    const handleCloseSnackBar = () => {
      this.props.doClearRequestStatus()
    }

    const onSubmitUserData = () => {
      const payload = {
        data: {
          id: this.props.userId,
          email: this.props.userData.email,
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          phone: this.state.phone
        },
        user: this.props.userLoginToken
      }
      this.props.doUpdateUserData(payload)
    }

    const onSubmitUserPassword = () => {
      const {
        current_password: currentPassword,
        new_password: newPassword,
        confirm_password: confirmPassword
      } = this.state
      if (newPassword.length < 6 || confirmPassword.length < 6) {
        this.setState((state) => ({
          ...state,
          passwordError: {
            error: true,
            message: 'La contraseña debe tener mínimo 6 caracteres'
          }
        }))
      } else if (newPassword !== confirmPassword) {
        this.setState((state) => ({
          ...state,
          passwordError: {
            error: true,
            message: 'Confirmación de contraseña no coincide'
          }
        }))
      } else {
        this.props.doCheckUserPassword({
          data: {
            email: this.props.userData.email,
            password: currentPassword
          }
        })
      }
    }

    return (
      <div className="page-status">
        <MySnackbar
          open={openSnackbar}
          variant={error ? 'error' : 'success'}
          message={message}
          onClose={handleCloseSnackBar}
        />
        <Header currentPage={'Mi perfil'} hasProfile={false} />
        <Sidebar level={0} />
        {isLoading ? (
          <CircularLoading isLoading={isLoading} />
        ) : (
          <div className={classes.content}>
            <Paper elevation={3} className={classes.content_paper}>
              <div className={classes.user_info}>
                <Avatar className={classes.user_avatar}>
                  {getUserInitials()}
                </Avatar>
                <div className={classes.user_data}>
                  <Typography variant="h6">
                    {`${userData.first_name} ${userData.last_name}`}
                  </Typography>
                  <Typography variant="subtitle1">{userData.email}</Typography>
                  <Typography variant="subtitle1">{getUserRole()}</Typography>
                </div>
              </div>
              <div className={classes.user_forms}>
                <div className={classes.data_form}>
                  <Typography variant="h6" className={classes.data_subtitle}>
                    Datos Personales
                  </Typography>
                  <hr className={classes.divider} />
                  <TextField
                    id="first_name"
                    variant="outlined"
                    type="text"
                    label="Nombres"
                    value={this.state.first_name}
                    onChange={handleChange}
                    InputLabelProps={{
                      classes: {
                        root: classes.cssLabel,
                        focused: classes.cssFocused
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline
                      }
                    }}
                    required
                  />
                  <TextField
                    id="last_name"
                    variant="outlined"
                    type="text"
                    label="Apellidos"
                    value={this.state.last_name}
                    onChange={handleChange}
                    className={classes.input_margin}
                    InputLabelProps={{
                      classes: {
                        root: classes.cssLabel,
                        focused: classes.cssFocused
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline
                      }
                    }}
                    required
                  />
                  <TextField
                    id="phone"
                    variant="outlined"
                    type="number"
                    label="Número de teléfono"
                    value={this.state.phone}
                    onChange={handleChange}
                    InputLabelProps={{
                      classes: {
                        root: classes.cssLabel,
                        focused: classes.cssFocused
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline
                      }
                    }}
                    required
                  />
                  <Button
                    className={classes.save_button}
                    onClick={onSubmitUserData}
                    disabled={invalidUserDataForm}
                  >
                    Guardar
                  </Button>
                </div>
                <div className={classes.data_form}>
                  <Typography variant="h6" className={classes.data_subtitle}>
                    Cambio de contraseña
                  </Typography>
                  <hr className={classes.divider} />
                  <TextField
                    id="current_password"
                    variant="outlined"
                    type="password"
                    label="Contraseña actual"
                    value={this.state.current_password}
                    onChange={handleChange}
                    InputLabelProps={{
                      classes: {
                        root: classes.cssLabel,
                        focused: classes.cssFocused
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline
                      }
                    }}
                    required
                  />
                  <TextField
                    id="new_password"
                    variant="outlined"
                    type="password"
                    label="Contraseña nueva"
                    value={this.state.new_password}
                    onChange={handleChange}
                    className={classes.input_margin}
                    InputLabelProps={{
                      classes: {
                        root: classes.cssLabel,
                        focused: classes.cssFocused
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline
                      }
                    }}
                    required
                    error={this.state.passwordError.error}
                    helperText={this.state.passwordError.message}
                  />
                  <TextField
                    id="confirm_password"
                    variant="outlined"
                    type="password"
                    label="Confirmar contraseña"
                    value={this.state.confirm_password}
                    onChange={handleChange}
                    InputLabelProps={{
                      classes: {
                        root: classes.cssLabel,
                        focused: classes.cssFocused
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline
                      }
                    }}
                    required
                    error={this.state.passwordError.error}
                    helperText={this.state.passwordError.message}
                  />
                  <Button
                    className={classes.save_button}
                    onClick={onSubmitUserPassword}
                    disabled={invalidPasswordForm}
                  >
                    Guardar
                  </Button>
                </div>
              </div>
            </Paper>
          </div>
        )}
      </div>
    )
  }
}

UserProfile.propTypes = {
  userLoginToken: PropTypes.string,
  isLoading: PropTypes.bool,
  userData: PropTypes.object,
  userId: PropTypes.number,
  users: PropTypes.arrayOf(PropTypes.object),
  isUpdated: PropTypes.bool,
  isValidPassword: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
  message: PropTypes.string,
  companyId: PropTypes.number,
  doClearRequestStatus: PropTypes.func,
  doUpdateUserData: PropTypes.func,
  doFetchUsers: PropTypes.func,
  doProfileUpdatePassword: PropTypes.func,
  doCheckUserPassword: PropTypes.func
}

const mapStateToProps = (state) => {
  return {
    userLoginToken: getLoginToken(state),
    isLoading: state.user.loading,
    userData: state.user.data,
    userId: state.user.userId,
    users: state.user.users,
    isUpdated: state.user.updated,
    isValidPassword: state.user.check.status,
    error: getError(state),
    success: getSuccess(state),
    message: getMessage(state),
    companyId: companyId(state)
  }
}

const mapDispatchToProps = {
  doClearRequestStatus,
  doUpdateUserData,
  doFetchUsers,
  doProfileUpdatePassword,
  doCheckUserPassword
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(userProfileStyles)(UserProfile))
