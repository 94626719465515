/* eslint-disable camelcase */
const getSuccess = (state) => state.city.success
const getError = (state) => state.city.error
const getMessage = (state) => {
  return state.city.message ? state.city.message : null
}

const citiesForFormats = (cityState, selectedFormat) => {
  const { kiosks_ids } = selectedFormat
  if (cityState.cities === undefined) {
    return []
  }
  const cities = cityState.cities
    .filter((city) => {
      city.locations = city.locations.filter((location) => {
        return location.areas.length > 0
      })
      return city.locations.length > 0
    })
    .map((city) => {
      city.open = false
      let checkedLocationsCount = 0
      city.locations.forEach((location) => {
        location.open = false
        var groupedKiosks = []
        location.areas.forEach((area) => {
          groupedKiosks.push(area.kiosks)
        })
        location.kiosks = groupedKiosks.flat()
        let checkedKiosksCount = 0
        location.kiosks.forEach((kiosk) => {
          kiosk.checked = kiosks_ids.indexOf(kiosk.id) !== -1
          if (kiosk.checked) {
            checkedKiosksCount += 1
          }
        })
        location.checked =
          checkedKiosksCount === location.kiosks.length &&
          checkedKiosksCount > 0
        location.indeterminate =
          checkedKiosksCount !== location.kiosks.length &&
          checkedKiosksCount > 0
        if (location.checked) {
          checkedLocationsCount += 1
        }
      })
      city.checked =
        checkedLocationsCount === city.locations.length &&
        checkedLocationsCount > 0
      city.indeterminate =
        checkedLocationsCount !== city.locations.length &&
        checkedLocationsCount > 0
      return city
    })
  return cities
}

export { getSuccess, getError, getMessage, citiesForFormats }
