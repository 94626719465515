import { call, put } from 'redux-saga/effects'
import {
  doGetAllVersionsSuccess,
  doGetAllVersionsError,
  doSendForcedUpdateSuccess,
  doSendForcedUpdateError
} from '../actions/update'
import { getAllVersions, sendForcedUpdate } from '../api/update'

function* handleGetAllVersions(action) {
  try {
    const result = yield call(getAllVersions, action.payload.data)
    if (result) {
      yield put(doGetAllVersionsSuccess(result))
    } else {
      yield put(doGetAllVersionsError())
    }
  } catch (error) {
    yield put(doGetAllVersionsError(error))
  }
}

function* handleSendForcedUpdate(action) {
  try {
    const result = yield call(sendForcedUpdate, action.payload)
    if (result) {
      yield put(doSendForcedUpdateSuccess(result))
    } else {
      yield put(doSendForcedUpdateError())
    }
  } catch (error) {
    yield put(doSendForcedUpdateError(error))
  }
}

export { handleGetAllVersions, handleSendForcedUpdate }
