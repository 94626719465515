import {
  CREATE_SETTINGS,
  UPDATE_SETTINGS,
  SETTINGS_FETCH,
  CREATE_SETTINGS_SUCCESS,
  UPDATE_SETTINGS_SUCCESS,
  SETTINGS_FETCH_SUCCESS,
  CREATE_SETTINGS_ERROR,
  UPDATE_SETTINGS_ERROR,
  SETTINGS_FETCH_ERROR
} from '../constants/ActionTypes'

export const doCreateSettings = payload => ({ type: CREATE_SETTINGS, payload })
export const doUpdateSettings = payload => ({ type: UPDATE_SETTINGS, payload })
export const doFetchSettings = payload => ({ type: SETTINGS_FETCH, payload })
export const doCreateSettingsSuccess = payload => ({ type: CREATE_SETTINGS_SUCCESS, payload })
export const doUpdateSettingsSuccess = payload => ({ type: UPDATE_SETTINGS_SUCCESS, payload })
export const doFetchSettingsSuccess = payload => ({ type: SETTINGS_FETCH_SUCCESS, payload })
export const doCreateSettingsError = payload => ({ type: CREATE_SETTINGS_ERROR, payload })
export const doUpdateSettingsError = payload => ({ type: UPDATE_SETTINGS_ERROR, payload })
export const doFetchSettingsError = payload => ({ type: SETTINGS_FETCH_ERROR, payload })
