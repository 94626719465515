// Dependences
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'

// UI Components
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Avatar from '@material-ui/core/Avatar'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

// Styles and assets
import './choosecompanycard.sass'
import { ChooseCompanyCardStyles } from './choosecompanycard.styles'

class ChooseCompanyCard extends Component {
  render() {
    const { classes, companyName, avatarSrc, onBtnClick, btnLabel } = this.props
    return (
      <Card className={this.props.classes.companyCard}>
        <CardContent>
          <Avatar alt="Company" src={avatarSrc} className={classes.cardAvatar}>
            {companyName ? companyName.substring(0, 1) : 'C'}
          </Avatar>
          <Typography className={classes.cardTitle}>{companyName}</Typography>
        </CardContent>
        <Divider variant="fullWidth" />
        <Button
          size="small"
          className={classes.cardButton}
          onClick={onBtnClick}
        >
          {btnLabel}
        </Button>
      </Card>
    )
  }
}
export default withStyles(ChooseCompanyCardStyles)(ChooseCompanyCard)
